/*
 * @Author: huangliang
 * @Date: 2020-01-05 15:21:27
 * @LastEditTime : 2020-01-05 17:14:10
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/components/studentfile/basic.js
 * @js files
 */
import React from 'react'
import { Descriptions } from 'antd'
import { GlobalContext } from '../../store/store'
import moment from 'moment'

export default function studentBasic() {
  const store = React.useContext(GlobalContext)
  const file = store.studentFile
  return (
    <Descriptions title="基本信息" bordered>
      <Descriptions.Item label="姓名">{file.name || ''}</Descriptions.Item>
      <Descriptions.Item label="国籍">{file.nation}</Descriptions.Item>
      <Descriptions.Item label="性别">{file.sex}</Descriptions.Item>
      <Descriptions.Item label="证件号码" span={2}>{file.certificate}</Descriptions.Item>
      <Descriptions.Item label="电话号码"> {file.contact}</Descriptions.Item>
      <Descriptions.Item label="身份证地址" span={3}>
        {file.certificateAddress}
      </Descriptions.Item>
      <Descriptions.Item label="报名时间">{file.signupDate ? moment(file.signupDate).format('YYYY-MM-DD') : ''}</Descriptions.Item>
      <Descriptions.Item label="报名点">{file.signupSpot || ''}</Descriptions.Item>
      <Descriptions.Item label="介绍人">{file.introducer || ''}</Descriptions.Item>
      <Descriptions.Item label="班制" span={2}>{file.classId || ''}</Descriptions.Item>
      <Descriptions.Item label="招生来源">{file.signupSource || ''}</Descriptions.Item>
      <Descriptions.Item label="业务类型">{file.businessType || ''}</Descriptions.Item>
      <Descriptions.Item label="报名车型">{file.carType || ''}</Descriptions.Item>
      <Descriptions.Item label="训练场">{file.traningPlace || ''}</Descriptions.Item>
      <Descriptions.Item label="预选教练">{file.preCoach || ''}</Descriptions.Item>
      <Descriptions.Item label="学员分组">{file.studentGroup || ''}</Descriptions.Item>
      <Descriptions.Item label="资料齐全">{file.dataComplete ? '是' : '否' || '未知'}</Descriptions.Item>
      <Descriptions.Item label="备注信息">
      </Descriptions.Item>
    </Descriptions>
  )
}