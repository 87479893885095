/*
 * @Author: huangliang
 * @Date: 2019-03-20 23:45:38
 * @LastEditTime : 2020-01-11 16:39:12
 * @LastEditors  : huangliang
 * @Description: 全局数据 包裹所有React Component
 * @FilePath: /00-pri-driving-web/src/store/store.js
 * @可以输入预定的版权声明、个性签名、空行等
 */
import React from 'react'

export const GlobalContext = React.createContext({})

export function GlobalProvider(props) {
  const [collapsed, changeCollapsed] = React.useState(false)
  const [studentFile, setStudentFile] = React.useState({})
  const [studentFileDrawer, setStudentFileDrawer] = React.useState(false)

  const [imagesList, setImageList] = React.useState([])
  const [avatar, setAvatar] = React.useState('')
  const [breadlist, setBreadList] = React.useState([])
  const [menuSelect, setMenuSelect] = React.useState('')


  const [userAccount, setUserAccount] = React.useState('')
  const [studentGroups, setStudentGroups] = React.useState([])
  const [staffGroups, setStaffGroups] = React.useState([])
  const [traningPlaces, SetTraningPlaces] = React.useState([])
  const [lisences, SetLisences] = React.useState([])
  const [classes, setClasses] = React.useState([])
  const [signupSpots, setSignupSpots] = React.useState([])
  const [coaches, setCoaches] = React.useState([])
  const [introducers, setIntroducers] = React.useState([])
  const [signUpSources, setSgnupSource] = React.useState([])
  const [payTypes, setPayTypes] = React.useState([])

  const [user, setUser] = React.useState({})
  const [roles, setRoles] = React.useState([])
  const [authority, setAuth] = React.useState([])

  const [condition, setCondition] = React.useState({})
  const [examAddress, setExamAddress] = React.useState([])

  const [selectedStudents, setSelectedStudents] = React.useState([])
  return (
    <GlobalContext.Provider
      value={{
        examAddress,
        setExamAddress,
        selectedStudents,
        setSelectedStudents,
        studentFile,
        setStudentFile,
        studentFileDrawer,
        setStudentFileDrawer,
        user,
        setUser,
        condition,
        setCondition,
        introducers,
        setIntroducers,
        signUpSources,
        setSgnupSource,
        payTypes,
        setPayTypes,
        userAccount,
        setUserAccount,
        collapsed,
        changeCollapsed,
        imagesList,
        setImageList,
        avatar,
        setAvatar,
        breadlist,
        setBreadList,
        menuSelect,
        setMenuSelect,
        studentGroups,
        setStudentGroups,
        staffGroups,
        setStaffGroups,
        traningPlaces,
        lisences,
        SetLisences,
        SetTraningPlaces,
        classes,
        setClasses,
        signupSpots,
        setSignupSpots,
        coaches,
        setCoaches,
        roles,
        setRoles,
        authority,
        setAuth
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  )
}
