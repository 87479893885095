import React from 'react'
import Content from '../../components/content/index'
import { Table, Modal, Row, Input, Col, Alert, Button, message } from 'antd'
import { css } from 'aphrodite'
import styles from './style'
import axios from '../../api/axios'
import md5 from 'md5'

export default function Company() {
  const [company, setCompany] = React.useState({})
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const columns = [
    {
      title: '公司名称',
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: '公司Id',
      dataIndex: 'companyId',
      key: 'companyId'
    },
    {
      title: '公司联系人',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '公司联系人电话',
      dataIndex: 'contact',
      key: 'contact'
    }
  ]
  function setCompanyValue(key, value) {
    const newCompany = JSON.parse(JSON.stringify(company))
    newCompany[key] = value
    setCompany(newCompany)
  }

  async function getData() {
    const result = await axios.get('/company/account')
    if (result.data.success) {
      setData(result.data.data)
    } else {
      message.error(result.data.failedMessage || '服务端未知错误')
    }
  }
  async function create() {
    try {
      if (!company.companyName || !company.companyId || !company.contact) {
        throw `请确认信息是否完整`
      }
      const result = await axios.post('/company/account', company)
      if (result.data.success) {
        const newData = [result.data.data].concat(data)
        setData(newData)
        setVisible(false)
      } else {
        throw (result.data.failedMessage || '服务端未知错误')
      }
    } catch (error) {
      message.error(error.message || error)
    }
  }
  React.useEffect(() => {
    getData()
  }, [])
  return (
    <Content>
      <Row>
        <Button onClick={() => setVisible(true)}>新建公司账号</Button>
      </Row>
      <Table dataSource={data} columns={columns} style={{ marginTop: 10 }} />
      <Modal
        title="创建公司信息"
        visible={visible}
        onOk={() => { create() }}
        onCancel={() => { setVisible(false) }}
      >
        <Alert message="公司代码是唯一标志,一旦提交,不可修改" type="info" showIcon />
        <Row style={{ marginTop: 10 }}>
          <Col span={6} className={css(styles.name)}>公司名称 : </Col>
          <Col span={18}><Input placeholder='请输入公司的名称' value={company.companyName} onChange={e => setCompanyValue('companyName', e.target.value)} /></Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={6} className={css(styles.name)}>营业执照号 : </Col>
          <Col span={18}><Input placeholder='请输入公司的营业执照号' value={company.companyId} onChange={e => setCompanyValue('companyId', e.target.value)} /></Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={6} className={css(styles.name)}>公司联系人 : </Col>
          <Col span={18}><Input placeholder='请输入公司紧急联系人' value={company.name} onChange={e => setCompanyValue('name', e.target.value)} /></Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={6} className={css(styles.name)}>联系人电话 : </Col>
          <Col span={18}><Input placeholder='联系人电话也是后期登陆系统的账号' value={company.contact} onChange={e => setCompanyValue('contact', e.target.value)} /></Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={6} className={css(styles.name)}>登陆密码 : </Col>
          <Col span={18}><Input.Password placeholder='该公司管理员密码' value={company.password} onChange={e => setCompanyValue('password', e.target.value)} /></Col>
        </Row>
      </Modal>
    </Content>
  )
}


