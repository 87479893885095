import React from 'react'
import { Alert, Select, Divider, Table, Timeline, Tooltip, Row, Button, Steps, DatePicker, Radio, Drawer, Tag, Modal, Col, Input, message, InputNumber, Popconfirm, Icon } from 'antd'
import { css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import Avatar from './upload'
import moment from 'moment'
import QueryBar from '../../../components/query/index'
import {
  studentsSchema,
  filterOptions,
  carTypes
} from './config'


const { Option } = Select
const { Search } = Input

export default function TrainingPlace() {
  const store = React.useContext(GlobalContext)



  const financeColums = [
    {
      title: '费用类型',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '实缴金额',
      dataIndex: 'paid',
      key: 'paid'
    },
    {
      title: '欠费',
      dataIndex: 'arrears',
      key: 'arrears'
    },
    {
      title: '优惠金额',
      dataIndex: 'offer',
      key: 'offer'
    },
    {
      title: '票号',
      dataIndex: 'bills',
      key: 'bills'

    },
    {
      title: '说明',
      dataIndex: 'comments',
      key: 'comments'
    },

    {
      title: '缴费时间',
      dataIndex: 'payDate',
      key: 'payDate',
      render: data => data ? moment(data).format('YYYY-MM-DD') : ''
    },
    {
      title: '收费人',
      dataIndex: 'creator',
      key: 'creator'
    },

    {
      title: '入账状态',
      dataIndex: 'checked',
      key: 'checked',
      render: data => data ? '已入账' : '未入账'

    },
    // {
    //   title: '退费金额',
    //   dataIndex: 'tuifei',
    //   key: 'tuifei',
    //   render: (data, group) => <InputNumber onChange={value => group.back = value} />
    // },
    // {
    //   title: '操作',
    //   dataIndex: 'operate',
    //   key: 'operate',
    //   render: (data, group) => <Button type="primary" icon="rollback" onClick={() => { addRefund(group) }}>确认退费</Button>
    // }
  ]
  const columns = [
    {
      title: '序号',
      dataIndex: '',
      key: '',
      render: (data, info, index) => `${index + 1}`
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '证件号',
      dataIndex: 'certificate',
      key: 'certificate'
    },
    {
      title: '联系电话',
      dataIndex: 'contact',
      key: 'contact'
    },
    {
      title: '报名地点',
      dataIndex: 'signupSpot',
      key: 'signupSpot'
    },
    {
      title: '报名时间',
      dataIndex: 'signupDate',
      key: 'signupDate',
      render: data => moment(data).format('YYYY-MM-DD')
    },
    {
      title: '换班前班制',
      dataIndex: 'primaryClassId',
      key: 'primaryClassId'
    },
    {
      title: '换班后班制',
      dataIndex: 'classId',
      key: 'classId'
    },
    {
      title: '金额',
      dataIndex: 'amount',
      key: 'amount'
    },

    {
      title: '介绍人',
      dataIndex: 'introducer',
      key: 'introducer'
    },
    {
      title: '换班介绍人',
      dataIndex: 'changeIntroducer',
      key: 'changeIntroducer'
    },
    {
      title: '换班原因',
      dataIndex: 'reason',
      key: 'reason'
    },
    {
      title: '办理人',
      dataIndex: 'operator',
      key: 'operator'
    },
    {
      title: '换班审核状态',
      dataIndex: 'checked',
      align: 'center',
      key: 'checked',
      fixed: 'right',
      render: checked => checked ? <Tag color="#87d068">已审核</Tag> : <Tag color="#2db7f5">审核中</Tag>
    }
  ]


  /**
   * React State
   */
  // const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const [dropoutVisible, setDroupoutVisible] = React.useState(false)
  const [student, setStudent] = React.useState({})
  const [refund, setRefund] = React.useState([])
  const [refundReason, setRefundReason] = React.useState('')
  const [bills, setBills] = React.useState('')
  const [refundDate, setRefundDate] = React.useState(moment())
  const [introducer, setIntroducer] = React.useState('')
  const [payType, setPayType] = React.useState('')
  const [primaryCarType, setPriCarType] = React.useState('')
  const [changeType, setChangeType] = React.useState('补费')

  const [amount, setAmount] = React.useState('')
  const [classId, setClassId] = React.useState('')
  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
  }, [])





  async function fetchData() {
    try {
      console.log(`筛选条件`)
      console.log(store.condition)
      let condition = ''
      if (store.condition.status) condition = `${condition}status:"${store.condition.status}",`
      if (store.condition.signupDate) condition = `${condition}signupDateStart:"${store.condition.signupDate[0]}",signupDateEnd:"${store.condition.signupDate[1]}",`
      if (store.condition.filter) condition = `${condition}filter:"${store.condition.filter}",`
      if (store.condition.classId) condition = `${condition}classId:"${store.condition.classId}",`
      if (store.condition.signupSpot) condition = `${condition}signupSpot:"${store.condition.signupSpot}",`
      if (store.condition.introducer) condition = `${condition}introducer:"${store.condition.introducer}",`
      console.log(condition)
      setLoading(true)
      const data = await axios.get('/student/class/change')
      const tableData = data.data.data
      setData(tableData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[ERROR]:')
      console.log(error)
    }

  }


  async function findStudentByCertificate(id) {
    const result = await axios.get(`/student/certificate/${id}`)
    console.log(result)
    if (result.data.success) {
      if (result.data.data.dropout) {
        message.warn(`该学生已经办理退学、无法进行换班处理`)
        return
      }
      if (result.data.data.fiveTimeNoPass) {
        message.warn(`该学生已经五次不过、无法进行换班处理`)
        return
      }
      setStudent(result.data.data)
      setDroupoutVisible(true)
    }
  }

  function changeRefundData(key, value, $index) {
    const refundData = [...refund]
    refundData[$index][key] = value
    setRefund(refundData)
  }

  async function createRefundStudent() {
    if (student.classId === classId) {
      message.warn(`更换的班制不能和原先的班制一样`)
      return
    }
    
    const charges = {
      type: '换班费用',
      payType,
      cost: changeType === '补费' ? amount : -amount,
      paid: changeType === '补费' ? amount : -amount,
      offer: 0,
      offerReason: '',
      arrears: 0,
      bills,
      comments: '',
      payDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      checked: false
    }
    const result = await axios.post('/student/class/change', {
      _id: student._id,
      charges,
      record: {
        classId,
        primaryCarType,
        reason: refundReason,
        date: refundDate,
        introducer,
      }
    })
    if (result.data.success) {
      message.success(`学员${student.name}换班成功`)
      setVisible(!visible)
      const tableData = [...data]
      tableData.push(result.data.data)
      setData(tableData)
    } else {
      message.error(result.data.failedMessage || '更换班级失败 请联系管理员')
    }

  }
  const financeType = (
    <Select  value={changeType} onChange={value => { setChangeType(value) }} style={{ width: 80 }}>
      <Option value="补费">补费</Option>
      <Option value="退费">退费</Option>
    </Select>
  )
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Row style={{ marginTop: 0 }}>
          <Button icon='user' type='primary' onClick={() => { setVisible(!visible) }}>新增</Button>
        </Row>
        <Table
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          scroll={{ x: 'max-content' }}
          loading={loading}
          rowKey={record => record._id}
          dataSource={data}
          columns={columns}
          style={{
            marginTop: 10,
          }} />
      </Row>
      <Drawer
        title="换班管理"
        width="70%"
        closable={true}
        visible={visible}
        onClose={() => { setVisible(!visible) }}
      >
        {
          dropoutVisible ? <Row></Row> :
            <Row>
              <Col span={3} className={css(styles.modalRowText)}>学生证件号</Col>
              <Col span={10}>
                {/* <Input placeholder="请输入学生证件号码" className={css(styles.modalInput)} value={certificate} onChange={e => { setCer(e.target.value) }} /> */}
                <Search
                  defaultValue="510521198803277777"
                  className={css(styles.modalInput)}
                  placeholder="请输入学生证件号码"
                  enterButton={<Icon type="search" />}
                  onSearch={value => findStudentByCertificate(value)}
                />
              </Col>
            </Row>
        }
        {
          dropoutVisible ?
            <Row>
              <Divider>学生信息</Divider>
              <Row className={css(styles.modalRow)}>
                <Col span={3} className={css(styles.modalRowText)}>姓名</Col>
                <Col span={3} className={css(styles.modalRowTextValue)}>{student.name || ''}</Col>
                <Col span={3} className={css(styles.modalRowText)}>手机号</Col>
                <Col span={3} className={css(styles.modalRowTextValue)}>{student.contact || ''}</Col>
                <Col span={3} className={css(styles.modalRowText)}>报名时间</Col>
                <Col span={3} className={css(styles.modalRowTextValue)}>{student.signupDate ? moment(student.signupDate).format('YYYY-MM-DD') : ''}</Col>
                <Col span={3} className={css(styles.modalRowText)}>报名点</Col>
                <Col span={3} className={css(styles.modalRowTextValue)}>{student.signupSpot || ''}</Col>
              </Row>
              <Row className={css(styles.modalRow)}>
                <Col span={3} className={css(styles.modalRowText)}>班制</Col>
                <Col span={3} className={css(styles.modalRowTextValue)}>{student.classId || ''}</Col>
                <Col span={3} className={css(styles.modalRowText)}>介绍人</Col>
                <Col span={3} className={css(styles.modalRowTextValue)}>{student.introducer || ''}</Col>
              </Row>
              <Divider>财务信息</Divider>
              <Table
                className="ant-table td"
                // loading={loading}
                pagination={false}
                rowKey="_id"
                size="small"
                columns={columns.map(item => {
                  const fun = () => ({ style: { whiteSpace: 'nowrap' } });
                  item.onHeaderCell = fun;
                  item.onCell = fun;
                  return item;
                })}
                scroll={{ x: 'max-content' }}
                dataSource={student.charges || []}
                columns={financeColums}
                style={{
                  marginTop: 10
                }}
              />
              <Divider>换班具体信息</Divider>
              <Row>
                <Col span={3} className={css(styles.modalRowText)}>付款方式</Col>
                <Col span={5}>
                  <Select value={payType} onChange={value => setPayType(value)} className={css(styles.modalInput)}>
                    {store.payTypes.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)}
                  </Select>
                </Col>
                <Col span={3} className={css(styles.modalRowText)}>金额</Col>
                <Col span={5}>
                  <Input addonAfter={financeType} className={css(styles.modalInput)} value={amount} onChange={e => setAmount(e.target.value)} />
                  {/* <DatePicker value={refundDate} onChange={value => { setRefundDate(value) }} className={css(styles.modalInput)} */}
                </Col>
                <Col span={3} className={css(styles.modalRowText)}>票号</Col>
                <Col span={5}>
                  <Input placeholder="请输入票号" className={css(styles.modalInput)} value={bills} onChange={e => { setBills(e.target.value) }} />
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col span={3} className={css(styles.modalRowText)}>车型</Col>
                <Col span={5}>
                  <Select value={primaryCarType} className={css(styles.modalInput)} onChange={value => { setPriCarType(value) }}>
                    {carTypes.map(item => <Option value={item} key={item}>{item}</Option>)}
                  </Select>
                  {/* <Input placeholder="请输入票号" className={css(styles.modalInput)} value={bills} onChange={e => { setBills(e.target.value) }} /> */}
                </Col>
                <Col span={3} className={css(styles.modalRowText)}>班制</Col>
                <Col span={5}>
                  <Select className={css(styles.modalInput)} value={classId} onChange={value => { setClassId(value) }}>
                    {store.classes.map(item => <Option key={item.name} value={item.name}>{item.name}</Option>)}
                  </Select>
                </Col>
                <Col span={3} className={css(styles.modalRowText)}>换班原因</Col>
                <Col span={5}>
                  <Input placeholder="请输入换班原因" className={css(styles.modalInput)} value={refundReason} onChange={e => { setRefundReason(e.target.value) }} />
                </Col>
              </Row>
              <Row style={{ marginTop: 10, paddingBottom: 40 }}>
                <Col span={3} className={css(styles.modalRowText)}>换班介绍人</Col>
                <Col span={5}>
                  <Select className={css(styles.modalInput)} value={introducer} onChange={value => { setIntroducer(value) }}>
                    {
                      store.introducers.map(item => <Option key={item.name}>{item.name}</Option>)
                    }
                  </Select>
                </Col>
                <Col span={3} className={css(styles.modalRowText)}>换班时间</Col>
                <Col span={5}>
                  <DatePicker value={refundDate} onChange={value => { setRefundDate(value) }} className={css(styles.modalInput)}></DatePicker>
                </Col>
              </Row>
            </Row>
            : <Row></Row>
        }
        <div className={css(styles.drawerFooter)}>
          <Button icon="cancel" className={css(styles.marginRight8)} onClick={() => { setVisible(!visible) }}>取消</Button>
          <Button icon="save" onClick={() => { createRefundStudent() }} type="primary">确认</Button>
        </div>
      </Drawer>



    </Row>
  )
}


