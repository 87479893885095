import React from 'react'
import { Menu, DatePicker, Select, Statistic, Table, Row, Button, Pagination, Radio, Tooltip, Icon, Tag, Modal, Col, Input, message, InputNumber, Popconfirm, Divider, Alert } from 'antd'
import { css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import QueryBar from '../../../components/query/index'

import {
  financeParams,
  studentsSchema,
  getGraphqlData,
  payTypes,
  filterOptions
} from './config'
import moment from 'moment'

const { Option } = Select

export default function settings() {
  /**
   * Define Table Colums
   */
  const financeColums = [
    {
      title: '费用类型',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '应缴金额',
      dataIndex: 'cost',
      key: 'cost'
    },
    {
      title: '实缴金额',
      dataIndex: 'paid',
      key: 'paid'
    },
    {
      title: '欠费',
      dataIndex: 'arrears',
      key: 'arrears',
    },
    {
      title: '优惠金额',
      dataIndex: 'offer',
      key: 'offer'
    },
    {
      title: '票号',
      dataIndex: 'bills',
      key: 'bills'

    },
    {
      title: '说明',
      dataIndex: 'comments',
      key: 'comments'
    },

    {
      title: '缴费时间',
      dataIndex: 'payDate',
      key: 'payDate',
      render: data => data ? moment(data).format('YYYY-MM-DD') : ''
    },
    {
      title: '收费人',
      dataIndex: 'creator',
      key: 'creator'
    },

    {
      title: '入账状态',
      dataIndex: 'checked',
      key: 'checked',
      render: data => data ? <Tag color="#f50">已入账</Tag> : <Tag color="#2db7f5">未入账</Tag>
    },
    {
      title: '操作',
      dataIndex: 'checked',
      // key: 'checked',
      render: (data, group) => data ? <div />
        :
        <Popconfirm title="确认删除?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleData(group._id) }}>
          <span className={css(styles.operate)} >删除</span>
        </Popconfirm>
    }
  ]
  const columns = [
    // {
    //   title: '序号',
    //   dataIndex: '',
    //   key: '',
    //   // fixed: 'left',
    //   render: (data, info, index) => `${index + 1}`
    // },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left'
      // fixed: 'left'
    },
    {
      title: '证件号',
      dataIndex: 'certificate',
      key: 'certificate',
      fixed: 'left'
      // fixed: 'left',
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex'
    },
    {
      title: '联系电话',
      dataIndex: 'contact',
      key: 'contact'
    },
    {
      title: '报名地点',
      dataIndex: 'signupSpot',
      key: 'signupSpot'
    },
    {
      title: '班制',
      dataIndex: 'classId',
      key: 'classId'
    },
    {
      title: '报名时间',
      dataIndex: 'signupDate',
      key: 'signupDate',
      render: data => moment(data).format('YYYY-MM-DD')
    },
    {
      title: '介绍人',
      dataIndex: 'introducer',
      key: 'introducer'
    },
    {
      title: '欠费',
      dataIndex: 'arrears',
      key: 'arrears',
      render: (data, info) => info.cost - info.paid > 0 ? <Tag color="#f50">{info.cost - info.paid}</Tag> : <Tag color="#2db7f5">已缴清</Tag>

      // render: data => data <= 0 ? <Tag color="#2db7f5">已缴清</Tag> : <Tag color="#f50">{data}</Tag>
    },
    {
      title: '班级原价',
      dataIndex: 'classPrimaryCost',
      key: 'classPrimaryCost',
      render: data => {
        return (
          <Tooltip title="只是该班级设置的各项费用的总价格,具体价格以应缴为准">
            <span>{data}</span>
          </Tooltip>
        )
      }
    },
    {
      title: '应缴',
      dataIndex: 'due',
      key: 'due'
    },
    {
      title: '优惠',
      dataIndex: 'offer',
      key: 'offer'
    },
    {
      title: '实缴',
      dataIndex: 'paid',
      key: 'paid'
    },

    {
      title: '支出',
      dataIndex: 'expenses',
      key: 'expenses',
      render: data => <span style={{ color: 'red' }}>{Math.abs(data)}</span>
    },

    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      fixed: 'right',
      render: (data, group) => {
        return (
          <Row>
            {/* <span className={css(styles.operate)} onClick={() => { openUpdateModal(group, '新增收入记录') }}>新增收入</span> */}
            <span className={css(styles.operate)} onClick={() => { openUpdateModal(group, '新增支出记录') }}>新增支出</span>
            <span className={css(styles.operate)} onClick={() => { openUpdateModal(group, '补缴欠费') }}>补缴欠费</span>
            <span className={css(styles.operate)} onClick={() => { SetCharges(group.charges); setFinVisible(true) }}>详细</span>
          </Row>
        )
      }
    }
  ]
  const initPageSize = window.localStorage.getItem('pageSize') ? parseInt(window.localStorage.getItem('pageSize')) : 10


  /**
   * React State
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [financeVisible, setFinVisible] = React.useState(false)
  const [financeOptions, setFinanceOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [modalName, setModalName] = React.useState('学员收支管理')
  const [statistics, setStatistics] = React.useState([0, 0, 0])
  const [page, setPage] = React.useState(1)
  const [limit, setLimit] = React.useState(initPageSize)
  const [total, setTotal] = React.useState(0)
  /**
   * 业务相关数据的定义
   */
  const [editId, setEditId] = React.useState('')
  const [name, setName] = React.useState('')
  const [certificate, setCertificate] = React.useState('')
  const [payType, setPayType] = React.useState(payTypes[0])
  const [type, setType] = React.useState('')
  const [bills, setBills] = React.useState('')
  const [offer, setOffer] = React.useState('')
  const [cost, setCost] = React.useState('')
  const [payDate, setPayDate] = React.useState(moment())
  const [charges, SetCharges] = React.useState([])
  const [comments, setComments] = React.useState('')

  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
    getBaseData()
    getFinanceTotal()
  }, [])



  /**
   * 获取分组数据
   */

  async function fetchData(page, size) {
    try {
      setLoading(true)
      setData([])
      // console.log(`筛选条件`)
      // console.log(store.condition)
      // let condition = ''
      // if (store.condition.status) condition = `${condition}status:"${store.condition.status}",`
      // if (store.condition.signupDate) condition = `${condition}signupDateStart:"${store.condition.signupDate[0]}",signupDateEnd:"${store.condition.signupDate[1]}",`
      // if (store.condition.filter) condition = `${condition}filter:"${store.condition.filter}",`
      // if (store.condition.classId) condition = `${condition}classId:"${store.condition.classId}",`
      // if (store.condition.signupSpot) condition = `${condition}signupSpot:"${store.condition.signupSpot}",`
      // if (store.condition.introducer) condition = `${condition}introducer:"${store.condition.introducer}",`
      // console.log(condition)


      const condition = {}
      if (store.condition.status) {
        condition.status = store.condition.status
      }
      if (store.condition.signupDate) {
        condition.signupDateStart = store.condition.signupDate[0]
        condition.signupDateEnd = store.condition.signupDate[1]
      }
      if (store.condition.filter) {
        condition.filter = store.condition.filter
      }
      if (store.condition.classId) {
        condition.classId = store.condition.classId
      }
      if (store.condition.signupSpot) {
        condition.signupSpot = store.condition.signupSpot
      }
      if (store.condition.introducer) {
        condition.introducer = store.condition.introducer
      }

      if (store.condition.clean) {
        condition.clean = store.condition.clean
      }
      if (store.condition.fullPaid) {
        condition.fullPaid = store.condition.fullPaid
      }

      condition.isFirstCheck = true
      condition.page = page || page || 1
      condition.limit = size || limit


      const data = await axios.post('/student/query', condition)

      // const data = await axios.post('/graphql', studentsSchema(condition))
      // console.log(data.data.data)
      const tableData = data.data.data
      /**
       * 处理数据中的charges数组 计算出班级原价、已经缴费金额、优惠金额、欠费金额
       */
      tableData.map(item => {
        let classPrimaryCost = 0
        let offer = 0
        let due = 0
        let arrears = 0
        let paid = 0
        let expenses = 0
        item.charges.map(charge => {
          classPrimaryCost = classPrimaryCost + charge.cost //班级原价等于原来价格 cost
          offer = offer + charge.offer //优惠等于offer相加

          /**
           * 如果paid === 负数  则为支出
           */
          const paidMoney = charge.paid < 0 ? 0 : charge.paid
          /**
           * 应缴费金额 = 原价cost - 优惠offer
           */
          // due = due + paidMoney + charge.arrears + charge.offer
          due = due + charge.cost - charge.offer

          /**
           * 如果缴费类型为补缴欠费则可以减去欠费
           */
          if (charge.type === '补缴欠费') {
            arrears = arrears + charge.arrears - charge.paid

          } else {
            arrears = arrears + charge.arrears
          }

          paid = paid + paidMoney
          const expense = charge.paid < 0 ? -charge.paid : 0
          expenses = expenses + expense
        })
        item.classPrimaryCost = classPrimaryCost
        item.offer = offer
        item.due = due
        item.expenses = expenses
        item.arrears = arrears
        item.paid = paid
      })


      let totalCost = 0
      let totalPaid = 0
      let totalArrs = 0
      tableData.map(item => {
        totalCost = totalCost + item.due
        totalPaid = totalPaid + item.paid
        totalArrs = totalArrs + item.arrears
      })

      setData(tableData)
      setTotal(data.data.total)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[ERROR]:')
      console.log(error)
    }
  }

  async function getFinanceTotal() {
    const condition = {}
    if (store.condition.status) {
      condition.status = store.condition.status
    }
    if (store.condition.signupDate) {
      condition.signupDateStart = store.condition.signupDate[0]
      condition.signupDateEnd = store.condition.signupDate[1]
    }
    if (store.condition.filter) {
      condition.filter = store.condition.filter
    }
    if (store.condition.classId) {
      condition.classId = store.condition.classId
    }
    if (store.condition.signupSpot) {
      condition.signupSpot = store.condition.signupSpot
    }
    if (store.condition.introducer) {
      condition.introducer = store.condition.introducer
    }
    if (store.condition.dataComplete) {
      if (store.condition.dataComplete === 'true') {
        condition.dataComplete = true
      }
      if (store.condition.dataComplete === 'false') {
        condition.dataComplete = false
      }
    }

    condition.isFirstCheck = true
    const finance = await axios.post('/student/finacne/query/total', condition)
    console.log(finance.data)
    if (finance.data.success) {
      const totalCost = finance.data.data.total
      const totalPaid = finance.data.data.paid
      const totalArrs = totalCost - totalPaid
      setStatistics([totalCost, totalPaid, totalArrs])
    }
  }

  async function getBaseData() {
    const data = await axios.get('/finance/config')
    setFinanceOptions(data.data.data)
  }

  /**
   * 删除分组数据
   */
  async function deleteSingleData(_id) {
    setLoading(true)
    const result = await axios.delete(`/student/finance/${_id}`)
    setLoading(false)
    if (result.data.success) {
      // const newData = data.filter(item => item._id !== _id)
      // setData(newData)
      const newCharges = [...charges].filter(item => item._id != _id)
      SetCharges(newCharges)
      message.success(`删除数据成功`)
    } else {
      message.error(`删除数据失败`)
    }
  }

  /**
   * 更新数据
   */
  async function updateSingleData() {
    try {
      const result = await axios.post(`/student/finance/update`, {
        _id: editId,
        charge: {
          type,
          payType,
          cost: 0,
          paid: modalName === '新增支出记录' ? -cost : cost,
          offer: offer || 0,
          offerReason: '',
          arrears: 0,
          bills,
          comments,
          payDate
        }
      })
      if (result.data.success) {
        fetchData()
        setVisible(!visible)
      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(`提交数据异常,请联系管理员`)
    }
  }
  /**
   * 打开更新数据对话框
   */
  async function openUpdateModal(info, modalName) {
    if (modalName === '补缴欠费' && info.arrears <= 0) {
      message.warn(`该学员没有需要补缴的欠款`)
      return
    } else {
      setModalName(modalName)
      setName(info.name)
      setCertificate(info.certificate)
      setVisible(!visible)
      setEditId(info._id)
      if (modalName === '补缴欠费') {
        setType('补缴欠费')
      }
    }
  }
  /**
   * Render View
   */
  return (
    <Row>
      <Row style={{
        marginLeft: store.collapsed ? 95 : 215,
        padding: '20px',
        marginTop: '15px',
        backgroundColor: 'white'
      }} className={css(styles.content)}>

        <Row style={{ marginTop: 20 }}>
          <Col span={8} className={css(styles.item)}>
            <Statistic title="应缴金额" value={statistics[0]} />
          </Col>
          <Col span={8} className={css(styles.item)} style={{
            borderLeft: '1px solid #e8e8e8',
            borderRight: '1px solid #e8e8e8'
          }}>
            <Statistic title="实缴金额" value={statistics[1]} />
          </Col>
          <Col span={8} className={css(styles.item)}>
            <Statistic title="欠费金额" value={statistics[2]} />
          </Col>
        </Row>

      </Row>
      <Row style={{
        marginLeft: store.collapsed ? 95 : 215,
      }} className={css(styles.content)}>
        <Row className={css(styles.main)}>
          <Row>
            <Col span={22}><QueryBar config={filterOptions} /></Col>
            <Col span={2}>
              <Button onClick={() => { fetchData(); getFinanceTotal() }} type="primary" icon="search" style={{ marginTop: 10, float: 'left', width: 100 }} >查询</Button>
            </Col>
            <Divider />
          </Row>
          <Alert type='info' message={`数据库中共查询到 ${total} 条符合条件的数据`}></Alert>
          <Table
            className="ant-table td"
            loading={loading}
            pagination={false}
            rowKey="_id"
            columns={columns.map(item => {
              const fun = () => ({ style: { whiteSpace: 'nowrap' } });
              item.onHeaderCell = fun;
              item.onCell = fun;
              return item;
            })}
            scroll={{ x: 'max-content' }}
            dataSource={data}
            columns={columns}
            style={{
              marginTop: 10
            }}
          />
          <Pagination style={{ marginTop: 10 }}
            showSizeChanger
            showQuickJumper
            pageSizeOptions={['10', '15', '20', '50']}
            pageSize={limit}
            current={page}
            total={total}
            onShowSizeChange={(page, pageSize) => {
              window.localStorage.setItem('pageSize', pageSize)
              setPage(page)
              setLimit(pageSize)
              fetchData(page, pageSize)
            }}
            onChange={(page, pageSize) => {
              setPage(page)
              setLimit(pageSize)
              fetchData(page, pageSize)
            }}
          />
        </Row>

        <Modal
          title="费用明细"
          visible={financeVisible}
          okText="完成"
          onOk={() => setFinVisible(!financeVisible)}
          onCancel={() => setFinVisible(!financeVisible)}
          width="60%"
        >
          <Table
            className="ant-table td"
            loading={loading}
            rowKey="_id"
            size="small"
            columns={columns.map(item => {
              const fun = () => ({ style: { whiteSpace: 'nowrap' } });
              item.onHeaderCell = fun;
              item.onCell = fun;
              return item;
            })}
            scroll={{ x: 'max-content' }}
            dataSource={charges}
            columns={financeColums}
            style={{
              marginTop: 10
            }}
          />
        </Modal>

        <Modal
          title={modalName}
          visible={visible}
          okText="完成"
          cancelText="取消"
          width="50%"
          onOk={() => { updateSingleData() }}
          onCancel={() => { setVisible(!visible) }}
        >
          <Row className={css(styles.modalRow)}>
            <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>学员姓名</Col>
            <Col span={9}><Input placeholder="学员姓名" disabled={true} className={css(styles.modalInput)} value={name} onChange={e => { setName(e.target.value) }} /></Col>
            <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>证件号码</Col>
            <Col span={9}><Input placeholder="证件号码" disabled={true} className={css(styles.modalInput)} value={certificate} onChange={e => { setCertificate(e.target.value) }} /></Col>
          </Row>
          <Row className={css(styles.modalRow)}>
            <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>付款类型</Col>
            <Col span={9}>
              <Select value={payType} onChange={value => setPayType(value)} className={css(styles.modalInput)}>
                {store.payTypes.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)}
              </Select>
            </Col>
            <Col span={3} className={css(styles.modalRowText)}>款项名称</Col>
            <Col span={9}>
              <Select value={type} disabled={type === '补缴欠费' ? true : false} onChange={value => { setType(value) }} className={css(styles.modalInput)}>
                {financeOptions.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)}
              </Select>
            </Col>
          </Row>
          <Row className={css(styles.modalRow)}>
            <Col span={3} className={css(styles.modalRowText)}>票号</Col>
            <Col span={9}>
              <Input placeholder="输入票号" className={css(styles.modalInput)} value={bills} onChange={e => { setBills(e.target.value) }} />
            </Col>
            {/* <Col span={3} className={css(styles.modalRowText)}>优惠</Col>
            <Col span={9}>
              <InputNumber placeholder="请输入优惠价格" className={css(styles.modalInput)} value={offer} onChange={value => { setOffer(value) }} />
            </Col> */}
          </Row>
          <Row className={css(styles.modalRow)}>
            <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>金额</Col>
            <Col span={9}>
              <InputNumber placeholder="请输入金额" className={css(styles.modalInput)} value={cost} onChange={value => { setCost(value) }} />
            </Col>
            <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>缴费日期</Col>
            <Col span={9}>
              <DatePicker className={css(styles.modalInput)} placeholder="请输入报名时间" value={payDate} onChange={value => { setPayDate(value) }} />
            </Col>
          </Row>
          <Row className={css(styles.modalRow)}>
            <Col span={3} className={css(styles.modalRowText)}>备注信息</Col>
            <Col span={21}>
              <Input placeholder="请输入备注信息" className={css(styles.modalInput)} value={comments} onChange={e => { setComments(e.target.value) }} />
            </Col>
          </Row>
        </Modal>
      </Row>
    </Row>
  )
}


