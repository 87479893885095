import React from 'react'
import Content from '../../../components/content/index'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'

import { Row, message, Col } from 'antd'
import { Chart, Geom, Axis, Tooltip, Legend, Coord, Label } from 'bizcharts';
import Customelabelpie from './sex'
export default function () {
  const store = React.useContext(GlobalContext)

  const [data, setData] = React.useState([])
  const [columns, setColums] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  async function fetchData() {
    try {
      setLoading(true)
      const result = await axios.post('/statistics/age')
      console.log(result.data)
      setData(result.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message || '服务器端未知错误')
    }

  }

  const total = data.reduce((total, item) => total + item.value, 0)
  console.log(total)
  React.useEffect(() => {
    fetchData()
  }, [])
  return (
    <Content>
      <Row>
        <Col span={12}>
          <Chart
            data={data}
            forceFit
          >
            <Coord type="theta" />
            <Tooltip
              //triggerOn='none'
              showTitle={true}
            // itemTpl={}
            />

            <Legend
              position="right"
            // offsetY={-window.innerHeight / 2 + 200}
            />
            <Geom
              type="intervalStack"
              position="value"
              color="name"
            >
              <Label
                content="percent"
                formatter={(val, item) => {
                  return item.point.name + ': ' + ((item.point.value / total) * 100).toFixed(2) + '%';
                }}
              />
            </Geom>
          </Chart>
        </Col>
        <Col span={12}>
          <Customelabelpie />
        </Col>
      </Row>
    </Content>
  )
}