/*
 * @Author: huangliang
 * @Date: 2019-11-27 16:03:37
 * @LastEditTime : 2020-01-11 17:17:27
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/Table/index.js
 * @js files
 */
import React from 'react'
import { css } from 'aphrodite'
import styles from './style'
import { Row, Tabs, Alert } from 'antd'
import { GlobalContext } from '../../store/store'
import { Chart, Geom, Axis, Tooltip, Legend, Coord } from 'bizcharts';
import axios from '../../api/axios'

// 定义度量
const cols = {
  sold: { alias: '报名点' },
  genre: { alias: '报名人数' }
}


const { TabPane } = Tabs

export default function Tables() {
  const store = React.useContext(GlobalContext)
  const [tableData, setData] = React.useState([])
  const chartWidth = store.collapsed ? document.body.clientWidth - 110 : document.body.clientWidth - 250
  const chart = (
    <Chart width={chartWidth * 1} height={400} data={tableData} scale={cols} style={{
      marginLeft: chartWidth * 0
    }}>
      <Axis name="genre" title />
      <Axis name="sold" title />
      <Legend position="top" dy={-20} />
      <Tooltip />
      <Geom type="interval" position="genre*sold" color="genre" />
    </Chart>
  )


  async function fetchData(value, type = 'student') {
    if (type === 'student') {
      const result = await axios.post('/statistics/count', {
        type: value ? value : 'signupSpot'
      })
      console.log(result.data)
      setData(result.data.data)
    } else if (type === 'indend') {
      const result = await axios.post('/statistics/indend/count', {
        type: value ? value : 'signupSpot'
      })
      console.log(result.data)
      setData(result.data.data)
    } else {

    }
  }


  async function getDataByTab(value) {
    const student = ['signupSpot', 'signupSource', 'introducer','class']
    const indend = ['indendSource', 'indendIntroducer']
    if (student.includes(value)) {
      fetchData(value, 'student')
    } else if (indend.includes(value)) {
      fetchData(value, 'indend')
    }
  }
  React.useEffect(() => {
    fetchData()
  }, [])
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Tabs defaultActiveKey="1"  onChange={value => getDataByTab(value)}>
          <TabPane tab="报名点统计" key="signupSpot">
            {chart}
          </TabPane>
          <TabPane tab="招生来源统计(报名学员)" key="signupSource">
            {chart}
          </TabPane>
          <TabPane tab="介绍人(报名学员)" key="introducer">
            {chart}
          </TabPane>
          <TabPane tab="招生来源统计(意向学员)" key="indendSource">
            {chart}
          </TabPane>
          <TabPane tab="介绍人(意向学员)" key="indendIntroducer">
            {chart}
          </TabPane>
          <TabPane tab="班制" key="class">
            {chart}
          </TabPane>
        </Tabs>
      </Row>
    </Row>
  )
}