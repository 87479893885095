/*
 * @Author: huangliang
 * @Date: 2020-01-21 14:19:13
 * @LastEditTime: 2020-02-25 14:49:48
 * @LastEditors: huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/Grade/GradeImport/upload.js
 * @js files
 */
import React from 'react'
import { Upload, message, Button, Icon } from 'antd'
import { origin } from '../../../api/axios'

export default function gradeImportButton() {
  const [fileList, setFileList] = React.useState([])
  function onChange(info) {
    setFileList([...info.fileList])
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} 成绩导入成功、后台正在处理中、请稍后查询结果`);
      setFileList([])
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} 成绩导入失败`);
    }
  }
  return (
    <Upload
      name='file'
      fileList={fileList}
      action={`${origin}/upload/grade`}
      headers={{ authorization: 'Bearer ' + localStorage.getItem('accesstoken'), }}
      onChange={info => { onChange(info) }}>
      <Button type="primary" style={{ marginLeft: 10 }}>
        <Icon type="upload" />导入成绩
      </Button>
    </Upload>
  )
}