/*
 * @Author: huangliang
 * @Date: 2019-10-20 18:22:53
 * @LastEditTime : 2019-12-25 15:11:13
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/Student/Signup/config.js
 * @可以输入预定的版权声明、个性签名、空行等
 */
import axios from '../../../api/axios'
/**
 * 查询报名点
 */
export const querySignupSopt = () => new Promise(async (resolve, reject) => {
  try {
    const query = {
      query: `query{
        signupSpots{
          name
        }
       }`
    }
    const result = await axios.post('/graphql', query)
    return resolve(result.data)
  } catch (error) {
    return reject(`获取报名点基础数据失败`)
  }
})

/**
 * 查询学员分组
 */
export const queryStudentGroups = () => new Promise(async (resolve, reject) => {
  try {
    const query = {
      query: `query{
        studentGroups{
          name
        }
       }`
    }
    const result = await axios.post('/graphql', query)
    return resolve(result.data)
  } catch (error) {
    return reject(`获取学员分组基础数据失败`)
  }
})



/**
 * 查询班制信息
 */
export const queryClasses = () => new Promise(async (resolve, reject) => {
  try {
    const query = {
      query: `query{
        classes{
          _id
          name
          costs{
            type
            cost
          }
        }
      }`
    }
    const result = await axios.post('/graphql', query)
    return resolve(result.data)
  } catch (error) {
    return reject(`获取学员分组基础数据失败`)
  }
})

/**
 * 查询班制信息
 */
export const queryTrainingPlaces = () => new Promise(async (resolve, reject) => {
  try {
    const query = {
      query: `query{
        traningPlaces{
          name
        }
      }`
    }
    const result = await axios.post('/graphql', query)
    return resolve(result.data)
  } catch (error) {
    return reject(`获取学员分组基础数据失败`)
  }
})



export const filterOptions = {
  // status: true,
  signUpDate: true,
  filter: true,
  introducer: true,
  class: true,
  signUpSpot: true
}


/**
 * 财务款项配置查询schema
 */
export const studentsSchema = (condition) => {
  return ({
    query: `query{
      students(uncheck:false,${condition}){
        _id
        name,
        nation,
        sex,
        certificateType,
        certificate,
        birth,
        certificateValid,
        contact,
        code,
        avatar,
        certificateAddress,
        liveAddress,
        signupSource,
        signupDate,
        signupSpot,
        introducer,
        businessType,
        businessType,
        primaryCarType,
        primaryCertificate,
        studentGroup,
        carType,
        classId,
        traningPlace,
        preCoach,
        dataComplete,
        comments,
        charges{
          type
          payType
          cost
          paid
          offer
          offerReason
          arrears
          bills
          comments
        }
        createAt
        lastModified
      }
    }`
  })
}


/**
 * 驾驶车辆类型
 */
export const carTypes = ['C1', 'C2', 'A1', 'A2', 'A3', 'B1', 'B2', 'C3', 'C4', 'C5', 'D', 'E', 'F', 'M', 'N', 'P']


/**
 * 性别
 */
export const sexOptions = ['男', '女']


/**
 * 证件类型
 */
export const certificateOptions = ['身份证', '护照', '军官证', '其他']


/**
 * 招生来源
 */
export const signUpSourcesOption = ['自主报名', '介绍人', '老学员介绍', '宣传彩页', '代理', '电视广告', '公交广告', '网络推广', '其他']

/**
 * 业务类型
 */
export const businessOptions = ['初领', '增驾', '其他']

/**
 * 费用类型
 */
export const costTypes = ['培训费', '考试费', '代收费', '科目一考试费', '科目二考试费', '科目三考试费', '科目一补考费', '科目二补考费', '科目三补考费', '保险费']

/**
 * 支付类型
 */
export const payTypes = ['现金', '刷卡', '支付宝', '微信', '转账', '其他', '综合']