import React from 'react'
import Content from '../../../components/content/index'
import { Table, Row, Button, Drawer, DatePicker, Select, Col, message, Tag } from 'antd'
import { css } from 'aphrodite'
import styles from './style'
import TreeSelected from './select'
import { GlobalContext } from '../../../store/store'
import moment from 'moment'
import Axios from '../../../api/axios'
import QueryBar from '../../../components/query/index'


const Option = Select.Option

export default function () {
  const store = React.useContext(GlobalContext)
  const columns = [
    {
      title: '学生姓名',
      dataIndex: 'studentName',
      key: 'studentName'
    },
    {
      title: '报名日期',
      dataIndex: 'signupDate',
      key: 'signupDate',
      render: data => data ? moment(data).format('YYYY-MM-DD') : ''
    },
    {
      title: '身份证',
      dataIndex: 'studentCertificate',
      key: 'studentCertificate'
    },
    {
      title: '电话',
      dataIndex: 'studentContact',
      key: 'studentContact'
    },
    {
      title: '班制',
      dataIndex: 'classId',
      key: 'classId',
    },
    {
      title: '考试费',
      dataIndex: 'kaoshifei',
      key: 'kaoshifei',
      render: data => data ? <Tag color="#108ee9">驾校缴纳</Tag> : <Tag color="#2db7f5">学员缴纳</Tag>

    },
    {
      title: '补考费',
      dataIndex: 'bukaofei',
      key: 'bukaofei',
      render: data => data ? <Tag color="#108ee9">驾校缴纳</Tag> : <Tag color="#2db7f5">学员缴纳</Tag>

    },
    {
      title: '考试日期',
      dataIndex: 'date',
      key: 'date',
      render: data => data ? moment(data).format('YYYY-MM-DD') : ''
    },
    {
      title: '考场地点',
      dataIndex: 'examAddress',
      key: 'examAddress',
    },
    {
      title: '报考科目',
      dataIndex: 'subject',
      key: 'subject',
      render: data => {
        switch (data) {
          case 'subjectOne': return '科目一'
          case 'subjectTwo': return '科目二'
          case 'subjectThree': return '科目三'
          case 'subjectFour': return '科目四'
        }
      }
    },
    {
      title: '预约状态',
      dataIndex: 'reserveStatus',
      key: 'reserveStatus',
      render: data => {
        switch (data) {
          case 0: return <Tag color="#2db7f5">待预约</Tag>
          case 1: return <Tag color="#108ee9">车管所预约成功</Tag>
          case 2: return <Tag>车管所预约失败</Tag>
          case 3: return <Tag color="#108ee9">已经约考</Tag>
          case 4: return <Tag color="#f50">已经取消</Tag>
        }
      }
    },
    {
      title: '科目二教练',
      dataIndex: 'subjectTwoCoach',
      key: 'subjectTwoCoach',
      render: (data, info) => {
        return info.allCoach || data
      }
    },
    {
      title: '科目三教练',
      dataIndex: 'subjectTwoCoach',
      key: 'subjectTwoCoach',
      render: (data, info) => {
        return info.allCoach || data
      }
    },
    {
      title: '申报人',
      dataIndex: 'staffName',
      key: 'staffName'
    },
    {
      title: '提交时间',
      dataIndex: 'createAt',
      key: 'createAt',
      render: data => moment(data).format('YYYY-MM-DD HH:mm')
    }
  ]


  async function reserveExam() {
    try {
      if (store.selectedStudents.length <= 0) {
        throw `请先选择需要预约的学生`
      }
      if (!examAddress) {
        throw `请选择考生的考试地点`
      }
      const result = await Axios.post('/exam/student', {
        ids: store.selectedStudents,
        date: moment(date).format('YYYY-MM-DD'),
        subject,
        examAddress
      })
      if (result.data.success) {
        message.success(`学科报考申请成功!`)
        const data = result.data.data.concat([...dataSource])
        setData(data)
        setVisible(!visible)
      } else {
        message.error(`学科报考申请失败: ${result.data.failedMessage || '服务器端未知错误'}`)
      }
    } catch (error) {
      message.error(error.message || error)
    }
  }


  async function queryExam() {
    const condition = store.condition
    if (condition.datePicker) {
      condition.startTime = moment(condition.datePicker[0]).format('YYYY-MM-DD')
      condition.endTime = moment(condition.datePicker[1]).format('YYYY-MM-DD')
    }
    condition.reserveStatus = [3,2]
    const result = await Axios.post('/exam/student/query', condition)
    if (result.data.success) {
      setData(result.data.data)
    } else {
      message.error(`报考信息查询失败 : ${result.data.failedMessage || '服务器端未知错误'}`)
    }
  }

  async function setReserveStauts(key, value) {
    setLoading(true)
    const result = await Axios.put('/exam/student', {
      ids: selectedRow,
      [key]: value
    })
    if (result.data.success) {
      message.success(`设置学员考试报考状态成功!`)
      const data = [...dataSource].map(item => {
        if (selectedRow.includes(item._id)) {
          item[key] = value
        }
        return item
      })
      setData(data)
      setSelectedRow([])
    } else {
      message.error(`报考信息查询失败 : ${result.data.failedMessage || '服务器端未知错误'}`)
    }
    setLoading(false)

  }

  const [visible, setVisible] = React.useState(false)
  const [date, setDate] = React.useState(moment())
  const [subject, setSubject] = React.useState('subjectOne')
  const [dataSource, setData] = React.useState([])
  const [selectedRow, setSelectedRow] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [examAddress, setExamAddress] = React.useState('')
  const [addressOptions, setAddress] = React.useState([])
  React.useEffect(() => {
    queryExam()
  }, [])

  React.useEffect(() => {
    setAddOption(subject)
    setExamAddress('')
  }, [subject, store.examAddress])

  function setAddOption(subject) {
    const addresses = store.examAddress.filter(item => item.dictionaryType === `${subject}Address`)
    setAddress(addresses)
  }
  const rowSelection = {
    selectedRowKeys: selectedRow,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRowKeys)
    }
  }
  return (
    <Content>
      <Row className={css(styles.main)}>
        <Col span={22}><QueryBar config={{ datePicker: true, subject: true, class: true, filter: true, subjectTwoCoach: true ,subjectThreeCoach:true }} /></Col>
        <Col span={2}>
          <Button onClick={() => { queryExam() }} type="primary" icon="search" style={{ marginTop: 10, float: 'left', width: 100 }} >查询</Button>
        </Col>
      </Row>
      <Row>
      <Button type='primary' icon='setting'  onClick={() => {
          setReserveStauts('reserveStatus', 1)
        }}>约考成功</Button>
        <Button type='primary' icon='setting' className={css(styles.buttonSpace)} onClick={() => {
          setReserveStauts('reserveStatus', 2)
        }}>约考失败</Button>
        <Button type='danger' icon='setting' className={css(styles.buttonSpace)} onClick={() => {
          setReserveStauts('reserveStatus', 4)
        }}>取消考试</Button>
      </Row>
      <Table rowKey='_id'

        columns={columns.map(item => {
          const fun = () => ({ style: { whiteSpace: 'nowrap' } });
          item.onHeaderCell = fun;
          item.onCell = fun;
          return item;
        })}
        scroll={{ x: 'max-content' }}
        rowSelection={rowSelection} columns={columns} style={{ marginTop: 10 }} dataSource={dataSource} loading={loading}></Table>
      <Drawer
        title="考试预约"
        visible={visible}
        onClose={() => { setVisible(!visible) }}
        closable={true}
        width="75%"
      >
        <Row>
          <Row>
            <Col span={2} className={css(styles.modalRowText)}>考试时间</Col>
            <Col span={6} className={css(styles.modalInput)} ><DatePicker value={date} onChange={e => { setDate(e) }} /></Col>
            <Col span={2} className={css(styles.modalRowText)} >考试科目</Col>
            <Col span={6}>
              <Select value={subject} onChange={e => { setSubject(e) }} className={css(styles.modalInput)} >
                <Option key='subjectOne'>科目一</Option>
                <Option key='subjectTwo'>科目二</Option>
                <Option key='subjectThree'>科目三</Option>
                <Option key='subjectFour'>科目四</Option>
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)} >考试地点</Col>
            <Col span={6}>
              <Select value={examAddress} onChange={e => { setExamAddress(e) }} className={css(styles.modalInput)} >
                {
                  addressOptions.map(item => {
                    return (
                      <Option key={item.name}>{item.name}</Option>
                    )
                  })
                }
              </Select>
            </Col>
          </Row>
          <Row>
            <TreeSelected />
          </Row>
        </Row>
        <div className={css(styles.drawerFooter)}>
          <Button icon="close" className={css(styles.marginRight8)} onClick={() => { setVisible(!visible) }}>取消</Button>
          <Button icon="check" onClick={() => { reserveExam() }} type="primary">预约考试</Button>
        </div>
      </Drawer>
    </Content>
  )
}