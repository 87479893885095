/*
 * @Author: huangliang
 * @Date: 2019-10-20 16:55:52
 * @LastEditTime: 2020-02-23 14:21:24
 * @LastEditors: huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/School/Class/config.js
 * @js files
 */
/**
 * 财务款项配置查询schema
 */
export const classesParams = {
  query: `query{
    classes{
      _id
      name
      type
      enable
      fatestLisence
      carType
      transferType
      subjectTwoCarType
      subjectThreeCarType
      classGroup
      onlineVisible
      stupickcoach
      sort
      reservingTime
      describe
      costs{
        type
        cost
      }
      createAt
      lastModified
    }
  }`
}

/**
 * 报名车型配置
 */
export const carTypes = ['C1','C2','B1','B2','A1','A2','A3','C3','C4','C5','D','E','F','M','N','P',
'从业资格-货运','从业资格-客运','从业资格-客货','从业资格-出租车','从业资格-网约车','从业资格-危险品','从业资格-危险品押运员',
'从业资格-爆炸品','从业资格-爆炸品押运员','从业资格-危+押','从业资格-爆+押',
'继续教育-客运','继续教育-货运','继续教育-客货','继续教育-出租车','继续教育-危险品','继续教育-危险品客危','继续教育-危险品货危','继续教育-危险品客货危']


export const transferTypes = ['班车接送','教练接送','自行前往']

export const studentCartypes = ['一人一车','两人一车','三人一车','四人一车','多人一车']

export const costTypes = ['培训费','考试费','代收费','科目一考试费','科目二考试费','科目三考试费','科目一补考费','科目二补考费','科目三补考费','保险费']

export const initCosts = [{
  type : '培训费',
  cost : 0
}]