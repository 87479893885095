/*
 * @Author: huangliang
 * @Date: 2019-10-20 16:55:52
 * @LastEditTime : 2020-01-05 16:46:38
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/Finance/StudentFinance/config.js
 */
/**
 * 财务款项配置查询schema
 */

import axios from '../../../api/axios'

export const getGraphqlData = schema => new Promise(async (resolve, reject) => {
  try {
    const result = await axios.post('/graphql', schema)
    return resolve(result.data)
  } catch (error) {
    return reject(`获取报名点基础数据失败`)
  }
})



export const filterOptions = {
  // status: true,
  signUpDate: true,
  filter: true,
  introducer: true,
  class: true,
  signUpSpot: true,
  clean: true,
  fullPaid:true
}
export const financeParams = {
  query: `query{
    financeConfigs{
      name
      amount
    }
  }`
}



/**
 * 财务款项配置查询schema
 */
export const studentsSchema = condition => { 
  return ({
    query: `query{
      students(uncheck:true,${condition}){
        _id
        name,
        nation,
        sex,
        certificateType,
        certificate,
        birth,
        certificateValid,
        contact,
        code,
        avatar,
        
        certificateAddress,
        liveAddress,
        signupSource,
        signupDate,
        signupSpot,
        introducer,
        businessType,
        businessType,
        primaryCarType,
        primaryCertificate,
        studentGroup,
        carType,
        classId,
        traningPlace,
        preCoach,
        dataComplete,
        comments,
        charges{
          type
          payType
          cost
          paid
          offer
          offerReason
          arrears
          bills
          payDate
          creator
          checked
          comments
        }
        createAt
        lastModified
      }
    }`
  })
}



//微信第一，支付宝第二，拉卡拉第三，卡友第四，现金第五
export const payTypes = ['微信', '支付宝', '拉卡拉', '卡友', '现金']
