import React from 'react'
import Content from '../../../components/content/index'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import QueryBar from '../../../components/query/index'
import moment from 'moment'
import { Row, message, Col, Button, Divider, Radio } from 'antd'
import { Chart, Geom, Axis, Tooltip, Legend, Coord } from 'bizcharts';

export default function () {
  const store = React.useContext(GlobalContext)

  const [data, setData] = React.useState([])
  const [columns, setColums] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  async function fetchData(payDate) {
    try {
      const condition = {}
      if (payDate) {
        condition.payDate = {
          startTime: payDate.startDate,
          endTime: payDate.endDate
        }
      } else {
        if (store.condition.payDate) {
          condition.payDate = {
            startTime: moment(store.condition.payDate[0]).format('YYYY-MM-DD 00:00:00'),
            endTime: moment(store.condition.payDate[1]).format('YYYY-MM-DD 23:59:59')
          }
        } else {
          condition.payDate = {
            startTime: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
            endTime: moment().endOf('month').format('YYYY-MM-DD 23:59:59')
          }
        }
      }
      console.log(condition)
      setLoading(true)
      const result = await axios.post('/student/finacne/date/total', condition)
      console.log(result.data)
      setData(result.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message || '服务器端未知错误')
    }

  }


  React.useEffect(() => {
    fetchData()
  }, [])
  const chartWidth = store.collapsed ? document.body.clientWidth - 110 : document.body.clientWidth - 250
  // 定义度量
  const cols = {
    genre: { alias: '指标' },
    sold: { alias: '值' }
  }
  return (
    <Content>
      <Row>
        <Col span={22}><QueryBar config={{
          // signUpDate: true,
          payDate: true,
          // filter: true,
          // introducer: true,
          // class: true,
          // signUpSpot: true,
          // clean: true,
          // fullPaid: true
        }} /></Col>
        <Col span={2}>
          <Button onClick={() => { fetchData() }} type="primary" icon="search" style={{ marginTop: 10, float: 'left', width: 100 }} >查询</Button>
        </Col>
      </Row>
      <Divider></Divider>

      <Row>
        <Col span={3} style={{ lineHeight: '32px', paddingLeft: 12 }}>按日期时间段查询:</Col>
        <Col span={19}>
          <Radio.Group defaultValue="a" buttonStyle="solid" onChange={e => {
            console.log(e)
            let payDate = ''

            if (e.target.value === 'a') {
              payDate = {
                startDate: moment().subtract('days', 1).startOf('days').format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment().subtract('days', 1).endOf('days').format('YYYY-MM-DD HH:mm:ss')
              }
            } else if (e.target.value === 'b') {
              payDate = {
                startDate: moment().startOf("weeks").format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment().endOf("weeks").format("YYYY-MM-DD HH:mm:ss")
              }
            } else if (e.target.value === 'c') {
              payDate = {
                startDate: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
                endDate: moment().endOf("month").format("YYYY-MM-DD HH:mm:ss")
              }
            } else if (e.target.value === 'd') {
              payDate = {
                startDate: moment().startOf('quarter').format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment().endOf('quarter').format('YYYY-MM-DD HH:mm:ss')
              }
            } else if (e.target.value === 'e') {
              payDate = {
                startDate: moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment().endOf('year').format('YYYY-MM-DD HH:mm:ss')
              }
            }
            fetchData(payDate)
          }}>
            <Radio.Button value="a">昨天</Radio.Button>
            <Radio.Button value="b">本周</Radio.Button>
            <Radio.Button value="c">本月</Radio.Button>
            <Radio.Button value="d">本季度</Radio.Button>
            <Radio.Button value="e">本年</Radio.Button>
          </Radio.Group>
        </Col>
        <Col span={2}>
          {/* <Button onClick={() => { fetchData() }} type="primary" icon="search" style={{ marginTop: 10, float: 'left', width: 100 }} >查询</Button> */}
        </Col>
      </Row>
      <Chart width={chartWidth * 1} height={400} data={data} scale={cols} style={{
        marginLeft: chartWidth * 0,
        marginTop: 20
      }}>
        <Axis name="genre" title />
        <Axis name="sold" title />
        <Legend position="top" dy={-20} />
        <Tooltip />
        <Geom type="interval" position="genre*sold" color="genre" />
      </Chart>
    </Content >
  )
}