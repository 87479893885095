/*
 * @Author: huangliang
 * @Date: 2019-03-20 23:45:38
 * @LastEditTime: 2020-02-25 17:34:16
 * @LastEditors: huangliang
 * @Description: 主路由文件
 * @FilePath: /00-pri-driving-web/src/index.js
 * @可以输入预定的版权声明、个性签名、空行等
 */
// import './theme/theme.less'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { Router, Route } from 'react-router-dom'
import history from './system/history'
import { ConfigProvider } from 'antd'
import { GlobalProvider } from './store/store'
import zhCN from 'antd/es/locale-provider/zh_CN'
import locale from './locale'
/**
 * 通用components
 */
import Menu from './components/menu/menu'
import Header from './components/header/header'

import Login from './pages/Login/index'

import StudentManage from './pages/Student/Signup/index'
import IndendStudent from './pages/Student/Indend/index'
import NoPass from './pages/Student/FiveNoPass/index'
import DroupOut from './pages/Student/Dropout/index'
import ChangeClass from './pages/Student/ChangeClass/index'

import Student from './pages/Student/Student/index'
import StudentFinance from './pages/Finance/StudentFinance/index'
import TrainingPlace from './pages/School/TrainingPlace'
import FinanceCheck from './pages/Finance/FinanceCheck/index'
import SignupSpot from './pages/School/SignupSpot/index'
import Class from './pages/School/Class/index'
import StaffManager from './pages/HR/Staff'
import FinanceConfig from './pages/Finance/Finconfig'
import Settings from './pages/System/Dictionary'
import Tables from './pages/Table/index'
import Role from './pages/HR/Role/index'
import User from './pages/User/Config/index'
import StudentFile from './components/studentfile/index'
import CarManager from './pages/Car/CarManager/index'
import GradeImport from './pages/Grade/GradeImport/index'
import CarDivider from './pages/Car/CarDivider/index'
import Schedule from './pages/Schedule/index'
import StudentImport from './pages/Student/StudentImport/index'
import Company from './pages/Company/index'
import ExamReserve from './pages/Exam/Reserve/index'
import ExamReserved from './pages/Exam/Reserved/index'
import ExamRecord from './pages/Exam/Record/index'

import CarDividerThree from './pages/Car/CarDividerThree/index'
import CarDividerd from './pages/Car/CarDividerd/index'

import ExamPublish from './pages/Exam/Publish/index'
import ExamQuery from './pages/Exam/Query/index'
import SignupTable from './pages/Table/SignupTable/index'
import SignupSource from './pages/Table/SignuoSource/index'
import Excise from './pages/Table/Excise/index'
import introducer from './pages/Table/Introducers/index'
import Finish from './pages/Table/Finsh/index'
import Coach from './pages/Table/Coach/index'
import Age from './pages/Table/Age/index'
import FinanceCount from './pages/Finance/FinaceCount/index'
import Work from './pages/Work/index'
locale()

function App() {
  return (
    <Router history={history}>
      <Menu />
      <Header />
      <StudentFile />
      <Route path='/dashboard/index' component={Work} />
      <Route path="/finance/bill" component={StudentFinance} />
      <Route path="/dashboard/signuptable" component={SignupTable} />
      <Route path="/student/indend" component={IndendStudent} />
      <Route path="/student/query" component={Student} />
      <Route path="/student/nopass" component={NoPass} />
      <Route path="/student/import" component={StudentImport} />
      <Route path="/student/dropout" component={DroupOut} />
      <Route path="/student/changeclass" component={ChangeClass} />
      <Route path="/training" component={TrainingPlace} />
      <Route path="/staff" component={StaffManager} />
      <Route path="/finsetting" component={FinanceConfig} />
      <Route path="/finance/checked" component={FinanceCheck} />
      <Route path="/dictionary" component={Settings} />
      <Route path="/signupspot" component={SignupSpot} />
      <Route path="/class" component={Class} />
      <Route path="/signup" component={StudentManage} />
      <Route path="/dashboard/signup" component={Tables} />
      <Route path="/dashboard/signusource" component={SignupSource} />
      <Route path="/dashboard/excise" component={Excise} />
      <Route path="/dashboard/introducer" component={introducer} />
      <Route path="/dashboard/studentfinish" component={Finish} />
      <Route path="/dashboard/coach" component={Coach} />
      <Route path="/dashboard/age" component={Age} />
      <Route path="/finance/count" component={FinanceCount} />
      {/* <Route path="/finance/count" component={FinanceCount} /> */}

      <Route path="/car/divider/coach" component={Coach} />

      <Route path="/hr/role" component={Role} />
      <Route path="/login" component={Login} />
      <Route path="/user" component={User} />
      <Route path="/car/manager" component={CarManager} />
      <Route path="/car/divide" component={CarDivider} />

      <Route path="/car/dividethree" component={CarDividerThree} />
      <Route path="/car/divided" component={CarDividerd} />

      <Route path="/grade/import" component={GradeImport} />
      <Route path="/schedule" component={Schedule} />
      <Route path="/company" component={Company} />
      <Route path="/exam/reserve" component={ExamReserve} />
      <Route path="/exam/reserved" component={ExamReserved} />
      <Route path="/exam/record" component={ExamRecord} />

      <Route path="/exam/publish" component={ExamPublish} />
      <Route path="/exam/query" component={ExamQuery} />

    </Router>
  )
}

ReactDOM.render(
  <GlobalProvider>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </GlobalProvider>
  , document.getElementById('root'))

serviceWorker.unregister()
