/*
 * @Author: huangliang
 * @Date: 2019-11-17 18:52:16
 * @LastEditTime : 2020-01-06 21:58:54
 * @LastEditors  : huangliang
 * @Description: 正则表达式文件
 * @FilePath: /00-pri-driving-web/src/tools/test.js
 * @js files
 */


/**
 * 身份证正则提示
 */ 
export const certificateId = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/

/**
 * 手机号码正则提示
 */
export const phoneNumber = /^1(3|4|5|6|7|8|9)\d{9}$/


// console.log(`检测手机号码`)
// console.log(phoneNumber.test('19112341234'))