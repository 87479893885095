/*
 * @Author: huangliang
 * @Date: 2019-12-05 21:11:07
 * @LastEditTime: 2019-12-07 13:40:42
 * @LastEditors: huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/HR/Role/test.js
 * @js files
 */
import { Tree } from 'antd';
import React from 'react'
import treeData from './treedata'
import { GlobalContext } from '../../../store/store'


const { TreeNode } = Tree

export default function Authority() {
  const store = React.useContext(GlobalContext)
  const [expandedKeys, setExpand] = React.useState([])
  // const [selectedKeys, setSelectKeys] = React.useState([])
  // const [checkedKeys, setCheckedKeys] = React.useState([])
  return (
    <Tree
      checkable
      onExpand={expandedKeys => setExpand(expandedKeys)}
      expandedKeys={expandedKeys}
      autoExpandParent={true}
      onCheck={value => store.setAuth(value)}
      checkedKeys={store.authority}
      onSelect={value => { store.setAuth(value) }}
      selectedKeys={store.authority}
    >
      {
        treeData.map(item => {
          if (item.children) {
            return (
              <TreeNode key={item.key} title={item.title} >
                {
                  item.children.map(each => {
                    if (each.children) {
                      return (
                        <TreeNode key={each.key} title={each.title} >
                          {
                            each.children.map(item => {
                              return <TreeNode key={item.key} title={item.title} />
                            })
                          }
                        </TreeNode>
                      )
                    } else {
                      return <TreeNode key={each.key} title={each.title} />
                    }
                  })
                }
              </TreeNode>
            )
          } else {
            return <TreeNode key={item.key} title={item.title} />
          }
        })
      }
    </Tree>
  )
}
