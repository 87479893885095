import React from 'react'
import { Menu, Select, PageHeader, Table, Row, Button, Switch, Radio, Icon, Tag, Modal, Col, Input, message, InputNumber, Popconfirm } from 'antd'
import { StyleSheet, css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import { signupSpotParams } from './config'
import { phoneNumber } from '../../../tools/verify'
import { initAllBaseData } from '../../../store/init/init'

const { Option } = Select

export default function settings() {
  const columns = [
    {
      title: '序号',
      dataIndex: 'test',
      key: 'test',
      render: (a, b, c) => c + 1
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'code'
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      render: data => <Row style={{
        // height : 54.32
      }}><Tag color="geekblue">{data == 1 ? '校区' : '报名点'}</Tag></Row>
    },

    {
      title: '联系方式',
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: '学员分教练',
      dataIndex: 'stupickcoach',
      key: 'stupickcoach',
      render: data => <span>{data ? '是' : '否'}</span>
    },


    {
      title: '坐标',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: '地址',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '票据抬头',
      dataIndex: 'billsup',
      key: 'billsup',
    },
    {
      title: '票号前缀',
      dataIndex: 'billsprefix',
      key: 'billsprefix',
    },
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      sorter: (a, b) => a.sort > b.sort
    },
    {
      title: '状态',
      dataIndex: 'enable',
      key: 'enable',
      render: data => <Row> <Switch checkedChildren="启用" unCheckedChildren="未启用" checked={data} onChange={() => { }} /></Row>
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      // fixed: 'right',
      render: (data, group) => {
        return (
          <Row>
            <span className={css(styles.operate)} onClick={() => { openUpdateModal(group) }}>编辑</span>
            <Popconfirm title="确认删除?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleData(group._id) }}>
              <span className={css(styles.operate)}>删除</span>
            </Popconfirm>
          </Row>
        )
      }
    }
  ]



  /**
   * React State
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  /**
   * [ModalType]:1.add 2.update
   */
  const [modalType, setModalType] = React.useState(1)

  /**
   * 业务相关数据的定义
   */
  const [editId, setEditId] = React.useState('')
  // const [code, setCode] = React.useState('')

  const [name, setName] = React.useState('')
  const [type, setType] = React.useState(1)
  const [contact, setContact] = React.useState('')
  const [stupickcoach, setStupickCoa] = React.useState(true)
  const [location, setLocation] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [billsup, setBillsUp] = React.useState('')
  const [billsprefix, setBillsPrefix] = React.useState('')
  const [sort, setSort] = React.useState('')
  const [enable, setEnable] = React.useState(true)



  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
  }, [])

  /**
   * 增加一条数据
   */
  async function createSingleData() {
    try {
      if (!name) { throw `请输入报名点名称` }
      if (contact !== '' && !phoneNumber.test(contact)) { throw `请输入合法有效的电话号码` }
      const result = await axios.post(`/school/signupspot`, {
        name,
        type,
        contact,
        stupickcoach,
        location,
        address,
        billsup,
        billsprefix,
        sort,
        enable
      })
      if (result.data.success) {
        const newData = result.data.data
        data.push(newData)
        setData(data)
        setVisible(!visible)
        initAllBaseData(store)
      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      console.log(error)
      message.error(error)
    }
  }

  /**
   * 获取分组数据
   */

  async function fetchData() {
    try {
      setLoading(true)
      setData([])
      const data = await axios.get('/school/signupspot')
      const tableData = data.data.data
      setData(tableData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[ERROR]:')
      console.log(error)
    }

  }

  /**
   * 删除分组数据
   */
  async function deleteSingleData(_id) {
    setLoading(true)
    const result = await axios.delete(`/school/signupspot/${_id}`)
    setLoading(false)
    if (result.data.success) {
      const newData = data.filter(item => item._id !== _id)
      setData(newData)
      initAllBaseData(store)
      message.success(`删除数据成功`)
    } else {
      message.error(`删除数据失败`)
    }
  }

  /**
   * 更新数据
   */
  async function updateSingleData() {
    try {
      const result = await axios.put(`/school/signupspot`, {
        _id: editId,
        name,
        type,
        contact,
        stupickcoach,
        location,
        address,
        billsup,
        billsprefix,
        sort,
        enable
      })
      if (result.data.success) {
        fetchData()
        setVisible(!visible)
        initAllBaseData(store)
      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(`提交数据异常,请联系管理员`)
    }
  }


  /**
   * 打开新建对话框
   */
  async function openCreateModal() {
    /**
     * 判断是否录入基础数据
     */

    setName('')
    setType(1)
    setContact('')
    setStupickCoa(true)
    setLocation('')
    setAddress('')
    setBillsUp('')
    setBillsPrefix('')
    setSort()
    setEnable(true)
    setVisible(!visible)
    setModalType(1)
  }

  /**
   * 打开更新数据对话框
   */
  async function openUpdateModal(info) {
    setName(info.name)
    setType(info.type)

    setContact(info.contact)
    setStupickCoa(info.stupickcoach)

    setLocation(info.location)
    setAddress(info.address)
    setBillsUp(info.billsup)
    setBillsPrefix(info.billsprefix)
    setSort(info.sort)
    setEnable(info.enable)
    setVisible(!visible)
    setEditId(info._id)
    setModalType(2)
  }






  /**
   * Render View
   */
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Row>
          <Button icon='plus' type='primary' onClick={() => { openCreateModal() }}>新增</Button>
        </Row>
        <Table
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          scroll={{ x: 'max-content' }}
          loading={loading}
          rowKey="_id"
          dataSource={data}
          columns={columns}
          style={{
            marginTop: 10,
          }} />
      </Row>
      <Modal
        title="报名点管理"
        visible={visible}
        width="60%"
        okText="完成"
        cancelText="取消"
        onOk={() => {
          console.log(modalType)
          if (modalType == 1) {
            createSingleData()
          } else {
            updateSingleData()
          }
        }}
        onCancel={() => { setVisible(!visible) }}
      >
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>名称</Col>
          <Col span={9}>
            <Input placeholder="输入名称" className={css(styles.modalInput)} value={name} onChange={e => { setName(e.target.value) }} /></Col>
          <Col span={3} className={css(styles.modalRowText)}>类型</Col>
          <Col span={9}>
            <Radio.Group style={{ marginLeft: 10, marginTop: 5 }} value={type} onChange={e => { setType(e.target.value) }}>
              <Radio value={1}>校区</Radio>
              <Radio value={2}>报名点</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>联系电话</Col>
          <Col span={9}><Input placeholder="输入联系电话" className={css(styles.modalInput)} value={contact} onChange={e => { setContact(e.target.value) }} /></Col>
          <Col span={3} className={css(styles.modalRowText)}>学员分教练</Col>
          <Col span={9}><Switch checkedChildren="" unCheckedChildren="" checked={stupickcoach} onChange={value => { setStupickCoa(value) }} style={{ marginLeft: 10, marginTop: 5 }} /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>坐标</Col>
          <Col span={9}><Input placeholder="输入分组坐标,逗号分开" className={css(styles.modalInput)} value={location} onChange={e => { setLocation(e.target.value) }} /></Col>
          <Col span={3} className={css(styles.modalRowText)}>地址</Col>
          <Col span={9}><Input placeholder="输入地址" className={css(styles.modalInput)} value={address} onChange={e => { setAddress(e.target.value) }} /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>票号抬头</Col>
          <Col span={9}><Input placeholder="输入票号抬头" className={css(styles.modalInput)} value={billsup} onChange={e => { setBillsUp(e.target.value) }} /></Col>
          <Col span={3} className={css(styles.modalRowText)}>票据前缀</Col>
          <Col span={9}><Input placeholder="输入票号前缀" className={css(styles.modalInput)} value={billsprefix} onChange={e => { setBillsPrefix(e.target.value) }} /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>是否启用</Col>
          <Col span={9}><Switch checkedChildren="启用" unCheckedChildren="停用" checked={enable} onChange={value => { setEnable(value) }} style={{ marginLeft: 10, marginTop: 5 }} /></Col>
          <Col span={3} className={css(styles.modalRowText)}>排序</Col>
          <Col span={9}><InputNumber placeholder="排序数字越大排名越靠前" className={css(styles.modalInput)} value={sort} onChange={value => { setSort(value) }} /></Col>
        </Row>
      </Modal>
    </Row>
  )
}


