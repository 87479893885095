/*
 * @Author: huangliang
 * @Date: 2019-10-10 14:44:14
 * @LastEditTime : 2019-12-28 17:20:54
 * @LastEditors  : huangliang
 * @Description: Menu@Component
 * @FilePath: /00-pri-driving-web/src/components/menu.js
 * @js files
 */
import React from 'react'
import { Menu, Icon, Row, message } from 'antd'
import { StyleSheet, css } from 'aphrodite'
import { GlobalContext } from '../../store/store'
import history from '../../system/history'
import axios from '../../api/axios'

/**
 * 获取菜单配置
 * [todo:后期不同用户不同权限会配置不同的可视化菜单]
 */
import { getUserAccessMenu } from './config'

const { SubMenu } = Menu

export default function Navigation() {
  const store = React.useContext(GlobalContext)
  const [menuConfig, setMenuConfig] = React.useState([])
  const [openKeys, setOpenKeys] = React.useState([])
  async function renderMenu() {
    const authority = await axios.get('/user/authority')
    if (authority.data.success) {
      message.success(`获取权限成功`)
      store.setUser(authority.data.user)
      store.setUserAccount(authority.data.user.contact)
      window.localStorage.setItem('roleName', authority.data.roleName)
      window.localStorage.setItem('companyName', authority.data.company)
      const menu = getUserAccessMenu(authority.data.data)
      if (authority.data.role === 'SuperAdmin') {
        menu.push({
          url: 'company',
          icon: 'user',
          name: '公司管理',
          breadcrumb: [
            {
              bread: '公司管理',
              url: 'company'
            }
          ]
        })
      }
      setMenuConfig(menu)
      reloadSet(menu)
    } else {
      message.error(`获取用户权限失败`)
    }
  }

  /**
   * 根据路由返回指定的面包屑
   * @param {路由} url 
   */
  function reloadAutoSet(url, menu) {
    let breadlist = []
    menu.map(item => {
      if (item.children) {
        item.children.map(each => {
          if (each.url === url) {
            breadlist = each.breadcrumb
          }
        })
      } else {
        if (item.url === url) {
          breadlist = item.breadcrumb
        }
      }
    })
    return breadlist
  }
  /**
   * 点击菜单进行跳转
   * 设置面包导航
   * 设置已经选择的菜单进行高亮显示
   * 跳转路由
   * @param {菜单配置项} item 
   */
  function link(item) {
    store.setCondition({})
    store.setBreadList(item.breadcrumb || [])
    store.setMenuSelect(item.url)
    history.push(`/${item.url}`)
  }

  /**
   * 当用户刷新界面时
   * 根据路由自动高亮菜单
   * 自动填充面包导航栏
   */
  function reloadSet(menu) {
    const url = window.location.pathname === '/' ? 'signup' : window.location.pathname.replace('/', '')
    const breadlist = reloadAutoSet(url, menu)
    store.setBreadList(breadlist)
    store.setMenuSelect(url)
    breadlist && setOpenKeys([breadlist.length > 0 ? breadlist[0].url : ''])

    history.push(`/${url}`)
  }

  React.useEffect(() => {
    renderMenu()
  }, [])

  return (
    <div className={css(styles.menu)} style={{
      width: store.collapsed ? 80 : 200,
      position: 'fixed',
      zIndex: 99
    }}>
      <Row className={css(styles.title)}>
        <img src="http://47.113.92.184/favicon.ico" style={{
          width: 30,
          marginLeft: store.collapsed ? 3 : -15,
          marginRight: 5,
          marginTop: -3
        }} /><span style={{ color: '#f0f2f5' }}>{store.collapsed ? '' : '驾陪云'}</span></Row>
      <Menu
        className={css(styles.menu)}
        style={{ width: store.collapsed ? 80 : 200 }}
        selectedKeys={[store.menuSelect]}
        onOpenChange={value => { setOpenKeys([value[1]]) }}
        openKeys={openKeys}
        mode="inline"
        theme="dark"
        inlineCollapsed={store.collapsed}>
        {
          menuConfig.map(item => {
            if (item.children) {
              return (
                <SubMenu key={item.url} title={<span><Icon type={item.icon} /><span >{item.name}</span></span>}>
                  {
                    item.children.map(each => <Menu.Item key={each.url} onClick={() => { link(each) }} >{each.name}</Menu.Item>)
                  }
                </SubMenu>
              )
            } else {
              return (
                <Menu.Item key={item.url} onClick={() => { link(item) }}>
                  <Icon type={item.icon} />
                  <span>{item.name}</span>
                </Menu.Item>
              )
            }
          })
        }
      </Menu>
    </div>
  )
}

const styles = StyleSheet.create({
  menu: {
    height: '100%',
    float: 'left',
    position: 'fixed',
    fontSize: '18px',
    zIndex: 99,
    color: '#f0f2f5'

    // color : '#f0f2f5'
  },
  title: {
    height: '50px',
    lineHeight: '50px',
    fontSize: 16,
    color: '#ffffff',
    textAlign: 'center',
    fontWeight: 'bold',
    borderRight: '1px solid #002240',
    borderBottom: '1px solid #002240',
    backgroundColor: '#002240'
  },
  menu: {
    height: '100%',
    float: 'left',
    position: 'fixed'
  }
})

