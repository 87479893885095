/*
 * @Author: huangliang
 * @Date: 2020-01-21 10:59:08
 * @LastEditTime : 2020-01-21 17:18:10
 * @LastEditors  : huangliang
 * @Description: 成绩导入界面
 * @FilePath: /00-pri-driving-web/src/pages/Grade/GradeImport/index.js
 * @js files
 */
import React from 'react'
import { Row, Col, Table, Modal, message, Button, List, Typography, Icon, Tooltip,Popconfirm } from 'antd'
import { css } from 'aphrodite'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import ImportButton from './upload'
import Axios from '../../../api/axios'
import moment from 'moment'

export default function GradeImport() {
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [selectedRowKeys, setSelectedRow] = React.useState([])
  const [visilbe, setVisible] = React.useState(false)
  const [logs, setLogs] = React.useState([])

  React.useEffect(() => { fetchData() }, [])
  const columns = [
    {
      title: '科目',
      dataIndex: 'subject',
      key: 'subject'
    },
    {
      title: '考试时间',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: '身份证',
      dataIndex: 'idcard',
      key: 'idcard'
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '流水号',
      dataIndex: 'serialNumber',
      key: 'serialNumber'
    },
    {
      title: '通过',
      dataIndex: 'pass',
      key: 'pass'
    },
    {
      title: '导入用户',
      dataIndex: 'creator',
      key: 'creator'
    },
    {
      title: '导入时间',
      dataIndex: 'createAt',
      key: 'createAt',
      render: data => moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '人工审核',
      dataIndex: 'check',
      key: 'check',
      render: data => data ? '审核' : '未审核'
    },
    {
      title: '审核结果',
      dataIndex: 'failedMessage',
      key: 'failedMessage',
      render: (data, info) => !info.check ? '未审核' : data ? <Tooltip title={data}><span><Icon type='info-circle' theme='filled' />查看失败原因</span></Tooltip> : '通过'
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      fixed: 'right',
      render: (data, group) => {
        return (
          <Row>
            {/* <span className={css(styles.operate)} onClick={() => { setDivider(!dividerVisible) }}>分配</span> */}
            <span className={css(styles.operate)} onClick={() => { }}>修正</span>
            <Popconfirm title="删除该记录?" okText="确认" cancelText="取消" onConfirm={() => { }}>
              <span className={css(styles.operate)}>删除</span>
            </Popconfirm>
          </Row>
        )
      }
    }
  ]
  async function checkGrade() {
    const result = await Axios.post('/grade/import/check', {
      checkList: selectedRowKeys
    })
    if (result.data.success) {
      message.success(`导入数据结束`)
      setVisible(!visilbe)
      setLogs(result.data.data)
    }
  }

  async function fetchData() {
    const result = await Axios.post('/grade/import/list')
    if (result.data.success) {
      setData(result.data.data)
    } else {
      message.error(result.data.failedMessage || '未知错误')
    }
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: value => setSelectedRow(value),
  }
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Row>
          <Button type='primary' icon='check' onClick={() => { checkGrade() }} style={{ float: 'left' }}>批量审核</Button>
          <Button type='primary' icon='download' onClick={()=>{window.open('/model/grade.xls')}} style={{ float: 'left', marginLeft: 10 }}>下载导入模版</Button>
          <ImportButton />
        </Row>
        <Table
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          rowSelection={rowSelection}
          scroll={{ x: 'max-content' }}
          loading={loading}
          rowKey="_id"
          dataSource={data}
          columns={columns}
          style={{
            marginTop: 10,
          }} />
      </Row>
      <Modal
        width='40%'
        visible={visilbe}
        footer={null}
        onCancel={() => { setVisible(!visilbe) }}
      >
        <List
          style={{ marginTop: 20 }}
          bordered
          dataSource={logs}
          renderItem={item => (
            <List.Item>
              <Typography.Text mark>{item.success ? '[成功]' : '失败'}</Typography.Text> {item.info}
            </List.Item>
          )}
        />
      </Modal>
    </Row>
  )
}