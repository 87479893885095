import React from 'react'
import { Descriptions, Select, Pagination, Table, Row, Button, Badge, List, Drawer, Avatar, Modal, Col, Input, message, InputNumber, Popconfirm, Divider, DatePicker, Alert } from 'antd'
import { StyleSheet, css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import moment from 'moment'
import QueryBar from '../../../components/query/index'


const { Option } = Select

const filterOptions = {
  status: true,
  signUpDate: true,
  filter: true,
  introducer: true,
  class: true,
  signUpSpot: true,
  S2coach: true,
  S3coach: true,
}

export default function () {
  const columns = [
    {
      title: '序号',
      dataIndex: '',
      key: '',
      render: (data, info, index) => (page - 1) * limit + index + 1
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      // fixed: 'left',
      render: (data, info) =>
        <Row style={{
          color: '#1890ff',
          cursor: 'pointer'
        }} onClick={() => {
          store.setStudentFileDrawer(!store.studentFileDrawer);
          store.setStudentFile(info)
        }}>
          {data}
        </Row>
    },
    {
      title: '证件号',
      dataIndex: 'certificate',
      key: 'certificate',
      // fixed: 'left',

    },
    {
      title: '联系电话',
      dataIndex: 'contact',
      key: 'contact',
      // fixed: 'left',
    },
    {
      title: '科目二教练',
      dataIndex: 'subjectTwoCoach',
      key: 'subjectTwoCoach',
      render: (data, info) => info.allCoach ? info.allCoach : data
      // fixed: 'left',
    },
    {
      title: '科目二上车时间',
      dataIndex: 'subjectTwoCoachDate',
      key: 'subjectTwoCoachDate',
      render: data => data ? moment(data).format('YYYY-MM-DD HH:mm') : ''
      // fixed: 'left',
    },
    {
      title: '科目三教练',
      dataIndex: 'subjectThreeCoach',
      key: 'subjectThreeCoach',
      render: (data, info) => info.allCoach ? info.allCoach : data

      // fixed: 'left',
    },
    {
      title: '科目三上车时间',
      dataIndex: 'subjectThreeCoachDate',
      key: 'subjectThreeCoachDate',
      render: data => data ? moment(data).format('YYYY-MM-DD HH:mm') : ''
      // fixed: 'left',
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex'
    },
    {
      title: '班制',
      dataIndex: 'classId',
      key: 'classId'
    },
    {
      title: '介绍人',
      dataIndex: 'introducer',
      key: 'introducer'
    },
    {
      title: '报名网店',
      dataIndex: 'signupSpot',
      key: 'signupSpot'
    },
    {
      title: '跟踪客服',
      dataIndex: 'follower',
      key: 'follower'
    },

    // {
    //   title: '操作',
    //   dataIndex: 'operate',
    //   key: 'operate',
    //   fixed: 'right',
    //   render: (data, group) => {
    //     return (
    //       <Row>
    //         {/* <span className={css(styles.operate)} onClick={() => { setSerNumberVisible(!serNumberVisible); setEditId(group._id) }}>生成流水号</span> */}
    //         {/* <span className={css(styles.operate)} onClick={() => { openSubject(group) }}>录入学员成绩</span> */}
    //         {/* <span className={css(styles.operate)} onClick={() => { openUpdateModal(group) }}>编辑</span> */}
    //         {/* <Popconfirm title="确认删除?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleData(group._id) }}> */}
    //         {/* <span className={css(styles.operate)}>删除</span> */}
    //         {/* </Popconfirm> */}
    //       </Row>
    //     )
    //   }
    // }
  ]


  /**
   * React State
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [dividerVisible, setDivider] = React.useState(false)
  const [coach, setCoach] = React.useState('')
  const [coachInfo, setCoachInfo] = React.useState({})
  const [selectedRowKeys, setSelectedRow] = React.useState([])
  const [studentList, setStudentList] = React.useState([])
  const [type, setType] = React.useState('')
  const [page, setPage] = React.useState(1)
  const [limit, setLimit] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  /**
   * [ModalType]:1.add 2.update
   */
  const [modalType, setModalType] = React.useState(1)
  const [editId, setEditId] = React.useState('')
  const [car, setCar] = React.useState({})
  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
  }, [])

  async function dividerCar() {
    const result = await axios.post('/car/manager/divide', {
      cars: selectedRowKeys,
      coach
    })
    if (result.data.success) {
      message.success(`分配车辆成功`)
      const tableData = [...data]
      tableData.map(item => {
        if (selectedRowKeys.includes(item._id)) {
          item.coach = coach
        }
      })
      setData(tableData)
      setDivider(!dividerVisible)
      setSelectedRow([])
    } else {
      message.error(result.data.failedMessage || '分配车辆失败')
    }
  }



  /**
   * 增加一条数据
   */
  async function cancelDivider(type) {
    const result = await axios.post('/car/coach/cancel', {
      student: selectedRowKeys,
      type: type
    })
    if (result.data.success) {
      message.success(`取消学员分配成功`)
      setLoading(true)
      const tableData = [...data].map(item => {
        if (selectedRowKeys.includes(item._id)) {
          item[type] = ''
        }
        return item
      })
      setData(tableData)
      setLoading(false)
      setSelectedRow([])
    }
  }

  async function divideCoach() {
    const result = await axios.post('/car/coach/divide', {
      coach,
      students: selectedRowKeys,
      type
    })
    if (result.data.success) {
      message.success(`学员分配教练成功`)
      const tableData = [...data]
      tableData.map(item => {
        if (selectedRowKeys.includes(item._id)) {
          item[type] = coach
          if (type === 'subjectTwoCoach' || type === 'subjectThreeCoach') {
            item.allCoach = ''
          }
        }
      })
      setData(tableData)
      setSelectedRow([])
    } else {
      message.error(`学员分配教练失败: ${result.data.failedMessage}`)
    }
    setVisible(!visible)
  }


  /**
   * 获取数据库学员数据
   * @param {*} page 
   * @param {*} size 
   */
  async function fetchData(page, size) {
    try {
      const condition = {}
      if (store.condition.status) {
        condition.status = store.condition.status
      }
      if (store.condition.signupDate) {
        condition.signupDateStart = store.condition.signupDate[0]
        condition.signupDateEnd = store.condition.signupDate[1]
      }
      if (store.condition.filter) {
        condition.filter = store.condition.filter
      }
      if (store.condition.classId) {
        condition.classId = store.condition.classId
      }
      if (store.condition.signupSpot) {
        condition.signupSpot = store.condition.signupSpot
      }
      if (store.condition.introducer) {
        condition.introducer = store.condition.introducer
      }
      if (store.condition.subjectTwoCoach) {
        condition.subjectTwoCoach = store.condition.subjectTwoCoach
      }
      if (store.condition.subjectThreeCoach) {
        condition.subjectThreeCoach = store.condition.subjectThreeCoach
      }
      condition.isFirstCheck = true
      condition.page = page || page || 1
      condition.limit = size || limit
      // condition.subjectTwoPass = false
      condition.dividered = true
      setLoading(true)
      const result = await axios.post('/student/query', condition)
      const tableData = result.data.data
      setData(tableData)
      setTotal(result.data.total)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }

  }





  const rowSelection = {
    selectedRowKeys,
    onChange: value => setSelectedRow(value),
  }
  /**
   * Render View
   */
  console.log(coachInfo)
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Col span={22}><QueryBar config={filterOptions} /></Col>
        <Col span={2}>
          <Button onClick={() => { fetchData() }} type="primary" icon="search" style={{ marginTop: 10, float: 'left', width: 100 }} >查询</Button>
        </Col>
        <Divider />
        <Row>
          {/* <Button icon='setting' style={{ marginTop: -10, marginLeft: 10 }} type='primary' onClick={() => {
            setType('subjectTwoCoach')
            if (selectedRowKeys.length > 0) {
              setVisible(!visible)
            } else {
              message.warn(`请勾选待分配学员`)
            }
          }}>科二学员分车</Button> */}

          <Button icon='setting' style={{ marginTop: -10, marginLeft: 10 }} type='danger' onClick={() => {
            if (selectedRowKeys.length > 0) {
              cancelDivider('subjectTwoCoach')
            } else {
              message.warn(`请勾选待分配学员`)
            }
          }}>撤销科目二教练</Button>
          <Button icon='setting' style={{ marginTop: -10, marginLeft: 10 }} type='danger' onClick={() => {
            if (selectedRowKeys.length > 0) {
              cancelDivider('subjectThreeCoach')
            } else {
              message.warn(`请勾选待分配学员`)
            }
          }}>撤销科目三教练</Button>
        </Row>
        <Alert message={`数据库中符合条件数据总数:${total}`} type="info" style={{ marginTop: 10 }} showIcon />
        <Table
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          rowSelection={rowSelection}
          scroll={{ x: 'max-content' }}
          pagination={false}
          loading={loading}
          rowKey="_id"
          dataSource={data}
          columns={columns}
          style={{
            marginTop: 10,
          }} />
        <Pagination style={{ marginTop: 10 }}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={['10', '15', '20', '50']}
          pageSize={limit}
          current={page}
          total={total}
          onShowSizeChange={(page, pageSize) => {
            window.localStorage.setItem('pageSize', pageSize)
            setPage(page)
            setLimit(pageSize)
            fetchData(page, pageSize)
          }}
          onChange={(page, pageSize) => {
            setPage(page)
            setLimit(pageSize)
            fetchData(page, pageSize)
          }}
        />
      </Row>
      <Drawer
        title="分车信息"
        visible={visible}
        onClose={() => { setVisible(!visible) }}
        closable={true}
        width="70%"
      >
        <Row>
          <Col span={12}>
            <Select placeholder='请选择教练' className={css(styles.modalInput)} value={coach} onChange={async value => {
              const result = await axios.get(`/car/coach/${value}`)
              setCoach(value)
              setCoachInfo(result.data.coach)
              setStudentList(result.data.students)
            }}>
              {
                store.coaches && store.coaches.length > 0 && store.coaches.filter(each => each.subject === '科目二').map(item => {
                  return (<Option key={item.name}>{item.name} </Option>)
                })
              }
            </Select></Col>
        </Row>
        <Divider />
        <Descriptions title="教练信息" bordered>
          <Descriptions.Item label="联系电话">{coachInfo.contact || ''}</Descriptions.Item>
          <Descriptions.Item label="性别">{coachInfo.sex || ''}</Descriptions.Item>
          <Descriptions.Item label="入职时间">{coachInfo.entry || ''}</Descriptions.Item>
          <Descriptions.Item label="状态"><Badge status="processing" />在职</Descriptions.Item>
          <Descriptions.Item label="拥有车辆" span={2}>
            {
              coachInfo.cars ? coachInfo.cars.map(item => item.name).join(',') : ''
            }
          </Descriptions.Item>
        </Descriptions>
        <Divider></Divider>
        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{`该教练已经拥有${studentList.length}学员`}</span>
        <List
          style={{
            overflow: 'auto',
            height: '300px'
          }}
          itemLayout="horizontal"
          dataSource={studentList}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item.sex === '男' ? 'https://img.58cdn.com.cn/dist/jxt/images/head-man.png' : 'https://img.58cdn.com.cn/dist/jxt/images/head-woman.png'} />}
                title={item.name}
                description={`身份证 : ${item.certificate || ''} 联系电话 : ${item.contact} 培训类型 : ${item.carType}`}
              />
            </List.Item>
          )}
        />
        <div className={css(styles.drawerFooter)}>
          <Button
            style={{
              marginRight: 8,
            }}
            onClick={() => { setVisible(!visible) }}
          >
            取消
            </Button>
          <Button onClick={() => {
            if (modalType == 1) {
              divideCoach()
            } else {
              // updateSingleData()
            }
          }} type="primary">
            完成
            </Button>
        </div>
      </Drawer>
    </Row>
  )
}


