/*
 * @Author: huangliang
 * @Date: 2019-12-26 14:32:50
 * @LastEditTime : 2019-12-28 16:58:13
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/User/Config/index.js
 * @js files
 */
import React from 'react'
import { Menu, PageHeader, Table, Row, Button, Switch, Descriptions, Badge, Select, Icon, Tag, Modal, Col, Input, message, InputNumber, Popconfirm, Divider } from 'antd'
import { StyleSheet, css } from 'aphrodite'
import { GlobalContext } from '../../../store/store'
import axios from '../../../api/axios'
import styles from './style'
import moment from 'moment'
import md5 from 'md5'
const { Option } = Select

export default function settings() {


  /**
   * React State
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [currentPage, setCurrent] = React.useState('info')
  const [old, setOld] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [check, setCheck] = React.useState('')


  async function changePassword() {
    if (password !== check) {
      message.warn(`新密码和确认密码输入值不一样,请确认后重新提交`)
      return
    } else {
      const result = await axios.post('/user/modify', {
        old: md5(old),
        password: md5(password)
      })
      console.log(result)
      if (result.data.success) {
        message.success(`修改密码成功`)
      } else {
        message.error(`修改密码失败: ${result.data.failedMessage}`)
      }
    }
  }


  /**
   * Render View
   */
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Menu
          style={{ width: '20%', float: 'left', zIndex: 99 }}
          selectedKeys={currentPage}
          onClick={key => { console.log('111' + key); setCurrent(key.key) }}
          mode='inline'
          theme='light'
        >
          <Menu.Item key="info"> 个人信息</Menu.Item>
          <Menu.Item key="modify"> 密码修改</Menu.Item>
        </Menu>
        {
          currentPage === 'info' ?
            <Row className={css(styles.rightBar)}>

              <Descriptions title="用户信息" bordered>
                <Descriptions.Item label="姓名">{store.user.name || '未知'}</Descriptions.Item>
                <Descriptions.Item label="联系电话">{store.user.contact || '未知'}</Descriptions.Item>
                <Descriptions.Item label="性别">{store.user.sex || '未知'}</Descriptions.Item>
                <Descriptions.Item label="入职时间">{store.user.entry || '未知'}</Descriptions.Item>
                <Descriptions.Item label="合同日期" span={2}>
                  {`${store.user.contractStart ? moment(store.user.contractStart).format('YYYY-MM-DD') : '未知'} ---${store.user.contractStart ? moment(store.user.contractEnd).format('YYYY-MM-DD') : '未知'} `}
                </Descriptions.Item>
                <Descriptions.Item label="工作状态" span={3}>
                  <Badge status="processing" text={store.user.inService ? '在职' : '离职' || '未知'} />
                </Descriptions.Item>
                <Descriptions.Item label="系统角色">{store.user.role || '未知'}</Descriptions.Item>
                <Descriptions.Item label="员工分组">{store.user.staffGroup || '未知'}</Descriptions.Item>
                <Descriptions.Item label="证件号码">{store.user.idcard || '未知'}</Descriptions.Item>
                <Descriptions.Item label="备注说明">
                </Descriptions.Item>
              </Descriptions>
            </Row>
            : <div></div>
        }
        {
          currentPage === 'modify' ?
            <Row className={css(styles.rightBar)}>
              <Row style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 10 }}>修改密码</Row>
              <Row style={{ marginTop: 10 }}><Col span={4} className={css(styles.modalRowVal)} style={{ textAlign: 'right' }}>请输入原密码</Col><Col span={10} className={css(styles.modalRowVal)}><Input.Password value={old} onChange={e => setOld(e.target.value)} /></Col></Row>
              <Row style={{ marginTop: 10 }}><Col span={4} className={css(styles.modalRowVal)} style={{ textAlign: 'right' }}>请输入新密码</Col><Col span={10} className={css(styles.modalRowVal)}><Input.Password value={password} onChange={e => setPassword(e.target.value)} /></Col></Row>
              <Row style={{ marginTop: 10 }}><Col span={4} className={css(styles.modalRowVal)} style={{ textAlign: 'right' }}>请确认原密码</Col><Col span={10} className={css(styles.modalRowVal)}><Input.Password value={check} onChange={e => setCheck(e.target.value)} /></Col></Row>
              <Divider />
              <Button type="primary" icon="setting" onClick={() => { changePassword() }}>确认修改</Button>
            </Row>

            : <div></div>
        }
      </Row>
    </Row>
  )
}

