/*
 * @Author: huangliang
 * @Date: 2019-10-20 18:22:53
 * @LastEditTime: 2020-03-15 16:11:48
 * @LastEditors: huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/Student/Student/config.js
 * @可以输入预定的版权声明、个性签名、空行等
 */


export const filterOptions = {
  status: true,
  signUpDate: true,
  filter: true,
  introducer: true,
  class: true,
  signUpSpot: true,
  dataComplete : true,
  fullPaid : true,
  clean:true
}

export const studentsSchema = (condition) => {
  return ({
    query: `query{
      students(check:true,${condition}){
        _id
        name,
        nation,
        sex,
        certificateType,
        certificate,
        birth,
        certificateValid,
        contact,
        code,
        avatar,
        certificateAddress,
        liveAddress,
        signupSource,
        signupDate,
        signupSpot,
        introducer,
        businessType,
        businessType,
        primaryCarType,
        primaryCertificate,
        studentGroup,
        carType,
        classId,
        traningPlace,
        preCoach,
        dataComplete,
        comments,
        isFirstCheck
        serialNumber
        fiveTimeNoPass
        subjectOnePass
        subjectOne{
          date
          pass
          operator
        }
        subjectTwoPass
        subjectTwo{
          date
          pass
          operator
        }
        subjectThree{
          date
          pass
          operator
        }
        subjectThreePass
        subjectFour{
          date
          pass
          operator
        }
        subjectFourPass
        charges{
          type
          payType
          cost
          paid
          offer
          offerReason
          arrears
          bills
          payDate
          creator
          checked
          comments
        }
        createAt
        lastModified
      }
    }`
  })
}


/**
 * 驾驶车辆类型
 */
export const carTypes = ['C1', 'C2', 'A1', 'A2', 'A3', 'B1', 'B2', 'C3', 'C4', 'C5', 'D', 'E', 'F', 'M', 'N', 'P']


/**
 * 性别
 */
export const sexOptions = ['男', '女']


/**
 * 证件类型
 */
export const certificateOptions = ['身份证', '护照', '军官证', '其他']


/**
 * 业务类型
 */
export const businessOptions = ['初领', '增驾', '其他']

/**
 * 费用类型
 */
export const costTypes = ['培训费', '考试费', '代收费', '科目一考试费', '科目二考试费', '科目三考试费', '科目一补考费', '科目二补考费', '科目三补考费', '保险费']

export const  financeColums = [
  {
    title: '序号',
    dataIndex: 'name',
    key: 'name',
    render: (data, info, index) => index +1
  },
  {
    title: '费用类型',
    dataIndex: 'type',
    key: 'type',
    // render: (data, info, index) => {
    //   return (
    //     <Select disabled value={data} onChange={value => { editFinanceDetail(index, 'type', value) }}>
    //       {costTypes.map(item => <Option value={item} key={item}>{item}</Option>)}
    //     </Select>
    //   )
    // }
  },
  {
    title: '费用',
    dataIndex: 'cost',
    key: 'cost',
    // render: (data, info, index) => <InputNumber disabled value={data} onChange={(value) => { editFinanceDetail(index, 'cost', value) }} />
  },

  {
    title: '支付类型',
    dataIndex: 'payType',
    key: 'payType',
    // render: (data, info, index) => {
    //   return (
    //     <Select value={data} onChange={value => { editFinanceDetail(index, 'payType', value) }} style={{ minWidth: 50 }}>
    //       {store.payTypes.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)}
    //     </Select>
    //   )
    // }
  },
  {
    title: '实缴金额',
    dataIndex: 'paid',
    key: 'paid',
    // render: (data, info, index) => <InputNumber value={data} onChange={(value) => { editFinanceDetail(index, 'paid', value) }} />
  },
  {
    title: '优惠金额',
    dataIndex: 'offer',
    key: 'offer',
    // render: (data, info, index) => <InputNumber value={data} onChange={(value) => { editFinanceDetail(index, 'offer', value) }} />
  },
  {
    title: '优惠原因',
    dataIndex: 'offerReason',
    key: 'offerReason',
    width: 100,
    // render: (data, info, index) => <Input value={data} onChange={(e) => { editFinanceDetail(index, 'offerReason', e.target.value) }} />
  },
  {
    title: '欠费',
    dataIndex: 'arrears',
    key: 'arrears',
    // render: (data, info, index) => <span style={{ color: 'red' }}>{data}</span>
  },
  {
    title: '票号',
    dataIndex: 'bills',
    key: 'bills',
    width: 100,
    // render: (data, info, index) => <Input value={data} onChange={(e) => { editFinanceDetail(index, 'bills', e.target.value) }} />

  },
  {
    title: '说明',
    dataIndex: 'comments',
    key: 'comments',
    width: 100,
    // render: (data, info, index) => <Input value={data} onChange={(e) => { editFinanceDetail(index, 'comments', e.target.value) }} />
  }
]