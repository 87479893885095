/*
 * @Author: huangliang
 * @Date: 2019-12-08 13:01:23
 * @LastEditTime: 2020-03-18 22:41:59
 * @LastEditors: huangliang
 * @Description: 前端数据请求地址配置
 * @FilePath: /00-pri-driving-web/src/config/config.js
 * @js files
 */

/**
 * 文件上传服务
 * 服务地址为开发服务器的地址@dev_server
 */
// export const fileuploadUrl = `http://localhost:4000`
// export const fileuploadUrl = `http://47.101.136.105:4000`
export const fileuploadUrl = window.location.origin == 'http://localhost:3000' ? 'http://localhost:4000' : window.location.origin


// http://47.113.92.184/

/**
 * 后端数据请求接口
 */
export const devServerUrl = `http://localhost:4000`
export const testServerUrl = `/`