/*
 * @Author: huangliang
 * @Date: 2019-10-20 18:22:47
 * @LastEditTime : 2019-12-20 20:23:10
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/Student/Signup/style.js
 * @可以输入预定的版权声明、个性签名、空行等
 */

import { StyleSheet, css } from 'aphrodite'

/**
 * Css in js
 * Aphrodite
 */
export default StyleSheet.create({
  content: {
    marginTop: '15px',
    paddingTop: '10px',
    marginRight: '20px',
    backgroundColor: '#ffffff',
  },
  operate: {
    color: '#1890ff',
    paddingRight: 10,
    cursor: 'pointer'
  },
  modalRow: {
    marginTop: 10
  },
  modalRowText: {
    textAlign: 'right',
    height: '32px',
    lineHeight: '32px'
  },
  modalRowTextLeft : {
    textAlign: 'right',
    height: '32px',
    lineHeight: '32px',
    marginTop:10
  },
  modalInput: {
    marginLeft: 10,
    width: "90%",
    marginTop:10

  },
  drawerFooter: {
    zIndex: 999,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    borderTop: '1px solid #e8e8e8',
    padding: '10px 16px',
    textAlign: 'right',
    left: 0,
    background: '#fff',
    borderRadius: '0 0 4px 4px'
  },
  longInput: {
    marginLeft: '3%',
    width: '95%'
  },
  marginRight8: {
    marginRight: 8
  },
  selectNeed: {
    color: 'red',
    letterSpacing: '1px',
    fontSize: '100%'
  }
})