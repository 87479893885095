import React from 'react'
import Content from '../../../components/content/index'
import { Calendar, Badge, Table, Row, Modal, Button, Drawer, DatePicker, Select, Col, message, Tag } from 'antd'
import { css } from 'aphrodite'
import styles from './style'
import TreeSelected from './select'
import { GlobalContext } from '../../../store/store'
import moment from 'moment'
import Axios from '../../../api/axios'

const Option = Select.Option

export default function () {
  const store = React.useContext(GlobalContext)
  const columns = [
    {
      title: '学生姓名',
      dataIndex: 'studentName',
      key: 'studentName'
    },
    {
      title: '学生电话',
      dataIndex: 'studentContact',
      key: 'studentContact'
    },
    {
      title: '报名日期',
      dataIndex: 'signupDate',
      key: 'signupDate',
      render: data => data ? moment(data).format('YYYY-MM-DD') : ''
    },
    {
      title: '班制',
      dataIndex: 'classId',
      key: 'classId'
    },
    {
      title: '考试日期',
      dataIndex: 'date',
      key: 'date',
      render: data => data ? moment(data).format('YYYY-MM-DD') : ''
    },
    {
      title: '考场地点',
      dataIndex: 'examAddress',
      key: 'examAddress',
    },
    {
      title: '报考科目',
      dataIndex: 'subject',
      key: 'subject',
      render: data => {
        switch (data) {
          case 'subjectOne': return '科目一'
          case 'subjectTwo': return '科目二'
          case 'subjectThree': return '科目三'
          case 'subjectFour': return '科目四'
        }
      }
    },
    // {
    //   title: '预约状态',
    //   dataIndex: 'reserveStatus',
    //   key: 'reserveStatus',
    //   render: data => {
    //     switch (data) {
    //       case 0: return <Tag color="#2db7f5">待预约</Tag>
    //       case 1: return <Tag color="#108ee9">预约成功</Tag>
    //       case 2: return <Tag>预约失败</Tag>
    //     }
    //   }
    // },
    {
      title: '考试状态',
      dataIndex: 'examStatus',
      key: 'examStatus',
      render: data => {
        switch (data) {
          case 0: return <Tag color="#2db7f5">待考试</Tag>
          case 1: return <Tag color="#108ee9">合格</Tag>
          case 2: return <Tag>不合格</Tag>
          case 3: return <Tag>取消考试</Tag>

        }
      }
    },
    // {
    //   title: '申报人',
    //   dataIndex: 'staffName',
    //   key: 'staffName'
    // },
    // {
    //   title: '提交时间',
    //   dataIndex: 'createAt',
    //   key: 'createAt',
    //   render: data => moment(data).format('YYYY-MM-DD HH:mm')
    // }
  ]

  async function queryExam() {
    const result = await Axios.post('/exam/student/bydate')
    if (result.data.success) {
      setData(result.data.data)
    } else {
      message.error(`报考信息查询失败 : ${result.data.failedMessage || '服务器端未知错误'}`)
    }
  }

  // const [selectedRow, setSelectedRow] = React.useState([])


  const [visible, setVisible] = React.useState(false)
  const [date, setDate] = React.useState(moment())
  const [subject, setSubject] = React.useState('subjectOne')
  const [dataSource, setData] = React.useState([])
  const [arrage, setArrange] = React.useState([])
  const [selectedRow, setSelectedRow] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    queryExam()
  }, [])

  function dateCellRender(value) {
    let content
    dataSource.map(item => {
      if (item.date === moment(value).format('YYYY-MM-DD')) {
        content = (
          <Row>
            {
              item.subjectOne.length > 0 ? <Badge status="processing" text={`科目一 : ${item.subjectOne.length}`} onClick={() => {
                setVisible(true)
                setArrange(item.subjectOne)
              }} /> : <Row />
            }
            {
              item.subjectTwo.length > 0 ? <Badge status="processing" text={`科目二 : ${item.subjectTwo.length}`} onClick={() => {
                setVisible(true)
                setArrange(item.subjectTwo)
              }} /> : <Row />
            }
            {
              item.subjectThree.length > 0 ? <Badge status="processing" text={`科目三 : ${item.subjectThree.length}`} onClick={() => {
                setVisible(true)
                setArrange(item.subjectThree)
              }} /> : <Row />
            }
            {
              item.subjectFour.length > 0 ? <Badge status="processing" text={`科目四 : ${item.subjectFour.length}`} onClick={() => {
                setVisible(true)
                setArrange(item.subjectFour)
              }} /> : <Row />
            }
          </Row>
        )
      }
    })
    return content
  }

  function getMonthData(value) {
    if (value.month() === 8) {
      return 1394;
    }
  }

  function monthCellRender(value) {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  }


  async function setReserveStauts(key, value) {
    setLoading(true)
    const result = await Axios.put('/exam/student', {
      ids: selectedRow,
      [key]: value
    })
    if (result.data.success) {
      message.success(`设置学员考试报考状态成功!`)
      const data = [...arrage].map(item => {
        if (selectedRow.includes(item._id)) {
          item[key] = value
        }
        return item
      })
      setArrange(data)
      setSelectedRow([])
    } else {
      message.error(`报考信息查询失败 : ${result.data.failedMessage || '服务器端未知错误'}`)
    }
    setLoading(false)

  }


  const rowSelection = {
    selectedRowKeys: selectedRow,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRowKeys)
    }
  }
  return (
    <Content>
      <Modal
        onCancel={() => { setVisible(false) }}
        visible={visible}
        width='60%'
        footer={null}
        title='考试信息'>
        <Row>
          <Button type='primary' icon='setting' onClick={() => {
            setReserveStauts('examStatus', 1)
          }}>考试合格</Button>
          <Button type='primary' icon='setting' className={css(styles.buttonSpace)} onClick={() => {
            setReserveStauts('examStatus', 2)
          }}>考试不合格</Button>
          <Button type='primary' icon='setting' className={css(styles.buttonSpace)} onClick={() => {
            setReserveStauts('examStatus', 3)
          }}>取消考试</Button>
        </Row>
        <Table
          style={{ marginTop: 10 }}
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          rowSelection={rowSelection}
          scroll={{ x: 'max-content' }}
          rowKey='_id'
          dataSource={arrage} ></Table>
      </Modal>
      <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} />
    </Content>
  )
}