/*
 * @Author: huangliang
 * @Date: 2019-10-10 16:11:37
 * @LastEditTime : 2020-01-11 17:14:26
 * @LastEditors  : huangliang
 * @Description: header@Component
 * @FilePath: /00-pri-driving-web/src/components/header.js
 * @js files
 */
import React from 'react'
import { GlobalContext } from '../../store/store'
import { StyleSheet, css } from 'aphrodite'
import { Avatar, List, Switch, Breadcrumb, Row, Badge, Menu, Button, Dropdown, Icon, Col, Modal, Divider, message } from 'antd'
import { withRouter } from 'react-router-dom'
import history from '../../system/history'
import { initAllBaseData } from '../../store/init/init'
import Axios from '../../api/axios'

function jump(store) {
  history.push(`dashboard`)
  store.setBreadList([{
    bread: '监控页',
    url: 'dashboard'
  }])
}


function logout() {
  window.localStorage.removeItem('userName')
  window.localStorage.removeItem('mitoken')
  history.push('/login')
}


const menu = (
  <Menu>
    <Menu.Item style={{ height: 50, lineHeight: '40px' }}>
      <a target="_blank" rel="noopener noreferrer" >
        <Icon type="setting" /> 个人中心
      </a>
    </Menu.Item>
    <Menu.Item style={{ height: 50, lineHeight: '40px' }} onClick={() => { logout() }}>
      <a target="_blank" rel="noopener noreferrer" >
        <Icon type="logout" /> 注销
      </a>
    </Menu.Item>
  </Menu>
)

function Header() {
  const store = React.useContext(GlobalContext)
  const visible = store.breadlist && store.breadlist.length > 0 ? true : false

  const [tipVisible, setTipVisible] = React.useState(false)
  const [data, setData] = React.useState({})

  React.useEffect(() => {
    getExpireIndentUser()
  }, [])

  async function getExpireIndentUser() {
    const result = await Axios.get('/student/indend/expire')
    if (result.data.success) {
      setData(result.data)
      if (window.location.pathname === '/dashboard' || window.location.pathname === '/student/indend') {
        if (result.data.expired.length > 0 || result.data.willexpired.length > 0) {
          setTipVisible(!tipVisible)
        }
      }
    } else {
      console.log(result.data.failedMessage || '未知错误')
    }

  }

  function updateNote() {
    Modal.info({
      title: '新版本提示',
      width: '600px',
      content: (
        <div>
          <p>1.字典管理增加了科目一~科目四的考场 </p>
          <p>2.学生预约考试可以根据科目筛选不同的考场</p>
          <p>3.考试预约界面预约时增加了显示该学员的教练功能 显示科目二、科目三的教练 </p>
          <p>4.考试预约、考试查询界面 增加了 根据学生姓名、电话、教练模糊查询  根据考试日期、科目、班制筛选</p>
          <p>5.考试查询界面增加审核功能、审核过的考试数据将同步学生查询</p>
          <p>6.审核过的数据将不可以再次改变考试结果、如需修改需要去学员查询里面修改</p>
        </div>
      ),
      async onOk() {
        const result = await Axios.get('/staff/message/checked')
        if (result.data.success) {
          console.log(`用户已经确认更新提示信息`)
        }
      }
    })
  }
  function info() {
    if (store.user.messageChecked === false && window.location.pathname !== '/login') {
      updateNote()
    } else {
      console.log(`用户已经确认过更新信息`)
    }
  }
  React.useEffect(() => {
    console.log(`准备渲染更新通知`)
    info()
    getExpireIndentUser()
  }, [store.user])
  React.useEffect(() => {
    if (!!window.localStorage.getItem('accesstoken')) {
      initAllBaseData(store)
    } else if (window.location.pathname !== '/login') {
      window.location.href = '/login'
    }
  }, [])
  return (
    <Row className={css(styles.header)} style={{
      marginLeft: store.collapsed ? 80 : 200,
      height: visible ? '145px' : '50px',
    }}>

      <Modal
        width={600}
        style={{
          marginTop: -50
        }}
        visible={tipVisible}
        onOk={() => { setTipVisible(!tipVisible) }}
        onCancel={() => { setTipVisible(!tipVisible) }}
        footer={null}
      >
        {
          data.expired instanceof Array && data.expired.length > 0 ?
            <Row>
              <Divider>已经过期学员</Divider>
              <List
                itemLayout="horizontal"
                style={{
                  maxHeight: 250,
                  overflowY: 'auto'
                }}
                dataSource={data.expired || []}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={item.sex === '男' ? 'https://img.58cdn.com.cn/dist/jxt/images/head-man.png' : 'https://img.58cdn.com.cn/dist/jxt/images/head-woman.png'} />}
                      title={<a href="https://ant.design">{item.name}</a>}
                      description={`跟进日期${item.followedDate} 等级${item.indendLevel} 过期时间 ${item.followedExpireDate}`}
                    />
                  </List.Item>
                )}
              />
            </Row>
            : <div></div>
        }
        {
          data.willexpired instanceof Array && data.willexpired.length > 0 ?
            <Row>
              <Divider>未来三天后即将过期学员</Divider>
              <List
                itemLayout="horizontal"
                dataSource={data.willexpired || []}
                style={{
                  maxHeight: 250,
                  overflowY: 'auto'
                }}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={item.sex === '男' ? 'https://img.58cdn.com.cn/dist/jxt/images/head-man.png' : 'https://img.58cdn.com.cn/dist/jxt/images/head-woman.png'} />}
                      title={<a href="https://ant.design">{item.name}</a>}
                      description={`跟进日期${item.followedDate} 等级${item.indendLevel} 过期时间 ${item.followedExpireDate}`}
                    />
                  </List.Item>
                )}
              />
            </Row>
            :
            <Row></Row>
        }
      </Modal>
      <Row className={css(styles.headerRow)}>
        <Col span={6}>
          <img src={`http://47.113.92.184/assets/${store.collapsed ? 'right' : 'left'}.svg`} alt='' className={css(styles.left)} onClick={() => { store.changeCollapsed(!store.collapsed) }} />
        </Col>
        <Col span={6}></Col>
        <Col span={12}>
          <Dropdown overlay={menu} className={css(styles.userinfo)}>
            <Row>
              <img src="https://img.58cdn.com.cn/dist/jxt/images/head-man.png" className={css(styles.avatar)} />
              {window.localStorage.getItem('displayName') || '未知用户'}<Icon type="down" style={{ textIndent: 2 }} />
            </Row>
          </Dropdown>
          <div style={{ cursor: 'pointer', float: 'right', height: 50, lineHeight: '50px' }}>
            <Badge count={1} dot onClick={() => { updateNote() }}>
              <Icon type="notification" />版本更新
            </Badge>
          </div>

          <div style={{ cursor: 'pointer', float: 'right', height: 50, lineHeight: '50px', marginRight: 20 }}>
            <Badge count={1} dot onClick={() => { setTipVisible(!tipVisible) }}>
              <Icon type="notification" />过期学员提醒
            </Badge>
          </div>
          {/* <div style={{ cursor: 'pointer', float: 'right', height: 50, lineHeight: '50px', marginRight: 20 }}>
           过期学员消息提示<Switch defaultChecked style={{marginTop:-2}} />
          </div> */}
        </Col>
      </Row>
      {
        store.breadlist
          ?
          <div>
            <Breadcrumb className={css(styles.breadcrumb)} style={{
              display: store.breadlist && store.breadlist.length > 0 ? '' : 'none'
            }}>
              <Breadcrumb.Item onClick={() => { jump(store) }}>首页</Breadcrumb.Item>
              {
                store.breadlist.map(item => <Breadcrumb.Item key={item.url}>{item.bread}</Breadcrumb.Item>)
              }
            </Breadcrumb>
            <h1 className={css(styles.mainTitle)} style={{
              display: store.breadlist && store.breadlist.length > 0 ? '' : 'none',
            }}>{store.breadlist && store.breadlist.length > 0 ? store.breadlist[store.breadlist.length - 1].bread : ''}</h1>
          </div>
          :
          <div />
      }

    </Row>
  )
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#ffffff',
    boxShadow: '1px 1px 2px rgba(0, 21, 41, 0.08)',
    zIndex: 99
  },
  headerRow: {
    borderBottom: '1px solid #e8e8e8',
    boxShadow: '0 1px 4px rgba(0, 21, 41, 0.08)',
    height: '50px'
  },
  left: {
    width: '20px',
    height: '20px',
    marginTop: '15px',
    marginLeft: '20px',
    cursor: 'pointer'
  },
  user: {
    height: '64px',
    width: '140px',
    cursor: 'pointer',
    lineHeight: '64px',
    textAlign: 'center',
    fontSize: '16px',
    marginRight: '20px',
    ':hover': {
      backgroundColor: '#f0f2f5'
    }
  },
  mainTitle: {
    textIndent: '20px',
    fontSize: '20px',
    lineHeight: '25px'
  },
  breadcrumb: {
    textIndent: '20px',
    lineHeight: '50px'
  },
  userinfo: {
    cursor: 'pointer',
    height: '50px',
    lineHeight: '50px',
    float: 'right',
    marginRight: '50px',
    paddingLeft: 20,
    paddingRight: 20,
    ':hover': {
      backgroundColor: '#f0f2f5'
    }
  },
  avatar: {
    width: 36,
    height: 36,
    marginRight: 10
  }
})

export default withRouter(Header)