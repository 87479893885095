import React from 'react'
import { Alert, Select, Divider, Table, Pagination, Tooltip, Row, Button, Steps, DatePicker, Radio, Drawer, Tag, Modal, Col, Input, message, InputNumber, Popconfirm } from 'antd'
import { css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import Avatar from './upload'
import moment from 'moment'
import QueryBar from '../../../components/query/index'
import {
  studentsSchema,
  carTypes,
  costTypes,
  businessOptions,
  certificateOptions,
  sexOptions,
  filterOptions
} from './config'
import {
  certificateId,
  phoneNumber
} from '../../../tools/verify'


const { Option } = Select
const { Step } = Steps

export default function TrainingPlace() {
  const columns = [
    {
      title: '序号',
      dataIndex: '',
      key: '',
      render: (data, info, index) => `${index + 1}`
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '证件号',
      dataIndex: 'certificate',
      key: 'certificate'
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex'
    },
    {
      title: '联系电话',
      dataIndex: 'contact',
      key: 'contact'
    },
    {
      title: '流水号',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      render: data => data ? data : <Tag color="#f50">未生成</Tag>
    },
    {
      title: '科目一考试结果',
      dataIndex: 'subjectOne',
      key: 'subjectOne',
      align: 'center',
      render: (data, group) => renderStatus(data, group)

    },
    {
      title: '科目二考试结果',
      dataIndex: 'subjectTwo',
      key: 'subjectTwo',
      align: 'center',
      render: (data, group) => renderStatus(data, group)
    },
    {
      title: '科目三考试结果',
      dataIndex: 'subjectThree',
      key: 'subjectThree',
      align: 'center',
      render: (data, group) => renderStatus(data, group)
    },
    {
      title: '科目四考试结果',
      dataIndex: 'subjectFour',
      key: 'subjectFour',
      align: 'center',
      render: (data, group) => renderStatus(data, group)
    },
    {
      title: '报名地点',
      dataIndex: 'signupSpot',
      key: 'signupSpot'
    },
    {
      title: '班制',
      dataIndex: 'classId',
      key: 'classId'
    },
    {
      title: '报名时间',
      dataIndex: 'signupDate',
      key: 'signupDate',
      render: data => moment(data).format('YYYY-MM-DD')
    },
    {
      title: '介绍人',
      dataIndex: 'introducer',
      key: 'introducer'
    },

    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      fixed: 'right',
      render: (data, group) => {
        return (
          <Row>
            <Popconfirm title="确认审核该学员状态为五次不过吗?" okText="确认" cancelText="取消" onConfirm={() => { }}>
              <span className={css(styles.operate)}>审核</span>
            </Popconfirm>
          </Row>
        )
      }
    }
  ]


  /**
   * React State
   */
  const initPageSize = window.localStorage.getItem('pageSize') ? parseInt(window.localStorage.getItem('pageSize')) : 10

  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const [page, setPage] = React.useState(1)
  const [limit, setLimit] = React.useState(initPageSize)
  const [total, setTotal] = React.useState(0)


  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
  }, [])



  async function fetchData(page, size) {
    try {

      const condition = {}
      if (store.condition.status) {
        condition.status = store.condition.status
      }
      if (store.condition.signupDate) {
        condition.signupDateStart = store.condition.signupDate[0]
        condition.signupDateEnd = store.condition.signupDate[1]
      }
      if (store.condition.filter) {
        condition.filter = store.condition.filter
      }
      if (store.condition.classId) {
        condition.classId = store.condition.classId
      }
      if (store.condition.signupSpot) {
        condition.signupSpot = store.condition.signupSpot
      }
      if (store.condition.introducer) {
        condition.introducer = store.condition.introducer
      }
      if (store.condition.dataComplete) {
        if (store.condition.dataComplete === 'true') {
          condition.dataComplete = true
        }
        if (store.condition.dataComplete === 'false') {
          condition.dataComplete = false
        }
      }
      condition.fiveTimeNoPass = true
      condition.page = page || page || 1
      condition.limit = size || limit

      setLoading(true)
      const data = await axios.post('/student/query', condition)
      const tableData = data.data.data
      console.log(tableData)
      setData(tableData)
      setLoading(false)

      // console.log(`筛选条件`)
      // console.log(store.condition)
      // let condition = ''
      // if (store.condition.status) condition = `${condition}status:"${store.condition.status}",`
      // if (store.condition.signupDate) condition = `${condition}signupDateStart:"${store.condition.signupDate[0]}",signupDateEnd:"${store.condition.signupDate[1]}",`
      // if (store.condition.filter) condition = `${condition}filter:"${store.condition.filter}",`
      // if (store.condition.classId) condition = `${condition}classId:"${store.condition.classId}",`
      // if (store.condition.signupSpot) condition = `${condition}signupSpot:"${store.condition.signupSpot}",`
      // if (store.condition.introducer) condition = `${condition}introducer:"${store.condition.introducer}",`
      // console.log(condition)
      // setLoading(true)
      // const data = await axios.post('/graphql', studentsSchema(condition))
      // const tableData = data.data.data.students
      // setData(tableData)
      // setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[ERROR]:')
      console.log(error)
    }

  }
  function renderStatus(data, item) {
    if (data.length === 5 && item && item.fiveTimeNoPass) {
      return (<Tag color="#f50">五次不过</Tag>)
    }
    if (data.length === 0) {
      return ('—')
    } else {
      const lastData = data[data.length - 1]
      return (
        <Tooltip placement="topLeft" title={`考试日期 : ${moment(lastData.date).format('YYYY-MM-DD')} 记录人 : ${lastData.operator}`}>
          {
            lastData.pass ?
              <Row>
                <Tag color="#87d068">通过</Tag>
              </Row>
              :
              <Tag color="#f50">未通过</Tag>
          }
        </Tooltip>
      )
    }
  }



  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Col span={22}><QueryBar config={filterOptions} /></Col>
        <Col span={2}>
          <Button onClick={() => { fetchData() }} type="primary" icon="search" style={{ marginTop: 10, float: 'left', width: 100 }} >查询</Button>
        </Col>
        <Divider />
        <Table
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          scroll={{ x: 'max-content' }}
          loading={loading}
          rowKey={record => record._id}
          dataSource={data}
          pagination={false}
          columns={columns}
          style={{
            marginTop: 10,
          }} />
        <Pagination style={{ marginTop: 10 }}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={['10', '15', '20', '50']}
          pageSize={limit}
          current={page}
          total={total}
          onShowSizeChange={(page, pageSize) => {
            window.localStorage.setItem('pageSize', pageSize)
            setPage(page)
            setLimit(pageSize)
            fetchData(page, pageSize)
          }}
          onChange={(page, pageSize) => {
            setPage(page)
            setLimit(pageSize)
            fetchData(page, pageSize)
          }}
        />
      </Row>




    </Row>
  )
}


