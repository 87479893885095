/*
 * @Author: huangliang
 * @Date: 2019-10-13 13:42:31
 * @LastEditTime: 2020-03-15 16:32:55
 * @LastEditors: huangliang
 * @Description: axios config file
 * @FilePath: /00-pri-driving-web/src/api/axios.js
 * @js files
 */
import axios from 'axios'
import { message } from 'antd'
import { devServerUrl, testServerUrl, fileuploadUrl } from '../config/config'

/**
 * 创建axios实例
 */
const instance = axios.create({
  // baseURL: devServerUrl,
  baseURL: testServerUrl,
  timeout: 1000 * 10
})

/**
 * axios请求前中间件
 */
instance.interceptors.request.use(config => {
  if (localStorage.getItem('accesstoken')) {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('accesstoken')
  }
  return config
}, error => {
  return Promise.reject(error)
})

instance.interceptors.response.use(
  response => {
    return Promise.resolve(response)
  },
  error => {
    if (/timeout\sof\s\d+ms\sexceeded/.test(error.message)) {
      message.error('网络出了点问题，请稍后重试！')
    }
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (window.location.pathname != '/login') {
            window.location.href = '/login'
          }
          break
        case 404:
          message.error('请求的资源不存在！')
          break
        case 500:
          message.error('内部错误，请稍后重试！')
          break
        case 503:
          message.error('服务器正在维护，请稍等！')
          break
      }
    }
    return Promise.reject(error)
  }
)

export const origin = fileuploadUrl

export default instance 