import React from 'react'
import { Transfer, Switch, Table, Tag } from 'antd';
import axios from '../../../api/axios'
import moment from 'moment'
import { GlobalContext } from '../../../store/store'

function difference(array, value) {
  if (!array || !Array.isArray(array) || array.length <= 0) {
    return [];
  }
  var except = Array.from(arguments).slice(1);
  if (!except || except.length <= 0) {
    return array.concat();
  }
  return array.filter(function (value) {
    return except.indexOf(value) < 0;
  });
}


// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => {

  return (
    <Transfer {...restProps} showSelectAll={false}>
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;

        const rowSelection = {
          getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            rowKey='_id'
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        );
      }}
    </Transfer>
  )

}



const leftTableColumns = [
  {
    dataIndex: 'name',
    title: '姓名',
  },
  {
    dataIndex: 'classId',
    title: '班制',
    // render: tag => <Tag>{tag}</Tag>,
  },

  {
    dataIndex: 'signupDate',
    title: '报名时间',
    render: data => data ? moment(data).format('YYYY-MM-DD') : ''
    // render: tag => <Tag>{tag}</Tag>,
  },
  {
    dataIndex: 'certificate',
    title: '证件号',
  },
  {
    dataIndex: 'contact',
    title: '电话',
  },
];
const rightTableColumns = [
  {
    dataIndex: 'name',
    title: '姓名',
  }
];

export default function SelectStudent() {
  const store = React.useContext(GlobalContext)
  const [dataSource, setDataSource] = React.useState([])

  async function getStudentLists() {
    const result = await axios.get('/student/exam')
    console.log(result.data)
    setDataSource(result.data.data)
  }
  React.useEffect(() => {
    getStudentLists()
  }, [])

  return (
    <div style={{
      padding: 10
    }}>
      <TableTransfer
        dataSource={dataSource}
        targetKeys={store.selectedStudents}
        disabled={false}
        showSearch={true}
        onChange={nextTargetKeys => { store.setSelectedStudents(nextTargetKeys) }}
        filterOption={(inputValue, item) =>
          item.classId.indexOf(inputValue) !== -1 || item.name.indexOf(inputValue) !== -1 || item.certificate.indexOf(inputValue) !== -1 || item.contact.indexOf(inputValue) !== -1
        }
        leftColumns={leftTableColumns}
        rightColumns={rightTableColumns}
      />
    </div>
  )
}


