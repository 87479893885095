/*
 * @Author: huangliang
 * @Date: 2019-12-07 16:54:45
 * @LastEditTime : 2019-12-24 17:28:32
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/Login/style.js
 * @js files
 */

import { StyleSheet } from 'aphrodite'

export default StyleSheet.create({
  default: {
    zIndex: 99,
    position: 'absolute',
    marginTop: -50,
    width: '100%',
    height: '100%',
    backgroundImage: 'url("./assets/login.jpeg")',
    backgroundRepeat: 'norepeat',
    backgroundSize: '100% 100%'
  },
  leftPannel: {
    height: '100%',
    backgroundColor: 'white',
    // backgroundImage: "url('https://cdn.dribbble.com/users/15687/screenshots/6362521/gas-pump-night.png')",
    backgroundImage: 'url("./assets/login.jpg")',
    backgroundRepeat: 'norepeat',
    backgroundSize: '100% 100%'
  },
  rightPannel: {
    height: '100%',
    // backgroundColor: 'rgba(0,0,0,0.3)'
  },
  tab: {
    height: 56,
    width: '50%',
    cursor:'pointer',
    textAlign: 'center',
    lineHeight: '56px'
  },
  table:{
    backgroundColor: 'white', 
    padding: 20, 
    paddingTop: 0, 
    paddingBottom: 50
  },
  input: {
    border: 'none',
    width: '100%',
    borderBottom: '1px solid #ebebeb',
    outline: 'none',
    lineHeight: '36px',
    background: 'transparent'
  },
  login: {
    margin: '40% auto 0',
    width: '90%',
    backgroundColor: 'rgba(0,0,0,0.3)'

  }
})