import React from 'react'
import { Menu, Select, PageHeader, Table, Row, Button, Switch, Radio, Icon, Tag, Modal, Col, Input, message, InputNumber, Popconfirm } from 'antd'
import { StyleSheet, css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import { financeParams } from './config'

const { Option } = Select

export default function settings() {
  const columns = [
    {
      title: '序号',
      dataIndex: 'test',
      key: 'test',
      render: (a, b, c) => c + 1
    },
    {
      title: '编号',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '款项名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '款项类型',
      dataIndex: 'type',
      key: 'type',
      render: data => <Tag color="geekblue">{data == 1 ? '学员类' : '其他类'}</Tag>
    },
    {
      title: '款项用途',
      dataIndex: 'use',
      key: 'use',
      render: data => {
        console.log(data)
        if (data == 1) {
          return <Tag color="green">收入</Tag>
        } else if (data == 2) {
          return <Tag color="gold">支出</Tag>
        } else if (data == 3) {
          return <Tag color="blue">代收</Tag>
        } else {
          return '其他'
        }
      }
    },
    {
      title: '金额',
      dataIndex: 'amount',
      key: 'amount',
      render: data => data == 0 ? '' : data
    },
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      sorter: (a, b) => a.sort > b.sort
    },
    {
      title: '状态',
      dataIndex: 'enable',
      key: 'enable',
      render: data => <Switch checkedChildren="启用" unCheckedChildren="未启用" checked={data} onChange={() => { }} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      render: (data, group) => {
        return (
          <Row>
            <span className={css(styles.operate)} onClick={() => { openUpdateModal(group) }}>编辑</span>
            <Popconfirm title="确认删除?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleData(group._id) }}>
              <span className={css(styles.operate)}>删除</span>
            </Popconfirm>
          </Row>
        )
      }
    }
  ]


  /**
   * React State
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [type, setType] = React.useState(1)
  const [use, setUse] = React.useState(1)
  /**
   * [ModalType]:1.add 2.update
   */
  const [modalType, setModalType] = React.useState(1)

  /**
   * 业务相关数据的定义
   */
  const [editId, setEditId] = React.useState('')
  const [code, setCode] = React.useState('')
  const [name, setName] = React.useState('')
  const [sort, setSort] = React.useState('')
  const [enable, setEnable] = React.useState(true)
  const [amount, setAmount] = React.useState(0)



  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
  }, [])

  /**
   * 增加一条数据
   */
  async function createSingleData() {
    try {
      if (!name) { throw `请输入款项名称` }
      if (!type) { throw `请选择款项类型` }
      if (!use) { throw `请选择款项用途` }
      const result = await axios.post(`/finance/config`, {
        code,
        name,
        type,
        use,
        amount,
        sort,
        enable
      })
      if (result.data.success) {
        const newData = result.data.data
        data.push(newData)
        setData(data)
        setVisible(!visible)
      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(error)
    }
  }

  /**
   * 获取分组数据
   */

  async function fetchData() {
    try {
      setLoading(true)
      setData([])
      const data = await axios.get('/finance/config')
      const tableData = data.data.data
      setData(tableData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[ERROR]:')
      console.log(error)
    }

  }

  /**
   * 删除分组数据
   */
  async function deleteSingleData(_id) {
    setLoading(true)
    const result = await axios.delete(`/finance/config/${_id}`)
    setLoading(false)
    if (result.data.success) {
      const newData = data.filter(item => item._id !== _id)
      setData(newData)
      message.success(`删除数据成功`)
    } else {
      message.error(`删除数据失败`)
    }
  }

  /**
   * 更新数据
   */
  async function updateSingleData() {
    try {
      const result = await axios.put(`/finance/config`, {
        _id: editId,
        code,
        name,
        type,
        use,
        amount,
        sort,
        enable
      })
      if (result.data.success) {
        fetchData()
        setVisible(!visible)
      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(`提交数据异常,请联系管理员`)
    }
  }


  /**
   * 打开新建对话框
   */
  async function openCreateModal() {
    setName('')
    setCode('')
    setType(1)
    setSort()
    setEnable(true)
    setVisible(!visible)
    setModalType(1)
    setUse(1)
  }

  /**
   * 打开更新数据对话框
   */
  async function openUpdateModal(info) {
    setName(info.name)
    setCode(info.code)
    setType(parseInt(info.type))
    setSort(info.sort)
    setEnable(info.enable)
    setVisible(!visible)
    setModalType(2)
    setEditId(info._id)
  }
  /**
   * Render View
   */
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Row>
          <Button icon='plus' type='primary' onClick={() => { openCreateModal() }}>新增</Button>
        </Row>
        <Table
          loading={loading}
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          scroll={{ x: 'max-content' }}
          rowKey="_id"
          dataSource={data}
          columns={columns}
          style={{
            marginTop: 10,
          }} />
      </Row>
      <Modal
        title="学员分组"
        visible={visible}
        okText="完成"
        cancelText="取消"
        onOk={() => { modalType === 1 ? createSingleData() : updateSingleData() }}
        onCancel={() => { setVisible(!visible) }}
      >
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}>编号</Col>
          <Col span={18}><InputNumber placeholder="输入分组编号" className={css(styles.modalInput)} value={code} onChange={value => { setCode(value) }} /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>款项名称</Col>
          <Col span={18}><Input placeholder="输入分组名称" className={css(styles.modalInput)} value={name} onChange={e => { setName(e.target.value) }} /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>类型</Col>
          <Col span={18}>
            <Radio.Group style={{ marginLeft: 10, marginTop: 5 }} value={type} onChange={e => { setType(e.target.value) }}>
              <Radio value={1}>学员类</Radio>
              <Radio value={2}>其他</Radio>
            </Radio.Group>
          </Col>
        </Row>

        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>款项用途</Col>
          <Col span={18}>
            <Select value={use} className={css(styles.modalInput)} onChange={value => { setUse(value) }}>
              <Option value={1}>收入</Option>
              <Option value={2}>支出</Option>
              <Option value={3}>代收</Option>
            </Select>
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}>金额</Col>
          <Col span={18}><InputNumber placeholder="没有金额时请勿填写" className={css(styles.modalInput)} value={amount} onChange={value => { setAmount(value) }} /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}>排序</Col>
          <Col span={18}><InputNumber placeholder="排序数字越大排名越靠前" className={css(styles.modalInput)} value={sort} onChange={value => { setSort(value) }} /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}>是否启用</Col>
          <Col span={18}><Switch checkedChildren="启用" unCheckedChildren="停用" checked={enable} onChange={value => { setEnable(value) }} style={{ marginLeft: 10, marginTop: 5 }} /></Col>
        </Row>
      </Modal>
    </Row>
  )
}


