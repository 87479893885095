import React from 'react'
import Content from '../../../components/content/index'
import axios from '../../../api/axios'
import { Table, message, Row, Drawer, List, Avatar, Modal } from 'antd'
import { GlobalContext } from '../../../store/store'
import moment from 'moment'

export default function () {
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [students, setStudents] = React.useState([])

  const columns = [
    {
      key: 'name',
      title: '教练姓名',
      dataIndex: 'name'
    },
    {
      key: 'contact',
      title: '电话',
      dataIndex: 'contact',
    },
    // {
    //   key: 'introducer',
    //   title: '介绍人数',
    //   dataIndex: 'introducer',
    // },
    {
      key: 'teaching',
      title: '总在培人数',
      dataIndex: 'teaching',
    },
    {
      key: 'subjectTwo',
      title: '科目二在培人数',
      dataIndex: 'subjectTwo',
      render: (data, info) => <span style={{
        color: '#1890ff',
        cursor: 'pointer'
      }}
        onClick={() => { setVisible(true); setStudents(data) }}>{data.length}</span>
    },
    {
      key: 'subjectThree',
      title: '科目三在培人数',
      dataIndex: 'subjectThree',
      render: (data, info) => <span style={{
        color: '#1890ff',
        cursor: 'pointer'
      }} onClick={() => { setVisible(true); setStudents(data) }}>{data.length}</span>

    },
    // {
    //   key: '',
    //   title: '操作',
    //   dataIndex: '',
    //   render: (data, info) =>
    //     <span style={{
    //       color: '#1890ff',
    //       cursor: 'pointer'
    //     }} onClick={() => { setVisible(true); }}>查看教练拥有学员</span>
    // }


  ]
  const [loading, setLoading] = React.useState(false)
  async function fetchData() {
    try {
      setLoading(true)
      const result = await axios.post('/statistics/coach')
      setData(result.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message || '服务器端未知错误')
    }

  }


  React.useEffect(() => {
    fetchData()
  }, [])
  return (
    <Content>
      <Table
        loading={loading}
        columns={columns.map(item => {
          const fun = () => ({ style: { whiteSpace: 'nowrap' } });
          item.onHeaderCell = fun;
          item.onCell = fun;
          return item;
        })}
        pagination={false}
        scroll={{ x: 'max-content' }}
        rowKey="contact"
        dataSource={data} columns={columns} />
      <Modal
        title="教练拥有学员"
        width='800px'
        style={{
          marginTop: -50
        }}
        closable={true}
        footer={null}
        onCancel={() => { setVisible(!visible) }}
        visible={visible}
      >

        <Table
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          scroll={{ x: 'max-content' }}

          rowKey="_id"
          // scroll={{ y: 240 }}
          pagination={false}
          style={{
            maxHeight: 500,
            overflowY: 'scroll',
          }}
          columns={[
            {
              title: '姓名',
              dataIndex: 'name',
              key: 'name',
              // fixed: 'left',
              render: (data, info) =>
                <Row style={{
                  color: '#1890ff',
                  cursor: 'pointer'
                }} onClick={() => {
                  store.setStudentFileDrawer(!store.studentFileDrawer);
                  store.setStudentFile(info)
                }}>
                  {data}
                </Row>
            },
            {
              title: '证件号',
              dataIndex: 'certificate',
              key: 'certificate',
              // fixed: 'left',

            },
            {
              title: '联系电话',
              dataIndex: 'contact',
              key: 'contact',
              // fixed: 'left',
            },
            {
              title: '科目二教练',
              dataIndex: 'subjectTwoCoach',
              key: 'subjectTwoCoach',
              render: (data, info) => info.allCoach ? info.allCoach : data
              // fixed: 'left',
            },
            {
              title: '科目二上车时间',
              dataIndex: 'subjectTwoCoachDate',
              key: 'subjectTwoCoachDate',
              render: data => data ? moment(data).format('YYYY-MM-DD HH:mm') : ''
              // fixed: 'left',
            },
            {
              title: '科目三教练',
              dataIndex: 'subjectThreeCoach',
              key: 'subjectThreeCoach',
              render: (data, info) => info.allCoach ? info.allCoach : data

              // fixed: 'left',
            },
            {
              title: '科目三上车时间',
              dataIndex: 'subjectThreeCoachDate',
              key: 'subjectThreeCoachDate',
              render: data => data ? moment(data).format('YYYY-MM-DD HH:mm') : ''
              // fixed: 'left',
            },
            {
              title: '班制',
              dataIndex: 'classId',
              key: 'classId'
            },
          ]}
          dataSource={students}
        />
        {/* <List
          header='科目二在培学员'
          itemLayout="horizontal"
          dataSource={user.subjectTwo || []}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                title={<a href="https://ant.design">{item.name}</a>}
                description={`电话 : ${item.contact}    身份证 : ${item.certificate}`}
              />
            </List.Item>
          )}
        />
        <List
          header='科目三在培学员'
          itemLayout="horizontal"
          dataSource={user.subjectThree || []}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                title={<a href="https://ant.design">{item.name}</a>}
                description={`电话 : ${item.contact}    身份证 : ${item.certificate}`}
              />
            </List.Item>
          )}
        /> */}
      </Modal>
    </Content>
  )
}