/*
 * @Author: huangliang
 * @Date: 2019-10-20 18:26:23
 * @LastEditTime : 2020-01-21 14:18:39
 * @LastEditors  : huangliang
 * @Description: avatar upload file
 * @FilePath: /00-pri-driving-web/src/pages/Student/Signup/upload.js
 * @js files
 */
import React from 'react'
import { GlobalContext } from '../../../store/store'
import { Upload, Icon, message } from 'antd';
import { origin } from '../../../api/axios'

export default function Avatar() {
  const store = React.useContext(GlobalContext)
  const [loading, setLoading] = React.useState(false)
  const uploadButton = (
    <div>
      <Icon type={loading ? 'loading' : 'robot'} />
      <div className="ant-upload-text">上传头像</div>
    </div>
  );
  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  function handleChange(info) {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      console.log(info.file.response.url)
      setLoading(false)
      store.setAvatar(info.file.response.url)
    }
  };
  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={`${origin}/upload/avatar`}
      beforeUpload={file => { beforeUpload(file) }}
      onChange={info => { handleChange(info) }}
    >
      {store.avatar && store.avatar !== '' ? <img src={store.avatar} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
    </Upload>
  )
}
