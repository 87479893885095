import React from 'react'
import { Select, PageHeader, Table, Row, Checkbox, Button, Switch, Divider, DatePicker, Radio, Icon, Tabs, Tag, Modal, Col, Input, message, InputNumber, Popconfirm } from 'antd'
import moment from 'moment'
import { css } from 'aphrodite'
import md5 from 'md5'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import { staffGroupsParams, staffParams, coachParams, carTypes, plainOptions } from './config'
import styles from './style'

import { phoneNumber, certificateId } from '../../../tools/verify'

const { TabPane } = Tabs
const { Option } = Select
const { RangePicker } = DatePicker



/**
 * 页面结构说明(约定:先import 后const))
 * 1.import 需要的第三框库
 * 2.import 自定义的模块
 * 3.const 一些第三方库中的内容
 * 
 * 关于react编写的function components 结构说明
 * 注释:
 * 1.一些没有完成的内容
 * 2.一些已知的[bug]
 * 
 * [常量定义] 如果不变的定量 会在config.js文件中定义从外部引入 
 * 1.表格表头内容定义 : 因为涉及到一些hooks的function的调用 所以无法归纳都api.js文件
 * 2.页面数据模型定义 : 用到的一些对话框的可见 loading层的可见 对话框的属性 新建?编辑?
 * 3.业务相关数据定义 : 一些和业务相关的数据
 * [生命周期事件]
 * 1.componentDidMount事件 [hooksUseEffect]
 * [页面交互事件] 查询的schmea 在config.js文件中定义从外部引入
 * 1.查询业务数据 表格数据、分组数据等
 * 2.增加员工数据事件
 * 3.删除员工数据事件
 * 4.修改员工数据事件
 * [功能性按钮事件]
 * 1.新建数据:打开对话框并初始数据事件
 * 2.更新数据:打开对话框并赋值已有数据事件
 * 
 * [HTML]
 * Dom树
 * 
 * [补充]:关于增删改查function 命名
 * 1.获取数据 fetchData
 * 2.增加一条数据 createSingleData
 * 3.更新一条数据 updateSingleData
 * 4.删除一条数据 deleteSingleData
 * [如果后面有删除多条数据] deleteData
 */


/**
 * [todo]
 * [选择所属报名点应该是下拉框需要先完成报名点设置]
 * [组织机构应该是可以选择的] 
 * [准驾车型应该是可以下拉选择的] [完成]
 * [员工分组应该是可以下拉选择的] [完成]
 */

export default function StaffManager() {
  /**
   * Define Table Colums
   */
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      render: (a, b, c) => c + 1
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex'
    },
    {
      title: '证件号码',
      dataIndex: 'idcard',
      key: 'idcard'
    },
    {
      title: '联系电话',
      dataIndex: 'contact',
      key: 'contact'
    },
    {
      title: '机构',
      dataIndex: 'organization',
      key: 'organization'
    },
    {
      title: '员工分组',
      dataIndex: 'staffGroup',
      key: 'staffGroup'
    },
    {
      title: '报名点',
      dataIndex: 'signupAddress',
      key: 'signupAddress'
    },
    {
      title: '在职',
      dataIndex: 'inService',
      key: 'inService',
      render: data => {
        if (data) {
          return <Row><Tag color="#2db7f5">在职</Tag></Row>
        } else {
          return <Row><Tag color="grey">离职</Tag></Row>
        }
      }
    },
    {
      title: '教练',
      dataIndex: 'isCoach',
      key: 'isCoach',
      render: data => {
        if (data) {
          return <Row><Tag color="#2db7f5">教练</Tag></Row>
        } else {
          return <Row><Tag color="grey">非教练</Tag></Row>
        }
      }
    },
    {
      title: '角色',
      dataIndex: 'role',
      key: 'role',
      render: data => <Row><Tag color="#108ee9">{data}</Tag></Row>
    },

    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort'
    },

    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      // fixed: 'right',
      render: (data, info) => {
        return (
          <Row>
            <span className={css(styles.operate)} onClick={() => { openUpdateModal(info) }}>编辑</span>
            <Popconfirm title="确认删除?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleData(info._id) }}>
              <span className={css(styles.operate)}>删除</span>
            </Popconfirm>
          </Row>
        )
      }
    }
  ]


  /**
   * React State
   * [页面模型数据]
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState('1')
  /**
   * [ModalType]:1.add 2.update
   */
  const [modalType, setModalType] = React.useState(1)
  const [editId, setEditId] = React.useState('')
  const [staffGroups, setStaffGroups] = React.useState([])
  const [userOptions, setUserOptions] = React.useState([])

  /**
   * [员工数据]
   */
  const [name, setName] = React.useState('')
  const [contact, setContact] = React.useState('')
  const [type, setType] = React.useState(1)
  const [idcard, setIdCard] = React.useState('')
  const [birth, setBirth] = React.useState(moment())
  const [sex, setSex] = React.useState('')
  const [organization, setOrganization] = React.useState('')
  const [job, setJob] = React.useState('')
  const [signupAddress, setSignupAddress] = React.useState('')
  const [inService, setInService] = React.useState(1)
  const [entry, setEntry] = React.useState(moment().format())
  const [leave, setLeave] = React.useState(moment().format())
  const [lisence, setLience] = React.useState('')
  const [firstLisenceDate, setFirstLisence] = React.useState(moment().format())
  const [allowCarType, setAllowCar] = React.useState([])
  const [staffGroup, setStaffGroup] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [contractStart, setContactStart] = React.useState('')
  const [contractEnd, setContactEnd] = React.useState('')
  const [isCoach, setIsCoach] = React.useState(false)
  const [isSystemUser, setSystemUser] = React.useState(false)
  const [isSystemAccount, setIsSysAcc] = React.useState(false)
  const [role, setRole] = React.useState('')
  const [sort, setSort] = React.useState('')

  /**
   * [教练信息]
   */
  const [coachId, setCoachId] = React.useState('')
  const [subject, setSubject] = React.useState('科目二')
  const [coachType, setCoachTyp] = React.useState(1)
  const [maxNumber, setMaxNumber] = React.useState('')
  const [carId, setCarId] = React.useState('')
  const [subjectTwo, setTwo] = React.useState('')
  const [subjectThree, setThree] = React.useState('')
  const [coachGroup, setCoachGroup] = React.useState('')
  const [certificate, setCertificate] = React.useState('')
  const [certificateLevel, setCerLevle] = React.useState('等级一')
  const [coachAllowCarType, setCoachAllowCar] = React.useState([])
  const [introduction, setIntroduction] = React.useState('')

  /**
   * componentDidMount
   */
  React.useEffect(() => {
    // getStaffGroup()
    fetchData()
  }, [])

  // /**
  //  * 获取员工分组数据
  //  * 录入员工数据的基础数据
  //  */
  // async function getStaffGroup() {
  //   try {
  //     const data = await axios.post('/graphql', staffGroupsParams)
  //     console.log(`获取员工分组信息:`)
  //     const staffData = data.data.data.staffGroups
  //     setStaffGroups(staffData)
  //   } catch (error) {
  //     message.error(`获取员工分组数据失败`)
  //   }
  // }

  /**
   * 获取表格数据
   */
  async function fetchData() {
    try {
      setLoading(true)
      setData([])
      const data = await axios.get('/staff/manager')
      const tableData = data.data.data
      setData(tableData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(`获取员工信息失败`)
      console.log('[ERROR]:')
      console.log(error)
    }
  }

  /**
   * 增加员工
   */
  async function createSingleData() {
    try {
      if (name === '') { throw `请输入员工姓名` }
      if (!contact) { throw `请输入员工联系电话` }
      if (!phoneNumber.test(contact)) {
        throw `请输入合法有效的联系电话`
      }
      if (idcard !== '') {
        console.log('身份证号码不为空')
        if (!certificateId.test(idcard)) {
          throw `请输入合法有效的身份证号码`
        }
      }
      if (!type) { throw `请选择员工类型` }

      if (!organization) { throw `请选择所属组织机构` }
      if (isCoach) {
        if (!subject) { throw `请选择教练所教科目` }
        if (!coachType) { throw `请选择教练类型` }
        if (coachAllowCarType.length === 0) { throw `请选择准教车型` }
      }
      const staffData = {
        name,
        contact,
        type,
        idcard,
        birth: moment(birth).format('YYYY-MM-DD'),
        sex,
        organization,
        job,
        signupAddress,
        inService: inService === 1 ? true : false,
        entry: moment(entry).format('YYYY-MM-DD'),
        leave: modalType === 1 ? '' : moment(leave).format('YYYY-MM-DD'),
        lisence,
        firstLisenceDate,
        allowCarType,
        staffGroup,
        address,
        contractStart,
        contractEnd,
        isCoach,
        sort,
        subject,
        coachtype: coachType,
        maxNumber,
        carId,
        subjectTwo,
        subjectThree,
        coachGroup,
        certificate,
        certificateLevel,
        introduction,
        isSystemAccount,
        allowTeaCarType: coachAllowCarType,
        password: md5('123456'),
        role
      }
      const result = await axios.post(`/staff/manager`, staffData)
      if (result.data.success) {
        const newData = result.data.data
        data.push(newData)
        setData(data)
        setVisible(!visible)
      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(error)
    }
  }

  /**
   * 删除员工数据
   */
  async function deleteSingleData(_id) {
    setLoading(true)
    const result = await axios.delete(`/staff/manager/${_id}`)
    setLoading(false)
    if (result.data.success) {
      const newData = data.filter(item => item._id !== _id)
      setData(newData)
      message.success(`删除数据成功`)
    } else {
      message.error(`删除数据失败`)
    }
  }

  /**
   * 修改数据
   */
  async function updateSingleData() {
    try {
      if (name === '') { throw `请输入员工姓名` }
      if (!contact) { throw `请输入员工联系电话` }
      if (!type) { throw `请选择员工类型` }
      if (!organization) { throw `请选择所属组织机构` }
      if (isCoach) {
        if (!subject) { throw `请选择教练所教科目` }
        if (!coachType) { throw `请选择教练类型` }
        if (coachAllowCarType.length === 0) { throw `请选择准教车型` }
      }
      const staffData = {
        _id: editId,
        name,
        contact,
        type,
        idcard,
        birth: moment(birth).format('YYYY-MM-DD'),
        sex,
        organization,
        job,
        signupAddress,
        inService: inService === 1 ? true : false,
        entry: moment(entry).format('YYYY-MM-DD'),
        leave: modalType === 1 ? '' : moment(leave).format('YYYY-MM-DD'),
        lisence,
        firstLisenceDate,
        allowCarType,
        staffGroup,
        address,
        contractStart,
        contractEnd,
        isCoach,
        sort,
        isSystemAccount,
        role,
        subject,
        maxNumber,
        carId,
        subjectTwo,
        subjectThree,
        coachGroup,
        certificate,
        certificateLevel,
        allowTeaCarType: coachAllowCarType,
        introduction
        // coach: !isCoach ? {} : {
        //   coachId: coachId,
        //   type: coachType,

        // }
      }
      const result = await axios.put(`/staff/manager`, staffData)
      if (result.data.success) {
        fetchData()
        setVisible(!visible)
      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(error)
    }
  }

  /**
   * 初始化教练员数据通用方法
   * @param {*}
   */
  function initCoachData() {
    setSubject('科目二')
    setCoachTyp(1)
    setMaxNumber('')
    setCarId('')
    setTwo('')
    setThree('')
    setCoachGroup('')
    setCertificate('')
    setCerLevle('等级一')
    setCoachAllowCar([])
    setIntroduction('')
  }


  /**
   * 更新赋值数据
   */
  async function openUpdateModal(info) {
    setVisible(!visible)
    setModalType(2)
    setEditId(info._id)
    setName(info.name)
    setContact(info.contact)
    setType(info.type)
    setIdCard(info.idcard)
    setBirth(moment(info.birth))
    setSex(info.sex)
    setOrganization(info.organization)
    setJob(info.job)
    setSignupAddress(info.signupAddress)
    setInService(info.inService ? 1 : 2)
    setEntry(moment(info.entry))
    setLeave(moment(info.leave || ''))
    setLience(info.lisence)
    setFirstLisence(moment(info.firstLisenceDate))
    setAllowCar(info.allowCarType)
    setStaffGroup(info.staffGroup)
    setAddress(info.address)
    setContactStart(moment(info.contractStart))
    setContactEnd(moment(info.setContactEnd))
    setIsSysAcc(info.isSystemAccount)
    setSort(info.sort)
    setIsCoach(info.isCoach)
    let userType = []
    if (info.isSystemAccount) {
      userType.push('系统用户')
      setRole(info.role)
    }
    
    if (info.isCoach) {
      userType.push('教练')
      // const result = await axios.post('/graphql', coachParams(info.coachId))
      const coach = info
      setIsCoach(true)
      console.log(coach.allowTeaCarType)
      // setCoachId(coach._id || '')
      setSubject(coach.subject)
      setCoachTyp(coach.type)
      setMaxNumber(coach.maxNumber)
      setCarId(coach.carId)
      setTwo(coach.subjectTwo)
      setThree(coach.subjectThree)
      setCoachGroup(coach.coachGroup)
      setCertificate(coach.certificate)
      setCerLevle(coach.certificateLevel)
      setCoachAllowCar(coach.allowTeaCarType)
      setIntroduction(coach.introduction)
    } else {
      initCoachData()
    }
    console.log(userType)
    setUserOptions(userType)
  }

  /**
   * 初始化模态框数据
   */
  async function openCreateModal() {
    setVisible(!visible)
    setModalType(1)
    setActiveKey('1')
    setName('')
    setContact()
    setType(1)
    setIdCard('')
    setBirth(moment())
    setSex()
    setOrganization('')
    setJob('')
    setSignupAddress('')
    setInService(1)
    setEntry(moment())
    setLeave(moment())
    setLience('')
    setFirstLisence(moment())
    setAllowCar([])
    setStaffGroup('')
    setContactStart(moment())
    setContactEnd(moment())
    setIsCoach(false)
    setSort('')
    initCoachData()
    setUserOptions([])
  }


  const subjectTwoTraningPlace = store.traningPlaces.filter(item => item.subject === '科目二')
  const subjectThrTraningPlace = store.traningPlaces.filter(item => item.subject === '科目三')

  /**
   * Render View
   */
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Row>
          <Button icon='plus' type='primary' onClick={() => { openCreateModal() }}>新增</Button>
        </Row>
        <Table
          loading={loading}
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          scroll={{ x: 'max-content' }}
          rowKey="_id"
          dataSource={data}
          columns={columns}
          style={{
            marginTop: 10,
          }} />
      </Row>
      <Modal
        title="学员分组"
        width="64%"
        style={{
          marginTop: -50
        }}
        visible={visible}
        okText="完成"
        cancelText="取消"
        onOk={() => {
          if (modalType == 1) {
            createSingleData()
          } else {
            updateSingleData()
          }
        }}

        onCancel={() => { setVisible(!visible) }}
      >
        <Tabs style={{ marginTop: -10 }} activeKey={activeKey} onChange={value => { console.log(value); setActiveKey(value) }}>
          <TabPane tab="员工信息" key="1">
            <Row>
              <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>姓名</Col>
              <Col span={5}>
                <Input placeholder="输入员工姓名" className={css(styles.modalInput)} value={name} onChange={e => { setName(e.target.value) }} />
              </Col>
              <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>手机号码</Col>
              <Col span={5}>
                <Input placeholder="输入电话号码" className={css(styles.modalInput)} value={contact} onChange={e => { setContact(e.target.value) }} />
              </Col>
              <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>员工类型</Col>
              <Col span={5}>
                <Select value={type} className={css(styles.modalInput)} onChange={value => { setType(value) }}>
                  <Option value={1}>内部员工</Option>
                  <Option value={2}>外部员工</Option>
                </Select>
              </Col>
            </Row>
            <Row className={css(styles.modalRow)}>
              <Col className={css(styles.modalRowText)} span={3}>身份证号</Col>
              <Col span={5}>
                <Input placeholder="输入身份证号" className={css(styles.modalInput)} value={idcard} onChange={e => { setIdCard(e.target.value) }} />
              </Col>
              <Col className={css(styles.modalRowText)} span={3}>出生日期</Col>
              <Col span={5}>
                <DatePicker className={css(styles.modalInput)} defaultValue={birth} onChange={value => { setBirth(value) }} />
              </Col>
              <Col className={css(styles.modalRowText)} span={3}>性别</Col>
              <Col span={5}>
                <Select value={sex} className={css(styles.modalInput)} onChange={value => { setSex(value) }}>
                  <Option value="男">男</Option>
                  <Option value="女">女</Option>
                </Select>
              </Col>
            </Row>
            <Row className={css(styles.modalRow)}>
              <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>组织机构</Col>
              <Col span={5}>
                <Input placeholder="请输入组织机构" className={css(styles.modalInput)} value={organization} onChange={e => { setOrganization(e.target.value) }} />
              </Col>
              <Col className={css(styles.modalRowText)} span={3}>岗位</Col>
              <Col span={5}>
                <Input placeholder="输入岗位" className={css(styles.modalInput)} value={job} onChange={e => { setJob(e.target.value) }} />
              </Col>
              <Col className={css(styles.modalRowText)} span={3}>所属报名点</Col>
              <Col span={5}>
                <Input placeholder="所属报名点" className={css(styles.modalInput)} value={signupAddress} onChange={e => { setSignupAddress(e.target.value) }} />
              </Col>
            </Row>
            <Row className={css(styles.modalRow)}>
              <Col className={css(styles.modalRowText)} span={3}>在职状态</Col>
              <Col span={5}>
                <Radio.Group style={{ marginLeft: 10, marginTop: 5 }} value={inService} onChange={e => { setInService(e.target.value) }}>
                  <Radio value={1}>在职</Radio>
                  <Radio value={2}>离职</Radio>
                </Radio.Group>
              </Col>
              <Col className={css(styles.modalRowText)} span={3}>入职时间</Col>
              <Col span={5}>
                <DatePicker className={css(styles.modalInput)} placeholder="选择入职时间" value={entry} onChange={value => { setEntry(value) }} />
              </Col>
              <Col className={css(styles.modalRowText)} span={3}>离职时间</Col>
              <Col span={5}>
                <DatePicker className={css(styles.modalInput)} placeholder="选择离职时间" onChange={value => { setLeave(value) }} disabled={modalType === 1 ? true : false} />
              </Col>
            </Row>
            <Row className={css(styles.modalRow)}>
              <Col className={css(styles.modalRowText)} span={3}>驾驶证号</Col>
              <Col span={5}>
                <Input placeholder="驾驶证号" className={css(styles.modalInput)} value={lisence} onChange={e => { setLience(e.target.value) }} />
              </Col>
              <Col className={css(styles.modalRowText)} span={3}>初领日期</Col>
              <Col span={5}>
                <DatePicker className={css(styles.modalInput)} value={firstLisenceDate} placeholder="初次领证时间" onChange={value => { setFirstLisence(value) }} />
              </Col>
              <Col className={css(styles.modalRowText)} span={3}>准驾车型</Col>
              <Col span={5}>
                <Select
                  mode="multiple"
                  className={css(styles.modalInput)}
                  placeholder="请选择准驾车型"
                  value={allowCarType}
                  // defaultValue={['a10', 'c12']}
                  onChange={value => { setAllowCar(value) }}
                >
                  {
                    carTypes.map(item => <Option key={item}>{item}</Option>)
                  }
                </Select>
                {/* <Input placeholder="准驾车型" className={css(styles.modalInput)} value={lisence} onChange={e => { setLience(e.target.value) }} /> */}
              </Col>

            </Row>
            <Row className={css(styles.modalRow)}>
              <Col className={css(styles.modalRowText)} span={3}>员工分组</Col>
              <Col span={5}>

                <Select value={staffGroup} placeholder="下拉选择分组" className={css(styles.modalInput)} onChange={value => { setStaffGroup(value) }} value={staffGroup}>
                  {
                    store.staffGroups && store.staffGroups.length > 0 && store.staffGroups.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)
                  }
                </Select>
              </Col>
              <Col className={css(styles.modalRowText)} span={3}>联系地址</Col>
              <Col span={5}>
                <Input placeholder="联系地址" className={css(styles.modalInput)} value={address} onChange={e => { setAddress(e.target.value) }} />

              </Col>
              <Col className={css(styles.modalRowText)} span={3}>排序</Col>
              <Col span={5}>
                <InputNumber placeholder="排序数字越大排名越靠前" className={css(styles.modalInput)} value={sort} onChange={value => { setSort(value) }} />
              </Col>
            </Row>
            <Row className={css(styles.modalRow)}>
              <Col className={css(styles.modalRowText)} span={3}>劳动合同</Col>
              <Col span={13}>
                <RangePicker className={css(styles.modalInput)} value={[contractStart, contractEnd]} onChange={value => {
                  setContactStart(value[0])
                  setContactEnd(value[1])

                }} />
              </Col>
            </Row>
          </TabPane>
          {
            isCoach
              ?
              <TabPane tab="教练信息" key="2" >
                <Row className={css(styles.modalRow)}>
                  <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>科目</Col>
                  <Col span={5}>
                    <Select value={subject} className={css(styles.modalInput)} onChange={value => { setSubject(value) }}>
                      <Option value="科目二">科目二</Option>
                      <Option value="科目三">科目三</Option>
                      <Option value="全科">全科</Option>
                    </Select>
                  </Col>
                  <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>类型</Col>
                  <Col span={5}>
                    <Select value={coachType} className={css(styles.modalInput)} onChange={value => { setCoachTyp(value) }}>
                      <Option value={1}>直属</Option>
                      <Option value={2}>挂靠</Option>
                      <Option value={3}>其他</Option>
                    </Select>
                  </Col>
                  <Col className={css(styles.modalRowText)} span={3}>培训人数</Col>
                  <Col span={5}>
                    <InputNumber placeholder="最大培训人数" className={css(styles.modalInput)} value={maxNumber} onChange={value => { setMaxNumber(value) }} />
                  </Col>
                </Row>
                <Row className={css(styles.modalRow)}>
                  <Col className={css(styles.modalRowText)} span={3}>车辆</Col>
                  <Col span={21}>
                    <Input placeholder="请输入车牌号" className={css(styles.modalInput)} value={carId} onChange={e => { setCarId(e.target.value) }} />
                  </Col>
                </Row>
                <Row className={css(styles.modalRow)}>
                  <Col className={css(styles.modalRowText)} span={3}>科二训练场</Col>
                  <Col span={5}>
                    <Select value={subjectTwo} className={css(styles.modalInput)} onChange={value => { setTwo(value) }}>
                      {
                        subjectTwoTraningPlace.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)
                      }
                    </Select>
                  </Col>
                  <Col className={css(styles.modalRowText)} span={3}>科三训练场</Col>
                  <Col span={5}>
                    <Select value={subjectThree} className={css(styles.modalInput)} onChange={value => { setThree(value) }}>
                      {
                        subjectThrTraningPlace.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)
                      }
                    </Select>
                  </Col>
                  <Col className={css(styles.modalRowText)} span={3}>教练分组</Col>
                  <Col span={5}>
                    <Select value={coachGroup} value={coachGroup} className={css(styles.modalInput)} onChange={value => { setCoachGroup(value) }}>
                      <Option value="分组1">分组1</Option>
                      <Option value="分组2">分组2</Option>
                      <Option value="分组3">分组3</Option>
                    </Select>
                  </Col>
                </Row>
                <Row className={css(styles.modalRow)}>
                  <Col className={css(styles.modalRowText)} span={3}>资格证号</Col>
                  <Col span={5}>
                    <Input placeholder="请输入资格证号" className={css(styles.modalInput)} value={certificate} onChange={e => { setCertificate(e.target.value) }} />

                  </Col>
                  <Col className={css(styles.modalRowText)} span={3}>资格等级</Col>
                  <Col span={5}>
                    <Select value={certificateLevel} className={css(styles.modalInput)} onChange={value => { setCerLevle(value) }}>
                      <Option value="一级">一级</Option>
                      <Option value="二级">二级</Option>
                      <Option value="三级">三级</Option>
                      <Option value="四级">四级</Option>
                    </Select>
                  </Col>
                  <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>准教车型</Col>
                  <Col span={5}>
                    <Select
                      mode="multiple"
                      className={css(styles.modalInput)}
                      placeholder="请选择准教车型"
                      value={coachAllowCarType}
                      onChange={value => { setCoachAllowCar(value) }}
                    >
                      {
                        carTypes.map(item => <Option key={item}>{item}</Option>)
                      }
                    </Select>
                  </Col>
                </Row>
                <Row className={css(styles.modalRow)}>
                  <Col className={css(styles.modalRowText)} span={3}>教练简介</Col>
                  <Col span={21}>
                    <Input placeholder="请输入教练简介" className={css(styles.modalInput)} value={introduction} onChange={e => { setIntroduction(e.target.value) }} />
                  </Col>
                </Row>
              </TabPane>
              : ''
          }
          {
            isSystemAccount ?
              <TabPane key="3" tab="系统用户" >
                <Row className={css(styles.modalRow)}>
                  <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>登陆账号</Col>
                  <Col span={5}>
                    <Input className={css(styles.modalInput)} value={contact} disabled={true} />
                  </Col>
                  <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>角色</Col>
                  <Col span={5}>
                    <Select value={role} className={css(styles.modalInput)} onChange={value => { setRole(value) }}>
                      {
                        store.roles.map(item => <Option key={item._id} value={item.name}>{item.name}</Option>)
                      }
                    </Select>
                  </Col>
                  <Col className={css(styles.modalRowText)} span={3}>操作</Col>
                  <Col span={5}>
                    <Button icon="bug" type="danger" className={css(styles.modalInput)}>重置密码</Button>
                  </Col>
                </Row>
              </TabPane>
              : ''
          }
        </Tabs>
        <Divider />
        <Checkbox.Group options={plainOptions} value={userOptions} onChange={value => {
          console.log(value)
          setUserOptions(value)
          if (value.includes('教练')) {
            setIsCoach(true)
          }
          if (value.includes('系统用户')) {
            setIsSysAcc(true)
            if (!phoneNumber.test(contact)) {
              message.error(`添加系统用户前请输入合法有效的联系电话`)
              return
            } else {
              setSystemUser(true)
            }
          }
        }}
        />
        <Row></Row>
      </Modal>
    </Row>
  )
}
