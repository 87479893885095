/*
 * @Author: huangliang
 * @Date: 2020-01-05 15:07:42
 * @LastEditTime : 2020-01-05 17:14:18
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/components/studentfile/index.js
 * @js files
 */
import React from 'react'
import { Drawer, Tabs } from 'antd'
import { GlobalContext } from '../../store/store'
import Basic from './basic'
import Finance from './finance'
import Exam from './exam'
const { TabPane } = Tabs

export default function StudentFile() {
  const store = React.useContext(GlobalContext)
  return (
    <Drawer
      title="测试学员的档案"
      width="75%"
      placement="right"
      closable={true}
      onClose={() => { store.setStudentFileDrawer(!store.studentFileDrawer)}}
      visible={store.studentFileDrawer}
    >
      <Tabs defaultActiveKey="1" tabPosition="left">
        <TabPane tab="学员基本信息" key="1">
          <Basic />
        </TabPane>
        <TabPane tab="学员财务信息" key="2">
          <Finance />
        </TabPane>
        <TabPane tab="学员考试信息" key="3">
          <Exam />
        </TabPane>
      </Tabs>
    </Drawer>
  )
}