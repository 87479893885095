/*
 * @Author: huangliang
 * @Date: 2019-10-20 09:38:51
 * @LastEditTime: 2019-12-07 15:10:50
 * @LastEditors: huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/HR/Staff/config.js
 * @js files
 */
/**
 * [config文件结构说明]
 * 1.关于查询时用到的graphql query 模型
 * 2.关于一些常量的定义
 */



/**
 * 员工分组信息
 */
export const staffGroupsParams = {
  query: `query{
    staffGroups{
      name
    }
  }`
}


/**
 * 查询员工分组信息
 */
export const staffParams = {
  query: `query{
    staffs{
    _id
    name
    type
    contact
    idcard
    birth
    sex
    organization
    job
    signupAddress
    inService
    entry
    leave
    lisence
    firstLisenceDate
    allowCarType
    staffGroup
    address
    contractStart
    contractEnd
    isCoach
    coachId
    isSystemAccount
    role
    sort
    createAt
    lastModified
  }
}`
}

/**
 * 员工教练信息
 */

export const coachParams = id => {
  return ({
    query:
      `
        query{
          coach(id:"${id}"){
            _id
            subject
            type
            maxNumber
            carId
            subjectTwo
            subjectThree
            coachGroup
            certificate
            certificateLevel
            allowCarType
            introduction
          }
        }
      `})
}




/**
 * 准驾类型以及准准教类型
 */
export const carTypes = ['C1', 'C2', 'A1', 'A2', 'A3', 'B1', 'B2', 'C3', 'C4', 'C5', 'D', 'E', 'F', 'M', 'N', 'P']

/**
 * 对话框底部的option选项
 */

export const plainOptions = ['教练','系统用户']
