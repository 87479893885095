import React from 'react'
import { Alert, Select, Divider, Table, Row, Button, Switch, DatePicker, Radio, Drawer, Tag, Modal, Col, Input, message, InputNumber, Popconfirm } from 'antd'
import { css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import Avatar from './upload'
import moment from 'moment'
import QueryBar from '../../../components/query/index'
import {
  studentsSchema,
  carTypes,
  payTypes,
  costTypes,
  businessOptions,
  certificateOptions,
  sexOptions,
  signUpSourcesOption,
  filterOptions
} from './config'
import {
  certificateId,
  phoneNumber
} from '../../../tools/verify'


const { Option } = Select

export default function TrainingPlace() {
  /**
   * Define Table Colums
   */

  const financeColums = [
    {
      title: '序号',
      dataIndex: 'name',
      key: 'name',
      render: (data, info, index) => `${index + 1}`
    },
    {
      title: '费用类型',
      dataIndex: 'type',
      key: 'type',
      render: (data, info, index) => {
        if (info.checked) {
          return data
        } else {
          return (
            <Select disabled value={data} onChange={value => { editFinanceDetail(index, 'type', value) }}>
              {costTypes.map(item => <Option value={item} key={item}>{item}</Option>)}
            </Select>
          )
        }
      }
    },
    {
      title: '费用',
      dataIndex: 'cost',
      key: 'cost',
      render: (data, info, index) => {
        if (info.checked) {
          return data
        } else {
          return <InputNumber disabled value={data} onChange={(value) => { editFinanceDetail(index, 'cost', value) }} />
        }
      }
    },

    {
      title: '支付类型',
      dataIndex: 'payType',
      key: 'payType',
      render: (data, info, index) => {
        if (info.checked) {
          return data
        } else {
          return (
            <Row>
              <span className={css(styles.selectNeed)}>*</span>
              <Select value={data} onChange={value => { editFinanceDetail(index, 'payType', value) }} style={{ minWidth: 120 }}>
                {store.payTypes.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)}
              </Select>
            </Row>

          )
        }
      }
    },
    {
      title: '实缴金额',
      dataIndex: 'paid',
      key: 'paid',
      render: (data, info, index) => {
        if (info.checked) {
          return data
        } else {
          return <InputNumber value={data} onChange={(value) => { editFinanceDetail(index, 'paid', value) }} />
        }
      }
    },
    {
      title: '优惠金额',
      dataIndex: 'offer',
      key: 'offer',
      render: (data, info, index) => {
        if (info.checked) {
          return data
        } else {
          return <InputNumber value={data} onChange={(value) => { editFinanceDetail(index, 'offer', value) }} />
        }
      }
    },
    {
      title: '优惠原因',
      dataIndex: 'offerReason',
      key: 'offerReason',
      width: 100,
      render: (data, info, index) => {
        if (info.checked) {
          return data
        } else {
          return <Input value={data} onChange={(e) => { editFinanceDetail(index, 'offerReason', e.target.value) }} />
        }
      }
    },
    {
      title: '欠费',
      dataIndex: 'arrears',
      key: 'arrears',
      render: (data, info, index) => <span style={{ color: 'red' }}>{data}</span>
      // render: (data, info, index) => <InputNumber value={data} onChange={(value) => { editFinanceDetail(index, 'arrears', value) }} />
    },
    {
      title: '票号',
      dataIndex: 'bills',
      key: 'bills',
      width: 100,
      render: (data, info, index) => <Input value={data} onChange={(e) => { editFinanceDetail(index, 'bills', e.target.value) }} />
    },
    {
      title: '说明',
      dataIndex: 'comments',
      key: 'comments',
      width: 100,
      render: (data, info, index) => <Input value={data} onChange={(e) => { editFinanceDetail(index, 'comments', e.target.value) }} />
    }
  ]

  const columns = [
    {
      title: '序号',
      dataIndex: '',
      key: '',
      render: (data, info, index) => `${index + 1}`
    },
    {
      title: '报名时间',
      dataIndex: 'signupDate',
      key: 'signupDate',
      render: data => moment(data).format('YYYY-MM-DD')
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '证件号',
      dataIndex: 'certificate',
      key: 'certificate'
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex'
    },
    {
      title: '联系电话',
      dataIndex: 'contact',
      key: 'contact'
    },
    {
      title: '应缴金额',
      dataIndex: 'cost',
      key: 'cost'
    },
    {
      title: '实缴金额',
      dataIndex: 'paid',
      key: 'paid'
    },
    {
      title: '欠费',
      dataIndex: 'arrears',
      key: 'arrears',
      render: data => <Row style={{ color: data > 0 ? '#ffa39e' : '' }}>{data}</Row>
    },
    {
      title: '报名地点',
      dataIndex: 'signupSpot',
      key: 'signupSpot'
    },
    {
      title: '班制',
      dataIndex: 'classId',
      key: 'classId'
    },

    {
      title: '介绍人',
      dataIndex: 'introducer',
      key: 'introducer'
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      fixed: 'right',
      render: (data, group) => {
        return (
          <Row>
            <span className={css(styles.operate)} onClick={() => { openUpdateModal(group) }}>编辑</span>
            <Popconfirm title="确认删除?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleData(group._id) }}>
              <span className={css(styles.operate)}>删除</span>
            </Popconfirm>
          </Row>
        )
      }
    }
  ]


  /**
   * React State
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [modalType, setModalType] = React.useState(1)
  const [editId, setEditId] = React.useState('')
  const [alertMsg, setAlertMsg] = React.useState('初始化提示信息')
  /**
   * 业务相关数据的定义
   */
  const [name, setName] = React.useState('')
  const [nation, setNation] = React.useState('')
  const [sex, setSex] = React.useState('男')
  const [certificateType, setCerType] = React.useState('身份证')
  const [certificate, setCer] = React.useState('')
  const [birth, setBirth] = React.useState('')
  const [certificateValid, serCerValid] = React.useState('')
  const [contact, setContact] = React.useState('')
  const [code, setCode] = React.useState('')
  const [certificateAddress, serCerAddr] = React.useState('')
  const [liveAddress, setLiveAddress] = React.useState('')
  const [signupSource, setSignupSource] = React.useState('')
  const [signupDate, setSignupDate] = React.useState('')
  const [signupSpot, setSignupSpot] = React.useState('')
  const [introducer, setIntroducer] = React.useState('')
  const [businessType, setBusinessType] = React.useState(businessOptions[0])
  const [primaryCarType, setPriCarType] = React.useState('')
  const [primaryCertificate, setPrimaryCer] = React.useState('')
  const [studentGroup, setStudentGroup] = React.useState('')
  const [carType, setCarType] = React.useState('')
  const [classId, setClass] = React.useState('')
  const [traningPlace, setTraningPlace] = React.useState('')
  const [preCoach, setPreCoach] = React.useState('')
  const [dataComplete, setDataComplete] = React.useState()
  const [comments, setComments] = React.useState('')
  const [charges, setCharge] = React.useState([])
  const [follower, setFollower] = React.useState('')

  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
  }, [])

  /**
   * 增加一条数据
   */
  async function createSingleData() {
    try {
      if (!introducer) { throw `请输入介绍人` }
      if (!signupSpot) { throw `请选择报名地点` }
      if (name === '') { throw `请输入学员姓名` }
      if (nation === '') { throw `请输入学员国籍` }
      if (!certificate) { throw `请输入学员证件号码` }
      // if (!certificateId.test(certificate)) { throw `请输入合法有效的证件号码` }
      if (contact === '') { throw `请输入学员联系电话` }
      if (!phoneNumber.test(contact)) { throw `请输入合法有效的电话号码` }
      if (signupDate === '') { throw `请选择学员报名时间` }
      if (businessType === '') { throw `请选择学员的业务类型` }
      if (classId === '') { throw `请选择学员的班制` }
      if (!follower) { throw `跟踪客服为必须选择` }
      let access = true
      charges.map(item => {
        if (!item.payType) {
          access = false
        }
      })
      if (!access) {
        throw `请选择付款方式`
      }
      if (dataComplete !== true && dataComplete !== false) {
        throw `请选择资料是否齐全`
      }
      const judgeIsExist = await axios.get(`/student/indend/exist/${contact}`)
      if (judgeIsExist.data.success) {
        if (judgeIsExist.data.isExist) {
          throw `该学员存在于意向学员中,请从意向学员中转报名`
        } else {
          const result = await axios.post(`/student/signup`, {
            name,
            nation,
            sex,
            certificateType,
            certificate,
            birth,
            certificateValid,
            contact,
            code,
            avatar: store.avatar,
            certificateAddress,
            liveAddress,
            signupSource,
            signupDate,
            signupSpot,
            introducer,
            businessType,
            businessType,
            primaryCarType,
            primaryCertificate,
            studentGroup,
            carType,
            classId,
            traningPlace,
            preCoach,
            dataComplete,
            comments,
            charges,
            follower
          })
          if (result.data.success) {
            const newData = result.data.data
            data.push(newData)
            setData(data)
            setVisible(!visible)
          } else {
            message.error(result.data.failedMessage || '提交数据异常、未捕获的错误')
          }
        }
      }
    } catch (error) {
      message.error(error.message || error)
    }
  }



  async function fetchData(signupSpot, filter) {
    try {
      const condition = {}
      if (store.condition.status) {
        condition.status = store.condition.status
      }
      if (store.condition.signupDate) {
        condition.signupDateStart = store.condition.signupDate[0]
        condition.signupDateEnd = store.condition.signupDate[1]
      }
      if (store.condition.filter) {
        condition.filter = store.condition.filter
      }
      if (store.condition.classId) {
        condition.classId = store.condition.classId
      }
      if (store.condition.signupSpot) {
        condition.signupSpot = store.condition.signupSpot
      }
      if (store.condition.introducer) {
        condition.introducer = store.condition.introducer
      }
      condition.isFirstCheck = false
      setLoading(true)
      const result = await axios.post('/student/query', condition)
      const tableData = result.data.data
      setData(tableData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[ERROR]:')
      console.log(error)
    }
  }

  /**
   * 删除分组数据
   */
  async function deleteSingleData(_id) {
    setLoading(true)
    const result = await axios.delete(`/student/signup/${_id}`)
    setLoading(false)
    if (result.data.success) {
      const newData = data.filter(item => item._id !== _id)
      setData(newData)
      message.success(`删除数据成功`)
    } else {
      message.error(`删除数据失败`)
    }
  }

  /**
   * 更新数据
   */
  async function updateSingleData() {
    try {

      if (name === '') { throw `请输入学员姓名` }
      if (nation === '') { throw `请输入学员国籍` }
      if (!certificate) { throw `请输入学员证件号码` }
      // if (!certificateId.test(certificate)) { throw `请输入合法有效的证件号码` }
      if (contact === '') { throw `请输入学员联系电话` }
      if (!introducer) { throw `请输入介绍人` }
      if (!phoneNumber.test(contact)) { throw `请输入合法有效的电话号码` }
      if (!signupSpot) { throw `请选择报名地点` }
      if (signupDate === '') { throw `请选择学员报名时间` }
      if (businessType === '') { throw `请选择学员的业务类型` }
      if (classId === '') { throw `请选择学员的班制` }
      if (!follower) { throw `跟踪客服为必须选择` }

      const result = await axios.put(`/student/signup`, {
        _id: editId,
        name,
        nation,
        sex,
        certificateType,
        certificate,
        birth,
        certificateValid,
        contact,
        code,
        avatar: store.avatar,
        certificateAddress,
        liveAddress,
        signupSource,
        signupDate,
        signupSpot,
        introducer,
        businessType,
        businessType,
        primaryCarType,
        primaryCertificate,
        studentGroup,
        carType,
        classId,
        traningPlace,
        preCoach,
        dataComplete,
        comments,
        charges,
        follower
      })
      if (result.data.success) {
        message.success(`添加数据成功`)
        fetchData()
        setVisible(!visible)
      } else {
        message.error(result.data.failedMessage || '提交数据异常、未捕获的错误')
      }
    } catch (error) {
      message.error(error)
    }
  }


  /**
   * 打开新建对话框
   */
  async function openCreateModal() {
    console.log(store.classes)
    if (store.signupSpots.length === 0 || store.traningPlaces.length === 0 || store.classes.length === 0) {
      message.warn(`需要优先录入报名点、训练场、以及班制等数据,否则无法新增学员`)
      return
    }
    setName('')
    setNation('中国')
    setSex('男')
    setCerType('身份证')
    setCer('')
    setBirth('')
    serCerValid(moment())
    setContact('')
    setCode('')
    serCerAddr('')
    setLiveAddress('')
    setSignupSource('')
    setSignupDate(moment())
    setSignupSpot('')
    setIntroducer(window.localStorage.getItem('displayName') || '')
    setFollower(window.localStorage.getItem('displayName') || '')
    setBusinessType(businessOptions[0])
    setPriCarType(carTypes[0])
    setPrimaryCer('')
    setStudentGroup()
    setCarType(carTypes[0])
    setClass('')
    setTraningPlace()
    setPreCoach('')
    setDataComplete('')
    setComments('')
    setCharge([])
    store.setAvatar('')
    setVisible(!visible)
    setModalType(1)
  }

  /**
   * 打开更新数据对话框
   */
  async function openUpdateModal(info) {
    setName(info.name)
    setNation(info.nation)
    setSex(info.sex)
    setCerType(info.certificateType)
    setCer(info.certificate)
    setBirth(moment(info.birth))
    serCerValid(moment(info.certificateValid))
    setContact(info.contact)
    setCode(info.code)
    serCerAddr(info.certificateAddress)
    setLiveAddress(info.liveAddress)
    setSignupSource(info.signupSource)
    setSignupDate(moment(info.signupDate))
    setSignupSpot(info.signupSpot)
    setIntroducer(info.introducer)
    setBusinessType(info.businessType)
    setPriCarType(info.primaryCarType)
    setPrimaryCer(info.primaryCertificate)
    setStudentGroup(info.studentGroup)
    setCarType(info.carType)
    setClass(info.classId)
    setTraningPlace(info.traningPlace)
    setPreCoach(info.preCoach)
    setDataComplete(info.dataComplete)
    setComments(info.comments)
    setFollower(info.follower || '')
    setCharge(info.charges)
    store.setAvatar(info.avatar)
    setVisible(!visible)
    setModalType(2)
    setEditId(info._id)
  }

  /**
   * 报名费由班级内费用相加
   * 对话框中选择班制后根据班制内的财务信息生成数据
   */
  async function setFinaceDefault(costs) {
    let cost = 0
    costs.map(item => {
      cost = cost + item.cost
    })
    const finance = {
      type: '报名费',
      cost: cost,
      paid: '',
      payType: '',
      offer: 0,
      offerReason: '',
      arrears: 0,
      bills: '',
      comments: ''
    }
    setCharge([finance])
  }

  /**
   * 财务明细中修改各个字段信息
   */
  async function editFinanceDetail(index, type, value) {

    const newFinanceData = charges.map((item, $index) => {
      if ($index === index) {
        item[type] = value
        if (type === 'paid' || type === 'offer') {
          /**
           * 如果修改的是实际缴费信息或者修改的是优惠信息 重新计算欠费金额
           */
          const arrear = item.cost - item.offer - item.paid
          const arrears = arrear > 0 ? arrear : 0
          item.arrears = arrears
        }
      }
      return item
    })

    /**
     * 整理信息
     */
    let allcosts = 0
    let allpaid = 0
    let arrears = 0
    newFinanceData.map(item => {
      allcosts = allcosts + item.cost
      allpaid = allpaid + item.paid
      arrears = arrears + item.arrears
    })
    const message = `一共需要缴纳费用为${allcosts}元,实际缴费 ${allpaid} 元 欠费${arrears} 元`
    console.log(message)
    setAlertMsg(message)
    setCharge(newFinanceData)
  }

  /**
   * Render View
   */
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Row>
          <Col span={22}><QueryBar config={filterOptions} /></Col>
          <Col span={2}>
            <Button onClick={() => { fetchData() }} type="primary" icon="search" style={{ marginTop: 10, float: 'left', width: 100 }} >查询</Button>
          </Col>
        </Row>
        <Divider style={{ marginTop: -10 }} />
        <Row style={{ marginTop: -10 }}>
          <Button icon='user' type='primary' onClick={() => { openCreateModal() }}>新增</Button>
        </Row>
        <Table
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          rowClassName={(record, index) => {
            return (record.arrears > 0 ? css(styles.top) : css(styles.normal))
          }}
          scroll={{ x: 'max-content' }}
          loading={loading}
          rowKey={record => record._id}
          dataSource={data}
          columns={columns}
          style={{
            marginTop: 10,
          }} />
      </Row>

      <Drawer
        title="学员报名"
        visible={visible}
        onClose={() => { setVisible(!visible) }}
        closable={true}
        width="85%"
      >
        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Row>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>姓名</Col>
              <Col span={6}>
                <Input placeholder="输入姓名" className={css(styles.modalInput)} value={name} onChange={e => { setName(e.target.value) }} />
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>国籍</Col>
              <Col span={6}>
                <Select value={nation} className={css(styles.modalInput)} onChange={value => { setNation(value) }}>
                  {
                    ['中国', '香港'].map(item => <Option value={item} key={item}>{item}</Option>)
                  }
                </Select>
                {/* <Input placeholder="输入国籍" className={css(styles.modalInput)} value={nation} onChange={e => { setNation(e.target.value) }} /> */}
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>性别</Col>
              <Col span={6}>
                <Select value={sex} className={css(styles.modalInput)} onChange={value => { setSex(value) }}>
                  {sexOptions.map(item => <Option value={item} key={item}>{item}</Option>)}
                </Select>
              </Col>
            </Row>
            <Row className={css(styles.modalRow)}>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>证件类型</Col>
              <Col span={6}>
                <Select value={certificateType} className={css(styles.modalInput)} onChange={value => { setCerType(value) }}>
                  {certificateOptions.map(item => <Option value={item} key={item}>{item}</Option>)}
                </Select>
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>证件号</Col>
              <Col span={6}>
                <Input disabled={modalType == 2 ? true : false} placeholder="输入对应的证件号码" className={css(styles.modalInput)} value={certificate} onChange={e => {
                  setCer(e.target.value)
                  if (certificateId.test(e.target.value)) {
                    console.log(`增则匹配通过、准备计算出生年月日`)
                    const year = e.target.value.substring(6, 10)
                    const month = e.target.value.substring(10, 12)
                    const day = e.target.value.substring(12, 14)
                    const birth = `${year}-${month}-${day}`
                    if (e.target.value[16] % 2 === 0) { setSex('女') } else {
                      setSex('男')
                    }
                    setBirth(moment(birth))
                  }
                }} />
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>出生日期</Col>
              <Col span={6}>
                <DatePicker disabled={true} className={css(styles.modalInput)} value={birth} placeholder="请选择出生日期" onChange={value => { setBirth(value) }} />
              </Col>
            </Row>
            <Row className={css(styles.modalRow)}>
              <Col span={2} className={css(styles.modalRowText)}>截止日期</Col>
              <Col span={6}>
                <DatePicker className={css(styles.modalInput)} value={certificateValid} placeholder="身份证有效期" onChange={value => { serCerValid(value) }} />
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>电话</Col>
              <Col span={6}>
                <Input disabled={modalType == 2 ? true : false} placeholder="请输入联系电话" className={css(styles.modalInput)} value={contact} onChange={e => { setContact(e.target.value) }} />
              </Col>
              <Col span={2} className={css(styles.modalRowText)}>编号</Col>
              <Col span={6}>
                <Input placeholder="输入自定义编号" className={css(styles.modalInput)} value={code} onChange={e => { setCode(e.target.value) }} />
              </Col>
            </Row>
          </Col>
          <Col span={1}></Col>
          <Col span={4}><Avatar /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={2} className={css(styles.modalRowText)}>身份证地址</Col>
          <Col span={10}>
            <Input placeholder="输入身份证居住地址" className={css(styles.longInput)} value={certificateAddress} onChange={e => { serCerAddr(e.target.value) }} />
          </Col>
          <Col span={2} className={css(styles.modalRowText)}>居住地址</Col>
          <Col span={10}>
            <Input placeholder="请输入居住地址" className={css(styles.longInput)} value={liveAddress} onChange={e => { setLiveAddress(e.target.value) }} />
          </Col>
        </Row>
        <Divider style={{ marginTop: 20 }}></Divider>
        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>招生来源</Col>
            <Col span={6}>
              <Select value={signupSource} className={css(styles.modalInput)} onChange={value => { setSignupSource(value) }}>
                {store.signUpSources.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>报名时间</Col>
            <Col span={6}>
              <DatePicker className={css(styles.modalInput)} placeholder="请输入报名时间" value={signupDate} onChange={value => { setSignupDate(value) }} />
            </Col>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>报名地点</Col>
            <Col span={6}>
              <Select value={signupSpot} className={css(styles.modalInput)} onChange={value => { setSignupSpot(value) }}>
                {store.signupSpots && store.signupSpots.length > 0 && store.signupSpots.map(item => <Option key={item.name}>{item.name}</Option>)}
              </Select>
            </Col>
          </Col>
          <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>介绍人</Col>
          <Col span={4}>
            <Select value={introducer} className={css(styles.modalInput)} onChange={value => { setIntroducer(value) }}>
              {store.introducers.map(item => <Option key={item.name}>{item.name}</Option>)}
            </Select>
          </Col>
        </Row>


        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>业务类型</Col>
            <Col span={6}>
              <Select value={businessType} className={css(styles.modalInput)} onChange={value => { setBusinessType(value) }}>
                {businessOptions.map(item => <Option value={item} key={item}>{item}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}>原驾车型</Col>
            <Col span={6}>
              <Select value={primaryCarType} disabled={businessType !== '增驾' ? true : false} className={css(styles.modalInput)} onChange={value => { setPriCarType(value) }}>
                {carTypes.map(item => <Option value={item} key={item}>{item}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}>原驾证号</Col>
            <Col span={6}>
              <Input placeholder="原驾驶证件号码" disabled={businessType !== '增驾' ? true : false} className={css(styles.modalInput)} value={primaryCertificate} onChange={e => { setPrimaryCer(e.target.value) }} />
            </Col>
          </Col>
          <Col span={2} className={css(styles.modalRowText)}>学员分组</Col>
          <Col span={4}>
            <Select value={studentGroup} className={css(styles.modalInput)} onChange={value => { setStudentGroup(value) }}>
              {store.studentGroups.map(item => <Option key={item.name} value={item.name}>{item.name}</Option>)}
            </Select>
          </Col>
        </Row>

        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>报名车型</Col>
            <Col span={6}>
              <Select value={carType} placeholder="下拉选择报名车型" className={css(styles.modalInput)} onChange={value => { setCarType(value) }}>
                {carTypes.map(item => <Option value={item} key={item}>{item}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>班制</Col>
            <Col span={6}>
              <Select value={classId} className={css(styles.modalInput)} onChange={value => {
                store.classes.map(item => {
                  if (item.name === value) { setClass(item.name); setFinaceDefault(item.costs) }
                })
              }}>
                {store.classes.map(item => <Option key={item.name} value={item.name}>{item.name}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}>预选训练场</Col>
            <Col span={6}>
              <Select value={traningPlace} className={css(styles.modalInput)} onChange={value => { setTraningPlace(value) }}>
                {store.traningPlaces.map(item => <Option key={item.name} value={item.name}>{item.name}</Option>)}
              </Select>

            </Col></Col>
          <Col span={2} className={css(styles.modalRowText)}>预选教练</Col>
          <Col span={4}>
            <Select value={preCoach} className={css(styles.modalInput)} onChange={value => { setPreCoach(value) }}>
              {
                store.coaches.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>资料齐全</Col>
            <Col span={6}>
              <Radio.Group className={css(styles.modalInput)} style={{ marginTop: 5.5 }} onChange={e => { setDataComplete(e.target.value) }} value={dataComplete}>
                <Radio value={true}>齐全</Radio>
                <Radio value={false}>不齐全</Radio>
              </Radio.Group>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}>备注</Col>
            <Col span={14}>
              <Input placeholder="补充说明" style={{
                width: '95%',
                marginLeft: '3%'
              }} value={comments} onChange={e => { setComments(e.target.value) }} />
            </Col>
          </Col>
          <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>跟踪客服</Col>
          <Col span={4}>
            <Select value={follower} className={css(styles.modalInput)} onChange={value => { setFollower(value) }}>
              {store.introducers.map(item => <Option key={item.name}>{item.name}</Option>)}
            </Select>
          </Col>
        </Row>
        <Divider>财务信息</Divider>
        <Alert message={alertMsg} type="info" showIcon />
        <Table size="small" className={css(styles.modalRow)}
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          scroll={{ x: 'max-content' }}
          dataSource={charges} columns={financeColums} rowKey="type" loading={loading} />
        <div className={css(styles.drawerFooter)}>
          <Button icon="cancel" className={css(styles.marginRight8)} onClick={() => { setVisible(!visible) }}>取消</Button>
          <Button icon="save" onClick={() => { modalType === 1 ? createSingleData() : updateSingleData() }} type="primary">保存并打印收据</Button>
        </div>
      </Drawer>
    </Row>
  )
}


