/*
 * @Author: huangliang
 * @Date: 2020-01-21 10:59:08
 * @LastEditTime: 2020-02-28 11:25:06
 * @LastEditors: huangliang
 * @Description: 成绩导入界面
 * @FilePath: /00-pri-driving-web/src/pages/Student/StudentImport/index.js
 * @js files
 */
import React from 'react'
import { Row, Table, Modal, message, Button, List, Typography, Icon, Tooltip, Popconfirm, Alert, Select } from 'antd'
import { css } from 'aphrodite'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import ImportButton from './upload'
import Axios from '../../../api/axios'
import moment from 'moment'

const Option = Select.Option

export default function GradeImport() {
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [selectedRowKeys, setSelectedRow] = React.useState([])
  const [visilbe, setVisible] = React.useState(false)
  const [logs, setLogs] = React.useState([])
  const [result, setResult] = React.useState({})
  const [check, setCheck] = React.useState('uncheck')
  React.useEffect(() => { fetchData() }, [])
  const columns = [
    {
      title: '学员姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '学员证件号',
      dataIndex: 'certificate',
      key: 'certificate'
    },
    {
      title: '学员号码',
      dataIndex: 'contact',
      key: 'contact'
    },
    {
      title: '报名时间',
      dataIndex: 'signupDate',
      key: 'signupDate'
    },
    {
      title: '班制',
      dataIndex: 'classId',
      key: 'classId'
    },
    {
      title: '导入用户',
      dataIndex: 'creator',
      key: 'creator'
    },
    {
      title: '导入时间',
      dataIndex: 'createAt',
      key: 'createAt',
      render: data => moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '人工审核',
      dataIndex: 'check',
      key: 'check',
      render: data => data ? '审核' : '未审核'
    },
    {
      title: '审核结果',
      dataIndex: 'failedMessage',
      key: 'failedMessage',
      render: (data, info) => !info.check ? '未审核' : data ? <Tooltip title={data}><span><Icon type='info-circle' theme='filled' />查看失败原因</span></Tooltip> : '通过'
    },
    // {
    //   title: '操作',
    //   dataIndex: 'operate',
    //   key: 'operate',
    //   // fixed: 'right',
    //   render: (data, group) => {
    //     return (
    //       <Row>
    //         {/* <span className={css(styles.operate)} onClick={() => { setDivider(!dividerVisible) }}>分配</span> */}
    //         <span className={css(styles.operate)} onClick={() => { }}>修正</span>
    //         <Popconfirm title="删除该记录?" okText="确认" cancelText="取消" onConfirm={() => { }}>
    //           <span className={css(styles.operate)}>删除</span>
    //         </Popconfirm>
    //       </Row>
    //     )
    //   }
    // }
  ]

  async function checkAll() {
    const result = await Axios.post('/student/import/check', {
      checkAll: true
    })
    if (result.data.success) {
      message.success(`审核数据结束`)
      setVisible(!visilbe)
      setSelectedRow([])
      if (result.data.data) {
        setLogs(result.data.data)
        setResult(result.data.result)
        const newData = [...data]
        newData.map(item => {
          result.data.data.map(each => {
            if (item._id === each._id) {
              item.check = true
              item.failedMessage = each.success ? '' : each.info
            }
          })
        })
        setData(newData)
      }
    }
  }
  async function checkGrade() {
    const result = await Axios.post('/student/import/check', {
      ids: selectedRowKeys
    })
    if (result.data.success) {
      message.success(`审核数据结束`)
      setVisible(!visilbe)
      setSelectedRow([])
      if (result.data.data) {
        setResult(result.data.result)
        setLogs(result.data.data)
        const newData = [...data]
        newData.map(item => {
          result.data.data.map(each => {
            if (item._id === each._id) {
              item.check = true
              item.failedMessage = each.success ? '' : each.info
            }
          })
        })
        setData(newData)
      }
    }
  }

  async function fetchData() {
    setLoading(true)
    const result = await Axios.post('/student/import/list', {
      check
    })
    setLoading(false)
    if (result.data.success) {
      setData(result.data.data)
    } else {
      message.error(result.data.failedMessage || '未知错误')
    }
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: value => setSelectedRow(value),
  }

  async function deleteStudent(value) {
    const body = {}
    if (value === 'check') {
      body.deleteCheck = true
    } else {
      body.ids = selectedRowKeys
    }
    const result = await Axios.post('/student/import/delete', body)
    if (result.data.success) {
      message.success(`删除数据成功`)
      const newData = [...data].filter(item => !selectedRowKeys.includes(item._id))
      setSelectedRow([])
      setData(newData)
    }
  }

  function expandedRowRender(value) {
    const columns = [
      { title: '费用类型', dataIndex: 'type', key: 'type' },
      { title: '金额', dataIndex: 'cost', key: 'cost' },
      { title: '实际缴费', dataIndex: 'paid', key: 'paid' },
      { title: '欠费', dataIndex: 'arrears', key: 'arrears' },
      { title: '审核', dataIndex: 'checked', key: 'checked' },
    ]
    return <Table columns={columns} dataSource={value.charges} pagination={false} />
  }
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Row>
          <Select value={check}
            onChange={e => { setCheck(e) }}
            style={{
              float: 'left',
              width: 150
            }}>
            <Option key='check' value='all'>所有</Option>
            <Option key='check' value='check'>已审核</Option>
            <Option key='check' value='uncheck'>未审核</Option>
            <Option key='check' value='checknopass'>审核未通过</Option>
          </Select>
          <Button type='primary' icon='check' onClick={() => { fetchData() }} style={{ float: 'left', marginLeft: 10 }}>查询数据</Button>
          <Button type='primary' icon='check' onClick={() => { checkGrade() }} style={{ float: 'left', marginLeft: 10 }}>批量审核</Button>
          <Button type='primary' icon='check' onClick={() => { checkAll() }} style={{ float: 'left', marginLeft: 10 }}>一键全部审核</Button>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Popconfirm title="请确定删除数据库中所有已经审核的数据?" okText="确认" cancelText="取消" onConfirm={() => { deleteStudent('check') }}>
            <Button type='danger' icon='delete' style={{ float: 'left', width: 150 }}>删除审核数据</Button>
          </Popconfirm>
          <Button type='danger' icon='delete' onClick={() => { deleteStudent() }} style={{ float: 'left', marginLeft: 10 }}>删除数据</Button>

        </Row>
        <Row style={{ marginTop: 10 }}>
          <Button type='primary' icon='download' onClick={() => { window.open('/model/importstudent.xlsx') }} style={{ float: 'left', width: 150 }}>下载导入模版</Button>
          <ImportButton />
        </Row>
        <Table
          expandedRowRender={expandedRowRender}
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          rowSelection={rowSelection}
          scroll={{ x: 'max-content' }}
          loading={loading}
          rowKey="_id"
          dataSource={data}
          columns={columns}
          style={{
            marginTop: 10,
          }} />
      </Row>
      <Modal
        width='40%'
        visible={visilbe}
        footer={null}
        onCancel={() => { setVisible(!visilbe) }}
      >
        <Alert style={{ marginTop: 10, marginBottom: -10 }} message={`本次审核数据 : ${result.total || '未知'}条 ,成功 ${result.ok || result.ok === 0 ? result.ok : '未知'} 条, 失败 ${result.failed || '未知'} 条`} icon='info' />
        <List
          style={{ marginTop: 20, height: 500, overflowY: 'scroll' }}
          bordered
          dataSource={logs}
          renderItem={item => (
            <List.Item>
              <Typography.Text mark>{item.success ? '[成功]' : '失败'}</Typography.Text> {item.info}
            </List.Item>
          )}
        />
      </Modal>
    </Row>
  )
}