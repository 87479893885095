import React from 'react'
import { Menu, PageHeader, Table, Row, Button, Switch, Select, Icon, Tag, Modal, Col, Input, message, InputNumber, Popconfirm } from 'antd'
import { StyleSheet, css } from 'aphrodite'
import { GlobalContext } from '../../../store/store'
import axios from '../../../api/axios'
import styles from './style'
import { dictionaryParams } from './config'
import { initAllBaseData } from '../../../store/init/init'

const { Option } = Select

export default function settings() {
  const columns = [
    {
      title: '序号',
      dataIndex: 'test',
      key: 'test',
      render: (a, b, c) => c + 1
    },
    {
      title: '编号',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      render: data => <Tag color="#108ee9">{data}</Tag>
    },
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      sorter: (a, b) => a.sort > b.sort
    },
    {
      title: '状态',
      dataIndex: 'enable',
      key: 'enable',
      render: data => <Switch checkedChildren="启用" unCheckedChildren="未启用" checked={data} onChange={() => { }} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      render: (data, group) => {
        return (
          <Row>
            <span className={css(styles.operate)} onClick={() => { openUpdateModal(group) }}>编辑</span>
            <Popconfirm title="确认删除?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleData(group._id) }}>
              <span className={css(styles.operate)}>删除</span>
            </Popconfirm>
          </Row>
        )
      }
    }
  ]


  /**
   * React State
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [currentPage, setCurrent] = React.useState('lisence')
  const [loading, setLoading] = React.useState(false)
  const [type, setType] = React.useState('驾校定义')

  /**
   * [ModalType]:1.add 2.update
   */
  const [modalType, setModalType] = React.useState(1)
  const [editId, setEditId] = React.useState('')
  const [code, setCode] = React.useState('')
  const [name, setName] = React.useState('')
  const [sort, setSort] = React.useState('')
  const [enable, setEnable] = React.useState(true)


  /**
   * componentDidMount
   */
  React.useEffect(() => {
    fetchData(currentPage)
  }, [currentPage])

  /**
   * [增加一个分组]
   */
  async function createSingleData() {
    try {
      const result = await axios.post(`/system/dictionary`, {
        dictionaryType: currentPage,
        code,
        name,
        type,
        sort,
        enable
      })
      if (result.data.success) {
        const newData = result.data.data
        data.push(newData)
        setData(data)
        setVisible(!visible)
        initAllBaseData(store)
      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(`提交数据异常,请联系管理员`)
    }
  }

  /**
   * 获取分组数据
   */
  async function fetchData(type) {
    try {
      setLoading(true)
      setData([])
      const queryType = currentPage
      const data = await axios.get(`/system/dictionary?type=${type}`)
      const result = data.data
      const tableData = result.data
      setData(tableData)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      message.error(`获取${currentPage}分组信息失败`)
      console.log('[ERROR]:')
      console.log(error)
    }

  }

  /**
   * 删除分组数据
   */
  async function deleteSingleData(_id) {
    setLoading(true)
    const result = await axios.delete(`/system/dictionary/${_id}`)
    setLoading(false)
    if (result.data.success) {
      const newData = data.filter(item => item._id !== _id)
      setData(newData)
      initAllBaseData(store)
      message.success(`删除数据成功`)
    } else {
      message.error(`删除数据失败`)
    }
  }

  /**
   * 更新分组数据
   */
  async function openUpdateModal(info) {
    setName(info.name)
    setCode(info.code)
    setSort(info.sort)
    setEnable(info.enable)
    setVisible(!visible)
    setModalType(2)
    setEditId(info._id)

  }

  async function openCreateModal() {
    setName('')
    setCode('')
    setSort()
    setEnable(true)
    setVisible(!visible)
    setModalType(1)
  }
  async function updateSingleData() {
    try {

      const result = await axios.put(`/system/dictionary`, {
        _id: editId,
        dictionaryType: currentPage,
        code,
        name,
        type,
        sort,
        enable
      })
      if (result.data.success) {
        // fetchData()

        const newData = [...data].map(item => {
          if (item._id === editId) {
            item.dictionaryType = currentPage
            item.code = code
            item.name = name
            item.type = type
            item.sort = sort
            item.enable = enable
            return item
          } else {
            return item
          }
        })
        setData(newData)
        setVisible(!visible)
        initAllBaseData(store)

      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(`提交数据异常,请联系管理员`)
    }
  }


  /**
   * Render View
   */
  return (
    <Row style={{
      marginLeft: store.collapsed ? 90 : 210,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Menu
          style={{ width: '20%', float: 'left' }}
          selectedKeys={[currentPage]}
          onClick={key => { setCurrent(key.key) }}
          mode='inline'
          theme='light'
        >
          <Menu.Item key="lisence"> 驾照定义</Menu.Item>
          <Menu.Item key="studentGroup"> 学员分组</Menu.Item>
          <Menu.Item key="staffGroup"> 员工分组</Menu.Item>
          <Menu.Item key="signUpSource"> 招生来源</Menu.Item>
          <Menu.Item key="payTypes"> 付款方式</Menu.Item>
          <Menu.Item key="subjectOneAddress"> 科目一考试地点</Menu.Item>
          <Menu.Item key="subjectTwoAddress"> 科目二考试地点</Menu.Item>
          <Menu.Item key="subjectThreeAddress"> 科目三考试地点</Menu.Item>
          <Menu.Item key="subjectFourAddress"> 科目四考试地点</Menu.Item>
        </Menu>
        <Row style={{
          width: '78%',
          marginLeft: '1%',
          marginTop: 10,
          float: 'left',
          backgroundColor: 'white'
        }}>
          <Row>
            <Button icon='plus' type='primary' onClick={() => { openCreateModal() }}>新增</Button>
          </Row>
          <Table

            loading={loading}
            rowKey="_id"
            dataSource={data}
            columns={columns}
            style={{
              marginTop: 10,
            }} />
        </Row>
      </Row>

      <Modal
        title="学员分组"
        visible={visible}
        okText="完成"
        cancelText="取消"
        onOk={() => { modalType === 1 ? createSingleData() : updateSingleData() }}
        onCancel={() => { setVisible(!visible) }}
      >
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}>分组编号</Col>
          <Col span={18}><Input placeholder="输入分组编号" className={css(styles.modalInput)} value={code} onChange={e => { setCode(e.target.value) }} /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}>分组名称</Col>
          <Col span={18}><Input placeholder="输入分组名称" className={css(styles.modalInput)} value={name} onChange={e => { setName(e.target.value) }} /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}>类型</Col>
          <Col span={18}>
            <Select value={type} className={css(styles.modalInput)} onChange={value => { setType(value) }}>
              <Option value='驾校定义'>驾校定义</Option>
              <Option value='系统默认'>系统默认</Option>
            </Select>
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}>排序</Col>
          <Col span={18}><InputNumber placeholder="排序数字越大排名越靠前" className={css(styles.modalInput)} value={sort} onChange={value => { setSort(value) }} /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}>是否启用</Col>
          <Col span={18}><Switch checkedChildren="启用" unCheckedChildren="停用" checked={enable} onChange={value => { setEnable(value) }} style={{ marginLeft: 10, marginTop: 5 }} /></Col>
        </Row>
      </Modal>
    </Row>
  )
}

