import React from 'react'
import { Alert, Select, Divider, Table, Pagination, Tooltip, Row, Button, Steps, DatePicker, Radio, Drawer, Tag, Modal, Col, Input, message, InputNumber, Popconfirm, Icon } from 'antd'
import { css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import Avatar from './upload'
import moment from 'moment'
import QueryBar from '../../../components/query/index'
import {
  studentsSchema,
  filterOptions
} from './config'


const { Option } = Select
const { Search } = Input

export default function TrainingPlace() {
  const store = React.useContext(GlobalContext)

  const refundColums = [
    {
      title: '费用类型',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '退费金额',
      dataIndex: 'paid',
      key: 'paid',
      render: data => data > 0 ? data : -data
    },
    {
      title: '收款方式',
      dataIndex: 'payType',
      key: 'payType',
      render: (data, group, index) => {
        return (
          <Select value={data} onChange={value => changeRefundData('payType', value, index)} className={css(styles.modalInput)}>
            {store.payTypes.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)}
          </Select>
        )
      }
    },
    {
      title: '说明',
      dataIndex: 'comments',
      key: 'comments',
      render: (data, group, index) => <Input value={data} onChange={e => changeRefundData('comments', e.target.value, index)} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      render: (data, group, index) => <span className={css(styles.operate)} onClick={() => { deleteRefund(group._id) }}>删除</span>
    }
  ]

  const financeColums = [
    {
      title: '费用类型',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '实缴金额',
      dataIndex: 'paid',
      key: 'paid'
    },
    {
      title: '欠费',
      dataIndex: 'arrears',
      key: 'arrears'
    },
    {
      title: '优惠金额',
      dataIndex: 'offer',
      key: 'offer'
    },
    {
      title: '票号',
      dataIndex: 'bills',
      key: 'bills'

    },
    {
      title: '说明',
      dataIndex: 'comments',
      key: 'comments'
    },

    {
      title: '缴费时间',
      dataIndex: 'payDate',
      key: 'payDate',
      render: data => data ? moment(data).format('YYYY-MM-DD') : ''
    },
    {
      title: '收费人',
      dataIndex: 'creator',
      key: 'creator'
    },

    {
      title: '入账状态',
      dataIndex: 'checked',
      key: 'checked',
      render: data => data ? <Tag color="#f50">已入账</Tag> : <Tag color="#2db7f5">未入账</Tag>
    },
    {
      title: '退费金额',
      dataIndex: 'tuifei',
      key: 'tuifei',
      render: (data, group) => <InputNumber onChange={value => group.back = value} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      render: (data, group) => <Button type="primary" icon="rollback" onClick={() => { addRefund(group) }}>确认退费</Button>
    }
  ]
  const columns = [
    {
      title: '序号',
      dataIndex: '',
      key: '',
      render: (data, info, index) => `${index + 1}`
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '证件号',
      dataIndex: 'certificate',
      key: 'certificate'
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex'
    },
    {
      title: '联系电话',
      dataIndex: 'contact',
      key: 'contact'
    },
    {
      title: '退费金额',
      dataIndex: 'charges',
      key: 'charges',
      render: data => {
        let cost = 0
        data.map(item => {
          if (item.type === '退费') {
            cost = cost + Math.abs(item.paid)
          }
        })
        return cost
      }
    },
    {
      title: '退学申请人',
      dataIndex: '',
      key: '',
      render: (data, group) => group.dropoutInfo.operator
    },
    {
      title: '退学原因',
      dataIndex: '',
      key: '',
      render: (data, group) => group.dropoutInfo.reason
    },
    {
      title: '退学日期',
      dataIndex: '',
      key: '',
      render: (data, group) => group.dropoutInfo.date
    },
    // {
    //   title: '流水号',
    //   dataIndex: 'serialNumber',
    //   key: 'serialNumber',
    //   render: data => data ? data : <Tag color="#f50">未生成</Tag>
    // },
    // {
    //   title: '科目一考试结果',
    //   dataIndex: 'subjectOne',
    //   key: 'subjectOne',
    //   align: 'center',
    //   render: (data, group) => renderStatus(data, group)

    // },
    // {
    //   title: '科目二考试结果',
    //   dataIndex: 'subjectTwo',
    //   key: 'subjectTwo',
    //   align: 'center',
    //   render: (data, group) => renderStatus(data, group)
    // },
    // {
    //   title: '科目三考试结果',
    //   dataIndex: 'subjectThree',
    //   key: 'subjectThree',
    //   align: 'center',
    //   render: (data, group) => renderStatus(data, group)
    // },
    // {
    //   title: '科目四考试结果',
    //   dataIndex: 'subjectFour',
    //   key: 'subjectFour',
    //   align: 'center',
    //   render: (data, group) => renderStatus(data, group)
    // },
    {
      title: '报名地点',
      dataIndex: 'signupSpot',
      key: 'signupSpot'
    },
    {
      title: '班制',
      dataIndex: 'classId',
      key: 'classId'
    },
    {
      title: '报名时间',
      dataIndex: 'signupDate',
      key: 'signupDate',
      render: data => moment(data).format('YYYY-MM-DD')
    },
    {
      title: '介绍人',
      dataIndex: 'introducer',
      key: 'introducer'
    },
    {
      title: '退学审核状态',
      dataIndex: '',
      align: 'center',
      key: '',
      fixed: 'right',
      render: (data, group) => group.dropoutInfo.checked ? <Tag color="#87d068">已审核</Tag> : <Tag color="#2db7f5">审核中</Tag>
    }
    // {
    //   title: '操作',
    //   dataIndex: 'operate',
    //   key: 'operate',
    //   fixed: 'right',
    //   render: (data, group) => {
    //     return (
    //       <Row>
    //         <Popconfirm title="确认审核该学员状态为五次不过吗?" okText="确认" cancelText="取消" onConfirm={() => { }}>
    //           <span className={css(styles.operate)}>审核</span>
    //         </Popconfirm>
    //       </Row>
    //     )
    //   }
    // }
  ]


  /**
   * React State
   */
  // const store = React.useContext(GlobalContext)
  const initPageSize = window.localStorage.getItem('pageSize') ? parseInt(window.localStorage.getItem('pageSize')) : 10

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const [dropoutVisible, setDroupoutVisible] = React.useState(false)
  const [student, setStudent] = React.useState({})
  const [refund, setRefund] = React.useState([])
  const [refundReason, setRefundReason] = React.useState('')
  const [bills, setBills] = React.useState('')
  const [refundDate, setRefundDate] = React.useState(moment())
  const [page, setPage] = React.useState(1)
  const [limit, setLimit] = React.useState(initPageSize)
  const [total, setTotal] = React.useState(0)
  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
  }, [])

  function deleteRefund(_id) {
    const refundData = refund.filter(item => item._id !== _id)
    setRefund(refundData)
  }
  function addRefund(group) {

    if (group.back > group.paid) {
      message.warn(`退费金额不得大于实际缴费金额`)
    } else {
      const refunds = [...refund]
      let contain = false
      refunds.map(item => {
        if (item._id === group._id) {
          contain = true
          item.paid = -group.back
        }
      })
      if (!contain) {
        refunds.push({
          _id: group._id,
          type: '退费',
          paid: -group.back,
          payType: '现金',
          arrears: 0,
          offer: 0,
          bills: '',
          comments: '',
          payDate: moment().format('YYYY-MM-DD'),
          creator: window.localStorage.getItem('displayName'),
          checked: false
        })
      }
      setRefund(refunds)
    }
  }


  async function fetchData(page, size) {
    try {
      console.log(`筛选条件`)
      console.log(store.condition)
      // let condition = {}
      // if (store.condition.status) condition = `${condition}status:"${store.condition.status}",`
      // if (store.condition.signupDate) condition = `${condition}signupDateStart:"${store.condition.signupDate[0]}",signupDateEnd:"${store.condition.signupDate[1]}",`
      // if (store.condition.filter) condition = `${condition}filter:"${store.condition.filter}",`
      // if (store.condition.classId) condition = `${condition}classId:"${store.condition.classId}",`
      // if (store.condition.signupSpot) condition = `${condition}signupSpot:"${store.condition.signupSpot}",`
      // if (store.condition.introducer) condition = `${condition}introducer:"${store.condition.introducer}",`
      const condition = {}
      if (store.condition.status) {
        condition.status = store.condition.status
      }
      if (store.condition.signupDate) {
        condition.signupDateStart = store.condition.signupDate[0]
        condition.signupDateEnd = store.condition.signupDate[1]
      }
      if (store.condition.filter) {
        condition.filter = store.condition.filter
      }
      if (store.condition.classId) {
        condition.classId = store.condition.classId
      }
      if (store.condition.signupSpot) {
        condition.signupSpot = store.condition.signupSpot
      }
      if (store.condition.introducer) {
        condition.introducer = store.condition.introducer
      }
      if (store.condition.dataComplete) {
        if (store.condition.dataComplete === 'true') {
          condition.dataComplete = true
        }
        if (store.condition.dataComplete === 'false') {
          condition.dataComplete = false
        }
      }
      condition.dropout = true
      condition.page = page || page || 1
      condition.limit = size || limit
      setLoading(true)
      const data = await axios.post('/student/query', condition)
      const tableData = data.data.data
      console.log(tableData)
      setData(tableData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[ERROR]:')
      console.log(error)
    }

  }
  function renderStatus(data, item) {
    if (data.length === 5 && item && item.fiveTimeNoPass) {
      return (<Tag color="#f50">五次不过</Tag>)
    }
    if (data.length === 0) {
      return ('—')
    } else {
      const lastData = data[data.length - 1]
      return (
        <Tooltip placement="topLeft" title={`考试日期 : ${moment(lastData.date).format('YYYY-MM-DD')} 记录人 : ${lastData.operator}`}>
          {
            lastData.pass ?
              <Row>
                <Tag color="#87d068">通过</Tag>
              </Row>
              :
              <Tag color="#f50">未通过</Tag>
          }
        </Tooltip>
      )
    }
  }

  async function findStudentByCertificate(id) {
    const result = await axios.get(`/student/certificate/${id}`)
    console.log(result)
    if (result.data.success) {
      if (result.data.data.dropout) {
        message.warn(`该学员已经办理退学,请勿重复办理`)
        return
      }
      setStudent(result.data.data)
      setDroupoutVisible(true)
    } else {
      message.error(result.data.failedMessage || '服务器未知错误')
    }
  }

  function changeRefundData(key, value, $index) {
    const refundData = [...refund]
    refundData[$index][key] = value
    setRefund(refundData)
  }

  async function createRefundStudent() {
    const refundData = [...refund]
    refundData.map(item => {
      delete item._id
      item.bills = bills
      item.payDate = moment().format('YYYY-MM-DD HH:mm:ss')
    })
    const result = await axios.post('/student/dropout', {
      _id: student._id,
      refund: refundData,
      dropoutInfo: {
        reason: refundReason
      }
    })
    if (result.data.success) {
      setVisible(!visible)
      message.success(`新增退学记录成功`)
    } else {
      message.error(result.data.failedMessage || '服务端未知错误')
    }
  }

  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Col span={22}><QueryBar config={filterOptions} /></Col>
        <Col span={2}>
          <Button onClick={() => { fetchData() }} type="primary" icon="search" style={{ marginTop: 10, float: 'left', width: 100 }} >查询</Button>
        </Col>
        <Divider />
        <Row style={{ marginTop: -10 }}>
          <Button icon='user' type='primary' onClick={() => { setVisible(!visible) }}>新增</Button>
        </Row>
        <Table
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          pagination={false}
          scroll={{ x: 'max-content' }}
          loading={loading}
          rowKey={record => record._id}
          dataSource={data}
          columns={columns}
          style={{
            marginTop: 10,
          }} />
        <Pagination style={{ marginTop: 10 }}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={['10', '15', '20', '50']}
          pageSize={limit}
          current={page}
          total={total}
          onShowSizeChange={(page, pageSize) => {
            window.localStorage.setItem('pageSize', pageSize)
            setPage(page)
            setLimit(pageSize)
            fetchData(page, pageSize)
          }}
          onChange={(page, pageSize) => {
            setPage(page)
            setLimit(pageSize)
            fetchData(page, pageSize)
          }}
        />
      </Row>
      <Drawer
        title="新增退学学员"
        width="70%"
        visible={visible}
        onClose={() => { setVisible(!visible) }}
        // onOk={() => { setStudentSerialNumber() }}
        onCancel={() => { setVisible(!visible) }}
      >
        {
          dropoutVisible ? <Row></Row> :
            <Row>
              <Col span={3} className={css(styles.modalRowText)}>学生证件号</Col>
              <Col span={10}>
                {/* <Input placeholder="请输入学生证件号码" className={css(styles.modalInput)} value={certificate} onChange={e => { setCer(e.target.value) }} /> */}
                <Search
                  defaultValue="321192199901011234"
                  className={css(styles.modalInput)}
                  placeholder="请输入学生证件号码"
                  enterButton={<Icon type="search" />}
                  onSearch={value => findStudentByCertificate(value)}
                />
              </Col>
            </Row>
        }
        {
          dropoutVisible ?
            <Row>
              <Divider>退学信息</Divider>
              <Row className={css(styles.modalRow)}>
                <Col span={3} className={css(styles.modalRowText)}>姓名</Col>
                <Col span={3} className={css(styles.modalRowTextValue)}>{student.name || ''}</Col>
                <Col span={3} className={css(styles.modalRowText)}>手机号</Col>
                <Col span={3} className={css(styles.modalRowTextValue)}>{student.contact || ''}</Col>
                <Col span={3} className={css(styles.modalRowText)}>报名时间</Col>
                <Col span={3} className={css(styles.modalRowTextValue)}>{student.signupDate ? moment(student.signupDate).format('YYYY-MM-DD') : ''}</Col>
                <Col span={3} className={css(styles.modalRowText)}>报名点</Col>
                <Col span={3} className={css(styles.modalRowTextValue)}>{student.signupSpot || ''}</Col>
              </Row>
              <Row className={css(styles.modalRow)}>
                <Col span={3} className={css(styles.modalRowText)}>班制</Col>
                <Col span={3} className={css(styles.modalRowTextValue)}>{student.classId || ''}</Col>
                <Col span={3} className={css(styles.modalRowText)}>介绍人</Col>
                <Col span={3} className={css(styles.modalRowTextValue)}>{student.introducer || ''}</Col>
              </Row>
              <Divider>财务信息</Divider>
              <Table
                className="ant-table td"
                // loading={loading}
                pagination={false}
                rowKey="_id"
                size="small"
                columns={columns.map(item => {
                  const fun = () => ({ style: { whiteSpace: 'nowrap' } });
                  item.onHeaderCell = fun;
                  item.onCell = fun;
                  return item;
                })}
                scroll={{ x: 'max-content' }}
                dataSource={student.charges || []}
                columns={financeColums}
                style={{
                  marginTop: 10
                }}
              />
              <Divider>退学财务信息</Divider>

              <Table
                className="ant-table td"
                // loading={loading}
                pagination={false}
                rowKey="_id"
                size="small"
                columns={columns.map(item => {
                  const fun = () => ({ style: { whiteSpace: 'nowrap' } });
                  item.onHeaderCell = fun;
                  item.onCell = fun;
                  return item;
                })}
                scroll={{ x: 'max-content' }}
                dataSource={refund || []}
                columns={refundColums}
                style={{
                  marginTop: 10
                }}
              />
              <Divider>退学具体信息</Divider>
              <Row style={{ paddingBottom: 40 }}>
                <Col span={3} className={css(styles.modalRowText)}>退学原因</Col>
                <Col span={5}>
                  <Input placeholder="请输入退学原因" className={css(styles.modalInput)} value={refundReason} onChange={e => { setRefundReason(e.target.value) }} />
                </Col>
                <Col span={3} className={css(styles.modalRowText)}>退学时间</Col>
                <Col span={5}>
                  <DatePicker value={refundDate} onChange={value => { setRefundDate(value) }} className={css(styles.modalInput)}
                  />
                </Col>
                <Col span={3} className={css(styles.modalRowText)}>票号</Col>
                <Col span={5}>
                  <Input placeholder="请输入退学原因" className={css(styles.modalInput)} value={bills} onChange={e => { setBills(e.target.value) }} />

                </Col>
              </Row>
            </Row>

            : <Row></Row>
        }
        <div className={css(styles.drawerFooter)}>
          <Button icon="cancel" className={css(styles.marginRight8)} onClick={() => { setVisible(!visible) }}>取消</Button>
          <Button icon="save" onClick={() => { createRefundStudent() }} type="primary">确认</Button>
        </div>
      </Drawer>



    </Row>
  )
}


