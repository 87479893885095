/*
 * @Author: huangliang
 * @Date: 2019-12-07 16:39:55
 * @LastEditTime: 2020-02-23 20:01:17
 * @LastEditors: huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/Login/index.js
 * @js files
 */
import React from 'react'
import { Row, Col, Tabs, Input, Icon, Button, message } from 'antd'
import { css } from 'aphrodite'
import styles from './style'
import md5 from 'md5'
import axios from '../../api/axios'

export default function Login() {
  const [userName, setUserName] = React.useState(window.localStorage.getItem('account') || '')
  const [password, setPassword] = React.useState('')
  const [activeKey, setActiveKey] = React.useState('1')
  async function login() {
    try {
      if (activeKey === '1') {
        const result = await axios.post('/user/login', {
          userName,
          data: md5(password)
        })
        if (result.data.success && result.data.accesstoken) {
          console.log(`登陆成功后获取到token : ${result.data.accesstoken}`)
          window.localStorage.setItem('account', userName)
          window.localStorage.setItem('accesstoken', result.data.accesstoken)
          window.localStorage.setItem('displayName', result.data.name)
          window.localStorage.setItem('isCoach', result.data.isCoach || false)
          window.localStorage.setItem('contact', result.data.contact)
          window.localStorage.setItem('role', result.data.role)


          window.location.href = '/dashboard/index'
        } else {
          message.error(`登陆失败,请确认用户名密码`)
        }
      } else {
        message.warn(`短信登陆功能正在开发中 ......🐦`)
        return
      }
    } catch (error) {
      console.log(error.message)
      message.error(`登陆失败 : ${error.message || error}`)
    }
  }
  function renderActive(self) {
    return (activeKey == self ? { backgroundColor: 'white', color: 'black' } : { backgroundColor: 'rgba(0,0,0,0.3)', color: 'gba(255,255,255,0.5)' })
  }
  const loginBtn = <Button style={{ width: '100%', height: '40px', marginTop: 20 }} type="primary" onClick={() => login()} >登陆</Button>
  return (
    <Row className={css(styles.default)}>
      <Col span={8}></Col>
      <Col span={8} className={css(styles.rightPannel)}>
        <Row>
          <Row style={{ marginTop: 150 }}>
            <Col span={12} className={css(styles.tab)} style={renderActive('1')} onClick={() => { setActiveKey('1') }}>用户名密码登陆</Col>
            <Col span={12} className={css(styles.tab)} style={renderActive('2')} onClick={() => { setActiveKey('2') }}>短信验证码登陆</Col>
          </Row>
          {
            activeKey == '1' ?
              <Row className={css(styles.table)}>
                <Row style={{ marginTop: 40 }}>
                  <input placeholder="请输入电话号码用于登陆" className={css(styles.input)} value={userName} onChange={e => setUserName(e.target.value)} />
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <input type="password" placeholder="请输入密码" className={css(styles.input)} value={password} onChange={e => setPassword(e.target.value)} />
                </Row>
                <Row style={{ marginTop: 20 }}>
                  {loginBtn}
                </Row>
              </Row>
              :
              <Row></Row>
          }
          {
            activeKey == '2' ?
              <Row className={css(styles.table)}>
                <Row style={{ marginTop: 40 }}>
                  <input placeholder="请输入电话号码用于登陆" className={css(styles.input)} value={userName} onChange={e => setUserName(e.target.value)} />
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={15}>
                    <input placeholder="请输入短信验证码" className={css(styles.input)} />
                  </Col>
                  <Col span={1}></Col>
                  <Col span={8}>
                    <Button style={{ width: '100%' }} type="primary" icon="file-protect">获取验证码</Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  {loginBtn}
                </Row>
              </Row>
              :
              <Row></Row>
          }
        </Row>
      </Col>
      <Col span={8}></Col>
    </Row>
  )
}
