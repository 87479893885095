/*
 * @Author: huangliang
 * @Date: 2019-12-06 16:55:53
 * @LastEditTime: 2020-03-03 15:35:44
 * @LastEditors: huangliang
 * @Description: 权限管理树结构
 * @FilePath: /00-pri-driving-web/src/pages/HR/Role/treedata.js
 * @js files
 */
export default [
  {
    title: '统计报表',
    key: '统计报表',
    children: [
      {
        title: '招生统计',
        key: '统计报表_招生统计',
      },
      {
        title: '招生统计表格',
        key: '统计报表_招生统计表格',
      },
      {
        title: '招生来源统计',
        key: '统计报表_招生来源统计',
      },
      {
        title: '在培人数统计',
        key: '统计报表_在培人数统计',
      },
      {
        title: '介绍人统计报表',
        key: '统计报表_介绍人统计报表',
      },
      {
        title: '学员合格率',
        key: '统计报表_学员合格率',
      },
      {
        title: '教练信息统计',
        key: '统计报表_教练信息统计',
      },
      {
        title: '学生属性',
        key: '统计报表_学生属性',
      },
    ]
  },
  {

    title: '学员管理',
    key: '学员管理',
    children: [
      {
        title: '意向学员',
        key: '学员管理_意向学员',
        children: [
          { title: '新增', key: '学员管理_意向学员_新增' },
          { title: '修改', key: '学员管理_意向学员_修改' },
          { title: '删除', key: '学员管理_意向学员_删除' }
        ]
      },
      {
        title: '学员报名',
        key: '学员管理_学员报名',
        children: [
          { title: '新增', key: '学员管理_学员报名_新增' },
          { title: '修改', key: '学员管理_学员报名_修改' },
          { title: '删除', key: '学员管理_学员报名_删除' }
        ]
      },
      {
        title: '学员查询',
        key: '学员管理_学员查询',
        children: [
          { title: '新增', key: '学员管理_学员查询_新增' },
          { title: '修改', key: '学员管理_学员查询_修改' },
          { title: '删除', key: '学员管理_学员查询_删除' }
        ]
      },
      {
        title: '学员导入',
        key: '学员管理_学员导入',
        children: [
          { title: '新增', key: '学员管理_学员导入_新增' },
          { title: '修改', key: '学员管理_学员导入_修改' },
          { title: '删除', key: '学员管理_学员导入_删除' }
        ]
      },
      {
        title: '五次不过',
        key: '学员管理_五次不过',
        children: [
          { title: '新增', key: '学员管理_五次不过_新增' },
          { title: '修改', key: '学员管理_五次不过_修改' },
          { title: '删除', key: '学员管理_五次不过_删除' }
        ]
      },
      {
        title: '退学管理',
        key: '学员管理_退学管理',
        children: [
          { title: '新增', key: '学员管理_退学管理_新增' },
          { title: '修改', key: '学员管理_退学管理_修改' },
          { title: '删除', key: '学员管理_退学管理_删除' }
        ]
      },
      {
        title: '换班管理',
        key: '学员管理_换班管理',
        children: [
          { title: '新增', key: '学员管理_换班管理_新增' },
          { title: '修改', key: '学员管理_换班管理_修改' },
          { title: '删除', key: '学员管理_换班管理_删除' }
        ]
      },
    ]
  },
  {
    title: '驾校管理',
    key: '驾校管理',
    children: [
      {
        title: '班制',
        key: '驾校管理_班制',
        children: [
          { title: '新增', key: '驾校管理_班制_新增' },
          { title: '修改', key: '驾校管理_班制_修改' },
          { title: '删除', key: '驾校管理_班制_删除' }
        ]
      },
      {
        title: '训练场',
        key: '驾校管理_训练场',
        children: [
          { title: '新增', key: '驾校管理_训练场_新增' },
          { title: '修改', key: '驾校管理_训练场_修改' },
          { title: '删除', key: '驾校管理_训练场_删除' }
        ]
      },
      {
        title: '报名点',
        key: '驾校管理_报名点',
        children: [
          { title: '新增', key: '驾校管理_报名点_新增' },
          { title: '修改', key: '驾校管理_报名点_修改' },
          { title: '删除', key: '驾校管理_报名点_删除' }
        ]
      }
    ]
  },
  {
    title: '考试管理',
    key: '考试管理',
    children: [
      {
        title: '考试预约',
        key: '考试管理_考试预约',
        children: [
          { title: '新增', key: '考试管理_考试预约_新增' },
          { title: '修改', key: '考试管理_考试预约_修改' },
          { title: '删除', key: '考试管理_考试预约_删除' }
        ]
      },
      {
        title: '已经约考',
        key: '考试管理_已经约考',
        children: [
          { title: '新增', key: '考试管理_已经约考_新增' },
          { title: '修改', key: '考试管理_已经约考_修改' },
          { title: '删除', key: '考试管理_已经约考_删除' }
        ]
      },
      {
        title: '车管所考试发布',
        key: '考试管理_车管所考试发布',
        children: [
          { title: '新增', key: '考试管理_车管所考试发布_新增' },
          { title: '修改', key: '考试管理_车管所考试发布_修改' },
          { title: '删除', key: '考试管理_车管所考试发布_删除' }
        ]
      },
      {
        title: '车管所考试查询',
        key: '考试管理_车管所考试查询',
        children: [
          { title: '新增', key: '考试管理_车管所考试查询_新增' },
          { title: '修改', key: '考试管理_车管所考试查询_修改' },
          { title: '删除', key: '考试管理_车管所考试查询_删除' }
        ]
      },
      
      {
        title: '成绩导入',
        key: '考试管理_成绩导入',
        children: [
          { title: '新增', key: '考试管理_成绩导入_新增' },
          { title: '修改', key: '考试管理_成绩导入_修改' },
          { title: '删除', key: '考试管理_成绩导入_删除' }
        ]
      },
      {
        title: '约考记录查询',
        key: '考试管理_约考记录查询',
        children: [
          { title: '新增', key: '考试管理_约考记录查询_新增' },
          { title: '修改', key: '考试管理_约考记录查询_修改' },
          { title: '删除', key: '考试管理_约考记录查询_删除' }
        ]
      }
    ]
  },
  {
    title: '车辆管理',
    key: '车辆管理',
    children: [
      {
        title: '车辆管理',
        key: '车辆管理_车辆管理',
        children: [
          { title: '新增', key: '车辆管理_车辆管理_新增' },
          { title: '修改', key: '车辆管理_车辆管理_修改' },
          { title: '删除', key: '车辆管理_车辆管理_删除' }
        ]
      }
    ]
  },
  {
    title: '分车排课',
    key: '分车排课',
    children: [
      {
        title: '科目二分车',
        key: '分车排课_科目二分车',
        children: [
          { title: '新增', key: '分车排课_科目二分车_新增' },
          { title: '修改', key: '分车排课_科目二分车_修改' },
          { title: '删除', key: '分车排课_科目二分车_删除' }
        ]
      },
      {
        title: '科目三分车',
        key: '分车排课_科目三分车',
        children: [
          { title: '新增', key: '分车排课_科目三分车_新增' },
          { title: '修改', key: '分车排课_科目三分车_修改' },
          { title: '删除', key: '分车排课_科目三分车_删除' }
        ]
      },
      {
        title: '已分车',
        key: '分车排课_已分车',
        children: [
          { title: '新增', key: '分车排课_已分车_新增' },
          { title: '修改', key: '分车排课_已分车_修改' },
          { title: '删除', key: '分车排课_已分车_删除' }
        ]
      }
    ]
  },
  {
    title: '人事管理',
    key: '人事管理',
    children: [
      {
        title: '员工管理',
        key: '人事管理_员工管理',
        children: [
          { title: '新增', key: '学员管理_员工管理_新增' },
          { title: '修改', key: '学员管理_员工管理_修改' },
          { title: '删除', key: '学员管理_员工管理_删除' }
        ]
      },
      {
        title: '角色管理',
        key: '人事管理_角色管理',
        children: [
          { title: '新增', key: '学员管理_角色管理_新增' },
          { title: '修改', key: '学员管理_角色管理_修改' },
          { title: '删除', key: '学员管理_角色管理_删除' }
        ]
      }
    ]
  },
  {
    title: '财务管理',
    key: '财务管理',
    children: [
      {
        title: '学员收支管理',
        key: '财务管理_学员收支管理',
        children: [
          { title: '新增', key: '财务管理_学员收支管理_新增' },
          { title: '修改', key: '财务管理_学员收支管理_修改' },
          // { title: '删除', key: '财务管理_财务入账_删除' }
        ]
      },
      {
        title: '财务统计',
        key: '财务管理_财务统计',
        children: [
          { title: '新增', key: '财务管理_财务统计' },
          { title: '修改', key: '财务管理_财务统计' },
          // { title: '删除', key: '财务管理_财务入账_删除' }
        ]
      },
      {
        title: '财务入账',
        key: '财务管理_财务入账',
        children: [
          { title: '新增', key: '财务管理_财务入账_新增' },
          { title: '修改', key: '财务管理_财务入账_修改' },
          // { title: '删除', key: '财务管理_财务入账_删除' }
        ]
      },
      {
        title: '财务款项配置',
        key: '财务管理_财务款项配置',
        children: [
          { title: '新增', key: '财务管理_财务款项配置_新增' },
          { title: '修改', key: '财务管理_财务款项配置_修改' },
          { title: '删除', key: '财务管理_财务款项配置_删除' }
        ]
      }
    ]
  },
  {
    title: '系统设置',
    key: '系统设置',
    children: [
      {
        title: '系统设置',
        key: '系统设置_字典管理',
        children: [
          { title: '新增', key: '系统设置_字典管理_新增' },
          { title: '修改', key: '系统设置_字典管理_修改' },
          { title: '删除', key: '系统设置_字典管理_删除' }
        ]
      }
    ]
  }
]