/*
 * @Author: huangliang
 * @Date: 2019-12-20 17:04:40
 * @LastEditTime: 2020-03-15 16:12:19
 * @LastEditors: huangliang
 * @Description: 通用搜索
 * @FilePath: /00-pri-driving-web/src/components/query/index.js
 * @js files
 */
import React from 'react'
import { Row, Col, Input, Button, Select, Icon, DatePicker } from 'antd'
import { css } from 'aphrodite'
import styles from './style'
import { GlobalContext } from '../../store/store'
import moment from 'moment'
const Option = Select.Option
const { RangePicker } = DatePicker





export default function QueryBar(props) {
  const store = React.useContext(GlobalContext)
  function setCondition(key, value) {
    const condition = { ...store.condition }
    condition[key] = value
    store.setCondition(condition)
  }
  return (
    <Row style={{ paddingBottom: 20 }}>
      <Col span={21}>
        {
          props.config.status ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>进度筛选 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue="all" onChange={value => { setCondition('status', value) }}>
                  <Option key="all">全部</Option>
                  <Option key="withoutSerialNumber">未生成流水号</Option>
                  <Option key="SerialNumber">已生成流水号</Option>
                  <Option key="subjectOneNoPass">科目一未通过</Option>
                  <Option key="subjectOnePass">科目一已通过</Option>
                  <Option key="subjectTwoNoPass">科目二未通过</Option>
                  <Option key="subjectTwoPass">科目二已通过</Option>
                  <Option key="subjectThreeNoPass">科目三未通过</Option>
                  <Option key="subjectThreePass">科目三已通过</Option>
                  <Option key="subjectFourNoPass">科目四未通过</Option>
                  <Option key="subjectFourPass">科目四已通过</Option>
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.signUpDate ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>报名时间 : </Col>
              <Col span={6}>
                <RangePicker className={css(styles.modalInput)} onChange={value => { setCondition('signupDate', value) }} />
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.payDate ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>缴费日期 : </Col>
              <Col span={6}>
                <RangePicker className={css(styles.modalInput)} onChange={value => { setCondition('payDate', value) }} defaultValue={[moment().startOf('month'), moment().endOf('month')]} />
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.datePicker ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>考试日期 : </Col>
              <Col span={6}>
                <RangePicker className={css(styles.modalInput)} onChange={value => { setCondition('datePicker', value) }} />
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.filter ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>模糊查询 : </Col>
              <Col span={6}>
                <Input placeholder="请输入姓名、证件号、电话号码进行模糊查询" className={css(styles.modalInput)} onChange={e => setCondition('filter', e.target.value)} />
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.introducer ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}> 介绍人 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue="all" onChange={value => { setCondition('introducer', value) }}>
                  <Option key="all">全部</Option>
                  {
                    store.introducers.map(item => <Option key={item.name}>{item.name}</Option>)
                  }
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.S2coach ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}> 科二教练 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue="all" onChange={value => { setCondition('subjectTwoCoach', value) }}>
                  <Option key="all">全部</Option>
                  {
                    store.coaches.filter(item => item.subject === '科目二').map(item => <Option key={item.name}>{item.name}</Option>)
                  }
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.S3coach ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}> 科三教练 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue="all" onChange={value => { setCondition('subjectThreeCoach', value) }}>
                  <Option key="all">全部</Option>
                  {
                    store.coaches.filter(item => item.subject === '科目三').map(item => <Option key={item.name}>{item.name}</Option>)
                  }
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.signUpSpot ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>报名点 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue="all" onChange={value => { setCondition('signupSpot', value) }}>
                  <Option key="all">全部</Option>
                  {
                    store.signupSpots.map(item => <Option key={item.name}>{item.name}</Option>)
                  }
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.class ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>班制 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue="all" onChange={value => { setCondition('classId', value) }}>
                  <Option key="all">全部</Option>
                  {
                    store.classes.map(item => <Option key={item.name}>{item.name}</Option>)
                  }
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.indendStatus ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>过期状态 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue="all" onChange={value => { setCondition('indendExpire', value) }}>
                  <Option key="all">全部</Option>
                  <Option key="expired">已经过期</Option>
                  <Option key="willexpired">即将过期(3天)</Option>
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.dataComplete ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>资料齐全 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue="all" onChange={value => { setCondition('dataComplete', value) }}>
                  <Option key="all">全部</Option>
                  <Option key='true'>资料齐全</Option>
                  <Option key='false'>资料不齐全</Option>
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }

        {
          props.config.subject ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>科目 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue="all" onChange={value => { setCondition('subject', value) }}>
                  <Option key="all">全部</Option>
                  <Option key='subjectOne'>科目一</Option>
                  <Option key='subjectTwo'>科目二</Option>
                  <Option key='subjectThree'>科目三</Option>
                  <Option key='subjectFour'>科目四</Option>
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }

        {
          props.config.clean ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>费用情况 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue="all" onChange={value => { setCondition('clean', value) }}>
                  <Option key="all">全部</Option>
                  <Option key="true">缴清费用</Option>
                  <Option key="false">未缴清</Option>
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.fullPaid ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>缴费类型 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue="all" onChange={value => { setCondition('fullPaid', value) }}>
                  <Option key="all">全部</Option>
                  <Option key="true">全款</Option>
                  <Option key="false">分期</Option>
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.reserveStatus ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>约考结果 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue={'all'} onChange={value => { setCondition('reserveStatus', value) }}>
                  <Option key='all'>全部</Option>
                  <Option key='0'>待约考</Option>
                  <Option key='3'>已约考</Option>
                  <Option key='1'>约考成功</Option>
                  <Option key='2'>约考失败</Option>
                  <Option key='4'>取消约考</Option>
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.examStatus ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>考试结果 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue={'0'} onChange={value => { setCondition('examStatus', value) }}>
                  <Option key='0'>待录入</Option>
                  <Option key='1'>考试合格</Option>
                  <Option key='2'>考试不合格</Option>
                  <Option key='3'>取消考试</Option>

                </Select>
              </Col>
            </div>
            :
            <div></div>
        }

        {
          props.config.subjectTwoCoach ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>科目二教练 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue={'all'} onChange={value => { setCondition('subjectTwoCoach', value) }}>
                  <Option key='all'>全部</Option>

                  {
                    store.coaches && store.coaches.filter(item => item.subject === '科目二').map(item => {
                      return (<Option key={item.name}>{item.name}</Option>)
                    })
                  }
                  {/* <Option key='1'>考试合格</Option>
                  <Option key='2'>考试不合格</Option>
                  <Option key='3'>取消考试</Option> */}
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }
        {
          props.config.subjectThreeCoach ?
            <div>
              <Col span={2} className={css(styles.modalRowTextLeft)}>科目三教练 : </Col>
              <Col span={6}>
                <Select className={css(styles.modalInput)} defaultValue={'all'} onChange={value => { setCondition('subjectThreeCoach', value) }}>
                  <Option key='all'>全部</Option>

                  {
                    store.coaches && store.coaches.filter(item => item.subject === '科目三').map(item => {
                      return (<Option key={item.name}>{item.name}</Option>)
                    })
                  }
                  {/* <Option key='1'>考试合格</Option>
                  <Option key='2'>考试不合格</Option>
                  <Option key='3'>取消考试</Option> */}
                </Select>
              </Col>
            </div>
            :
            <div></div>
        }
      </Col>
      {/* <Col span={3} style={{ lineHeight: '32px', marginTop: 10, textAlign: 'center', color: '#1890ff', cursor: 'pointer' }}>
        展开<Icon type="down" />
      </Col> */}
    </Row>
  )
}

