import React from 'react'
import { Menu, DatePicker, Select, Divider, Table, Row, Button, Switch, Radio, Tooltip, Icon, Tag, Modal, Col, Input, message, InputNumber, Popconfirm } from 'antd'
import { css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import moment from 'moment'

const { Option } = Select

export default function settings() {
  /**
   * Define Table Colums
   */
  const columns = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left'
    },
    {
      title: '证件号',
      dataIndex: 'certificate',
      key: 'certificate',
      fixed: 'left'
    },
    {
      title: '缴费时间',
      dataIndex: 'payDate',
      key: 'payDate',
      render: data => data ? moment(data).format('YYYY-MM-DD') : '',
      // sorter : (a,b) => moment(a.payDate).format('YYYY-MM-DD') - moment(b.payDate).format('YYYY-MM-DD') > 0
    },
    
    {
      title: '班制',
      dataIndex: 'classId',
      key: 'classId'
    },
    {
      title: '费用类型',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '原价',
      dataIndex: 'cost',
      key: 'cost'
    },
    {
      title: '应缴金额',
      dataIndex: '',
      key: '',
      render :(data,info) => {
        return info.cost - info.offer
      }
    },
    {
      title: '缴费类型',
      dataIndex: 'payType',
      key: 'payType'
    },
    {
      title: '实缴金额',
      dataIndex: 'paid',
      key: 'paid'
    },
    {
      title: '欠费',
      dataIndex: 'arrears',
      key: 'arrears'
    },
    {
      title: '优惠金额',
      dataIndex: 'offer',
      key: 'offer'
    },
    {
      title: '票号',
      dataIndex: 'bills',
      key: 'bills'

    },
    {
      title: '说明',
      dataIndex: 'comments',
      key: 'comments'
    },
    {
      title: '收费人',
      dataIndex: 'creator',
      key: 'creator'
    },
    {
      title: '联系电话',
      dataIndex: 'contact',
      key: 'contact'
    },
    {
      title: '介绍人',
      dataIndex: 'introducer',
      key: 'introducer'
    },
    {
      title: '报名地点',
      dataIndex: 'signupSpot',
      key: 'signupSpot'
    },
    {
      title: '是否入账',
      dataIndex: 'checked',
      key: 'checked',
      fixed: 'right',
      render: data => data ? <Tag color="#f50">已入账</Tag> : <Tag color="#2db7f5">未入账</Tag>
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      fixed: 'right',
      render: (data, group) => {
        return (
          <Row>
            <span className={css(styles.operate)} onClick={() => {
              if (!group.checked) { checkFinances(group._id) } else {
                message.warn(`该条记录已经入账`)
              }
            }}>入账该记录</span>

          </Row>
        )
      }
    }
  ]


  /**
   * React State
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [selectedRowKeys, setSelectedRow] = React.useState([])
  const [loading, setLoading] = React.useState(false)


  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
  }, [])



  /**
   * 获取分组数据
   */

  async function fetchData() {
    try {
      setLoading(true)
      setData([])
      const unchecked = await axios.post('/student/finance/unchecked')
      const tableData = unchecked.data.data
      setData(tableData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[ERROR]:')
      console.log(error)
    }
  }

  async function checkFinances(_id) {
    try {
      const checkedData = _id ? [_id] : selectedRowKeys
      const tableData = [...data].filter( item => checkedData.includes(item._id))
      const checked = tableData.map( item => {
        return({
          financeId : item._id,
          userId : item.userId,
          type : item.type
        })
      })
      const result = await axios.post('/student/finance/checked', { data: checked })
      if (result.data.success) {
        setLoading(true)
        const tableData = [...data]
        const isFirstCheck = []
        tableData.map(item => {
          if (checkedData.includes(item._id)) {
            item.checked = true
            if (!item.isFirstCheck) {
              isFirstCheck.push(item.userId)
            }
          }
        })
        setData(tableData)
        message.success(`入账学员财务记录成功`)
        setSelectedRow([])
        setLoading(false)
        const result = await axios.post('/student/finance/firstCheck', { data: isFirstCheck })
        if (result.data.success) {
          console.log(`修改学员状态成功`)
        } else {
          console.log(`修改学员状态失败`)
        }
      } else {
        message.error(result.data.failedMessage || '服务器未知错误')
      }
    } catch (error) {
      console.log(error)
      message.error(error.message || error)
    }
  }



  const rowSelection = {
    selectedRowKeys,
    onChange: value => setSelectedRow(value),
  };
  /**
   * Render View
   */
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Row style={{ marginTop: 0 }}>
          <Button icon='check-square' type='primary' onClick={() => { checkFinances() }}>批量确认</Button>
        </Row>
        <Table
          className="ant-table td"
          loading={loading}
          rowKey="_id"
          rowSelection={rowSelection}
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          rowClassName={(record, index) => {
            return (record.arrears > 0 ? css(styles.top) : css(styles.normal))
          }}
          scroll={{ x: 'max-content' }}
          dataSource={data}
          columns={columns}
          style={{
            marginTop: 10
          }}
        />
      </Row>
    </Row>
  )
}


