import React from 'react'
import { Row, Col, Statistic, Icon, message } from 'antd'
import { GlobalContext } from '../../store/store'


import Content from '../../components/content'
import Axios from '../../api/axios'

export default function () {
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState({})
  async function getData() {
    const result = await Axios.post('/dashboard/work/data')
    if (result.data.success) {
      setData(result.data.data)
    }
  }
  React.useEffect(() => {
    getData()
  }, [])
  return (
    <div>
      <div style={{
        marginLeft: store.collapsed ? 80 : 200,
        // width: '100%',
        padding: 15,
        height: 180,
        backgroundColor: 'white',
        boxShadow: ''
      }}>
        <Row>
          <h2 style={{
            // textIndent : 15
          }}>工作台</h2>
        </Row>
        <Row style={{
          height: 72,
          marginTop: 20
        }}>
          <div style={{
            float: 'left'
          }}>
            <img src='https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png' style={{
              width: 72
            }} />
          </div>
          <div style={{
            float: 'left', marginLeft: 20
          }}>
            <div style={{
              lineHeight: '36px'
            }}>
              <h3>您好, {window.localStorage.getItem('displayName')} ,祝你开心每一天！</h3>
            </div>
            <div style={{
              lineHeight: '36px'
            }}>
              <span>驾陪云-{window.localStorage.getItem('companyName')}-{window.localStorage.getItem('roleName')}</span>
            </div>

          </div>
          <div style={{
            float: 'right'
          }}>
            <Statistic title="昨日销售额金额" value={data.cost} prefix={<Icon type="like" />} style={{ float: 'left', marginRight: 30 }} />
            <Statistic title="昨日销售额排名" value={data.sortIndex} suffix={`/ ${data.list ? data.list.length : 'loading'}`} style={{ float: 'left', marginRight: 30 }} />

          </div>
        </Row>
      </div>
      <Content>
        <Row>
         <Statistic title="昨天抢入私海学员" value={data.indendPicker} prefix={<Icon type="calendar" />} style={{ float: 'left', marginRight: 30 }} />
          <Statistic title="正在跟进学员" value={data.following} prefix={<Icon type="clock-circle" />} style={{ float: 'left', marginRight: 30 }} />
          <Statistic title="已经转化报名的学员" value={data.registered} prefix={<Icon type="check" />} style={{ float: 'left', marginRight: 30 }} />
          <Statistic title="昨日销售金额" value={data.cost} prefix={<Icon type="like" />} style={{ float: 'left', marginRight: 30 }} />
          <Statistic title="本月销售金额" value={data.monthcost} prefix={<Icon type="pay-circle" />} style={{ float: 'left', marginRight: 30 }} />
        </Row>
        {/* 1.昨天联系了多少学员(抢了多少到私海里面)
        2.预约上门的学员()
        3.开单数据(昨日报名数据)(报名时间是昨天,并且介绍人是自己)
        4.昨天的销售金额
        5.销售金额 */}
      </Content>
    </div>
  )
}