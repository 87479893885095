import React from 'react'
import { Menu, Select, PageHeader, Table, Row, Button, Switch, Radio, Drawer, Tag, Modal, Col, Input, message, InputNumber, Popconfirm, Divider } from 'antd'
import { StyleSheet, css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import { traningPlaceParams } from './config'
import Avatar from './upload'
import { initAllBaseData } from '../../../store/init/init'


const { Option } = Select

export default function TrainingPlace() {
  /**
   * Define Table Colums
   */
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      render: (a, b, c) => c + 1
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '科目',
      dataIndex: 'subject',
      key: 'subject'
    },
    {
      title: '车辆类型',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: '联系电话',
      dataIndex: 'contact',
      key: 'contact'
    },
    {
      title: '地址',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: '坐标',
      dataIndex: 'location',
      key: 'location'
    },
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      sorter: (a, b) => a.sort > b.sort
    },
    {
      title: '状态',
      dataIndex: 'enable',
      key: 'enable',
      render: data => <Row><Switch checkedChildren="启用" unCheckedChildren="未启用" checked={data} onChange={() => { }} /></Row>
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      // fixed: 'right',
      render: (data, group) => {
        return (
          <Row>
            <span className={css(styles.operate)} onClick={() => { openUpdateModal(group) }}>编辑</span>
            <Popconfirm title="确认删除?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleData(group._id) }}>
              <span className={css(styles.operate)}>删除</span>
            </Popconfirm>
          </Row>
        )
      }
    }
  ]


  /**
   * React State
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [subjectFilter, setSubjectFilter] = React.useState('all')
  const [search, setSearch] = React.useState('')
  /**
   * [ModalType]:1.add 2.update
   */
  const [modalType, setModalType] = React.useState(1)
  const [editId, setEditId] = React.useState('')

  /**
   * 业务相关数据的定义
   */
  const [name, setName] = React.useState('')
  const [sort, setSort] = React.useState('')
  const [enable, setEnable] = React.useState(true)
  const [type, setType] = React.useState('C1')
  const [subject, setSubject] = React.useState('科目二')
  const [contact, setContact] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [accommodate, setAcc] = React.useState('')
  const [inuse, setInuse] = React.useState('')
  const [location, setLocation] = React.useState('')
  const [acreage, setAcreage] = React.useState('')
  const [routes, setRoutes] = React.useState('')
  const [group, setGroup] = React.useState('')


  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
  }, [])

  /**
   * 增加一条数据
   */
  async function createSingleData() {
    try {
      if (!name) { throw `请输入训练场名称` }
      if (!subject) { throw `请选择科目类型` }
      if (!address) { throw `请输入训练场地址` }
      const result = await axios.post(`/school/traning`, {
        name,
        type,
        subject,
        contact,
        accommodate,
        inuse,
        location,
        address,
        acreage,
        routes,
        group,
        enable,
        sort,
        images: store.imagesList
      })
      if (result.data.success) {
        const newData = result.data.data
        data.push(newData)
        setData(data)
        setVisible(!visible)
        initAllBaseData(store)
      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(error)
    }
  }

  /**
   * 获取分组数据
   */

  async function fetchData(subject, filter) {
    try {
      setLoading(true)
      setData([])
      const data = await axios.get('/school/traning')
      const tableData = data.data.data
      setData(tableData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[ERROR]:')
      console.log(error)
    }

  }

  /**
   * 删除分组数据
   */
  async function deleteSingleData(_id) {
    setLoading(true)
    const result = await axios.delete(`/school/traning/${_id}`)
    setLoading(false)
    if (result.data.success) {
      const newData = data.filter(item => item._id !== _id)
      setData(newData)
      initAllBaseData(store)

      message.success(`删除数据成功`)
    } else {
      message.error(`删除数据失败`)
    }
  }

  /**
   * 更新数据
   */
  async function updateSingleData() {
    try {
      const result = await axios.put(`/school/traning`, {
        _id: editId,
        name,
        type,
        subject,
        contact,
        accommodate,
        inuse,
        location,
        address,
        acreage,
        routes,
        group,
        enable,
        sort,
        images: store.imagesList
      })
      if (result.data.success) {
        fetchData()
        setVisible(!visible)
        initAllBaseData(store)

      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(`提交数据异常,请联系管理员`)
    }
  }


  /**
   * 打开新建对话框
   */
  async function openCreateModal() {
    setName('')
    setType('C1')
    setSort('')
    setEnable(true)
    setSubject('科目二')
    setContact('')
    setAddress('')
    setAcc('')
    setInuse('')
    setLocation('')
    setAcreage('')
    setRoutes('')
    setGroup('')
    setVisible(!visible)
    setModalType(1)
    store.setImageList([])
  }

  /**
   * 打开更新数据对话框
   */
  async function openUpdateModal(info) {
    console.log(info)
    for (let i in info) {
      if (!info[i]) {
        info[i] = ''
      }
    }
    setName(info.name)
    setType(info.type)
    setSort(info.sort || '')
    setEnable(info.enable)
    setSubject(info.subject)
    setContact(info.contact)
    setAddress(info.address)
    setAcc(info.accommodate || '')
    setInuse(info.inuse || '')
    setLocation(info.location)
    setAcreage(info.acreage || '')
    setRoutes(info.routes)
    setGroup(info.group)
    store.setImageList(info.images)
    // setCode(info.code)
    setVisible(!visible)
    setModalType(2)
    setEditId(info._id)

  }






  /**
   * Render View
   */
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Row className={css(styles.modalRow)}>
          <Col span={2} className={css(styles.modalRowTextLeft)}>训练场分组 : </Col>
          <Col span={5}>
            <Input placeholder="字典没有录入、只支持输入查询" className={css(styles.modalInput)} />
          </Col>
          <Col span={2} className={css(styles.modalRowTextLeft)}>科目类型 : </Col>
          <Col span={5}>
            <Select value={subjectFilter} className={css(styles.modalInput)} onChange={value => { setSubjectFilter(value) }}>
              <Option value="all">全部</Option>
              <Option value="科目二">科目二</Option>
              <Option value="科目三">科目三</Option>
            </Select>
          </Col>
          <Col span={2} className={css(styles.modalRowTextLeft)}>模糊查询 : </Col>
          <Col span={5}>
            <Input placeholder="请输入训练场名称" onChange={e => { setSearch(e.target.value) }} className={css(styles.modalInput)} />
          </Col>
          <Col>
            <Button onClick={() => { fetchData(subjectFilter, search) }} type="primary" icon="search" style={{ float: 'left', marginLeft: 20, width: 100 }}>查询</Button>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Button icon='plus' style={{ marginTop: -10 }} type='primary' onClick={() => { openCreateModal() }}>新增</Button>
        </Row>
        <Table
          loading={loading}
          rowKey="_id"
          dataSource={data}
          columns={columns}
          style={{
            marginTop: 10,
          }} />
      </Row>
      <Drawer
        title="训练场管理"
        visible={visible}
        onClose={() => { setVisible(!visible) }}
        closable={true}
        width="70%"
      >
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>训练场名称</Col>
          <Col span={5}>
            <Input placeholder="输入名称" className={css(styles.modalInput)} value={name} onChange={e => { setName(e.target.value) }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>培训车型</Col>
          <Col span={5}>
            <Select className={css(styles.modalInput)} value={type} onChange={value => { setType(value) }}>
              <Option value="C1">C1</Option>
              <Option value="C2">C2</Option>
            </Select>
          </Col>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>科目</Col>
          <Col span={5}>
            <Select className={css(styles.modalInput)} value={subject} onChange={value => { setSubject(value) }}>
              <Option value="科目二">科目二</Option>
              <Option value="科目三">科目三</Option>
            </Select>
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>电话</Col>
          <Col span={5}>
            <Input placeholder="输入名称" className={css(styles.modalInput)} value={contact} onChange={e => { setContact(e.target.value) }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>容纳车辆</Col>
          <Col span={5}>
            <InputNumber placeholder="最多容纳车辆数" className={css(styles.modalInput)} value={accommodate} onChange={value => { setAcc(value) }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>投放车辆</Col>
          <Col span={5}>
            <InputNumber placeholder="已经投入使用车辆数" className={css(styles.modalInput)} value={inuse} onChange={value => { setInuse(value) }} />
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>坐标</Col>
          <Col span={5}>
            <Input placeholder="输入名称" className={css(styles.modalInput)} value={location} onChange={e => { setLocation(e.target.value) }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>地址</Col>
          <Col span={13}>
            <Input placeholder="输入地址" className={css(styles.modalInput)} value={address} onChange={e => { setAddress(e.target.value) }} />
          </Col>

        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>面积</Col>
          <Col span={5}>
            <Input placeholder="输入名称" className={css(styles.modalInput)} value={acreage} onChange={e => { setAcreage(e.target.value) }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>乘车路线</Col>
          <Col span={13}>
            <Input placeholder="输入地址" className={css(styles.modalInput)} value={routes} onChange={e => { setRoutes(e.target.value) }} />
          </Col>

        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>分组</Col>
          <Col span={5}>
            <Input placeholder="输入分组名称" className={css(styles.modalInput)} value={group} onChange={e => { setGroup(e.target.value) }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>排序</Col>
          <Col span={5}>
            <InputNumber placeholder="数字越大排名越靠前" className={css(styles.modalInput)} value={sort} onChange={value => { setSort(value) }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>是否启用</Col>
          <Col span={5}><Switch checkedChildren="启用" unCheckedChildren="停用" checked={enable} onChange={value => { setEnable(value) }} style={{ marginLeft: 10, marginTop: 5 }} /></Col>
        </Row>
        <Divider>训练场图片</Divider>
        <Avatar />
        <div className={css(styles.drawerFooter)}>
          <Button
            style={{
              marginRight: 8,
            }}
            onClick={() => { setVisible(!visible) }}
          >
            取消
            </Button>
          <Button onClick={() => {
            if (modalType == 1) {
              createSingleData()
            } else {
              updateSingleData()
            }
          }} type="primary">
            完成
            </Button>
        </div>
      </Drawer>
    </Row>
  )
}


