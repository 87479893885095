/*
 * @Author: huangliang
 * @Date: 2020-01-05 15:59:18
 * @LastEditTime : 2020-01-05 16:56:47
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/components/studentfile/finance.js
 * @js files
 */
import React from 'react'
import { Table, Tag, Row } from 'antd'
import moment from 'moment'
import { GlobalContext } from '../../store/store'


/**
   * Define Table Colums
   */
const financeColums = [
  {
    title: '费用类型',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: '应缴金额',
    dataIndex: 'cost',
    key: 'cost'
  },
  {
    title: '实缴金额',
    dataIndex: 'paid',
    key: 'paid'
  },
  {
    title: '欠费',
    dataIndex: 'arrears',
    key: 'arrears'
  },
  {
    title: '缴费方式',
    dataIndex: 'payType',
    key: 'payType'
  },
  {
    title: '优惠金额',
    dataIndex: 'offer',
    key: 'offer'
  },
  {
    title: '票号',
    dataIndex: 'bills',
    key: 'bills'

  },
  {
    title: '说明',
    dataIndex: 'comments',
    key: 'comments'
  },

  {
    title: '缴费时间',
    dataIndex: 'payDate',
    key: 'payDate',
    render: data => data ? moment(data).format('YYYY-MM-DD') : ''
  },
  {
    title: '收费人',
    dataIndex: 'creator',
    key: 'creator'
  },

  {
    title: '入账状态',
    dataIndex: 'checked',
    key: 'checked',
    render: data => data ? <Tag color="#f50">已入账</Tag> : <Tag color="#2db7f5">未入账</Tag>
  }
]

export default function Finances() {
  const store = React.useContext(GlobalContext)
  const data = store.studentFile.charges || []
  console.log(data)
  return (
    <Row>
      <Row className="ant-descriptions-title">
        财务信息
      </Row>
      <Table dataSource={data} columns={financeColums} />
    </Row>
  )
}

