import React from 'react'
import { Alert, Select, Avatar, Divider, Table, List, Pagination, Tabs, Tooltip, Row, Button, Steps, DatePicker, Radio, Drawer, Tag, Modal, Col, Input, message, InputNumber, Popconfirm } from 'antd'
import { css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import _Avatar from './upload'
import moment from 'moment'
import QueryBar from '../../../components/query/index'
import ImportButton from './import'
import {
  studentsSchema,
  carTypes,
  costTypes,
  businessOptions,
  certificateOptions,
  sexOptions,
  filterOptions,
  financeColums
} from './config'
import {
  certificateId,
  phoneNumber
} from '../../../tools/verify'


const { Option } = Select
const { Step } = Steps
const { TabPane } = Tabs

export default function TrainingPlace() {
  const columns = [
    {
      title: '序号',
      dataIndex: '',
      key: '',
      fixed: 'left',
      render: (data, info, index) => (page - 1) * limit + index + 1
      // render: (data, info, index) => `${index + 1}`
    },
    {
      title: '报名时间',
      dataIndex: 'signupDate',
      key: 'signupDate',
      fixed: 'left',
      render: data => moment(data).format('YYYY-MM-DD')
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (data, info) => {
        let name = info.changeClass ? `${data}(已换班)` : data
        name = info.dropout ? `${name}(已退学)` : name
        // info.drop
        return (
          <Row style={{
            color: '#1890ff',
            cursor: 'pointer'
          }} onClick={() => {
            store.setStudentFileDrawer(!store.studentFileDrawer);
            store.setStudentFile(info)
          }}>
            {name}
          </Row>
        )
      }
    },
    {
      title: '证件号',
      dataIndex: 'certificate',
      key: 'certificate',
      fixed: 'left',

    },
    {
      title: '联系电话',
      dataIndex: 'contact',
      key: 'contact',
      fixed: 'left',

    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex'
    },
    {
      title: '状态',
      dataIndex: '',
      key: '',
      render: (data, info) => {
        if (!data.dataComplete) {
          return (`资料未齐全`)
        }
        if (!data.serialNumber) {
          return (`流水号未生成`)
        }
        if (!data.subjectOnePass) {
          return (`科目一未通过`)
        }
        if (!data.subjectTwoPass) {
          return (`科目二未通过`)
        }
        if (!data.subjectThreePass) {
          return (`科目三未通过`)
        }
        if (!data.subjectFourPass) {
          return (`科目四未通过`)
        } else {
          return (`未知`)
        }

      }
    },

    {
      title: '班制',
      dataIndex: 'classId',
      key: 'classId'
    },
    {
      title: '应缴金额',
      dataIndex: 'cost',
      key: 'cost'
    },
    {
      title: '实缴金额',
      dataIndex: 'paid',
      key: 'paid'
    },
    {
      title: '欠费',
      dataIndex: 'arrears',
      key: 'arrears',
      render: (data, info) => info.cost - info.paid > 0 ? info.cost - info.paid : 0
    },
    {
      title: '介绍人',
      dataIndex: 'introducer',
      key: 'introducer'
    },
    {
      title: '报名网店',
      dataIndex: 'signupSpot',
      key: 'signupSpot'
    },
    {
      title: '跟踪客服',
      dataIndex: 'follower',
      key: 'follower'
    },
    {
      title: '交资料',
      dataIndex: 'dataComplete',
      key: 'dataComplete',
      render: data => data ? '资料已齐全' : '资料未齐全'
    },
    {
      title: '流水号',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      render: data => data ? data : <Row><Tag color="#f50">未生成</Tag></Row>
    },
    {
      title: '科目一考试时间',
      dataIndex: '',
      key: '',
      align: 'center',
    },
    {
      title: '科目一考试结果',
      dataIndex: 'subjectOne',
      key: 'subjectOne',
      align: 'center',
      render: (data, group) => renderStatus(data, group)

    },
    {
      title: '科目二考试时间',
      dataIndex: '',
      key: '',
      align: 'center',
    },
    {
      title: '科目二考试结果',
      dataIndex: 'subjectTwo',
      key: 'subjectTwo',
      align: 'center',
      render: data => renderStatus(data)

    },
    {
      title: '科目三考试时间',
      dataIndex: '',
      key: '',
      align: 'center',
    },
    {
      title: '科目三考试结果',
      dataIndex: 'subjectThree',
      key: 'subjectThree',
      align: 'center',
      render: data => renderStatus(data)

    },
    {
      title: '科目四考试时间',
      dataIndex: '',
      key: '',
      align: 'center',
    },
    {
      title: '科目四考试结果',
      dataIndex: 'subjectFour',
      key: 'subjectFour',
      align: 'center',
      render: data => renderStatus(data)

    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      fixed: 'right',
      render: (data, group) => {
        return (
          <Row>
            <span className={css(styles.operate)} onClick={() => { setSerNumberVisible(!serNumberVisible); setEditId(group._id) }}>生成流水号</span>
            <span className={css(styles.operate)} onClick={() => { openSubject(group) }}>录入成绩</span>
            <span className={css(styles.operate)} onClick={() => { openUpdateModal(group) }}>编辑</span>
            <Popconfirm title="确认删除?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleData(group._id) }}>
              <span className={css(styles.operate)}>删除</span>
            </Popconfirm>
          </Row>
        )
      }
    }
  ]
  /**
   * React State
   */
  const initPageSize = window.localStorage.getItem('pageSize') ? parseInt(window.localStorage.getItem('pageSize')) : 10
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [modalType, setModalType] = React.useState(1)
  const [editId, setEditId] = React.useState('')
  const [alertMsg, setAlertMsg] = React.useState('初始化提示信息')
  const [subjectVisible, setSubVisible] = React.useState(false)
  const [serNumberVisible, setSerNumberVisible] = React.useState(false)
  const [examList, setExamList] = React.useState([])
  const [examIsPass, setExamPass] = React.useState(false)

  const [subjects, setSubjects] = React.useState([])
  const [examDate, setExamDate] = React.useState(moment())
  const [passed, setPassed] = React.useState('success')
  const [current, setCurrent] = React.useState(0)

  const [page, setPage] = React.useState(1)
  const [limit, setLimit] = React.useState(initPageSize)
  const [total, setTotal] = React.useState(0)
  const [exportVisible, setExportVisible] = React.useState(false)
  const [exportLoading, setExportLoading] = React.useState(false)
  const [downloadUrl, setDownloadingUrl] = React.useState('')
  const [student, setStudent] = React.useState({})
  /**
   * 业务相关数据的定义
   */
  const [name, setName] = React.useState('')
  const [nation, setNation] = React.useState('')
  const [sex, setSex] = React.useState('男')
  const [certificateType, setCerType] = React.useState('身份证')
  const [certificate, setCer] = React.useState('')
  const [birth, setBirth] = React.useState('')
  const [certificateValid, serCerValid] = React.useState('')
  const [contact, setContact] = React.useState('')
  const [code, setCode] = React.useState('')
  const [certificateAddress, serCerAddr] = React.useState('')
  const [liveAddress, setLiveAddress] = React.useState('')
  const [signupSource, setSignupSource] = React.useState('')
  const [signupDate, setSignupDate] = React.useState('')
  const [signupSpot, setSignupSpot] = React.useState('')
  const [introducer, setIntroducer] = React.useState('')
  const [businessType, setBusinessType] = React.useState(businessOptions[0])
  const [primaryCarType, setPriCarType] = React.useState('')
  const [primaryCertificate, setPrimaryCer] = React.useState('')
  const [studentGroup, setStudentGroup] = React.useState('')
  const [carType, setCarType] = React.useState('')
  const [classId, setClass] = React.useState('')
  const [traningPlace, setTraningPlace] = React.useState('')
  const [preCoach, setPreCoach] = React.useState('')
  const [dataComplete, setDataComplete] = React.useState(true)
  const [comments, setComments] = React.useState('')
  const [charges, setCharge] = React.useState([])
  const [serialNumber, setSerial] = React.useState('')
  const [follower, setFollower] = React.useState('')


  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
  }, [])


  function renderStatus(data, item) {
    if (data.length === 5 && item && item.fiveTimeNoPass) {
      return (<Tag color="#f50">五次不过</Tag>)
    }
    if (data.length === 0) {
      return ('—')
    } else {
      const lastData = data[data.length - 1]
      return (
        <Tooltip placement="topLeft" title={`考试日期 : ${moment(lastData.date).format('YYYY-MM-DD')} 记录人 : ${lastData.operator}`}>
          {
            lastData.pass ?
              <Row>
                <Tag color="#87d068">通过</Tag>
              </Row>
              :
              <Tag color="#f50">未通过</Tag>
          }
        </Tooltip>
      )
    }
  }
  /**
   * 增加一条数据
   */
  async function createSingleData() {
    try {
      if (!introducer) { throw `请输入介绍人` }
      if (!signupSpot) { throw `请选择报名地点` }
      if (name === '') { throw `请输入学员姓名` }
      if (nation === '') { throw `请输入学员国籍` }
      if (!certificate) { throw `请输入学员证件号码` }
      if (!certificateId.test(certificate)) { throw `请输入合法有效的证件号码` }
      if (contact === '') { throw `请输入学员联系电话` }
      if (!phoneNumber.test(contact)) { throw `请输入合法有效的电话号码` }
      if (signupDate === '') { throw `请选择学员报名时间` }
      if (businessType === '') { throw `请选择学员的业务类型` }
      if (classId === '') { throw `请选择学员的班制` }
      const result = await axios.post(`/student/signup`, {
        name,
        nation,
        sex,
        certificateType,
        certificate,
        birth,
        certificateValid,
        contact,
        code,
        avatar: store.avatar,
        certificateAddress,
        liveAddress,
        signupSource,
        signupDate,
        signupSpot,
        introducer,
        businessType,
        businessType,
        primaryCarType,
        primaryCertificate,
        studentGroup,
        carType,
        classId,
        traningPlace,
        preCoach,
        dataComplete,
        comments,
        charges
      })
      if (result.data.success) {
        const newData = result.data.data
        data.push(newData)
        setData(data)
        setVisible(!visible)
      } else {
        console.log(result.data)
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      console.log(error)
      message.error(error)
    }
  }



  async function fetchData(page, size) {
    try {
      const condition = {}
      if (store.condition.status) {
        condition.status = store.condition.status
      }
      if (store.condition.signupDate) {
        condition.signupDateStart = store.condition.signupDate[0]
        condition.signupDateEnd = store.condition.signupDate[1]
      }
      if (store.condition.filter) {
        condition.filter = store.condition.filter
      }
      if (store.condition.classId) {
        condition.classId = store.condition.classId
      }
      if (store.condition.signupSpot) {
        condition.signupSpot = store.condition.signupSpot
      }
      if (store.condition.introducer) {
        condition.introducer = store.condition.introducer
      }
      if (store.condition.dataComplete) {
        if (store.condition.dataComplete === 'true') {
          condition.dataComplete = true
        }
        if (store.condition.dataComplete === 'false') {
          condition.dataComplete = false
        }
      }
      if (store.condition.clean) {
        condition.clean = store.condition.clean
      }
      if (store.condition.fullPaid) {
        condition.fullPaid = store.condition.fullPaid
      }
      condition.isFirstCheck = true
      condition.page = page || page || 1
      condition.limit = size || limit
      
      setLoading(true)
      const result = await axios.post('/student/query', condition)
      const tableData = result.data.data
      setData(tableData)
      setTotal(result.data.total)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }

  }

  /**
   * 删除分组数据
   */
  async function deleteSingleData(_id) {
    setLoading(true)
    const result = await axios.delete(`/student/signup/${_id}`)
    setLoading(false)
    if (result.data.success) {
      const newData = data.filter(item => item._id !== _id)
      setData(newData)
      message.success(`删除数据成功`)
    } else {
      message.error(`删除数据失败`)
    }
  }

  /**
   * 更新数据
   */
  async function updateSingleData() {
    try {
      if (name === '') { throw `请输入学员姓名` }
      if (nation === '') { throw `请输入学员国籍` }
      if (!certificate) { throw `请输入学员证件号码` }
      if (!certificateId.test(certificate)) { throw `请输入合法有效的证件号码` }
      if (contact === '') { throw `请输入学员联系电话` }
      if (!introducer) { throw `请输入介绍人` }
      if (!phoneNumber.test(contact)) { throw `请输入合法有效的电话号码` }
      if (!signupSpot) { throw `请选择报名地点` }
      if (signupDate === '') { throw `请选择学员报名时间` }
      if (businessType === '') { throw `请选择学员的业务类型` }
      if (classId === '') { throw `请选择学员的班制` }
      if (!follower) { throw `跟踪客服为必须选择` }
      const result = await axios.put(`/student/signup`, {
        _id: editId,
        name,
        nation,
        sex,
        certificateType,
        certificate,
        birth,
        certificateValid,
        contact,
        code,
        avatar: store.avatar,
        certificateAddress,
        liveAddress,
        signupSource,
        signupDate,
        signupSpot,
        introducer,
        businessType,
        businessType,
        primaryCarType,
        primaryCertificate,
        studentGroup,
        carType,
        classId,
        traningPlace,
        preCoach,
        dataComplete,
        comments,
        charges,
        follower
      })
      if (result.data.success) {
        message.success(`更新数据成功`)
        fetchData()
        setVisible(!visible)
      } else {
        message.error(result.data.failedMessage || '提交数据异常、未捕获的错误')
      }
    } catch (error) {
      message.error(error)
    }
  }
  /**
   * 打开更新数据对话框
   */
  async function openUpdateModal(info) {
    setName(info.name)
    setNation(info.nation)
    setSex(info.sex)
    setCerType(info.certificateType)
    setCer(info.certificate)
    setBirth(moment(info.birth))
    serCerValid(moment(info.certificateValid))
    setContact(info.contact)
    setCode(info.code)
    serCerAddr(info.certificateAddress)
    setLiveAddress(info.liveAddress)
    setSignupSource(info.signupSource)
    setSignupDate(moment(info.signupDate))
    setSignupSpot(info.signupSpot)
    setIntroducer(info.introducer)
    setBusinessType(info.businessType)
    setPriCarType(info.primaryCarType)
    setPrimaryCer(info.primaryCertificate)
    setStudentGroup(info.studentGroup)
    setCarType(info.carType)
    setClass(info.classId)
    setTraningPlace(info.traningPlace)
    setPreCoach(info.preCoach)
    setDataComplete(info.dataComplete)
    setComments(info.comments)
    setCharge(info.charges)
    setFollower(info.follower || '')
    store.setAvatar(info.avatar)
    setVisible(!visible)
    setModalType(2)
    setEditId(info._id)

  }

  /**
   * 对话框中选择班制后根据班制内的财务信息生成数据
   */
  async function setFinaceDefault(costs) {
    const financeData = costs.map(item => {
      return ({
        type: item.type,
        cost: item.cost,
        paid: item.cost,
        payType: '现金',
        offer: 0,
        offerReason: '',
        arrears: 0,
        bills: '',
        comments: ''
      })
    })
    setCharge(financeData)
  }
  async function openSubject(info) {
    setStudent(info)
    if (!info.serialNumber) {
      message.warn(`该学员未生成流水号,请先生成流水号后再编辑成绩`)
      return
    }
    const steps = [{
      key: 'subjectOne',
      title: '科目一',
      examIsPass: info.subjectOnePass,
      examList: info.subjectOne
    },
    {
      key: 'subjectTwo',
      title: '科目二',
      examIsPass: info.subjectTwoPass,
      examList: info.subjectTwo
    },
    {
      key: 'subjectThree',
      title: '科目三',
      examIsPass: info.subjectThreePass,
      examList: info.subjectThree
    },
    {
      key: 'subjectFour',
      title: '科目四',
      examIsPass: info.subjectFourPass,
      examList: info.subjectFour
    }]
    setEditId(info._id)
    setCurrent('subjectOne')
    setSubVisible(!subjectVisible)
    setExamList(info.subjectOne)
    setExamPass(info.subjectOnePass)
    setSubjects(steps)
  }


  async function setStudentExamResult() {
    try {
      const result = await axios.post('/student/exam/submit', {
        _id: editId,
        subject: current,
        data: {
          pass: passed === 'success' ? true : false,
          date: examDate
        }
      })
      if (result.data.success) {
        message.success(`设置学员成绩成功`)
        const tableData = [...data].map( item => {
          if(item._id == editId){
            item = result.data.data
          }
          return item
        })
        setData(tableData)
      } else {
        message.error(result.data.failedMessage || '设置学员失败')
      }
    } catch (error) {
      message.error(error.message || error)
    }
  }


  async function setStudentSerialNumber() {
    const result = await axios.post('/student/serial/submit', { _id: editId, serialNumber })
    if (result.data.success) {
      message.info(`设置学员流水号成功`)
      const tableData = [...data]
      tableData.map(item => {
        if (item._id === editId) {
          item.serialNumber = serialNumber
        }
      })
      setData(tableData)
    } else {
      message.error(result.data.failedMessage || '设置学员流水号失败')
    }
    setSerNumberVisible(!serNumberVisible)
  }

  /**
   * 财务明细中修改各个字段信息
   */
  async function editFinanceDetail(index, type, value) {

    const newFinanceData = charges.map((item, $index) => {
      if ($index === index) {
        item[type] = value
        if (type === 'paid' || type === 'offer') {
          /**
           * 如果修改的是实际缴费信息或者修改的是优惠信息 重新计算欠费金额
           */
          const arrear = item.cost - item.offer - item.paid
          const arrears = arrear > 0 ? arrear : 0
          item.arrears = arrears
        }
      }
      return item
    })

    /**
     * 整理信息
     */
    let allcosts = 0
    let allpaid = 0
    let arrears = 0
    newFinanceData.map(item => {
      allcosts = allcosts + item.cost
      allpaid = allpaid + item.paid
      arrears = arrears + item.arrears
    })
    const message = `一共需要缴纳费用为${allcosts}元,实际缴费 ${allpaid} 元 欠费${arrears} 元`
    console.log(message)
    setAlertMsg(message)
    setCharge(newFinanceData)
  }
  async function deleteSingleGrade(info) {
    console.log(student)
    console.log(current)
    console.log(info)
    const result = await axios.post('/student/finacne/delete/grade', {
      id: student._id,
      subject: current,
      gradeId: info._id
    })
    if (result.data.success) {
      const newExam = [...examList].filter(item => item._id !== info._id)
      setExamList(newExam)
      const newData = [...data].map(item => {
        if (item._id === student._id) {
          item[current] = newExam
        }
        return item
      })
      setData(newData)
      message.success(`删除学员成绩成功`)
    }


  }

  async function exportStudent() {
    try {
      setExportVisible(!exportVisible)
      setExportLoading(true)
      let condition = {}
      if (store.condition.status) {
        condition.status = store.condition.status
      }
      if (store.condition.signupDate) {
        condition.signupDateStart = store.condition.signupDate[0]
        condition.signupDateEnd = store.condition.signupDate[1]
      }
      if (store.condition.filter) {
        condition.filter = store.condition.filter
      }
      if (store.condition.classId) {
        condition.classId = store.condition.classId
      }
      if (store.condition.signupSpot) {
        condition.signupSpot = store.condition.signupSpot
      }
      if (store.condition.introducer) {
        condition.introducer = store.condition.introducer
      }
      condition.isFirstCheck = true
      condition.page = 1
      condition.limit = 10
      const result = await axios.post('/student/export', condition)
      message.info(`导出数据结束`)
      if (result.data.success) {
        setExportLoading(false)
        setDownloadingUrl(`${result.data.data}`)
      }
    } catch (error) {
      setExportVisible(!exportVisible)
      message.error(error.message || error)
    }
  }
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Col span={22}><QueryBar config={filterOptions} /></Col>
        <Col span={2}>
          <Button onClick={() => { fetchData() }} type="primary" icon="search" style={{ marginTop: 10, float: 'left', width: 100 }} >查询</Button>
        </Col>
        <Divider />
        <Row style={{ marginTop: -10 }}>
          {
            store.user && store.user.role === '管理员' ?
              <Button icon='export' type='primary' onClick={() => { exportStudent() }}>导出数据</Button>
              : <div></div>
          }
        </Row>
        <Alert message={`数据库中符合条件数据总数:${total}`} type="info" style={{ marginTop: 10 }} showIcon />
        <Table
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          rowClassName={(record, index) => {
            return (record.cost - record.paid > 0 ? css(styles.top) : css(styles.normal))
          }}
          scroll={{ x: 'max-content' }}
          loading={loading}
          rowKey={record => record._id}
          dataSource={data}
          columns={columns}
          pagination={false}
          style={{
            marginTop: 10,
          }} />
        <Pagination style={{ marginTop: 10 }}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={['10', '15', '20', '50']}
          pageSize={limit}
          current={page}
          total={total}
          onShowSizeChange={(page, pageSize) => {
            window.localStorage.setItem('pageSize', pageSize)
            setPage(page)
            setLimit(pageSize)
            fetchData(page, pageSize)
          }}
          onChange={(page, pageSize) => {
            setPage(page)
            setLimit(pageSize)
            fetchData(page, pageSize)
          }}
        />
      </Row>

      <Drawer
        title="学员报名"
        visible={visible}
        onClose={() => { setVisible(!visible) }}
        closable={true}
        width="85%"
      >
        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Row>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>姓名</Col>
              <Col span={6}>
                <Input placeholder="输入姓名" className={css(styles.modalInput)} value={name} onChange={e => { setName(e.target.value) }} />
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>国籍</Col>
              <Col span={6}>
                <Select value={nation} className={css(styles.modalInput)} onChange={value => { setNation(value) }}>
                  {
                    ['中国', '香港'].map(item => <Option value={item} key={item}>{item}</Option>)
                  }
                </Select>

                {/* <Input placeholder="输入国籍" className={css(styles.modalInput)} value={nation} onChange={e => { setNation(e.target.value) }} /> */}
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>性别</Col>
              <Col span={6}>
                <Select value={sex} className={css(styles.modalInput)} onChange={value => { setSex(value) }}>
                  {sexOptions.map(item => <Option value={item} key={item}>{item}</Option>)}
                </Select>
              </Col>
            </Row>
            <Row className={css(styles.modalRow)}>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>证件类型</Col>
              <Col span={6}>
                <Select value={certificateType} className={css(styles.modalInput)} onChange={value => { setCerType(value) }}>
                  {certificateOptions.map(item => <Option value={item} key={item}>{item}</Option>)}
                </Select>
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>证件号</Col>
              <Col span={6}>
                <Input disabled={modalType == 2 ? true : false} placeholder="输入对应的证件号码" className={css(styles.modalInput)} value={certificate} onChange={e => {
                  setCer(e.target.value)
                  if (certificateId.test(e.target.value)) {
                    console.log(`增则匹配通过、准备计算出生年月日`)
                    const year = e.target.value.substring(6, 10)
                    const month = e.target.value.substring(10, 12)
                    const day = e.target.value.substring(12, 14)
                    const birth = `${year}-${month}-${day}`
                    console.log(`${year}-${month}-${day}`)
                    setBirth(moment(birth))
                  }
                }} />
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>出生日期</Col>
              <Col span={6}>
                <DatePicker className={css(styles.modalInput)} value={birth} placeholder="请选择出生日期" onChange={value => { setBirth(value) }} />
              </Col>
            </Row>
            <Row className={css(styles.modalRow)}>
              <Col span={2} className={css(styles.modalRowText)}>截止日期</Col>
              <Col span={6}>
                <DatePicker className={css(styles.modalInput)} value={certificateValid} placeholder="身份证有效期" onChange={value => {
                  serCerValid(value)

                }} />
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>电话</Col>
              <Col span={6}>
                <Input disabled={modalType == 2 ? true : false} placeholder="请输入联系电话" className={css(styles.modalInput)} value={contact} onChange={e => { setContact(e.target.value) }} />
              </Col>
              <Col span={2} className={css(styles.modalRowText)}>编号</Col>
              <Col span={6}>
                <Input placeholder="输入自定义编号" className={css(styles.modalInput)} value={code} onChange={e => { setCode(e.target.value) }} />
              </Col>
            </Row>
          </Col>
          <Col span={1}></Col>
          <Col span={4}><_Avatar /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={2} className={css(styles.modalRowText)}>身份证地址</Col>
          <Col span={10}>
            <Input placeholder="输入身份证居住地址" className={css(styles.longInput)} value={certificateAddress} onChange={e => { serCerAddr(e.target.value) }} />
          </Col>
          <Col span={2} className={css(styles.modalRowText)}>居住地址</Col>
          <Col span={10}>
            <Input placeholder="请输入居住地址" className={css(styles.longInput)} value={liveAddress} onChange={e => { setLiveAddress(e.target.value) }} />
          </Col>
        </Row>
        <Divider style={{ marginTop: 20 }}></Divider>
        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>招生来源</Col>
            <Col span={6}>
              <Select value={signupSource} className={css(styles.modalInput)} onChange={value => { setSignupSource(value) }}>
                {store.signUpSources.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>报名时间</Col>
            <Col span={6}>
              <DatePicker className={css(styles.modalInput)} placeholder="请输入报名时间" value={signupDate} onChange={value => { setSignupDate(value) }} />
            </Col>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>报名地点</Col>
            <Col span={6}>
              <Select value={signupSpot} className={css(styles.modalInput)} onChange={value => { setSignupSpot(value) }}>
                {store.signupSpots && store.signupSpots.length > 0 && store.signupSpots.map(item => <Option key={item.name}>{item.name}</Option>)}
              </Select>
            </Col>
          </Col>
          <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>介绍人</Col>
          <Col span={4}>
            <Select value={introducer} className={css(styles.modalInput)} onChange={value => { setIntroducer(value) }}>
              {store.introducers.map(item => <Option key={item.name}>{item.name}</Option>)}
            </Select>
            {/* <Input placeholder="介绍人" className={css(styles.modalInput)} value={introducer} onChange={e => { setIntroducer(e.target.value) }} /> */}
          </Col>
        </Row>


        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>业务类型</Col>
            <Col span={6}>
              <Select value={businessType} className={css(styles.modalInput)} onChange={value => { setBusinessType(value) }}>
                {businessOptions.map(item => <Option value={item} key={item}>{item}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}>原驾车型</Col>
            <Col span={6}>
              <Select value={primaryCarType} disabled={businessType !== '增驾' ? true : false} className={css(styles.modalInput)} onChange={value => { setPriCarType(value) }}>
                {carTypes.map(item => <Option value={item} key={item}>{item}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}>原驾证号</Col>
            <Col span={6}>
              <Input placeholder="原驾驶证件号码" disabled={businessType !== '增驾' ? true : false} className={css(styles.modalInput)} value={primaryCertificate} onChange={e => { setPrimaryCer(e.target.value) }} />
            </Col>
          </Col>
          <Col span={2} className={css(styles.modalRowText)}>学员分组</Col>
          <Col span={4}>
            <Select value={studentGroup} className={css(styles.modalInput)} onChange={value => { setStudentGroup(value) }}>
              {store.studentGroups.map(item => <Option key={item.name} value={item.name}>{item.name}</Option>)}
            </Select>
          </Col>
        </Row>

        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>报名车型</Col>
            <Col span={6}>
              <Select value={carType} placeholder="下拉选择报名车型" className={css(styles.modalInput)} onChange={value => { setCarType(value) }}>
                {carTypes.map(item => <Option value={item} key={item}>{item}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>班制</Col>
            <Col span={6}>
              <Select disabled={true} value={classId} className={css(styles.modalInput)} onChange={value => {
                store.classes.map(item => {
                  if (item.name === value) { setClass(item.name); setFinaceDefault(item.costs) }
                })
              }}>
                {store.classes.map(item => <Option key={item.name} value={item.name}>{item.name}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}>预选训练场</Col>
            <Col span={6}>
              <Select value={traningPlace} className={css(styles.modalInput)} onChange={value => { setTraningPlace(value) }}>
                {store.traningPlaces.map(item => <Option key={item.name} value={item.name}>{item.name}</Option>)}
              </Select>

            </Col></Col>
          <Col span={2} className={css(styles.modalRowText)}>预选教练</Col>
          <Col span={4}>
            <Select value={preCoach} className={css(styles.modalInput)} onChange={value => { setPreCoach(value) }}>
              {
                store.coaches.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Col span={2} className={css(styles.modalRowText)}>资料齐全</Col>
            <Col span={6}>
              <Radio.Group className={css(styles.modalInput)} style={{ marginTop: 5.5 }} onChange={e => { setDataComplete(e.target.value) }} value={dataComplete}>
                <Radio value={true}>齐全</Radio>
                <Radio value={false}>不齐全</Radio>
              </Radio.Group>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}>备注</Col>
            <Col span={14}>
              <Input placeholder="补充说明" style={{
                width: '95%',
                marginLeft: '3%'
              }} value={comments} onChange={e => { setComments(e.target.value) }} />
            </Col>
          </Col>
          <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>跟踪客服</Col>
          <Col span={4}>
            <Select value={follower} className={css(styles.modalInput)} onChange={value => { setFollower(value) }}>
              {store.introducers.map(item => <Option key={item.name}>{item.name}</Option>)}
            </Select>
          </Col>
        </Row>
        <Divider>财务信息</Divider>
        <Alert message={alertMsg} type="info" showIcon />
        <Table size="small" className={css(styles.modalRow)}
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          scroll={{ x: 'max-content' }}
          dataSource={charges} columns={financeColums} rowKey="type" loading={loading} />
        <div className={css(styles.drawerFooter)}>
          <Button icon="cancel" className={css(styles.marginRight8)} onClick={() => { setVisible(!visible) }}>取消</Button>
          <Button icon="save" onClick={() => { updateSingleData() }} type="primary">保存并打印收据</Button>
        </div>
      </Drawer>
      <Modal
        title="学员成绩录入"
        visible={subjectVisible}
        width="60%"
        footer={false}
        style={{ marginTop: -50 }}
        onOk={() => { setSubVisible(!subjectVisible); setStudentExamResult() }}
        onCancel={() => { setSubVisible(!subjectVisible) }}
      >
        <Tabs activeKey={current} tabPosition="left" onChange={value => {
          setExamList(student[value])
          setExamPass(student[`${value}Pass`])
          setCurrent(value)
        }}>
          {
            subjects.map((item) => {
              return (
                <TabPane tab={item.title} key={item.key}>
                  <Divider>历史考试成绩</Divider>
                  <Row>
                    <List
                      itemLayout="horizontal"
                      dataSource={examList}
                      renderItem={item => (
                        <List.Item
                          actions={[<a onClick={() => { deleteSingleGrade(item) }}>删除</a>]}
                        >
                          <List.Item.Meta
                            avatar={<Avatar src={student.sex === '男' ? 'https://img.58cdn.com.cn/dist/jxt/images/head-man.png' : 'https://img.58cdn.com.cn/dist/jxt/images/head-woman.png'} />}
                            title={`考试结果 : ${item.pass ? '通过' : '未通过'}`}
                            description={`考试时间 : ${moment(item.date).format('YYYY-MM-DD')} 审核人 : ${item.operator}`}
                          />
                        </List.Item>
                      )}
                    />
                    <Row style={{
                      // display: examIsPass ? 'none' : ''
                    }}>
                      <Divider>录入成绩</Divider>
                      <Row style={{ marginTop: 20 }}>
                        <Col span={3} className={css(styles.modalRowText)}>考试时间</Col>
                        <Col span={6}>
                          <DatePicker className={css(styles.modalInput)} value={examDate} onChange={value => setExamDate(value)} />
                        </Col>
                        <Col span={3} className={css(styles.modalRowText)}>考试结果</Col>
                        <Col span={6}>
                          <Radio.Group value={passed} buttonStyle="solid" onChange={e => { setPassed(e.target.value) }} className={css(styles.modalInput)} style={{ lineHeight: '32px' }}>
                            <Radio value="success" >通过</Radio>
                            <Radio value="failed">未通过</Radio>
                          </Radio.Group>
                        </Col>
                        <Col span={6}>
                          <Button onClick={() => { setSubVisible(!subjectVisible); setStudentExamResult() }} type="primary" icon="save">保存成绩</Button>
                        </Col>
                      </Row>
                      <Row style={{ paddingBottom: 20 }}>
                      </Row>
                    </Row>
                  </Row>
                </TabPane>
              )
            })
          }
        </Tabs>
      </Modal>

      <Modal
        title="数据导出"
        visible={exportVisible}
        footer={false}
        onOk={() => { setExportVisible(!exportVisible) }}
        onCancel={() => { setExportVisible(!exportVisible) }}
      >
        <Button type="primary" onClick={() => { window.open(downloadUrl) }} block loading={exportLoading}>{exportLoading ? '后台数据生成中...' : '点击下载Excel数据'}</Button>
      </Modal>
      <Modal
        title="设置学员流水号"
        visible={serNumberVisible}
        onOk={() => { setStudentSerialNumber() }}
        onCancel={() => { setSerNumberVisible(!serNumberVisible) }}
      >
        <Input placeholder="请输入学员的流水号" value={serialNumber} onChange={e => setSerial(e.target.value)} />
      </Modal>
    </Row>
  )
}


