/*
 * @Author: huangliang
 * @Date: 2019-10-20 09:38:59
 * @LastEditTime : 2019-12-28 16:41:34
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/HR/Staff/style.js
 * @js files
 */
import { StyleSheet } from 'aphrodite'

/**
 * Css in js
 * Aphrodite
 */
export default StyleSheet.create({
  content: {
    paddingTop: '5px',
    marginRight: '15px',
  },
  main: {
    width: '100%',
    padding: 20,
    marginTop: 10,
    float: 'left',
    backgroundColor: '#ffffff'
  },
  operate: {
    color: '#1890ff',
    paddingRight: 10,
    cursor: 'pointer'
  },
  modalRow: {
    marginTop: 15
  },
  modalRowText: {
    textAlign: 'right',
    height: '32px',
    lineHeight: '32px'
  },
  modalInput: {
    marginLeft: 10,
    width: "90%"
  },
  selectNeed: {
    color: 'red',
    letterSpacing: '1px',
    fontSize: '100%'
  }
})