import { StyleSheet } from 'aphrodite'

export default StyleSheet.create({
  modalRowText: {
    textAlign: 'right',
    height: '32px',
    lineHeight: '32px'
  },
  buttonSpace: {
    marginLeft: 10
  },
  modalInput: {
    marginLeft: 10,
    width:200
    // width: "90%"
  },
  drawerFooter: {
    zIndex: 999,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    borderTop: '1px solid #e8e8e8',
    padding: '10px 16px',
    textAlign: 'right',
    left: 0,
    background: '#fff',
    borderRadius: '0 0 4px 4px'
  },
  marginRight8: {
    marginRight: 8
  },
})
