const flatAuthority = authorities => {
  const flatAuth = authorities.map(item => {
    if (item.indexOf('_') > -1) {
      return item.split('_')[0]
    }
  })
  const flatAuth2 = authorities.map(item => {
    if (item.indexOf('_') > -1) {
      return item.split('_')[1]
    }
  })
  return ({
    firstMenu: Array.from(new Set(flatAuth)),
    secondMenu: Array.from(new Set(flatAuth2))
  })
}


export const getUserAccessMenu = (authority) => {
  const { firstMenu, secondMenu } = flatAuthority(authority)
  const menu = [
    {
      url: 'dashboard/index',
      icon: 'area-chart',
      name: '工作台',
      // breadcrumb: [{
      //   bread: '工作台',
      //   url: 'dashboard'
      // }]
    }
  ]

  if (firstMenu.includes('统计报表')) {
    const count = {
      url: 'dashboard',
      icon: 'area-chart',
      name: '统计报表',
      children: []
    }
    if (secondMenu.includes('招生统计')) {
      count.children.push({
        url: 'dashboard/signup',
        icon: 'dashboard',
        name: '招生统计',
        breadcrumb: [{
          bread: '统计报表',
          url: 'dashboard'
        }, {
          bread: '招生统计',
          url: 'dashboard'
        }]
      })
    }
    if (secondMenu.includes('招生统计表格')) {
      count.children.push({
        url: 'dashboard/signuptable',
        icon: 'dashboard',
        name: '招生统计表格',
        breadcrumb: [{
          bread: '统计报表',
          url: 'dashboard/signuptable'
        }, {
          bread: '招生统计表格',
          url: 'dashboard/signuptable'
        }]
      })
    }

    if (secondMenu.includes('招生来源统计')) {
      count.children.push({
        url: 'dashboard/signusource',
        icon: 'dashboard',
        name: '招生来源统计',
        breadcrumb: [{
          bread: '统计报表',
          url: 'dashboard/signusource'
        }, {
          bread: '招生统计表格',
          url: 'dashboard/signusource'
        }]
      })
    }

    if (secondMenu.includes('在培人数统计')) {
      count.children.push({
        url: 'dashboard/excise',
        icon: 'dashboard',
        name: '在培人数统计',
        breadcrumb: [{
          bread: '统计报表',
          url: 'dashboard/excise'
        }, {
          bread: '在培人数统计',
          url: 'dashboard/excise'
        }]
      })
    }
    if (secondMenu.includes('介绍人统计报表')) {
      count.children.push({
        url: 'dashboard/introducer',
        icon: 'dashboard',
        name: '介绍人统计报表',
        breadcrumb: [{
          bread: '统计报表',
          url: 'dashboard/introducer'
        }, {
          bread: '介绍人统计报表',
          url: 'dashboard/introducer'
        }]
      })
    }
    if (secondMenu.includes('学员合格率')) {
      count.children.push({
        url: 'dashboard/studentfinish',
        icon: 'dashboard',
        name: '学员合格率',
        breadcrumb: [{
          bread: '统计报表',
          url: 'dashboard/studentfinish'
        }, {
          bread: '学员合格率',
          url: 'dashboard/studentfinish'
        }]
      })
    }
    if (secondMenu.includes('教练信息统计')) {
      count.children.push({
        url: 'dashboard/coach',
        icon: 'dashboard',
        name: '教练信息统计',
        breadcrumb: [{
          bread: '统计报表',
          url: 'dashboard/coach'
        }, {
          bread: '教练信息统计',
          url: 'dashboard/coach'
        }]
      })
    }
    if (secondMenu.includes('学生属性')) {
      count.children.push({
        url: 'dashboard/age',
        icon: 'dashboard',
        name: '学生属性',
        breadcrumb: [{
          bread: '统计报表',
          url: 'dashboard/age'
        }, {
          bread: '学生属性',
          url: 'dashboard/age'
        }]
      })
    }
    menu.push(count)
  }

  if (firstMenu.includes('学员管理')) {
    const studentMenu = {
      url: 'student',
      icon: 'idcard',
      name: '学员管理',
      children: []
    }
    if (secondMenu.includes('意向学员')) {
      studentMenu.children.push({
        url: 'student/indend',
        icon: 'solution',
        name: '意向学员',
        breadcrumb: [{
          bread: '学员管理',
          url: 'student'
        }, {
          bread: '意向学员',
          url: 'student/indend'
        }]
      })
    }
    if (secondMenu.includes('学员报名')) {
      studentMenu.children.push({
        url: 'signup',
        icon: 'solution',
        name: '学员报名',
        breadcrumb: [{
          bread: '学员管理',
          url: 'student'
        }, {
          bread: '学员报名',
          url: 'signup'
        }]
      })
    }
    if (secondMenu.includes('学员查询')) {
      studentMenu.children.push({
        url: 'student/query',
        icon: 'solution',
        name: '学员查询',
        breadcrumb: [{
          bread: '学员管理',
          url: 'student'
        }, {
          bread: '学员查询',
          url: 'student/query'
        }]
      })
    }
    if (secondMenu.includes('学员导入')) {
      studentMenu.children.push({
        url: 'student/import',
        icon: 'solution',
        name: '学员导入',
        breadcrumb: [{
          bread: '学员管理',
          url: 'student'
        }, {
          bread: '学员导入',
          url: 'student/import'
        }]
      })
    }
    if (secondMenu.includes('五次不过')) {
      studentMenu.children.push({
        url: 'student/nopass',
        icon: 'solution',
        name: '五次不过',
        breadcrumb: [{
          bread: '学员管理',
          url: 'student'
        }, {
          bread: '五次不过',
          url: 'student/nopass'
        }]
      })
    }
    if (secondMenu.includes('退学管理')) {
      studentMenu.children.push({
        url: 'student/dropout',
        icon: 'solution',
        name: '退学管理',
        breadcrumb: [{
          bread: '学员管理',
          url: 'student'
        }, {
          bread: '退学管理',
          url: 'student/dropout'
        }]
      })
    }

    if (secondMenu.includes('换班管理')) {
      studentMenu.children.push({
        url: 'student/changeclass',
        icon: 'solution',
        name: '换班管理',
        breadcrumb: [{
          bread: '学员管理',
          url: 'student'
        }, {
          bread: '换班管理',
          url: 'student/changeclass'
        }]
      })
    }
    menu.push(studentMenu)
  }

  if (firstMenu.includes('驾校管理')) {
    const school = {
      url: 'driveschool',
      icon: 'hdd',
      name: '驾校管理',
      children: []
    }
    if (secondMenu.includes('报名点')) {
      school.children.push({
        url: 'signupspot',
        icon: 'solution',
        name: '报名点',
        breadcrumb: [{
          bread: '驾校管理',
          url: 'driveschool'
        }, {
          bread: '报名点',
          url: 'signupspot'
        }]
      })
    }
    if (secondMenu.includes('班制')) {
      school.children.push({
        url: 'class',
        icon: 'solution',
        name: '班制',
        breadcrumb: [{
          bread: '驾校管理',
          url: 'driveschool'
        }, {
          bread: '班制',
          url: 'class'
        }]
      })
    }
    if (secondMenu.includes('训练场')) {
      school.children.push({
        url: 'training',
        icon: 'solution',
        name: '训练场',
        breadcrumb: [{
          bread: '驾校管理',
          url: 'driveschool'
        }, {
          bread: '训练场',
          url: 'region'
        }]
      })
    }
    menu.push(school)
  }


  if (firstMenu.includes('车辆管理')) {
    const school = {
      url: 'car',
      icon: 'hdd',
      name: '车辆管理',
      children: []
    }
    if (secondMenu.includes('车辆管理')) {
      school.children.push({
        url: 'car/manager',
        icon: 'solution',
        name: '车辆管理',
        breadcrumb: [{
          bread: '车辆管理',
          url: 'car'
        }, {
          bread: '车辆管理',
          url: 'signupspot'
        }]
      })
    }
    menu.push(school)
  }

  if (firstMenu.includes('分车排课')) {
    const school = {
      url: 'dividr',
      icon: 'hdd',
      name: '分车排课',
      children: []
    }

    if (secondMenu.includes('科目二分车')) {
      school.children.push({
        url: 'car/divide',
        icon: 'solution',
        name: '科目二分车',
        breadcrumb: [{
          bread: '分车排课',
          url: 'car'
        }, {
          bread: '科目二分车',
          url: 'divide'
        }]
      })
    }
    if (secondMenu.includes('科目三分车')) {
      school.children.push({
        url: 'car/dividethree',
        icon: 'solution',
        name: '科目三分车',
        breadcrumb: [{
          bread: '分车排课',
          url: 'car'
        }, {
          bread: '科目三分车',
          url: 'divide'
        }]
      })
    }
    if (secondMenu.includes('已分车')) {
      school.children.push({
        url: 'car/divided',
        icon: 'solution',
        name: '已分车',
        breadcrumb: [{
          bread: '分车排课',
          url: 'car'
        }, {
          bread: '已分车',
          url: 'divide'
        }]
      })
    }
    if (secondMenu.includes('教练信息统计')) {
      school.children.push({
        url: 'car/divider/coach',
        icon: 'dashboard',
        name: '教练信息统计',
        breadcrumb: [{
          bread: '统计报表',
          url: 'dashboard/coach'
        }, {
          bread: '教练信息统计',
          url: 'dashboard/coach'
        }]
      })
    }
    if (window.localStorage.getItem('isCoach')) {
      school.children.push({
        url: 'schedule',
        icon: 'area-chart',
        name: '教练排课',
        breadcrumb: [
          {
            bread: '教练排课',
            url: 'schedule'
          }
        ]
      })
    }
    menu.push(school)
  }

  if (firstMenu.includes('考试管理')) {
    const school = {
      url: 'exam',
      icon: 'hdd',
      name: '考试管理',
      children: []
    }
    if (secondMenu.includes('考试预约')) {
      school.children.push({
        url: 'exam/reserve',
        icon: 'solution',
        name: '考试预约',
        breadcrumb: [{
          bread: '考试管理',
          url: 'car'
        }, {
          bread: '考试预约',
          url: 'signupspot'
        }]
      })
    }
    if (secondMenu.includes('已经约考')) {
      school.children.push({
        url: 'exam/reserved',
        icon: 'solution',
        name: '已经约考',
        breadcrumb: [{
          bread: '考试管理',
          url: 'car'
        }, {
          bread: '已经约考',
          url: 'signupspot'
        }]
      })
    }
    if (secondMenu.includes('车管所考试发布')) {
      school.children.push({
        url: 'exam/publish',
        icon: 'solution',
        name: '车管所考试发布',
        breadcrumb: [{
          bread: '考试管理',
          url: 'car'
        }, {
          bread: '车管所考试发布',
          url: 'signupspot'
        }]
      })
    }
    if (secondMenu.includes('车管所考试查询')) {
      school.children.push({
        url: 'exam/query',
        icon: 'solution',
        name: '车管所考试查询',
        breadcrumb: [{
          bread: '考试管理',
          url: 'car'
        }, {
          bread: '车管所考试查询',
          url: 'signupspot'
        }]
      })
    }
    if (secondMenu.includes('成绩导入')) {
      school.children.push({
        url: 'grade/import',
        icon: 'solution',
        name: '成绩导入',
        breadcrumb: [{
          bread: '成绩管理',
          url: 'car'
        }, {
          bread: '成绩导入',
          url: 'signupspot'
        }]
      })
    }
    if (secondMenu.includes('约考记录查询')) {
      school.children.push({
        url: 'exam/record',
        icon: 'solution',
        name: '约考记录查询',
        breadcrumb: [{
          bread: '考试管理',
          url: 'car'
        }, {
          bread: '约考记录查询',
          url: 'divide'
        }]
      })
    }
    menu.push(school)
  }

  /**
   * 人事管理
   */

  if (firstMenu.includes('人事管理')) {
    const per = {
      url: 'per',
      icon: 'apartment',
      name: '人事管理',
      children: []
    }
    if (secondMenu.includes('员工管理')) {
      per.children.push({
        url: 'staff',
        icon: 'solution',
        name: '员工管理',
        breadcrumb: [{
          bread: '人事管理',
          url: 'per'
        }, {
          bread: '员工管理',
          url: 'staff'
        }]
      })
    }
    if (secondMenu.includes('角色管理')) {
      per.children.push({
        url: 'hr/role',
        icon: 'solution',
        name: '角色管理',
        breadcrumb: [{
          bread: '人事管理',
          url: 'per'
        }, {
          bread: '角色管理',
          url: 'hr/role'
        }]
      })
    }
    menu.push(per)

  }

  /**
   * 财务管理
   */
  if (firstMenu.includes('财务管理')) {
    const fin = {
      url: 'fin',
      icon: 'transaction',
      name: '财务管理',
      children: []
    }
    if (secondMenu.includes('学员收支管理')) {
      fin.children.push({
        url: 'finance/bill',
        icon: 'solution',
        name: '学员收支管理',
        breadcrumb: [{
          bread: '财务管理',
          url: 'fin'
        }, {
          bread: '学员收支管理',
          url: 'finance/bill'
        }]
      })
    }
    if (secondMenu.includes('财务统计')) {
      fin.children.push({
        url: 'finance/count',
        icon: 'solution',
        name: '财务统计',
        breadcrumb: [{
          bread: '财务管理',
          url: 'fin'
        }, {
          bread: '财务统计',
          url: 'count'
        }]
      })
    }
    if (secondMenu.includes('财务入账')) {
      fin.children.push({
        url: 'finance/checked',
        icon: 'solution',
        name: '财务入账',
        breadcrumb: [{
          bread: '财务管理',
          url: 'fin'
        }, {
          bread: '财务入账',
          url: 'checked'
        }]
      })
    }

    if (secondMenu.includes('财务款项配置')) {
      fin.children.push({
        url: 'finsetting',
        icon: 'solution',
        name: '财务款项配置',
        breadcrumb: [{
          bread: '财务管理',
          url: 'fin'
        }, {
          bread: '财务款项配置',
          url: 'finsetting'
        }]
      })
    }
    menu.push(fin)
  }


  /**
  * 财务管理
  */
  if (firstMenu.includes('系统设置')) {
    const fin = {
      url: 'sys',
      icon: 'setting',
      name: '系统设置',
      children: []
    }
    if (secondMenu.includes('字典管理')) {
      fin.children.push({
        url: 'dictionary',
        icon: 'pie-chart',
        name: '字典管理',
        breadcrumb: [
          {
            bread: '系统设置',
            url: 'sys'
          },
          {
            bread: '字典管理',
            url: 'dictionary'
          }]
      })
    }
    menu.push(fin)
  }
  menu.push({
    url: 'user',
    icon: 'user',
    name: '个人中心',
    breadcrumb: [
      {
        bread: '个人中心',
        url: 'user'
      }
    ]
  })
  return menu
}

// console.log(`********* debug authority *********`)

// getUserAccessMenu(mock_authority)