/*
 * @Author: huangliang
 * @Date: 2019-11-16 16:11:41
 * @LastEditTime : 2020-01-18 13:53:09
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/components/initSystem.js
 * @js files
 */

import axios from '../../api/axios'
import {
  traningPlaceParams,
  dictionaryParams,
  classesParams,
  signupSpotParams,
  staffsParams,
  baseDataAll
} from './graphql-params'

/**
 * 调用后端gq数据通用方法
 * @param {*} graphqlSchema 
 * @param {*} type 
 */
const getBaseData = graphqlSchema => new Promise(async (resolve, reject) => {
  try {
    const result = await axios.post('/graphql', graphqlSchema)
    return resolve(result.data.data)
  } catch (error) {
    console.error(`获取gq数据失败,失败信息:`)
    console.log(error)
  }
})


/**
 * 一次性获取所有基础数据
 * @param {} store 
 */
export const initAllBaseData = store => new Promise(async (resolve, reject) => {
  try {
    const result = await axios.get('/base/data')
    console.log(result.data)
    // const baseData = await getBaseData(baseDataAll)
    // console.log(baseData)
    const { classes, dictionarys, signupSpots, staffs, traningPlaces, roles, introducers } = result.data.data
    store.setIntroducers(introducers)
    store.SetTraningPlaces(traningPlaces)
    store.setClasses(classes)
    store.setSignupSpots(signupSpots)
    store.setCoaches(staffs)
    const studentGroups = dictionarys.filter(item => item.dictionaryType === 'studentGroup')
    const staffGroups = dictionarys.filter(item => item.dictionaryType === 'staffGroup')
    const signupSource = dictionarys.filter(item => item.dictionaryType === 'signUpSource')
    const payType = dictionarys.filter(item => item.dictionaryType === 'payTypes')
    const examAddress = dictionarys.filter(item => item.dictionaryType === 'subjectOneAddress' || item.dictionaryType === 'subjectTwoAddress' || item.dictionaryType === 'subjectThreeAddress' || item.dictionaryType === 'subjectFourAddress')
    store.setSgnupSource(signupSource)
    store.setPayTypes(payType)
    store.setStudentGroups(studentGroups)
    store.setStaffGroups(staffGroups)
    store.setRoles(roles)
    store.setExamAddress(examAddress)
  } catch (error) {
  }
})