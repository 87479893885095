/*
 * @Author: huangliang
 * @Date: 2019-10-20 18:26:23
 * @LastEditTime: 2019-11-17 19:10:55
 * @LastEditors: huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/School/TrainingPlace/upload.js
 * @可以输入预定的版权声明、个性签名、空行等
 */
import React from 'react'
import { Upload, Icon, Modal } from 'antd'
import { GlobalContext } from '../../../store/store'
import {origin} from '../../../api/axios'
 
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


export default function UploadFiles() {
  const store = React.useContext(GlobalContext)
  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  /**
   * state参数
   */
  // const [fileList, setFileList] = React.useState(initImage)
  const [previewImage, setPreviewImage] = React.useState('')
  const [previewVisible, setPreviewVisible] = React.useState(false)

  /**
   * 发生预览之前
   * @param {文件对象} file 
   */
  async function handlePreview(file) {
    console.log('preview')
    console.log(file)
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  }

  /**
   * 文件流有变化更新此函数
   * [uid] 唯一id
   * [stauts] uploading 或者 done
   * [url] 文件地址
   * @param {*} fileList 
   */
  async function handleChange(fileList) {
    const imagesList = []
    fileList.map( item => {
      let url = ''
      if((!item.url || item.url === '')  && item.status === 'done' && item.response){
        url = item.response.url
      }else{
        url = item.url
      }
      imagesList.push({
        uid : item.uid,
        status : item.status,
        url
      })
    })
    console.log(`处理后的imagesList`)
    console.log(imagesList)
    store.setImageList(imagesList)
  }

  return (
    <div className="clearfix">
      <Upload
        action={`${origin}/uploadfile`}
        listType="picture-card"
        fileList={store.imagesList}
        onPreview={file => { handlePreview(file) }}
        onChange={({ fileList }) => { handleChange(fileList) }}
      >
        {store.imagesList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={() => { setPreviewVisible(false) }}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  )
}
