import React from 'react'
import Content from '../../../components/content/index'
import axios from '../../../api/axios'
import { Table, message } from 'antd'

export default function () {
  const [data, setData] = React.useState([])
  const [columns, setColums] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  async function fetchData() {
    try {
      setLoading(true)
      const result = await axios.post('/statistics/signup/table')
      if (result.data.data.length > 1) {
        const currentColums = []
        for (let i in result.data.data[0]) {
          currentColums.push({
            title: i,
            dataIndex: i,
            key: i,
          })
        }
        setColums(currentColums)
      }
      setData(result.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message || '服务器端未知错误')
    }

  }


  React.useEffect(() => {
    fetchData()
  }, [])
  return (
    <Content>
      <Table
        loading={loading}
        columns={columns.map(item => {
          const fun = () => ({ style: { whiteSpace: 'nowrap' } });
          item.onHeaderCell = fun;
          item.onCell = fun;
          return item;
        })}
        pagination={false}
        scroll={{ x: 'max-content' }}
        rowKey="报名点"
        dataSource={data} columns={columns} />
    </Content>
  )
}