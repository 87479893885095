import React from 'react'
import Content from '../../../components/content/index'
import axios from '../../../api/axios'
import { Table, message } from 'antd'

export default function () {
  const [data, setData] = React.useState([])
  const columns = [
    {
      key: 'name',
      title: '姓名',
      dataIndex: 'name'
    },
    {
      key: 'contact',
      title: '电话',
      dataIndex: 'contact',
    },
    {
      key: 'num',
      title: '介绍人数',
      dataIndex: 'num',
      sorter: (a, b) => parseInt(a) - parseInt(b) > 0
    }
  ]
  const [loading, setLoading] = React.useState(false)
  async function fetchData() {
    try {
      setLoading(true)
      const result = await axios.post('/statistics/introducer')
      setData(result.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message || '服务器端未知错误')
    }

  }


  React.useEffect(() => {
    fetchData()
  }, [])
  return (
    <Content>
      <Table
        loading={loading}
        columns={columns.map(item => {
          const fun = () => ({ style: { whiteSpace: 'nowrap' } });
          item.onHeaderCell = fun;
          item.onCell = fun;
          return item;
        })}
        pagination={false}
        scroll={{ x: 'max-content' }}
        rowKey="contact"
        dataSource={data} columns={columns} />
    </Content>
  )
}