import React from 'react'
import Content from '../../../components/content/index'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'

import { Table, message, Col } from 'antd'
import { Chart, Geom, Axis, Tooltip, Legend, Coord } from 'bizcharts';

export default function () {
  const store = React.useContext(GlobalContext)

  const [data, setData] = React.useState([])
  const [columns, setColums] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  async function fetchData() {
    try {
      setLoading(true)
      const result = await axios.post('/statistics/count', {
        type: 'signupSource'
      })
      console.log(result.data)
      setData(result.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message || '服务器端未知错误')
    }

  }


  React.useEffect(() => {
    fetchData()
  }, [])
  const chartWidth = store.collapsed ? document.body.clientWidth - 110 : document.body.clientWidth - 250
  // 定义度量
  const cols = {
    genre: { alias: '报名来源' },
    sold: { alias: '报名人数' }
  }

  // const chart = (
  //   <Chart width={chartWidth * 1} height={400} data={data} scale={cols} style={{
  //     marginLeft: chartWidth * 0
  //   }}>
  //     <Axis name="genre" title />
  //     <Axis name="sold" title />
  //     <Legend position="top" dy={-20} />
  //     <Tooltip />
  //     <Geom type="interval" position="genre*sold" color="genre" />
  //   </Chart>
  // )
  return (
    <Content>
      <Chart width={chartWidth * 1} height={400} data={data} scale={cols} style={{
        marginLeft: chartWidth * 0
      }}>
        <Axis name="genre" title />
        <Axis name="sold" title />
        <Legend position="top" dy={-20} />
        <Tooltip />
        <Geom type="interval" position="genre*sold" color="genre" />
      </Chart>
    </Content>
  )
}