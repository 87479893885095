import React from 'react'
import { Menu, Select, PageHeader, Table, Row, Button, Switch, Radio, Drawer, Tag, Modal, Col, Input, message, InputNumber, Popconfirm, Divider, DatePicker } from 'antd'
import { StyleSheet, css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import moment from 'moment'

const { Option } = Select

export default function TrainingPlace() {
  /**
   * Define Table Colums
   */
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      render: (a, b, c) => c + 1
    },
    {
      title: '车牌号',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '所属教练',
      dataIndex: 'coach',
      key: 'coach'
    },
    {
      title: '车架号',
      dataIndex: 'frameNumber',
      key: 'frameNumber'
    },
    {
      title: '发动机号',
      dataIndex: 'engineNumber',
      key: 'engineNumber'
    },
    {
      title: '厂家',
      dataIndex: 'factory',
      key: 'factory'
    },
    {
      title: '品牌',
      dataIndex: 'brand',
      key: 'brand'
    },
    {
      title: '型号',
      dataIndex: 'model',
      key: 'model'
    },
    {
      title: '油品',
      dataIndex: 'oil',
      key: 'oil'
    },
    {
      title: '入户时间',
      dataIndex: 'homeDate',
      key: 'homeDate'
    },
    {
      title: '年审时间',
      dataIndex: 'annualReviewDate',
      key: 'annualReviewDate'
    },
    {
      title: '交强险时间',
      dataIndex: 'insuranceDate',
      key: 'insuranceDate'
    },
    {
      title: '商业险时间',
      dataIndex: 'businessInsuranceDate',
      key: 'businessInsuranceDate'
    },
    {
      title: '二级维护时间',
      dataIndex: 'towLevelMaintenanceDate',
      key: 'towLevelMaintenanceDate'
    },
    {
      title: '综合检测时间',
      dataIndex: 'comprehensiveDetectionDate',
      key: 'comprehensiveDetectionDate'
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      fixed: 'right',
      render: (data, group) => {
        return (
          <Row>
            {/* <span className={css(styles.operate)} onClick={() => { setDivider(!dividerVisible) }}>分配</span> */}
            <span className={css(styles.operate)} onClick={() => { openUpdateModal(group) }}>编辑</span>
            <Popconfirm title="该记录已分配教练也会同时删除,请确认?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleData(group._id) }}>
              <span className={css(styles.operate)}>删除</span>
            </Popconfirm>
          </Row>
        )
      }
    }
  ]


  /**
   * React State
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [dividerVisible, setDivider] = React.useState(false)
  const [coach, setCoach] = React.useState('')
  const [selectedRowKeys, setSelectedRow] = React.useState([])

  /**
   * [ModalType]:1.add 2.update
   */
  const [modalType, setModalType] = React.useState(1)
  const [editId, setEditId] = React.useState('')
  const [car, setCar] = React.useState({})
  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
  }, [])
  console.log(store.coaches)
  async function dividerCar() {
    const result = await axios.post('/car/manager/divide', {
      cars: selectedRowKeys,
      coach
    })
    if (result.data.success) {
      message.success(`分配车辆成功`)
      const tableData = [...data]
      tableData.map(item => {
        if (selectedRowKeys.includes(item._id)) {
          item.coach = coach
        }
      })
      setData(tableData)
      setDivider(!dividerVisible)
      setSelectedRow([])
    } else {
      message.error(result.data.failedMessage || '分配车辆失败')
    }
  }

  /**
   * 增加一条数据
   */
  async function createSingleData() {
    const result = await axios.post('/car/manager', car)
    if (result.data.success) {
      message.success(`添加车辆信息成功`)
      const tableData = [...data]
      tableData.push(result.data.data)
      setData(tableData)
      setVisible(!visible)
    }
  }

  /**
   * 获取分组数据
   */

  async function fetchData() {
    try {
      setLoading(true)
      setData([])
      const result = await axios.get('/car/manager')
      const tableData = result.data.data
      setData(tableData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[ERROR]:')
      console.log(error)
    }

  }

  /**
   * 删除分组数据
   */
  async function deleteSingleData(_id) {
    try {
      const result = await axios.delete(`/car/manager/${_id}`)
      if (result.data.success) {
        message.success(`删除车辆成功`)
        const tableData = data.filter(item => item._id !== _id)
        setData(tableData)
      } else {
        message.error(result.data.failedMessage || '未知错误')
      }
    } catch (error) {
      message.error(error.message || error || '未知错误')
    }
  }

  /**
   * 更新数据
   */
  async function updateSingleData() {
    try {
      const result = await axios.put('/car/manager', car)
      if (result.data.success) {
        message.success(`更新车辆信息成功`)
        setVisible(!visible)
        const tableData = [...data]
        let index = 0
        tableData.map((item, index) => {
          if (item._id === car._id) {
            index = index
          }
        })
        tableData[index] = car
        console.log(tableData)
        setData(tableData)
      } else {
        message.error(`更新车辆信息失败: ${result.data.failedMessage || '服务器端未知错误'}`)
      }
    } catch (error) {
      message.error(error.message || error || '未知错误')
    }
  }


  /**
   * 打开新建对话框
   */
  async function openCreateModal() {
    setCar({})
    setVisible(!visible)
    setModalType(1)
  }

  /**
   * 打开更新数据对话框
   */
  async function openUpdateModal(info) {
    setCar(info)
    setVisible(!visible)
    setModalType(2)
    setEditId(info._id)
  }


  function setItem(type, value) {
    const updateCar = JSON.parse(JSON.stringify(car))
    updateCar[type] = value
    setCar(updateCar)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: value => setSelectedRow(value),
  }
  /**
   * Render View
   */
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Row>
          <Button icon='plus' style={{ marginTop: -10 }} type='primary' onClick={() => { openCreateModal() }}>新增</Button>
          <Button icon='setting' style={{ marginTop: -10, marginLeft: 10 }} type='primary' onClick={() => {
            console.log(selectedRowKeys)
            if (selectedRowKeys.length > 0) {
              setDivider(!dividerVisible)
            } else {
              message.warn(`请勾选分配的车辆`)
            }
          }}>分配车辆</Button>

        </Row>
        <Table
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          rowSelection={rowSelection}
          scroll={{ x: 'max-content' }}
          loading={loading}
          rowKey="_id"
          dataSource={data}
          columns={columns}
          style={{
            marginTop: 10,
          }} />
      </Row>
      <Drawer
        title="车辆管理"
        visible={visible}
        onClose={() => { setVisible(!visible) }}
        closable={true}
        width="70%"
      >
        <Divider>基本信息</Divider>

        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>车牌号</Col>
          <Col span={5}>
            <Input placeholder="输入名称" className={css(styles.modalInput)} value={car.name || ''} onChange={e => { setItem('name', e.target.value) }} />
          </Col>

          <Col span={3} className={css(styles.modalRowText)}>车架号</Col>
          <Col span={5}>
            <Input placeholder="输入车架号" className={css(styles.modalInput)} value={car.frameNumber || ''} onChange={e => { setItem('frameNumber', e.target.value) }} />
          </Col>

          <Col span={3} className={css(styles.modalRowText)}>发动机号</Col>
          <Col span={5}>
            <Input placeholder="输入发动机号" className={css(styles.modalInput)} value={car.engineNumber || ''} onChange={e => { setItem('engineNumber', e.target.value) }} />
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>厂家</Col>
          <Col span={5}>
            <Input placeholder="输入汽车厂家" className={css(styles.modalInput)} value={car.factory || ''} onChange={e => { setItem('factory', e.target.value) }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>品牌</Col>
          <Col span={5}>
            <Input placeholder="输入汽车品牌" className={css(styles.modalInput)} value={car.brand || ''} onChange={e => { setItem('brand', e.target.value) }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>型号</Col>
          <Col span={5}>
            <Input placeholder="输入汽车型号" className={css(styles.modalInput)} value={car.model || ''} onChange={e => { setItem('model', e.target.value) }} />
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>油品</Col>
          <Col span={5}>
            <Input placeholder="输入汽车所加汽油型号" className={css(styles.modalInput)} value={car.oil || ''} onChange={e => { setItem('oil', e.target.value) }} />
          </Col>
        </Row>
        <Divider>预警时间</Divider>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>入户时间</Col>
          <Col span={5}>
            <DatePicker value={car.homeDate ? moment(car.homeDate) : ''} className={css(styles.modalInput)} onChange={value => setItem('homeDate', moment(value).format('YYYY-MM-DD'))} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>年审时间</Col>
          <Col span={5}>
            <DatePicker value={car.annualReviewDate ? moment(car.annualReviewDate) : ''} className={css(styles.modalInput)} onChange={value => setItem('annualReviewDate', moment(value).format('YYYY-MM-DD'))} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>交强险时间</Col>
          <Col span={5}>
            <DatePicker value={car.insuranceDate ? moment(car.insuranceDate) : ''} className={css(styles.modalInput)} onChange={value => setItem('insuranceDate', moment(value).format('YYYY-MM-DD'))} />
          </Col>
        </Row>

        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>商业险时间</Col>
          <Col span={5}>
            <DatePicker value={car.businessInsuranceDate ? moment(car.businessInsuranceDate) : ''} className={css(styles.modalInput)} onChange={value => setItem('businessInsuranceDate', moment(value).format('YYYY-MM-DD'))} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>二级维护时间</Col>
          <Col span={5}>
            <DatePicker value={car.towLevelMaintenanceDate ? moment(car.towLevelMaintenanceDate) : ''} className={css(styles.modalInput)} onChange={value => setItem('towLevelMaintenanceDate', moment(value).format('YYYY-MM-DD'))} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>综合检测时间</Col>
          <Col span={5}>
            <DatePicker value={car.comprehensiveDetectionDate ? moment(car.comprehensiveDetectionDate) : ''} className={css(styles.modalInput)} onChange={value => setItem('comprehensiveDetectionDate', moment(value).format('YYYY-MM-DD'))} />
          </Col>
        </Row>
        {/* <Divider>训练场图片</Divider> */}
        {/* <Avatar /> */}
        <div className={css(styles.drawerFooter)}>
          <Button
            style={{
              marginRight: 8,
            }}
            onClick={() => { setVisible(!visible) }}
          >
            取消
            </Button>
          <Button onClick={() => {
            if (modalType == 1) {
              createSingleData()
            } else {
              updateSingleData()
            }
          }} type="primary">
            完成
            </Button>
        </div>
      </Drawer>
      <Modal
        title="车辆分配"
        visible={dividerVisible}
        onOk={() => { dividerCar() }}
        onCancel={() => { setDivider(!dividerVisible) }}
      >
        <Select className={css(styles.modalInput)} value={coach} onChange={value => setCoach(value)}>
          {
            store.coaches && store.coaches.length > 0 && store.coaches.map(item => {
              return (<Option key={item.name}>{`${item.name}-${item.subject}`} </Option>)
            })
          }
        </Select>
      </Modal>
    </Row>
  )
}


