/*
 * @Author: huangliang
 * @Date: 2020-02-22 21:16:02
 * @LastEditTime: 2020-02-25 14:30:51
 * @LastEditors: huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/Schedule/index.js
 * @js files
 */
import React from 'react'
import Content from '../../components/content/index'
import { Row, Col, Button, Divider, Table, Tabs, PageHeader, Drawer, TimePicker, DatePicker, Select, message, Input, Icon } from 'antd'
import { css, StyleSheet } from 'aphrodite'
import { GlobalContext } from '../../store/store'
import moment from 'moment'
import Axios from '../../api/axios'
const Option = Select.Option
const { TabPane } = Tabs




export default function Schedule() {
  const store = React.useContext(GlobalContext)
  const columns = [
    {
      title: '开始时间',
      dataIndex: 'startTime',
      key: 'startTime'
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      key: 'endTime'
    },
    {
      title: '预约学生',
      dataIndex: 'studentName',
      key: 'studentName'
    },
    {
      title: '预约学生电话',
      dataIndex: 'studentContact',
      key: 'studentContact'
    },
    {
      title: '状态',
      dataIndex: 'reserved',
      key: 'reserved',
      render: data => data ? '已预约' : '未预约'
    },
    {
      title: '预定时间',
      dataIndex: 'reserveTime',
      key: 'reserveTime'
    }
    // {
    //   title: '操作',
    //   render: (info, data) => {
    //     return (
    //       <Row>
    //         <span className={css(styles.operate)} onClick={() => { }}>编辑</span>
    //         <span className={css(styles.operate)} onClick={() => { }}>删除</span>
    //       </Row>
    //     )
    //   }
    // }
  ]
  const [initTrain, setTrain] = React.useState('')
  const [type, setType] = React.useState('create')
  const [list, setList] = React.useState([])
  const [currentPage, setCurrent] = React.useState('')
  const [visible, setVisible] = React.useState(false)
  const [schedule, setSchedule] = React.useState({})
  const [scheduleList, setScheduleList] = React.useState([])
  const [studentList, setStudentList] = React.useState([])

  async function getScheduleList() {
    const coachScheduleList = await Axios.get('/schedule/list')
    setList(coachScheduleList.data.data)
    const result = await Axios.get(`/weapp/coach/${window.localStorage.getItem('contact')}`)
    if (result.data.coachInfo) {
      setTrain(result.data.coachInfo.subjectTwo || result.data.coachInfo.subjectThree || '')
    }
    setStudentList(result.data.students)
  }
  React.useEffect(() => {
    getScheduleList()
    // setList(mockData)
    // setCurrent(mockData[0].date)
    // console.log(mockData[0].openList)
    // setData(mockData[0].openList)
  }, [])
  const format = 'HH:mm'

  function handleScheduleChange(key, value) {
    const newSchedule = { ...schedule }
    newSchedule[key] = value
    setSchedule(newSchedule)
  }
  /**
   * 修改schedule的内容
   * @param {*} key 
   * @param {*} value 
   * @param {*} index 
   */
  function handleListChange(key, value, index) {
    const newList = [...scheduleList]
    newList.map((item, $index) => {
      if ($index === index) {
        item[key] = value
      }
    })
    setScheduleList(newList)
  }

  function autoOpenList() {
    if (!schedule.date || !schedule.startTime || !schedule.endTime) {
      message.error(`请先设置日期、开始时间、结束时间`)
      return
    }
    const startTime = moment(schedule.startTime).format('YYYY-MM-DD HH:mm')
    const endTime = moment(schedule.endTime).format('YYYY-MM-DD HH:mm')
    if (Date.parse(endTime) - Date.parse(startTime) < 0) {
      message.error(`结束时间必须大于开始时间`)
      return
    }
    const hour = (Date.parse(endTime) - Date.parse(startTime)) / 1000 / 3600
    const openList = []
    for (let i = 0; i < hour; i++) {
      const endTimeTimeStamp = Date.parse(startTime) + 1000 * 3600 * (i + 1)
      const endTimeList = endTimeTimeStamp < Date.parse(endTime) ? endTimeTimeStamp : Date.parse(endTime)
      openList.push({
        startTime: moment(Date.parse(startTime) + 1000 * 3600 * i).format('YYYY-MM-DD HH:mm'),
        endTime: moment(endTimeList).format('YYYY-MM-DD HH:mm')
      })
    }
    setScheduleList(openList)
  }

  /**
   * 打开新增一条数据
   */
  async function addOpen() {
    try {
      setType('create')
      setVisible(!visible)
      const today = moment().format('YYYY-MM-DD')
      setSchedule({
        startTime: moment(`${today} 09:00`),
        endTime: moment(`${today} 17:00`),
        traingPlace: initTrain
      })
      setScheduleList([])
    } catch (error) {
      message.error(error.message)
    }
  }
  /**
    * 打开新增一条数据
    */
  async function editOpen(item) {
    try {
      setType('update')
      setVisible(!visible)
      setSchedule(item)
      setScheduleList(item.openList)

    } catch (error) {
      message.error(error.message)
    }
  }
  /**
   * 选择指定的学生数据
   */
  function setStudent(value, index) {
    const newList = [...scheduleList]
    newList.map((item, $index) => {
      if ($index === index) {
        if (value === 'all') {
          item.studentId = ''
          item.studentName = ''
          item.studentContact = ''
        } else {
          studentList.map(each => {
            if (each.name === value) {
              item.studentId = each._id
              item.studentName = each.name
              item.studentContact = each.contact
            }
          })
        }
      }
    })
    setScheduleList(newList)
  }
  /**
   * 创建一条记录
   */
  async function createSchedule() {
    try {
      const body = schedule
      body.startTime = `${body.date} ${moment(body.startTime).format(format)}`
      body.endTime = `${body.date} ${moment(body.endTime).format(format)}`
      const openlists = [...scheduleList]
      openlists.map(item => {
        item.startTime = `${schedule.date} ${moment(item.startTime).format('HH:mm')}`
        item.endTime = `${schedule.date} ${moment(item.endTime).format('HH:mm')}`
      })
      body.openList = openlists
      const result = await Axios.post('/schedule/create', body)
      if (result.data.success) {
        setVisible(false)
        getScheduleList()
        message.success(`开放时间成功`)
      } else {
        message.error(`开放时间失败 : ${result.data.failedMessage || '服务器返回未知错误'}`)
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  async function updateSchedule() {
    try {
      const body = schedule
      body.startTime = `${body.date} ${moment(body.startTime).format(format)}`
      body.endTime = `${body.date} ${moment(body.endTime).format(format)}`
      const openlists = [...scheduleList]
      openlists.map(item => {
        item.startTime = `${schedule.date} ${moment(item.startTime).format('HH:mm')}`
        item.endTime = `${schedule.date} ${moment(item.endTime).format('HH:mm')}`
      })
      body.openList = openlists
      const result = await Axios.put('/schedule/update', body)
      if (result.data.success) {
        setVisible(false)
        getScheduleList()
        message.success(`修改排期内容成功`)
      } else {
        message.error(`修改排期失败 : ${result.data.failedMessage || '服务器返回未知错误'}`)
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  async function deleteSchedule(id) {
    const result = await Axios.delete(`/schedule/delete/${id}`)
    if (result.data.success) {
      message.success(`删除指定排期成功`)
      getScheduleList()
    }
  }

  return (
    <Content>
      <Row>
        <Button type='primary' icon='plus' onClick={() => { addOpen() }}>开放时间</Button>
      </Row>
      <Divider />
      <Tabs tabPosition='left'
        current={currentPage}
        onChange={key => {
          setCurrent(key)
        }}
        style={{
          minHeight: 500
        }}>
        {
          list.map(
            item => {
              return (
                <TabPane tab={item.date} key={item.date} loading={true}>
                  <PageHeader
                    style={{
                      marginLeft: -24
                    }}
                    title={item.traingPlace}
                    subTitle={`开放时间段 : ${item.startTime} ~ ${item.endTime}`}
                  />
                  <Row style={{ paddingBottom: 10 }}>
                    <Button type='primary' onClick={() => { editOpen(item) }} icon='edit'>修改排期</Button>
                    <Button type='danger' onClick={() => { deleteSchedule(item._id) }} icon='delete' style={{ marginLeft: 10 }}>删除排期</Button>
                  </Row>
                  <Table
                    rowKey='startTime'
                    columns={columns}
                    dataSource={item.openList}
                  >
                  </Table>
                </TabPane>
              )
            })
        }

      </Tabs>
      <Drawer
        closable
        onClose={() => { setVisible(!visible) }}
        visible={visible} title='新增开放时间' width='60%'>
        <Row>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>开放日期</Col>
          <Col span={5}>
            <DatePicker placeholder="请选择开放日期" className={css(styles.modalInput)} value={schedule.date ? moment(schedule.date) : null} onChange={value => {
              handleScheduleChange('date', moment(value).format('YYYY-MM-DD'))
            }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>开始时间</Col>
          <Col span={5}>
            <TimePicker className={css(styles.modalInput)} format={format} minuteStep={30} value={moment(schedule.startTime)} onChange={value => {
              handleScheduleChange('startTime', moment(value))
            }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>结束时间</Col>
          <Col span={5} >
            <TimePicker className={css(styles.modalInput)} format={format} minuteStep={30} value={moment(schedule.endTime)} onChange={value => {
              handleScheduleChange('endTime', moment(value))
            }} />
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>训练场</Col>
          <Col span={5}>
            <Select className={css(styles.modalInput)} value={schedule.traingPlace} onChange={value => handleScheduleChange('traingPlace', value)}>
              {store.traningPlaces.map(item => <Option key={item.name} value={item.name}>{item.name}</Option>)}
            </Select>
          </Col>
          <Col span={3} className={css(styles.modalRowText)}></Col>
          <Col span={5}>
            <Button type='primary' className={css(styles.modalInput)} onClick={() => { autoOpenList() }} icon='plus'>生成时间段</Button>
          </Col>
        </Row>
        <Divider>时间段设置</Divider>
        <Row style={{ paddingBottom: 50, overflowY: 'scroll' }}>
          {
            scheduleList.map((item, index) => {
              return (
                <Row key={index} style={{ marginTop: 10 }}>
                  <Col span={1} className={css(styles.modalRowAdd)}><Icon type="plus-circle"
                    onClick={() => {
                      const newList = [...scheduleList]
                      newList.splice(index + 1, 0, {
                        startTime: `${schedule.date ? schedule.date : moment().format('YYYY-MM-DD')} 00:00`,
                        endTime: `${schedule.date ? schedule.date : moment().format('YYYY-MM-DD')} 00:00`,
                      })
                      setScheduleList(newList)
                    }} /></Col>
                  <Col span={3} className={css(styles.modalRowText)}>开始时间</Col>
                  <Col span={4}>
                    <TimePicker className={css(styles.modalInput)} format={format} minuteStep={30} value={moment(item.startTime)} onChange={value => {
                      handleListChange('startTime', moment(value).format('YYYY-MM-DD HH:mm'), index)
                    }} />
                  </Col>
                  <Col span={3} className={css(styles.modalRowText)}>结束时间</Col>
                  <Col span={4} >
                    <TimePicker className={css(styles.modalInput)} format={format} minuteStep={30} value={moment(item.endTime)} onChange={value => {
                      handleListChange('endTime', moment(value).format('YYYY-MM-DD HH:mm'), index)
                    }} />
                  </Col>
                  <Col span={3} className={css(styles.modalRowText)}>预选学生</Col>
                  <Col span={4} >
                    <Select className={css(styles.modalInput)} value={item.studentName} onChange={value => { setStudent(value, index) }}>
                      <Option key={'all'} value={'all'}>空</Option>
                      {studentList.map(each => <Option key={each.name} value={each.name}>{each.name}</Option>)}
                    </Select>
                  </Col>
                  <Col span={2} className={css(styles.operater)} onClick={() => {
                    const newList = [...scheduleList].filter((item, $index) => $index !== index)
                    setScheduleList(newList)
                  }}>删除</Col>
                </Row>
              )
            })
          }
        </Row>
        <div className={css(styles.drawerFooter)}>
          <Button
            style={{
              marginRight: 8,
            }}
            onClick={() => { setVisible(!visible) }}
          >
            取消
            </Button>
          <Button onClick={() => {
            if (type === 'create') {
              createSchedule()
            } else {
              updateSchedule()
            }
          }} type="primary">
            完成
            </Button>
        </div>
      </Drawer>
    </Content>
  )
}

const styles = StyleSheet.create({
  operate: {
    color: '#1890ff',
    paddingRight: 10,
    cursor: 'pointer'
  },
  modalRow: {
    marginTop: 10
  },
  modalRowAdd: {
    cursor: 'pointer',
    textAlign: 'right',
    height: '32px',
    lineHeight: '32px'
  },
  modalRowText: {
    textAlign: 'right',
    height: '32px',
    lineHeight: '32px'
  },
  modalInput: {
    marginLeft: 10,
    width: "90%"
  },
  selectNeed: {
    color: 'red',
    letterSpacing: '1px',
    fontSize: '100%'
  },
  operater: {
    color: '#1890ff',
    lineHeight: '32px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  add: {
    float: 'right',
    cursor: 'pointer'
  },
  drawerFooter: {
    zIndex: 999,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    borderTop: '1px solid #e8e8e8',
    padding: '10px 16px',
    textAlign: 'right',
    left: 0,
    background: '#fff',
    borderRadius: '0 0 4px 4px'
  },
})