import React from 'react'
import { Menu, Select, PageHeader, Table, Row, Button, Switch, Divider, Drawer, Icon, Tag, Modal, Col, Input, message, InputNumber, Popconfirm } from 'antd'
import { StyleSheet, css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import { classesParams, carTypes, transferTypes, studentCartypes, costTypes, initCosts } from './config'
import { initAllBaseData } from '../../../store/init/init'

const { Option } = Select
const { TextArea } = Input

export default function settings() {

  /**
   * Define Table Colums
   */
  const costColumn = [
    {
      title: '序号',
      dataIndex: 'name',
      key: 'name',
      render: (data, info, index) => `${index + 1}`
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      render: (data, info, index) => {
        return <Select style={{ width: 200 }} value={data} onChange={value => setCostsValue(index, 'type', value)}>
          {
            costTypes.map(item => <Option value={item} key={item}>{item}</Option>)
          }
        </Select>
      }
    },
    {
      title: '费用金额(单位:元)',
      dataIndex: 'cost',
      key: 'cost',
      render: (data, info, index) => <InputNumber value={data} onChange={value => setCostsValue(index, 'cost', value)} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      render: (data, group, index) => {
        return (
          <Row>
            <Popconfirm title="确认删除?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleCost(index) }}>
              <span className={css(styles.operate)}>删除</span>
            </Popconfirm>
          </Row>
        )
      }
    }
  ];
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      render: (data, info, index) => `${index + 1}`
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',

    },
    {
      title: '状态',
      dataIndex: 'enable',
      key: 'enable',
      render: data => <Row> <Switch checkedChildren="启用" unCheckedChildren="未启用" checked={data} onChange={() => { }} /></Row>
    },
    {
      title: '最短领证时间(单位:天)',
      dataIndex: 'fatestLisence',
      key: 'fatestLisence',
    },



    {
      title: '报名车型',
      dataIndex: 'carType',
      key: 'carType',
    },
    {
      title: '接送形式',
      dataIndex: 'transferType',
      key: 'transferType',
    },
    {
      title: '科目二车辆分配',
      dataIndex: 'subjectTwoCarType',
      key: 'subjectTwoCarType',
    },
    {
      title: '科目三车辆分配',
      dataIndex: 'subjectThreeCarType',
      key: 'subjectThreeCarType',
    },
    {
      title: '班级分组',
      dataIndex: 'classGroup',
      key: 'classGroup',
    },

    {
      title: '线上可见',
      dataIndex: 'onlineVisible',
      key: 'onlineVisible',
    },
    {
      title: '学员分教练',
      dataIndex: 'stupickcoach',
      key: 'stupickcoach',
      render: data => <span>{data ? '是' : '否'}</span>
    },

    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      sorter: (a, b) => a.sort > b.sort
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      fixed: 'right',
      render: (data, group) => {
        return (
          <Row>
            <span className={css(styles.operate)} onClick={() => { openUpdateModal(group) }}>编辑</span>
            <Popconfirm title="确认删除?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleData(group._id) }}>
              <span className={css(styles.operate)}>删除</span>
            </Popconfirm>
          </Row>
        )
      }
    }
  ]


  /**
   * React State
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  /**
   * [ModalType]:1.add 2.update
   */
  const [modalType, setModalType] = React.useState(1)

  /**
   * 业务相关数据的定义
   */
  const [editId, setEditId] = React.useState('')
  // const [code, setCode] = React.useState('')

  const [name, setName] = React.useState('')
  const [type, setType] = React.useState('C1')
  const [enable, setEnable] = React.useState(true)
  const [fatestLisence, setFirstLisence] = React.useState(0)
  const [carType, setCarType] = React.useState('')
  const [transferType, setTransferType] = React.useState('')
  const [subjectTwoCarType, setTwoCartype] = React.useState('')
  const [subjectThreeCarType, setThreeCarType] = React.useState('')
  const [classGroup, setClassGroup] = React.useState('')
  const [onlineVisible, setOnlineVisible] = React.useState('')
  const [stupickcoach, setStupickcoa] = React.useState(false)
  const [sort, setSort] = React.useState('')
  const [describe, setDescribe] = React.useState('')
  const [costs, setCosts] = React.useState(initCosts)
  const [reservingTime, setReserve] = React.useState(2)
  const [kaoshifei, setKaoshi] = React.useState(true)
  const [bukaofei, setBukao] = React.useState(true)

  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
  }, [])

  /**
   * 增加一条数据
   */
  async function createSingleData() {
    try {
      if (name === '') { throw `请输入班级名称` }
      if (type === '') { throw `请输入报名车型` }
      if (fatestLisence === '') { throw `请输入最快拿证时间` }
      if (carType === '') { throw `请输入车辆类型` }
      if (transferType === '') { throw `请选择接送形式` }
      if (subjectTwoCarType === '') { throw `请选择科目二的培训形式` }
      if (subjectThreeCarType === '') { throw `请选择科目三的培训形式` }
      const result = await axios.post(`/school/class`, {
        name,
        type,
        enable,
        fatestLisence,
        carType,
        transferType,
        subjectTwoCarType,
        subjectThreeCarType,
        classGroup,
        stupickcoach,
        onlineVisible,
        sort,
        reservingTime,
        describe,
        costs,
        kaoshifei,
        bukaofei
      })
      if (result.data.success) {
        const newData = result.data.data
        data.push(newData)
        setData(data)
        setVisible(!visible)
        initAllBaseData(store)
      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(error)
    }
  }

  /**
   * 获取分组数据
   */

  async function fetchData() {
    try {
      setLoading(true)
      setData([])
      const data = await axios.get('/school/class')
      const tableData = data.data.data
      setData(tableData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[ERROR]:')
      console.log(error)
    }

  }

  /**
   * 删除分组数据
   */
  async function deleteSingleData(_id) {
    setLoading(true)
    const result = await axios.delete(`/school/class/${_id}`)
    setLoading(false)
    if (result.data.success) {
      const newData = data.filter(item => item._id !== _id)
      setData(newData)
      initAllBaseData(store)
      message.success(`删除数据成功`)
    } else {
      message.error(`删除数据失败`)
    }
  }

  /**
   * 更新数据
   */
  async function updateSingleData() {
    try {
      const result = await axios.put(`/school/class`, {
        _id: editId,
        name,
        type,
        enable,
        fatestLisence,
        carType,
        transferType,
        subjectTwoCarType,
        subjectThreeCarType,
        classGroup,
        stupickcoach,
        onlineVisible,
        sort,
        describe,
        costs,
        reservingTime,
        kaoshifei,
        bukaofei
      })
      if (result.data.success) {
        fetchData()
        setVisible(!visible)
        initAllBaseData(store)
      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(`提交数据异常,请联系管理员`)
    }
  }


  /**
   * 打开新建对话框
   */
  async function openCreateModal() {
    setName('')
    setType('C1')
    setEnable(true)
    setFirstLisence('')
    setCarType('')
    setTransferType('')
    setTwoCartype('')
    setThreeCarType('')
    setClassGroup('')
    setOnlineVisible(true)
    setStupickcoa(false)
    setKaoshi(true)
    setBukao(true)
    setSort('')
    setDescribe('')
    setReserve(2)
    setVisible(!visible)
    setModalType(1)
  }

  /**
   * 打开更新数据对话框
   */
  async function openUpdateModal(info) {
    setName(info.name)
    setType(info.type)
    setEnable(info.enable)
    setFirstLisence(info.fatestLisence)
    setCarType(info.carType)
    setTransferType(info.transferType)
    setTwoCartype(info.subjectTwoCarType)
    setThreeCarType(info.subjectThreeCarType)
    setClassGroup(info.classGroup)
    setOnlineVisible(info.onlineVisible)
    setStupickcoa(info.stupickcoach)
    setSort(info.sort)
    setDescribe(info.describe)
    setCosts(info.costs)
    setVisible(!visible)
    setEditId(info._id)
    setReserve(info.reservingTime)
    setKaoshi(info.kaoshifei || false)
    setBukao(info.bukaofei || false)
    setModalType(2)
  }

  /**
   * 下面为费用列的增删改查
   */
  function addSingleCost() {
    const newCosts = costs.concat([{ type: '考试费', cost: 0 }])
    setCosts(newCosts)
  }
  /**
   * 修改费用列的费用类型以及费用
   * @param {数组的序号} index 
   * @param {字段} type 
   * @param {值} value 
   */
  function setCostsValue(index, type, value) {
    const newCosts = costs.map((item, $index) => {
      if ($index === index) {
        item[type] = value
      }
      return item
    })
    setCosts(newCosts)
  }

  function deleteSingleCost(index) {
    const newCosts = costs.filter((item, $index) => $index !== index)
    setCosts(newCosts)

  }
  /**
   * Render View
   */
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Row>
          <Button icon='plus' type='primary' onClick={() => { openCreateModal() }}>新增</Button>
        </Row>
        <Table
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          scroll={{ x: 'max-content' }}
          className="ant-table td"
          loading={loading}
          className={css(styles.modalRow)}
          rowKey="_id"
          dataSource={data}
          columns={columns}
        />
      </Row>

      <Drawer
        title="班制管理"
        visible={visible}
        onClose={() => { setVisible(!visible) }}
        closable={true}
        width="70%"
        onCancel={() => { setVisible(!visible) }}
      >
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>班制名称</Col>
          <Col span={5}>
            <Input placeholder="输入名称" className={css(styles.modalInput)} value={name} onChange={e => { setName(e.target.value) }} /></Col>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>报名车型</Col>
          <Col span={5}>
            <Select className={css(styles.modalInput)} value={type} onChange={value => { setType(value) }}>
              {
                carTypes.map(item => <Option value={item} key={item}>{item}</Option>)
              }
            </Select>
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>是否启用</Col>
          <Col span={5}><Switch checkedChildren="启用" unCheckedChildren="停用" checked={enable} onChange={value => { setEnable(value) }} style={{ marginLeft: 10, marginTop: 5 }} /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>最快拿证:天</Col>
          <Col span={5}>
            <InputNumber placeholder="单位:天" className={css(styles.modalInput)} value={fatestLisence} onChange={value => { setFirstLisence(value) }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>车辆类型</Col>
          <Col span={5}>
            <Input placeholder="输入名称" className={css(styles.modalInput)} value={carType} onChange={e => { setCarType(e.target.value) }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>接送形式</Col>
          <Col span={5}>
            <Select className={css(styles.modalInput)} value={transferType} onChange={value => { setTransferType(value) }}>
              {
                transferTypes.map(item => <Option value={item} key={item}>{item}</Option>)
              }
            </Select>
          </Col>

        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>科目二车辆类型</Col>
          <Col span={5}>
            <Select className={css(styles.modalInput)} value={subjectTwoCarType} onChange={value => { setTwoCartype(value) }}>
              {
                studentCartypes.map(item => <Option value={item} key={item}>{item}</Option>)
              }
            </Select>
          </Col>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>科目三车辆类型</Col>
          <Col span={5}>
            <Select className={css(styles.modalInput)} value={subjectThreeCarType} onChange={value => { setThreeCarType(value) }}>
              {
                studentCartypes.map(item => <Option value={item} key={item}>{item}</Option>)
              }
            </Select>
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>班级分组</Col>
          <Col span={5}>
            <Input placeholder="输入名称" className={css(styles.modalInput)} value={classGroup} onChange={e => { setClassGroup(e.target.value) }} />
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>学员自选教练</Col>
          <Col span={5}>
            <Switch checked={stupickcoach} style={{ marginLeft: 10, marginTop: 5 }} onChange={value => {
              console.log(value)
              console.log(stupickcoach ? 'checked' : '')
              setStupickcoa(value)
            }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>线上显示</Col>
          <Col span={5}>
            <Switch checked={onlineVisible} style={{ marginLeft: 10, marginTop: 5 }} onChange={value => setOnlineVisible(value)} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}>排序</Col>
          <Col span={5}>
            <InputNumber placeholder="数字越大排名越靠前" className={css(styles.modalInput)} value={sort} onChange={value => { setSort(value) }} />
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>提前预约练车:天</Col>
          <Col span={5}>
            <InputNumber placeholder="单位:天" className={css(styles.modalInput)} value={reservingTime} onChange={value => { setReserve(value) }} />
          </Col>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>是否包含考试费</Col>
          <Col span={5}>
            <Select className={css(styles.modalInput)} value={kaoshifei} onChange={value => { setKaoshi(value) }}>
              <Option value={true}>包含</Option>
              <Option value={false}>不包含</Option>
            </Select>
          </Col>
          <Col span={3} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>是否包含补考费</Col>
          <Col span={5}>
            <Select className={css(styles.modalInput)} value={bukaofei} onChange={value => { setBukao(value) }}>
              <Option value={true}>包含</Option>
              <Option value={false}>不包含</Option>
            </Select>
          </Col>
        </Row>

        <Row className={css(styles.modalRow)}>
          <Col span={3} className={css(styles.modalRowText)}>描述</Col>
          <Col span={21}>
            <TextArea rows={2} value={describe} onChange={e => { setDescribe(e.target.value) }} className={css(styles.modalInput)} />
          </Col>
        </Row>
        <Divider>费用明细</Divider>
        <Row>
          <Button type="primary" icon="plus" onClick={() => {
            addSingleCost()
          }}>增加一条费用信息</Button>
        </Row>
        <Table size="small" className={css(styles.modalRow)} dataSource={costs} columns={costColumn} rowKey="type" loading={loading} />
        <div className={css(styles.drawerFooter)}>
          <Button
            style={{
              marginRight: 8,
            }}
            onClick={() => { setVisible(!visible) }}
          >
            取消
            </Button>
          <Button onClick={() => {
            if (modalType == 1) {
              createSingleData()
            } else {
              updateSingleData()
            }
          }} type="primary">
            完成
            </Button>
        </div>
      </Drawer>
    </Row>
  )
}


