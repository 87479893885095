/*
 * @Author: huangliang
 * @Date: 2020-01-05 16:11:46
 * @LastEditTime : 2020-01-12 15:12:17
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/components/studentfile/exam.js
 * @js files
 */
import React from 'react'
import { Row, Timeline, Icon } from 'antd'
import { GlobalContext } from '../../store/store'
import moment from 'moment'

export default function Exam() {
  const store = React.useContext(GlobalContext)
  const student = store.studentFile
  console.log(student.isFirstCheck)
  const files = []
  files.push({
    color: 'green',
    process: false,
    content: `${moment(parseInt(student.createAt)).format('YYYY-MM-DD HH:mm:ss')}创建了这条数据`
  })
  files.push({
    color: student.dataComplete ? 'green' : 'red',
    process: false,
    content: `资料 : ${student.dataComplete ? '齐全' : '不齐全'}`
  })
  files.push({
    color: 'green',
    process: false,
    content: `财务审核第一条记录 : 已审核`
  })
  files.push({
    color: student.serialNumber ? 'green' : 'red',
    process: student.isFirstCheck ? true : false,
    content: `流水号 : ${student.serialNumber ? `已生成 ${student.serialNumber}` : '未生成'}`
  })

  if (student.subjectOnePass) {
    files.push({
      color: 'green',
      process: false,
      content: `科目一考试 : 已经通过 通过日期:${moment(student.subjectOne[student.subjectOne.length - 1].date).format('YYYY-MM-DD')}`
    })
  } else if (student.subjectOne.length === 0) {
    files.push({
      color: 'blue',
      process: false,
      content: `科目一考试 : 未考试`
    })
  } else {
    files.push({
      color: 'red',
      process: true,
      content: `科目一考试 : 正在进行中`
    })
  }

  if (student.subjectTwoPass) {
    files.push({
      color: 'green',
      process: false,
      content: `科目二考试 : 已经通过 通过日期:${student.subjectTwo[student.subjectTwo.length - 1].date}`
    })
  } else if (student.subjectTwo.length === 0) {
    files.push({
      color: 'blue',
      process: false,
      content: `科目二考试 : 未考试`
    })
  } else {
    files.push({
      color: 'red',
      process: true,
      content: `科目二考试 : 正在进行中`
    })
  }

  if (student.subjectThreePass) {
    files.push({
      color: 'green',
      process: false,
      content: `科目三考试 : 已经通过 通过日期:${student.subjectThree[student.subjectThree.length-1].date}`
    })
  } else if (student.subjectThree.length === 0) {
    files.push({
      color: 'blue',
      process: false,
      content: `科目三考试 : 未考试`
    })
  } else {
    files.push({
      color: 'red',
      process: true,
      content: `科目三考试 : 正在进行中`
    })
  }

  if (student.subjectFourPass) {
    files.push({
      color: 'green',
      process: false,
      content: `科目四考试 : 已经通过 通过日期:${student.subjectFour[student.subjectFour.length -1].date}`
    })
  } else if (student.subjectFour.length === 0) {
    files.push({
      color: 'blue',
      process: false,
      content: `科目四考试 : 未考试`
    })
  } else {
    files.push({
      color: 'red',
      process: true,
      content: `科目四考试 : 正在进行中`
    })
  }


  return (
    <Row>
      <Row className="ant-descriptions-title">
        考试信息
      </Row>
      <Timeline mode="alternate">
        {
          files.map(item => {
            if (item.process) {
              return (
                <Timeline.Item dot={<Icon type="clock-circle-o" style={{ fontSize: '16px' }} />} color={item.color} key={item.content}>{item.content}</Timeline.Item>
              )
            } else {
              return (
                <Timeline.Item color={item.color} key={item.content}>{item.content}</Timeline.Item>
              )
            }

          })
        }
      </Timeline>
    </Row>
  )
}
