import React from 'react'
import { Alert, Select, Divider, Pagination, Timeline, Tooltip, Table, Row, Button, Tabs, Icon, DatePicker, Radio, Drawer, Tag, Modal, Col, Input, message, InputNumber, Popconfirm, Dropdown, Menu } from 'antd'
import { css } from 'aphrodite'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'
import styles from './style'
import Avatar from './upload'
import moment from 'moment'
import QueryBar from '../../../components/query/index'

import {
  studentsSchema,
  carTypes,
  businessOptions,
  certificateOptions,
  sexOptions
} from './config'
import {
  certificateId,
  phoneNumber
} from '../../../tools/verify'

const { TextArea } = Input
const { Option } = Select
const { TabPane } = Tabs
const { confirm } = Modal

const filterOptions = {
  introducer: true,
  filter: true,
}

export default function TrainingPlace() {
  /**
   * Define Table Colums
   */
  const financeColums = [
    {
      title: '序号',
      dataIndex: 'name',
      key: 'name',
      render: (data, info, index) => `${index + 1}`
    },
    {
      title: '费用类型',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: '费用',
      dataIndex: 'cost',
      key: 'cost',
      render: (data, info, index) => <InputNumber disabled value={data} onChange={(value) => { editFinanceDetail(index, 'cost', value) }} />
    },
    {
      title: '支付类型',
      dataIndex: 'payType',
      key: 'payType',
      render: (data, info, index) => {
        return (
          <Row>
            <span className={css(styles.selectNeed)}>*</span>
            <Select value={data} onChange={value => { editFinanceDetail(index, 'payType', value) }} style={{ minWidth: 120 }}>
              {store.payTypes.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)}
            </Select>
          </Row>
        )
      }
    },
    {
      title: '实缴金额',
      dataIndex: 'paid',
      key: 'paid',
      render: (data, info, index) => <InputNumber value={data} onChange={(value) => { editFinanceDetail(index, 'paid', value) }} />
    },
    {
      title: '优惠金额',
      dataIndex: 'offer',
      key: 'offer',
      render: (data, info, index) => <InputNumber value={data} onChange={(value) => { editFinanceDetail(index, 'offer', value) }} />
    },
    {
      title: '优惠原因',
      dataIndex: 'offerReason',
      key: 'offerReason',
      width: 100,
      render: (data, info, index) => <Input value={data} onChange={(e) => { editFinanceDetail(index, 'offerReason', e.target.value) }} />
    },
    {
      title: '欠费',
      dataIndex: 'arrears',
      key: 'arrears',
      render: (data, info, index) => <span style={{ color: 'red' }}>{data}</span>

      // render: (data, info, index) => <InputNumber value={data} onChange={(value) => { editFinanceDetail(index, 'arrears', value) }} />
    },
    {
      title: '票号',
      dataIndex: 'bills',
      key: 'bills',
      width: 100,
      render: (data, info, index) => <Input value={data} onChange={(e) => { editFinanceDetail(index, 'bills', e.target.value) }} />

    },
    {
      title: '说明',
      dataIndex: 'comments',
      key: 'comments',
      width: 100,
      render: (data, info, index) => <Input value={data} onChange={(e) => { editFinanceDetail(index, 'comments', e.target.value) }} />
    }
  ]



  const columns = [
    {
      title: '序号',
      dataIndex: '',
      key: '',
      fixed: 'left',
      render: (data, info, index) => (page - 1) * limit + index + 1
    },
    {
      title: '咨询时间',
      dataIndex: 'consultationTime',
      key: 'consultationTime',
      fixed: 'left',
      render: data => <Row>{moment(data).format('YYYY-MM-DD')}</Row>,
      sorter: (a, b) => moment(a.consultationTime).format('YYYY-MM-DD HH:mm:ss') > moment(b.consultationTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (data, group) =>
        <span
          style={{ color: '#1890ff', cursor: 'pointer' }}
          onClick={() => { setCommentVisible(!commentVisible); setCommentsList(group.comments) }}
        >
          {data}
        </span>

    },
    {
      title: '电话号码',
      dataIndex: 'contact',
      key: 'contact',
      fixed: 'left',
      render: data => <Row>{data}</Row>
    },
    {
      title: '意向等级',
      dataIndex: 'indendLevel',
      key: 'indendLevel',

      render: data => <Row>{data}</Row>
    },
    {
      title: '微信号',
      dataIndex: 'wechatCode',
      key: 'wechatCode',
      render: data => data || ''
    },
    {
      title: '是否添加微信',
      dataIndex: 'iswechatCode',
      key: 'iswechatCode',
      render: data => data ? <Tag color="#2db7f5">已经添加</Tag> : <Tag>未添加</Tag>
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',

      render: status => {
        if (status === 'waiting') {
          return (<Row><Tag color="#87d068">待分配</Tag></Row>)
        } else if (status === 'following') {
          return (<Row><Tag color="#f50">跟进中</Tag></Row>)
        } else if (status === 'registered') {
          return (<Row><Tag color="#2db7f5">已报名</Tag></Row>)
        } else if (status === 'reserving') {
          return (<Row><Tag color="#2db7f5">预约上门</Tag></Row>)
        }
        else {
          return (<Row><Tag>未知状态</Tag></Row>)
        }
      }
    },
    {
      title: '预约上门',
      dataIndex: 'reservingDate',
      key: 'reservingDate',

      sorter: (a, b) => a.reservingDate > b.reservingDate,
      render: data => data ? moment(data).format('YYYY-MM-DD') : ''
    },
    {
      title: '最新跟进记录',
      dataIndex: 'comment',
      key: 'comment',
      // ellipsis: true,
      width: '200px',
      render: (data, info) => {
        if (info.comments && info.comments.length > 1) {
          return (
            <Tooltip title={info.comments[info.comments.length - 1].content}>
              <Row style={{
                width: 200,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}>{info.comments[info.comments.length - 1].content}</Row>
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title={data}>
              <Row style={{
                width: 200,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}>{data}</Row>
            </Tooltip>
          )
        }
      }
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex'
    },
    {
      title: '居住地址',
      dataIndex: 'address',
      key: 'address',
      render: data => {
        return (
          <Tooltip title={data}>
            <Row style={{
              width: 120,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>{data}</Row>
          </Tooltip>
        )
      }
    },
    {
      title: '过期时间',
      dataIndex: 'followedExpireDate',
      key: 'followedExpireDate',

      render: data => {
        if (data) {
          if ((Date.parse(data) - Date.now()) / 1000 > 0) {
            const hours = (Date.parse(data) - Date.now()) / 1000 / 60 / 60
            if (hours <= 24) {
              return (parseInt(hours) + '小时')
            } else {
              const day = parseInt(hours / 24)
              const hour = parseInt(hours % 24)
              return (`${day}天${hour}小时`)
            }
          } else {
            return (`已过期`)
          }
        } else {
          return ('')
        }
      },
      sorter: (a, b) => a.followedExpireDate > b.followedExpireDate
    },
    {
      title: '跟进人',
      dataIndex: 'followedUser',
      key: 'followedUser',
      render: (data, info) => info.status === 'waiting' ? '' : data
    },

    window.localStorage.getItem('role') === 'CompanyAdmin' ?
      {
        title: '渠道来源',
        dataIndex: 'source',
        key: 'source'
      }
      :
      {}
    ,
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      fixed: 'right',
      render: (data, group) => {
        if (activeTab === 'public') {
          return (
            <Row>
              <span className={css(styles.operate)} onClick={() => {
                if (group.status === 'following') {
                  message.warn(`该学员已经在跟进中`)
                  return
                } else if (group.status === 'registered') {
                  message.warn(`该学员已经报名`)
                  return
                } else {
                  setLevelVisible(!levelVisible);
                  setEditId(group._id)
                }
              }}>抢学员</span>
              <span className={css(styles.operate)} onClick={() => { openUpdateModal(group) }}>编辑</span>
              <Popconfirm title="确认删除?" okText="确认" cancelText="取消" onConfirm={() => { deleteSingleData(group._id) }}>
                <span className={css(styles.operate)}>删除</span>
              </Popconfirm>
            </Row>
          )
        } else if (activeTab === 'private') {
          return (
            <Row>
              <span className={css(styles.operate)} onClick={() => { showConfirm(group) }}>放入公海</span>
              <span className={css(styles.operate)} onClick={() => { openCreateModal(group) }}>转报名</span>
              <span className={css(styles.operate)} onClick={() => { openUpdateModal(group) }}>编辑</span>

              <Dropdown overlay={
                <Menu>
                  <Menu.Item key="1">
                    {
                      group.reservingDate === ''
                        ? <span className={css(styles.operate)} onClick={() => { setReserveVisible(!reserveVisible); setEditId(group._id) }}>预约上门</span>
                        : <span className={css(styles.operate)} onClick={() => { cancelReserving(group._id) }}>取消预约</span>
                    }
                  </Menu.Item>
                  <Menu.Item>
                    <span className={css(styles.operate)} onClick={() => { submitWechat(!group.iswechatCode, group._id) }}>{group.iswechatCode ? '设置未添加微信' : '设置已添加微信号'}</span>

                  </Menu.Item>

                  <Menu.Item key="4">
                    <span className={css(styles.operate)} >
                      {
                        group.top !== ''
                          ?
                          <Tooltip title="点击取消置顶">
                            {/* <Icon onClick={() => { cancelSticky(group) }} style={{ color: '#fadb12', cursor: 'pointer' }} theme="filled" type="star" /> */}
                            <span onClick={() => { cancelSticky(group) }} style={{ color: '#fadb12', cursor: 'pointer' }}>已置顶</span>
                          </Tooltip>
                          :
                          <Tooltip title="点击置顶该数据">
                            {/* <Icon onClick={() => { stickyIndent(group) }} style={{ color: 'grey', cursor: 'pointer' }} type="star" /> */}
                            <span onClick={() => { stickyIndent(group) }} style={{ color: 'grey', cursor: 'pointer' }}>置顶</span>
                          </Tooltip>
                      }
                    </span>
                  </Menu.Item>
                  <Menu.Divider />

                  <Menu.Item key="5">
                    <span className={css(styles.operate)} onClick={() => { setAddVisible(!addCommentVisible); setNewComment(''); setEditId(group._id) }}>添加跟进记录</span>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <span className={css(styles.operate)} onClick={() => { setCommentVisible(!commentVisible); setCommentsList(group.comments) }}>查看跟进记录</span>
                  </Menu.Item>
                  <Menu.Divider />

                  <Menu.Item key="8">
                    <span className={css(styles.operate)} onClick={() => {
                      if (group.status === 'registered') {
                        message.warn(`该学员已经报名,无需修改意向等级`)
                        return
                      } else {
                        setLevelVisible(!levelVisible);
                        setEditId(group._id)
                      }
                    }}>编辑学员意向等级</span>
                  </Menu.Item>
                </Menu>
              }>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  其他操作 <Icon type="down" />
                </a>
              </Dropdown>






            </Row>
          )
        } else if (activeTab === 'registered') {
          return (
            <span className={css(styles.operate)} onClick={() => { setCommentVisible(!commentVisible); setCommentsList(group.comments) }}>查看跟进记录</span>

          )
        }
      }
    }
  ]


  async function countIndnet() {
    try {
      const result = await axios.get('/student/indend/count')
      console.log(result.data)
      if (result.data.success) {
        setCount([result.data.public, result.data.private, result.data.registered])
      }
    } catch (error) {
      message.error(error.message || error)
    }
  }

  function showConfirm(info) {
    confirm({
      title: '提示',
      content: '确定将该学员放入公海吗?',
      async onOk() {
        try {
          if (info.status === 'registered') {
            message.warn(`该学员已经报名,无法放入公海`)
            return
          } else {
            setLoading(true)
            const result = await axios.post('/student/indend/unfollow', { _id: info._id })
            if (result.data.success) {
              message.success(`学员放入公海成功`)
              const tableData = data.filter(item => item._id !== info._id)
              setData(tableData)
              setLoading(false)
              countIndnet()
            } else {
              message.error(result.data.failedMessage || '学员放入公海失败')
              setLoading(false)
            }
          }

        } catch (error) {
          message.error(error.message || error)
          setLoading(false)

        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  const initPageSize = window.localStorage.getItem('pageSize') ? parseInt(window.localStorage.getItem('pageSize')) : 10
  /**
   * React State
   */
  const store = React.useContext(GlobalContext)
  const [data, setData] = React.useState([])
  const [visible, setVisible] = React.useState(false)
  const [signupVisible, setSignVisible] = React.useState(false)
  const [levelVisible, setLevelVisible] = React.useState(false)
  const [commentVisible, setCommentVisible] = React.useState(false)
  const [addCommentVisible, setAddVisible] = React.useState(false)
  const [newComment, setNewComment] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [modalType, setModalType] = React.useState(1)
  const [editId, setEditId] = React.useState('')
  const [commentsList, setCommentsList] = React.useState([])
  const [filterOption, setFilterOption] = React.useState(filterOptions)
  const [alertMsg, setAlertMsg] = React.useState('初始化提示信息')
  const [activeTab, setActiveTab] = React.useState('public')
  const [count, setCount] = React.useState([])
  const [reserveVisible, setReserveVisible] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const [limit, setLimit] = React.useState(initPageSize)
  const [total, setTotal] = React.useState(0)
  const [wechatVisible, setWechatVisible] = React.useState(false)
  /**
   * 意向学员相关数据
   */
  const [consultationTime, setConsultation] = React.useState(moment())
  const [company, setCompany] = React.useState('')
  const [indendLevel, setIndendLevel] = React.useState('未知')
  const [indendCoach, setIndendCoach] = React.useState('')
  const [indendSignupDate, setIndentSignup] = React.useState(moment())
  const [followUpDays, setFollowUpDays] = React.useState(3)
  /**
   * 业务相关数据的定义
   */
  const [name, setName] = React.useState('')
  const [nation, setNation] = React.useState('中国')
  const [sex, setSex] = React.useState('男')
  const [certificateType, setCerType] = React.useState('身份证')
  const [certificate, setCer] = React.useState('')
  const [birth, setBirth] = React.useState('')
  const [certificateValid, serCerValid] = React.useState('')
  const [contact, setContact] = React.useState('')
  const [code, setCode] = React.useState('')
  const [certificateAddress, serCerAddr] = React.useState('')
  const [liveAddress, setLiveAddress] = React.useState('')
  const [signupSource, setSignupSource] = React.useState('')
  const [signupDate, setSignupDate] = React.useState('')
  const [signupSpot, setSignupSpot] = React.useState('')
  const [introducer, setIntroducer] = React.useState('')
  const [businessType, setBusinessType] = React.useState(businessOptions[0])
  const [primaryCarType, setPriCarType] = React.useState('')
  const [primaryCertificate, setPrimaryCer] = React.useState('')
  const [studentGroup, setStudentGroup] = React.useState('')
  const [carType, setCarType] = React.useState('')
  const [classId, setClass] = React.useState('')
  const [traningPlace, setTraningPlace] = React.useState('')
  const [preCoach, setPreCoach] = React.useState('')
  const [dataComplete, setDataComplete] = React.useState('')
  const [comments, setComments] = React.useState('')
  const [charges, setCharge] = React.useState([])
  const [reservingDate, setReserveDate] = React.useState(moment)
  const [follower, setFollower] = React.useState('')
  const [wechatCode, setWechatCode] = React.useState('')

  /**
   * componentDidMount
   */
  React.useEffect(() => {
    console.log(`componentDidMount`)
    fetchData()
    countIndnet()
  }, [])

  async function followIndentStudent() {
    try {
      if (!editId) {
        throw `请选择对应学员`
      } else {
        const result = await axios.post('/student/indend/follow', { _id: editId, indendLevel })
        if (result.data.success) {
          if (activeTab === 'public') {
            fetchData()
          } else {
            const tableData = data.map(item => {
              if (item._id === editId) {
                item.indendLevel = indendLevel
              }
              return item
            })
            setData(tableData)
          }
          setLevelVisible(!levelVisible)
          countIndnet()
        } else {
          message.error(result.data.failedMessage || '未知错误')
        }
      }
    } catch (error) {
      message.error(error.message || error)
    }
  }
  function indendLevelToDays(level) {
    const num = level === 'A'
      ? 7
      : level === 'B'
        ? 5
        : level === 'C'
          ? 4 / 24
          : level === 'D'
            ? 1 / 24
            : level === '未知'
              ? 0.5 / 24
              : 0.5 / 24
    return num
  }
  async function cancelSticky(info) {
    const result = await axios.get(`/student/indend/top/${info._id}`)
    if (result.data.success) {
      const tableData = [...data]
      tableData.map(item => {
        if (item._id === info._id) {
          item.top = ''
        }
      })
      setData(tableData)
      message.success(`取消置顶数据成功`)
    }
  }

  async function addIndendUser() {
    try {
      if (!name) { throw `请输入学员姓名` }
      if (!phoneNumber.test(contact)) { throw `请输入合法有效的电话号码` }
      const commentsList = [
        {
          date: moment().format('YYYY-MM-DD'),
          content: '创建了这条数据',
          operator: window.localStorage.getItem('displayName')
        }
      ]
      const indend = {
        name, sex, contact, consultationTime,
        address: liveAddress,
        idcard: certificate,
        company,
        source: signupSource,
        indendLevel,
        introducer,
        indendCoach,
        indendSignupDate,
        followUpDays,
        status: 'waiting',
        wechatCode,
        comment: comments,
        comments: commentsList
      }
      if (introducer !== '公海') {
        store.introducers.map(item => {
          if (item.name === introducer) {
            indend.status = 'following'
            indend.followedUserContact = item.contact
            indend.followedUser = item.name
            indend.followedDate = moment().format('YYYY-MM-DD HH:mm:ss')
            indend.followedExpireDate = moment(Date.now() + indendLevelToDays(indendLevel) * 1000 * 60 * 60 * 24).format('YYYY-MM-DD HH:mm:ss')
          }
        })
      }
      const result = await axios.post('/student/indend', indend)
      if (result.data.success) {
        const tabledata = [...data]
        tabledata.push(result.data.data)
        setData(tabledata)
        setVisible(!visible)
        countIndnet()
      } else {
        message.error(result.data.failedMessage || '未知错误')
      }
    } catch (error) {
      message.error(error.message || error)
    }
  }

  async function addOneComment() {
    try {
      const result = await axios.post('/student/indend/comment', { content: newComment, _id: editId })
      if (result.data.success) {
        const tableData = [...data]
        tableData.map(item => {
          if (item._id === editId) { item.comments.push(result.data.data) }
        })
        setData(tableData)
        setAddVisible(!addCommentVisible)
      } else {
        throw `添加跟进记录失败,失败信息:${result.data.failedMessage}`
      }
    } catch (error) {
      message.error(error.message || error)

    }
  }
  /**
   * 增加一条数据
   */
  async function createSingleData() {
    try {
      if (!introducer) { throw `请输入介绍人` }
      if (!signupSpot) { throw `请选择报名地点` }
      if (name === '') { throw `请输入学员姓名` }
      if (nation === '') { throw `请输入学员国籍` }
      if (!certificate) { throw `请输入学员证件号码` }
      // if (!certificateId.test(certificate)) { throw `请输入合法有效的证件号码` }
      if (contact === '') { throw `请输入学员联系电话` }
      if (!phoneNumber.test(contact)) { throw `请输入合法有效的电话号码` }
      if (signupDate === '') { throw `请选择学员报名时间` }
      if (businessType === '') { throw `请选择学员的业务类型` }
      if (classId === '') { throw `请选择学员的班制` }
      if (!follower) { throw `跟踪客服为必须选择` }
      let access = true
      charges.map(item => {
        if (!item.payType) {
          access = false
        }
      })
      if (!access) {
        throw `请选择付款方式`
      }
      if (dataComplete !== true && dataComplete !== false) {
        throw `请选择资料是否齐全`
      }
      const result = await axios.post(`/student/signup`, {
        name,
        nation,
        sex,
        certificateType,
        certificate,
        birth,
        certificateValid,
        contact,
        code,
        avatar: store.avatar,
        certificateAddress,
        liveAddress,
        signupSource,
        signupDate,
        signupSpot,
        introducer,
        businessType,
        businessType,
        primaryCarType,
        primaryCertificate,
        studentGroup,
        carType,
        classId,
        traningPlace,
        preCoach,
        dataComplete,
        comments,
        charges,
        follower
      })
      if (result.data.success) {
        message.success(`学员转报名成功`)
        const result = await axios.post(`/student/indend/sign/`, { _id: editId })
        if (result.data.success) {
          const tableData = data.map(item => {
            if (item._id === editId) {
              item.status = 'registered'
            }
            return item
          })
          setData(tableData)
          countIndnet()
        }
        setSignVisible(!signupVisible)
      } else {
        message.error(result.data.failedMessage || '提交数据异常、未捕获的错误')
      }
    } catch (error) {
      console.log(error)
      message.error(error)
    }
  }



  async function fetchData(page, size, type) {
    try {
      setLoading(true)
      setData([])
      const condition = {}
      const requestType = type || activeTab
      condition.type = requestType
      condition.displayName = window.localStorage.getItem('displayName')
      condition.id = store.userAccount
      console.log(store.condition)
      if (store.condition.filter) {
        condition.filter = store.condition.filter
      }
      if (store.condition.introducer) {
        condition.introducer = store.condition.introducer
      }
      if (store.condition.indendExpire && store.condition.indendExpire !== 'all') {
        condition.expire = store.condition.indendExpire
      }

      condition.page = page || page || 1
      condition.limit = size || limit
      const result = await axios.post('/student/indend/query', condition)
      const tableData = result.data.data
      setData(tableData)
      setTotal(result.data.total)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[ERROR]:')
      console.log(error)
    }

  }

  /**
   * 删除
   */
  async function deleteSingleData(_id) {
    setLoading(true)
    const result = await axios.delete(`/student/indend/${_id}`)
    setLoading(false)
    if (result.data.success) {
      const newData = data.filter(item => item._id !== _id)
      setData(newData)
      countIndnet()
      message.success(`删除数据成功`)
    } else {
      message.error(`删除数据失败`)
    }
  }

  /**
   * 更新数据
   */
  async function updateSingleData() {
    try {
      const result = await axios.put(`/student/indend`, {
        _id: editId,
        name, sex, contact, consultationTime,
        address: liveAddress,
        idcard: certificate,
        company,
        source: signupSource,
        indendLevel,
        introducer,
        indendCoach,
        wechatCode,
        indendSignupDate,
        followUpDays,
        comment: comments
      })
      if (result.data.success) {
        // fetchData()
        const newData = [...data].map(item => {
          if (item._id === editId) {
            item.name = name
            item.sex = sex
            item.contact = contact
            item.consultationTime = consultationTime
            item.address = liveAddress
            item.idcard = certificate
            item.company = company
            item.wechatCode = wechatCode
            item.source = signupSource
            item.indendLevel = indendLevel
            item.introducer = introducer
            item.indendCoach = indendCoach
            item.indendSignupDate = indendSignupDate
            item.followUpDays = followUpDays
            item.comment = comments
            return item
          } else {
            return item
          }
        })
        setData(newData)
        setVisible(!visible)
      } else {
        message.error(`提交数据异常,请联系管理员`)
      }
    } catch (error) {
      message.error(error)
    }
  }


  async function submitWechat(value, id) {
    console.log(value)
    const result = await axios.post('/student/indent/wechat', {
      indentid: id,
      value: value
    })
    console.log(result.data)
    if (result.data.success) {
      message.success(`设置学员微信号成功`)
      const newdata = [...data].map(item => {
        if (item._id === id) {
          item.iswechatCode = value
        }
        return item
      })
      console.log(newdata)
      setData(newdata)
      // setWechatVisible(!wechatVisible)
    } else {
      message.error(`设置学员微信号失败`)
    }
  }

  /**
   * 打开新建对话框
   */
  async function openCreateModal(info) {
    if (info.status === 'registered') {
      message.warn(`该学员已经报名,请勿重复报名`)
      return
    }
    if (store.signupSpots.length === 0 || store.traningPlaces.length === 0 || store.classes.length === 0) {
      message.warn(`需要优先录入报名点、训练场、以及班制等数据,否则无法新增学员`)
      return
    }
    setEditId(info._id)
    setName(info.name)
    setNation('中国')
    setSex('男')
    setCerType(certificateOptions[0])
    setCer(info.idcard)
    setBirth(moment())
    serCerValid(moment())
    setContact(info.contact)
    setCode('')
    serCerAddr('')
    setLiveAddress(info.address)
    setSignupSource(info.source)
    setSignupDate(moment())
    setSignupSpot('')
    setIntroducer(info.introducer)
    setFollower(info.introducer)
    setBusinessType(businessOptions[0])
    setPriCarType(carTypes[0])
    setPrimaryCer('')
    setStudentGroup(store.studentGroups[0].name)
    setCarType(carTypes[0])
    setClass('')
    setTraningPlace('')
    setPreCoach(info.indendCoach)
    setDataComplete('')
    setComments('')
    setCharge([])
    store.setAvatar('')
    setSignVisible(!visible)
    setModalType(1)
  }
  function openCreateIndent() {
    setSignupSource('')
    setName('')
    setSex('男')
    setContact('')
    setCer('')
    setConsultation(moment())
    setLiveAddress('')
    setCompany('')
    setIndendLevel('未知')
    setIntroducer('公海')
    setIndendCoach('')
    setIndentSignup(moment())
    setFollowUpDays(3)
    setComments('')
    setVisible(!visible)
    setModalType(1)
  }
  /**
   * 打开更新数据对话框
   */
  function openUpdateModal(info) {
    setName(info.name)
    setWechatCode(info.wechatCode)

    setSex(info.sex)
    setContact(info.contact)
    setCer(info.idcard)
    setConsultation(moment(info.consultationTime))
    setLiveAddress(info.address)
    setCompany(info.company)
    setSignupSource(info.source)
    setIndendLevel(info.indendLevel)
    setIntroducer(info.introducer)
    setIndendCoach(info.indendCoach)
    setIndentSignup(moment(info.indendSignupDate))
    setFollowUpDays(info.followUpDays)
    setComments(info.comment || '')
    setVisible(!visible)
    setModalType(2)
    setEditId(info._id)
  }

  async function setActiveTabData(value) {
    store.setCondition({})
    if (value === 'public') {
      setFilterOption({
        introducer: true,
        filter: true,
        // indendStatus: true
      })
    } else if (value === 'private') {
      setFilterOption({
        introducer: true,
        filter: true,
        indendStatus: true
      })
    } else if (value === 'registered') {
      setFilterOption({
        introducer: true,
        filter: true,
        // indendStatus: true
      })
    }
    setActiveTab(value)
    setPage(1)
    fetchData(1, limit, value)
  }
  /**
   * 对话框中选择班制后根据班制内的财务信息生成数据
   */
  async function setFinaceDefault(costs) {
    let cost = 0
    costs.map(item => {
      cost = cost + item.cost
    })
    const finance = {
      type: '报名费',
      cost: cost,
      paid: '',
      payType: '',
      offer: 0,
      offerReason: '',
      arrears: 0,
      bills: '',
      comments: ''
    }
    setCharge([finance])
  }

  /**
   * 财务明细中修改各个字段信息
   */
  async function editFinanceDetail(index, type, value) {
    const newFinanceData = charges.map((item, $index) => {
      if ($index === index) {
        item[type] = value
        if (type === 'paid' || type === 'offer') {
          /**
           * 如果修改的是实际缴费信息或者修改的是优惠信息 重新计算欠费金额
           */
          const arrear = item.cost - item.offer - item.paid
          const arrears = arrear > 0 ? arrear : 0
          item.arrears = arrears
        }
      }
      return item
    })
    /**
     * 整理信息
     */
    let allcosts = 0
    let allpaid = 0
    let arrears = 0
    newFinanceData.map(item => {
      allcosts = allcosts + item.cost
      allpaid = allpaid + item.paid
      arrears = arrears + item.arrears
    })
    const message = `一共需要缴纳费用为${allcosts}元,实际缴费 ${allpaid} 元 欠费${arrears} 元`
    console.log(message)
    setAlertMsg(message)
    setCharge(newFinanceData)
  }
  const CommonView = (
    <Row>
      <Row>
        <Col span={20}><QueryBar config={filterOption} /></Col>
        <Col span={4}>
          <Button onClick={() => { fetchData() }} type="primary" icon="search" style={{ marginTop: 10, float: 'left', width: 100 }} >查询</Button>
        </Col>
      </Row>
      <Divider style={{ marginTop: -10 }} />
      {
        activeTab === 'public' ?
          <Row>
            <Button icon='user' type='primary' style={{
              marginTop: -20,
              display: activeTab === 'public' ? '' : 'none'
            }} onClick={() => { openCreateIndent() }}>新增</Button>
          </Row>
          :
          <div></div>
      }
      <Alert message={`数据库中符合条件数据总数:${total}`} type="info" style={{ marginTop: 10 }} showIcon />

      <Table
        // columns={columns.map(item => {
        //   const fun = () => ({ style: { whiteSpace: 'nowrap', width: '200px' } });
        //   item.onHeaderCell = fun;
        //   item.onCell = fun;
        //   return item;
        // })}

        rowClassName={(record, index) => {
          return (record.top !== '' ? css(styles.top) : css(styles.normal))
        }}
        scroll={{ x: 'max-content' }}
        loading={loading}
        pagination={false}
        rowKey={record => record._id}
        dataSource={data}
        columns={columns}
        style={{
          // width : 200000,
          // overflowX: 'scroll',
          marginTop: 10,
        }} />
      <Pagination style={{ marginTop: 10 }}
        showSizeChanger
        showQuickJumper
        pageSizeOptions={['10', '15', '20', '50']}
        pageSize={limit}
        current={page}
        total={total}
        onShowSizeChange={(page, pageSize) => {
          window.localStorage.setItem('pageSize', pageSize)
          setPage(page)
          setLimit(pageSize)
          fetchData(page, pageSize)
        }}
        onChange={(page, pageSize) => {
          setPage(page)
          setLimit(pageSize)
          fetchData(page, pageSize)
        }}
      />
    </Row >
  )


  async function makeAppointment() {
    try {
      const result = await axios.post('/student/indend/reserve', {
        _id: editId,
        reservingDate,
        type: 'reserve'
      })
      if (result.data.success) {
        const table = [...data]
        table.map(item => {
          if (item._id === editId) {
            item.status = 'reserving'
            item.reservingDate = reservingDate
          }
        })
        setData(table)
        setReserveVisible(!reserveVisible)
        message.success(`预约学员上门时间成功`)
      } else {
        message.error(result.data.failedMessage || '预约学员上门失败')

      }
    } catch (error) {
      message.error(error.message || error)
    }
  }

  async function stickyIndent(info) {
    const result = await axios.get(`/student/indend/top/${info._id}`)
    info.top = moment().format('YYYY-MM-DD HH:mm:ss')
    if (result.data.success) {
      const removeSingle = [...data].filter(item => item._id !== info._id)
      const tableData = [info].concat(removeSingle)
      setData(tableData)
      message.success(`置顶该学员数据成功`)
    }
  }

  async function cancelReserving(_id) {
    const result = await axios.post('/student/indend/reserve', {
      _id,
      type: 'cancel'
    })
    if (result.data.success) {
      const table = [...data]
      table.map(item => {
        if (item._id === editId) {
          item.status = 'following'
          item.reservingDate = ''
        }
      })
      setData(table)
      // setReserveVisible(!reserveVisible)
      message.success(`取消预约学员上门时间成功`)
    } else {
      message.error(result.data.failedMessage || '取消预约上门失败')
    }

  }
  /**
   * Render View
   */
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>

        <Tabs animated={false} activeKey={activeTab} onChange={value => setActiveTabData(value)}>
          <TabPane tab={<span><Icon type="database" />公海学员{count.length === 3 ? count[0] : ''}</span>} key="public">
            {CommonView}
          </TabPane>
          <TabPane tab={<span><Icon type="folder" />私海学员{count.length === 3 ? count[1] : ''}</span>} key="private">
            {CommonView}
          </TabPane>
          <TabPane tab={<span><Icon type="ordered-list" />已报名{count.length === 3 ? count[2] : ''}</span>} key="registered">
            {CommonView}
          </TabPane>
        </Tabs>
      </Row>

      <Modal
        title="添加更新记录"
        visible={addCommentVisible}
        onOk={() => { addOneComment() }}
        onCancel={() => { setAddVisible(!addCommentVisible) }}
        closable={true}
      >
        <TextArea rows={4} value={newComment} onChange={e => setNewComment(e.target.value)} placeholder="请输入跟进记录" />
      </Modal>
      <Modal
        title="跟进记录"
        visible={commentVisible}
        onOk={() => { setCommentVisible(!commentVisible) }}
        onCancel={() => { setCommentVisible(!commentVisible) }}
        closable={true}
      >
        <Timeline style={{ maxHeight: 300, overflowY: 'scroll', padding: 10 }}>
          <Alert message="该学员数据从旧数据导入,暂无跟进记录,请添加" style={{ display: commentsList.length === [] ? '' : 'none' }} type="warning" showIcon />
          {
            commentsList.map((item, index) => <Timeline.Item key={index}>{`${item.date} ${item.content} 操作人:${item.operator}`}</Timeline.Item>)
          }
        </Timeline>
      </Modal>
      <Drawer
        title="学员报名"
        visible={signupVisible}
        onClose={() => { setSignVisible(!signupVisible) }}
        closable={true}
        width="85%"
      >
        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Row>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>姓名</Col>
              <Col span={6}>
                <Input placeholder="输入姓名" className={css(styles.modalInput)} value={name} onChange={e => { setName(e.target.value) }} />
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>国籍</Col>
              <Col span={6}>
                <Select value={nation} className={css(styles.modalInput)} onChange={value => { setNation(value) }}>
                  {
                    ['中国', '香港'].map(item => <Option value={item} key={item}>{item}</Option>)
                  }
                </Select>
                {/* <Input placeholder="输入国籍" className={css(styles.modalInput)} value={nation} onChange={e => { setNation(e.target.value) }} /> */}
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>性别</Col>
              <Col span={6}>
                <Select value={sex} className={css(styles.modalInput)} onChange={value => { setSex(value) }}>
                  {sexOptions.map(item => <Option value={item} key={item}>{item}</Option>)}
                </Select>
              </Col>
            </Row>
            <Row className={css(styles.modalRow)}>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>证件类型</Col>
              <Col span={6}>
                <Select value={certificateType} className={css(styles.modalInput)} onChange={value => { setCerType(value) }}>
                  {certificateOptions.map(item => <Option value={item} key={item}>{item}</Option>)}
                </Select>
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>证件号</Col>
              <Col span={6}>
                <Input disabled={modalType == 2 ? true : false} placeholder="输入对应的证件号码" className={css(styles.modalInput)} value={certificate} onChange={e => {
                  setCer(e.target.value)
                  if (certificateId.test(e.target.value)) {
                    console.log(`增则匹配通过、准备计算出生年月日`)
                    const year = e.target.value.substring(6, 10)
                    const month = e.target.value.substring(10, 12)
                    const day = e.target.value.substring(12, 14)
                    const birth = `${year}-${month}-${day}`
                    if (e.target.value[16] % 2 === 0) { setSex('女') } else {
                      setSex('男')
                    }
                    setBirth(moment(birth))
                  }
                }} />
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>出生日期</Col>
              <Col span={6}>
                <DatePicker disabled={true} className={css(styles.modalInput)} value={birth} placeholder="请选择出生日期" onChange={value => { setBirth(value) }} />
              </Col>
            </Row>
            <Row className={css(styles.modalRow)}>
              <Col span={2} className={css(styles.modalRowText)}>截止日期</Col>
              <Col span={6}>
                <DatePicker className={css(styles.modalInput)} value={certificateValid} placeholder="身份证有效期" onChange={value => { serCerValid(value) }} />
              </Col>
              <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>电话</Col>
              <Col span={6}>
                <Input disabled={modalType == 2 ? true : false} placeholder="请输入联系电话" className={css(styles.modalInput)} value={contact} onChange={e => { setContact(e.target.value) }} />
              </Col>
              <Col span={2} className={css(styles.modalRowText)}>编号</Col>
              <Col span={6}>
                <Input placeholder="输入自定义编号" className={css(styles.modalInput)} value={code} onChange={e => { setCode(e.target.value) }} />
              </Col>
            </Row>
          </Col>
          <Col span={1}></Col>
          <Col span={4}><Avatar /></Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={2} className={css(styles.modalRowText)}>身份证地址</Col>
          <Col span={10}>
            <Input placeholder="输入身份证居住地址" className={css(styles.longInput)} value={certificateAddress} onChange={e => { serCerAddr(e.target.value) }} />
          </Col>
          <Col span={2} className={css(styles.modalRowText)}>居住地址</Col>
          <Col span={10}>
            <Input placeholder="请输入居住地址" className={css(styles.longInput)} value={liveAddress} onChange={e => { setLiveAddress(e.target.value) }} />
          </Col>
        </Row>
        <Divider style={{ marginTop: 20 }}></Divider>
        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>招生来源</Col>
            <Col span={6}>
              <Select value={signupSource} className={css(styles.modalInput)} onChange={value => { setSignupSource(value) }}>
                {store.signUpSources.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>报名时间</Col>
            <Col span={6}>
              <DatePicker className={css(styles.modalInput)} placeholder="请输入报名时间" value={signupDate} onChange={value => { setSignupDate(value) }} />
            </Col>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>报名地点</Col>
            <Col span={6}>
              <Select value={signupSpot} className={css(styles.modalInput)} onChange={value => { setSignupSpot(value) }}>
                {store.signupSpots && store.signupSpots.length > 0 && store.signupSpots.map(item => <Option key={item.name}>{item.name}</Option>)}
              </Select>
            </Col>
          </Col>
          <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>介绍人</Col>
          <Col span={4}>
            <Select className={css(styles.modalInput)} value={introducer} onChange={value => { setIntroducer(value) }}>
              {
                store.introducers.map(item => <Option key={item.name}>{item.name}</Option>)
              }
            </Select>
            {/* <Input placeholder="介绍人" className={css(styles.modalInput)} value={introducer} onChange={e => { setIntroducer(e.target.value) }} /> */}
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>业务类型</Col>
            <Col span={6}>
              <Select value={businessType} className={css(styles.modalInput)} onChange={value => { setBusinessType(value) }}>
                {businessOptions.map(item => <Option value={item} key={item}>{item}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}>原驾车型</Col>
            <Col span={6}>
              <Select value={primaryCarType} disabled={businessType !== '增驾' ? true : false} className={css(styles.modalInput)} onChange={value => { setPriCarType(value) }}>
                {carTypes.map(item => <Option value={item} key={item}>{item}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}>原驾证号</Col>
            <Col span={6}>
              <Input placeholder="原驾驶证件号码" disabled={businessType !== '增驾' ? true : false} className={css(styles.modalInput)} value={primaryCertificate} onChange={e => { setPrimaryCer(e.target.value) }} />
            </Col>
          </Col>
          <Col span={2} className={css(styles.modalRowText)}>学员分组</Col>
          <Col span={4}>
            <Select value={studentGroup} className={css(styles.modalInput)} onChange={value => { setStudentGroup(value) }}>
              {store.studentGroups.map(item => <Option key={item.name} value={item.name}>{item.name}</Option>)}
            </Select>
          </Col>
        </Row>

        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>报名车型</Col>
            <Col span={6}>
              <Select value={carType} placeholder="下拉选择报名车型" className={css(styles.modalInput)} onChange={value => { setCarType(value) }}>
                {carTypes.map(item => <Option value={item} key={item}>{item}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>班制</Col>
            <Col span={6}>
              <Select value={classId} className={css(styles.modalInput)} onChange={value => {
                store.classes.map(item => {
                  if (item.name === value) { setClass(item.name); setFinaceDefault(item.costs) }
                })
              }}>
                {store.classes.map(item => <Option key={item.name} value={item.name}>{item.name}</Option>)}
              </Select>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}>预选训练场</Col>
            <Col span={6}>
              <Select value={traningPlace} className={css(styles.modalInput)} onChange={value => { setTraningPlace(value) }}>
                {store.traningPlaces.map(item => <Option key={item.name} value={item.name}>{item.name}</Option>)}
              </Select>
            </Col></Col>
          <Col span={2} className={css(styles.modalRowText)}>预选教练</Col>
          <Col span={4}>
            <Select value={preCoach} className={css(styles.modalInput)} onChange={value => { setPreCoach(value) }}>
              {
                store.coaches.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={18}>
            <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>资料齐全</Col>
            <Col span={6}>
              <Radio.Group className={css(styles.modalInput)} style={{ marginTop: 5.5 }} onChange={e => { setDataComplete(e.target.value) }} value={dataComplete}>
                <Radio value={true}>齐全</Radio>
                <Radio value={false}>不齐全</Radio>
              </Radio.Group>
            </Col>
            <Col span={2} className={css(styles.modalRowText)}>备注</Col>
            <Col span={14}>
              <Input placeholder="补充说明" style={{
                width: '95%',
                marginLeft: '3%'
              }} value={comments} onChange={e => { setComments(e.target.value) }} />
            </Col>
          </Col>
          <Col span={2} className={css(styles.modalRowText)}><span className={css(styles.selectNeed)}>*</span>跟踪客服</Col>
          <Col span={4}>
            <Select value={follower} className={css(styles.modalInput)} onChange={value => { setFollower(value) }}>
              {store.introducers.map(item => <Option key={item.name}>{item.name}</Option>)}
            </Select>
          </Col>
        </Row>
        <Divider>财务信息</Divider>
        <Alert message={alertMsg} type="info" showIcon />
        <Table size="small" className={css(styles.modalRow)}
          columns={columns.map(item => {
            const fun = () => ({ style: { whiteSpace: 'nowrap' } });
            item.onHeaderCell = fun;
            item.onCell = fun;
            return item;
          })}
          scroll={{ x: 'max-content' }}
          dataSource={charges} columns={financeColums} rowKey="type" loading={loading} />
        <div className={css(styles.drawerFooter)}>
          <Button icon="cancel" className={css(styles.marginRight8)} onClick={() => { setVisible(!visible) }}>取消</Button>
          <Button icon="save" onClick={() => { createSingleData() }} type="primary">保存并打印收据</Button>
        </div>
      </Drawer>


      <Modal
        title="意向学员"
        visible={visible}
        width="64%"
        style={{
          marginTop: -50
        }}
        onOk={() => { modalType === 1 ? addIndendUser() : updateSingleData() }}
        onCancel={() => { setVisible(!visible) }}
      >
        <Row>
          <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>姓名</Col>
          <Col span={5}>
            <Input placeholder="输入学员姓名" className={css(styles.modalInput)} value={name} onChange={e => { setName(e.target.value) }} />
          </Col>
          <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>手机号码</Col>
          <Col span={5}>
            <Input placeholder="输入电话号码" className={css(styles.modalInput)} value={contact} onChange={e => { setContact(e.target.value) }} />
          </Col>
          <Col className={css(styles.modalRowText)} span={3}>性别</Col>
          <Col span={5}>
            <Select value={sex} className={css(styles.modalInput)} onChange={value => { setSex(value) }}>
              {sexOptions.map(item => <Option value={item} key={item}>{item}</Option>)}
            </Select>
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>咨询时间</Col>
          <Col span={5}>
            <DatePicker className={css(styles.modalInput)} value={consultationTime} placeholder="咨询时间" onChange={value => { setConsultation(value) }} />
          </Col>
          <Col className={css(styles.modalRowText)} span={3}>居住地址</Col>
          <Col span={5}>
            <Input placeholder="请输入居住地址" className={css(styles.longInput)} value={liveAddress} onChange={e => { setLiveAddress(e.target.value) }} />
          </Col>
          <Col className={css(styles.modalRowText)} span={3}>身份证</Col>
          <Col span={5}>
            <Input placeholder="输入对应的证件号码" className={css(styles.modalInput)} value={certificate} onChange={e => { setCer(e.target.value) }} />
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>

          <Col className={css(styles.modalRowText)} span={3}>学员单位</Col>
          <Col span={5}>
            <Input placeholder="请输入学员单位" className={css(styles.longInput)} value={company} onChange={e => { setCompany(e.target.value) }} />
          </Col>
          <Col className={css(styles.modalRowText)} span={3}><span className={css(styles.selectNeed)}>*</span>招生来源</Col>
          <Col span={5}>
            <Select value={signupSource} className={css(styles.modalInput)} onChange={value => { setSignupSource(value) }}>
              {store.signUpSources.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)}
            </Select>
          </Col>
          <Col className={css(styles.modalRowText)} span={3}>意向级别</Col>
          <Col span={5}>
            <Select value={indendLevel} className={css(styles.modalInput)} onChange={value => { setIndendLevel(value) }}>
              {['未知', 'A', 'B', 'C', 'D'].map(item => <Option value={item} key={item}>{item}</Option>)}
            </Select>
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col className={css(styles.modalRowText)} span={3}>跟进人</Col>
          <Col span={5}>
            <Select className={css(styles.modalInput)} value={introducer} onChange={value => { setIntroducer(value) }}>
              <Option key='公海'>公海</Option>
              {
                store.introducers.map(item => <Option key={item.name}>{item.name}</Option>)
              }
            </Select>
            {/* <Input placeholder="请输入推荐人名称" className={css(styles.longInput)} value={introducer} onChange={e => { setIntroducer(e.target.value) }} /> */}
          </Col>
          <Col className={css(styles.modalRowText)} span={3}>意向教练</Col>
          <Col span={5}>
            <Select value={preCoach} className={css(styles.modalInput)} onChange={value => { setPreCoach(value) }}>
              {
                store.coaches.map(item => <Option value={item.name} key={item.name}>{item.name}</Option>)
              }
            </Select>
          </Col>
          <Col className={css(styles.modalRowText)} span={3}>预计报名时间</Col>
          <Col span={5}>
            <DatePicker className={css(styles.modalInput)} value={indendSignupDate} placeholder="咨询时间" onChange={value => { setIndentSignup(value) }} />

          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>

          <Col className={css(styles.modalRowText)} span={3}>跟进间隔天数</Col>
          <Col span={5}>
            <InputNumber placeholder="请输入跟进间隔时间" className={css(styles.longInput)} value={followUpDays} onChange={value => { setFollowUpDays(value) }} />
          </Col>
          <Col className={css(styles.modalRowText)} span={3}>备注</Col>
          <Col span={5}>
            <Input placeholder="请输入备注信息" className={css(styles.longInput)} value={comments} onChange={e => { setComments(e.target.value) }} />

          </Col>
          <Col className={css(styles.modalRowText)} span={3}>微信号</Col>
          <Col span={5}>
            <Input placeholder='请输入用户微信号' className={css(styles.longInput)} value={wechatCode} onChange={e => setWechatCode(e.target.value)} />
          </Col>




        </Row>
      </Modal>
      <Modal
        title="设置预约上门时间"
        visible={reserveVisible}
        onOk={async () => { makeAppointment() }}
        onCancel={() => { setReserveVisible(!reserveVisible) }}
      >
        <Row>
          <DatePicker value={reservingDate} onChange={value => setReserveDate(value)} />
        </Row>
      </Modal>

      <Modal
        title="设置微信号"
        visible={wechatVisible}
        onOk={async () => { submitWechat() }}
        onCancel={() => { setWechatVisible(!wechatVisible) }}
      >
        <Row>
          <Input placeholder='请输入用户微信号' value={wechatCode} onChange={e => setWechatCode(e.target.value)} />
        </Row>
      </Modal>
      <Modal
        title="设置意向等级"
        visible={levelVisible}
        onOk={() => { followIndentStudent() }}
        onCancel={() => { setLevelVisible(!levelVisible) }}
      >
        <Row>
          <Radio.Group value={indendLevel} buttonStyle="solid" onChange={value => { setIndendLevel(value.target.value) }} style={{ width: '100%' }}>
            <Radio.Button value="A" className={css(styles.level)}>A:7天</Radio.Button>
            <Radio.Button value="B" className={css(styles.level)}>B:5天</Radio.Button>
            <Radio.Button value="C" className={css(styles.level)}>C:2天</Radio.Button>
            <Radio.Button value="D" className={css(styles.level)}>D:8小时</Radio.Button>
          </Radio.Group>
        </Row>
      </Modal>
    </Row>
  )
}


