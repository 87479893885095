import React from 'react'
import Content from '../../../components/content/index'
import axios from '../../../api/axios'
import { GlobalContext } from '../../../store/store'

import { Table, message, Col } from 'antd'
import { Chart, Geom, Axis, Tooltip, Legend, Coord, Label } from 'bizcharts';

export default function () {
  const store = React.useContext(GlobalContext)

  const [data, setData] = React.useState([
    { name: 'IE', value: 56.33 },
    { name: 'Chrome', value: 24.03 },
    { name: 'Firefox', value: 10.38 },
    { name: 'Safari', value: 4.77 },
    { name: 'Opera', value: 0.91 },
    { name: 'Unknown', value: 0.2 },
  ])
  const [columns, setColums] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  async function fetchData() {
    try {
      setLoading(true)
      const result = await axios.post('/statistics/excise')
      console.log(result.data)
      setData(result.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.message || '服务器端未知错误')
    }

  }


  React.useEffect(() => {
    fetchData()
  }, [])
  const chartWidth = store.collapsed ? document.body.clientWidth - 110 : document.body.clientWidth - 250
  // 定义度量
  const cols = {
    genre: { alias: '报名来源' },
    sold: { alias: '报名人数' }
  }


  return (
    <Content>
      <Chart
        data={data}
        forceFit
      >
        <Coord type="theta" />
        <Tooltip
          //triggerOn='none'
          showTitle={true}
          itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
        />
        <Legend
          position="right"
          // offsetY={-window.innerHeight / 2 + 200}
        />
        <Geom
          type="intervalStack"
          position="value"
          color="name"
        >
          <Label content="name" />
        </Geom>
      </Chart>
    </Content>
  )
}