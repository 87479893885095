/*
 * @Author: huangliang
 * @Date: 2020-02-25 14:50:16
 * @LastEditTime: 2020-02-25 17:40:20
 * @LastEditors: huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/Student/StudentImport/upload.js
 * @js files
 */
/*
 * @Author: huangliang
 * @Date: 2020-01-21 14:19:13
 * @LastEditTime: 2020-02-25 14:49:48
 * @LastEditors: huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/Grade/GradeImport/upload.js
 * @js files
 */
import React from 'react'
import { Upload, message, Button, Icon, Modal, Alert, Row } from 'antd'
import { origin } from '../../../api/axios'

export default function gradeImportButton() {
  const [visible, setVisible] = React.useState(false)
  const [fileList, setFileList] = React.useState([])
  const [data, setData] = React.useState({})
  function onChange(info) {
    setFileList([...info.fileList])
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      console.log(info)
      message.success(`${info.file.name} 学员数据导入成功、后台正在处理中、请稍后查询结果`);
      setData(info.file.response.data)
      setVisible(!visible)
      setFileList([])
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} 学员数据导入失败`);
    }
  }
  return (
    <div>
      <Upload
        name='file'
        fileList={fileList}
        action={`${origin}/upload/student`}
        headers={{ authorization: 'Bearer ' + localStorage.getItem('accesstoken') }}
        onChange={info => { onChange(info) }}>
        <Button type="primary" style={{ marginLeft: 10 }}>
          <Icon type="upload" />导入学员
      </Button>
      </Upload>
      <Modal width='60%' visible={visible} onCancel={() => { setVisible(false) }} onOk={() => { setVisible(false) }} title='导入数据结果'>
        <Alert type='success' message={`本次一共导入数据 ${data.total || 0}条 成功${data.total ? data.total - data.failed.length : 0}条`}></Alert>
        <Row style={{maxHeight:'500px',overflowY:'scroll'}}>
          {
            data.failed && data.failed.length > 0 && data.failed.map((item, index) => {
              return (
                <Alert type='error' key={index} message={item} style={{marginTop:5}}></Alert>
              )
            })
          }
        </Row>
      </Modal>
    </div>
  )
}