/*
 * @Author: huangliang
 * @Date: 2019-10-20 18:22:53
 * @LastEditTime : 2019-12-22 17:17:31
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/Student/Signup/config.js
 * @可以输入预定的版权声明、个性签名、空行等
 */


export const filterOptions = {
  status: true,
  signUpDate: true,
  filter: true,
  introducer: true,
  class: true,
  signUpSpot: true
}

export const studentsSchema = (condition) => {
  return ({
    query: `query{
      students(check:true,fiveNoPass:true,${condition}){
        _id
        name,
        nation,
        sex,
        certificateType,
        certificate,
        birth,
        certificateValid,
        contact,
        code,
        avatar,
        certificateAddress,
        liveAddress,
        signupSource,
        signupDate,
        signupSpot,
        introducer,
        businessType,
        businessType,
        primaryCarType,
        primaryCertificate,
        studentGroup,
        carType,
        classId,
        traningPlace,
        preCoach,
        dataComplete,
        comments,
        serialNumber
        fiveTimeNoPass
        fiveTimeNoPassCheck
        fiveTimeNoPassChecker
        fiveTimeNoPassDate
        subjectOnePass
        subjectOne{
          date
          pass
          operator
        }
        subjectTwoPass
        subjectTwo{
          date
          pass
          operator
        }
        subjectThree{
          date
          pass
          operator
        }
        subjectThreePass
        subjectFour{
          date
          pass
          operator
        }
        subjectFourPass
        charges{
          type
          payType
          cost
          paid
          offer
          offerReason
          arrears
          bills
          comments
        }
        createAt
        lastModified
      }
    }`
  })
}


/**
 * 驾驶车辆类型
 */
export const carTypes = ['C1', 'C2', 'A1', 'A2', 'A3', 'B1', 'B2', 'C3', 'C4', 'C5', 'D', 'E', 'F', 'M', 'N', 'P']


/**
 * 性别
 */
export const sexOptions = ['男', '女']


/**
 * 证件类型
 */
export const certificateOptions = ['身份证', '护照', '军官证', '其他']


/**
 * 业务类型
 */
export const businessOptions = ['初领', '增驾', '其他']

/**
 * 费用类型
 */
export const costTypes = ['培训费', '考试费', '代收费', '科目一考试费', '科目二考试费', '科目三考试费', '科目一补考费', '科目二补考费', '科目三补考费', '保险费']

