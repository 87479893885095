/*
 * @Author: huangliang
 * @Date: 2020-02-22 21:10:59
 * @LastEditTime: 2020-02-22 21:28:50
 * @LastEditors: huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/components/content/index.js
 * @js files
 */

import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { GlobalContext } from '../../store/store'
import { Row } from 'antd'

export default function Content(props) {
  //props.n
  //props.config
  const store = React.useContext(GlobalContext)
  return (
    <Row style={{
      marginLeft: store.collapsed ? 95 : 215,
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        {props.children}
      </Row>
    </Row>
  )
} 


const styles = StyleSheet.create({
  content: {
    paddingTop: '5px',
    marginRight: '15px',
  },
  main: {
    width: '100%',
    padding: 20,
    marginTop: 10,
    float: 'left',
    backgroundColor: '#ffffff'
  }
})