/*
 * @Author: huangliang
 * @Date: 2019-12-05 20:59:54
 * @LastEditTime : 2019-12-28 16:43:57
 * @LastEditors  : huangliang
 * @Description: 
 * @FilePath: /00-pri-driving-web/src/pages/HR/Role/index.js
 * @js files
 */
import React from 'react'
import styles from './style'
import { css } from 'aphrodite'
import { Row, Col, Menu, Button, Modal, Input, InputNumber, Icon, message } from 'antd'
import { GlobalContext } from '../../../store/store'
import SelectedTree from './authority'
import axios from '../../../api/axios'


export default function Role() {
  const store = React.useContext(GlobalContext)
  const [modalType, setModalType] = React.useState(1)
  const [visible, setVisible] = React.useState(false)
  const [name, setName] = React.useState('')
  const [sort, setSort] = React.useState('')
  const [roleSelected, setRoleSelected] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  /**
   * 点击角色
   * @param {*} key 
   */
  function selectRole(key) {
    setRoleSelected(key.key)
    store.roles.map(item => {
      if (item._id === key.key) {
        store.setAuth(item.authority)
        setName(item.name)
        setSort(item.sort)
      }
    })
  }


  React.useEffect(() => {
    if (roleSelected === '' && store.roles.length >= 1) {
      setLoading(true)
      const defaultRoles = store.roles[0]
      console.log(`初始化被选中的用户信息`)
      console.log(defaultRoles)
      setName(defaultRoles.name)
      setSort(defaultRoles.sort)
      setRoleSelected(defaultRoles._id)
      store.setAuth(defaultRoles.authority)
      setLoading(false)
    }
  }, [store.roles])


  async function addRole() {
    try {
      const result = await axios.post('/user/role', {
        name,
        sort
      })
      if (result.data.success) {
        const roles = [...store.roles]
        roles.push(result.data.data)
        store.setRoles(roles)
      } else {
        message.error(`添加角色失败 : ${result.data.failedMessage || '未知异常'}`)
      }
      setVisible(!visible)
    } catch (error) {
      message.error(`更新角色权限失败 : ${error.message || error}`)
    }
  }



  async function saveAuthority() {
    try {

      let role = {
        _id: roleSelected,
        name: name,
        sort: sort,
        authority: store.authority
      }
      if (name === '') {
        store.roles.map(item => {
          if (item._id === role._id) {
            role.name = item.name
            role.sort = item.sort
          }
        })
      }

      const result = await axios.put('/user/role', role)
      if (result.data.success) {
        message.success(`更新角色信息成功`)
        const roles = [...store.roles]
        roles.map(item => {
          if (item._id === roleSelected) {
            item.name = role.name
            item.sort = role.sort
            item.authority = role.authority
          }
        })
        store.setRoles(roles)
        setVisible(false)
      } else {
        message.error(`更新角色权限失败 : ${result.data.failedMessage || '未知异常'}`)
      }
    } catch (error) {
      message.error(`更新角色权限失败 : ${error.message || error}`)
    }
  }
  return (
    <Row style={{
      marginLeft: store.collapsed ? 90 : 210,
      paddingBottom: 20
    }} className={css(styles.content)}>
      <Row className={css(styles.main)}>
        <Col span={8}>
          <Row style={{ paddingLeft: 24 }}><h2>角色类型管理</h2></Row>
          <Menu
            style={{ width: '100%', float: 'left' }}
            selectedKeys={roleSelected}
            onClick={key => { selectRole(key) }}
            mode='inline'
            theme='light'
          >
            {store.roles.map(item =>
              <Menu.Item key={item._id}>
                {item.name}
                <Icon onClick={() => { alert('1') }} style={{ float: 'right', lineHeight: '40px' }} type="delete" />
                <Icon onClick={() => {
                  setModalType(2)
                  setVisible(!visible)
                }} style={{ float: 'right', lineHeight: '40px' }} type="edit" />
              </Menu.Item>)
            }
          </Menu>
          <Button icon="plus" type="dashed" onClick={() => {
            setName('')
            setSort('')
            setModalType(1)
            setVisible(!visible)
          }} style={{ width: '90%', marginLeft: '5%' }}>增加角色类型</Button>
        </Col>

        <Col span={16} style={{ paddingLeft: 50 }}>
          <Row style={{ paddingLeft: 24 }}>
            <Col ><h2>角色权限管理</h2></Col>
            <Col span={12}><Button icon="sync" type="primary" onClick={() => { saveAuthority() }}>保存数据</Button></Col>
          </Row>
          <SelectedTree />
        </Col>
      </Row>


      <Modal
        title="角色管理"
        visible={visible}
        onOk={() => { modalType === 1 ? addRole() : saveAuthority() }}
        onCancel={() => { setVisible(!visible) }}
      >
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}>角色名</Col>
          <Col span={18}>
            <Input placeholder="请输入角色名称" className={css(styles.modalInput)} value={name} onChange={e => { setName(e.target.value) }} />
          </Col>
        </Row>
        <Row className={css(styles.modalRow)}>
          <Col span={6} className={css(styles.modalRowText)}>排序</Col>
          <Col span={18}><InputNumber placeholder="排序数字越大排名越靠前" className={css(styles.modalInput)} value={sort} onChange={value => { setSort(value) }} /></Col>
        </Row>

      </Modal>
    </Row>
  )
}

